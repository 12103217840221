import React from "react"

const LineItem = ({ copy }) => {
  if (typeof copy === "string") {
    return <li dangerouslySetInnerHTML={{ __html: copy }} />
  }

  if (Array.isArray(copy)) {
    return (
      <ul className="ul--sublist">
        {copy.map(item => {
          return <li dangerouslySetInnerHTML={{ __html: item }} />
        })}
      </ul>
    )
  }
}

export default LineItem

/* IMPLEMENTATION FOR STANDARD LIST

{["",""].map(item => {
  return <LineItem copy={item} /> 
})}

*/

/* IMPLEMENTATION FOR SUBLIST

{[
  "",
  ["", ""],
].map(item => {
  return <LineItem copy={item} />
})}

*/
