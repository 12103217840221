import React from 'react'


const PemKristinaTranscript = (props) => {

  return (
    <>
    
      <p><strong>Graphics:</strong></p>

      <p><strong>Kristina</strong></p>

      <p><strong>Rhythm InTune logo</strong></p>

      <p><strong>KRISTINA on-camera:</strong></p>

      <p>Hi, my name is Kristina. I have a background in clinical social work, and over the last 25 years I've worked in the areas of hospice and pediatric health, mental health and genetics.</p>

      <p>A couple of things people might find interesting about me would be that I speak French, German, and English, and I have lived in four different countries throughout my life. I enjoy all that nature has to offer, including skiing, hiking, camping, and fishing.</p>

      <p><strong>Graphic:</strong></p>

      <p><strong>My Role</strong></p>

      <p>During the past 17 years I've had the privilege to work with many patients and families, primarily focusing on a family-centered approach to solving healthcare issues, specifically working with people living with genetic conditions.</p>

      <p><strong>Graphic:</strong></p>

      <p><strong>The Best Part of My Job</strong></p>

      <p>You will always be your own best advocate, and I'm here to walk with you on this journey. By partnering with you and your healthcare team, we will work together to overcome whatever challenges are standing in your way. The most rewarding part of my job is hearing from families like yours that what we're doing is making a positive change in how you live as a family.</p>

      <p><strong>Graphic:</strong></p>

      <p className='text-center'><strong>Patient Education Managers (PEMs) are employees of Rhythm Pharmaceuticals, Inc. The information they provide is for educational purposes only and should not be construed as medical advice or healthcare treatment recommendations. The content on this website is not intended to be a substitute for professional medical advice, diagnosis, or treatment. Always seek the advice of your treating physician with any questions regarding your medical care or treatment plan.</strong></p>

      <p className='text-center'><strong>US-SET-2300069 06/2023</strong></p>


    </>
  )
}

export default PemKristinaTranscript
