import * as React from "react"
import Helmet from "react-helmet"

import Layout from "../../components/bbs-layout"
import Seo from "../../components/seo"
import VideoTranscript from "../../components/video-transcript"
import AmbarsJourneyToImcivreeVideo from "../../components/ambars-journey-to-imcivree-video"
import AmbersJourneyVideoEsTranscript from "../../components/template-partials/patient/ambars-journey-to-imcivree-es-transcript"

const AmbersJourneyVideoPage = () => (
  <Layout noNav>
    <Helmet bodyAttributes={{ class: "patient-bbs bbs-resources" }} />
    <Seo title="El viaje de Ambar a IMCIVREE | IMCIVREE® (setmelanotide) injection" />

    <div className="content-block">

      <div className="row og">
        <div className="columns og">
          <h1 className="h1">El viaje de Ambar a IMCIVREE</h1>
          <h2 className="h2">Escuche a Ambar, que vive con BBS, y a su madre Mayra, mientras comparten su viaje a IMCIVREE.</h2>
        </div>
      </div>

      <AmbarsJourneyToImcivreeVideo/>
      <VideoTranscript>
        <AmbersJourneyVideoEsTranscript/>
      </VideoTranscript>

    </div>

  </Layout>
)

export default AmbersJourneyVideoPage