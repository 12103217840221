import React, {useState} from 'react'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";

const VideoTranscript = (props) => {

  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen =(e)=>{
    setIsOpen(!isOpen);
    e.preventDefault();
  }

  const Button =()=>{
    return(
      <>
        <button className={isOpen ? 'io' : ''} onClick={(e)=>toggleOpen(e)}>
          {isOpen &&
            <>
            Close Video Transcript <FontAwesomeIcon icon={faMinus}/>
            </>
          }
          {!isOpen &&
            <>
            Expand Video Transcript <FontAwesomeIcon icon={faPlus}/>
            </>
          }
        </button>
      </>
    )
  }

  return (
    <>
      <div className={"row og " + props.className}>
        <div className={"video-transcript  " + props.className}>
            <Button/>
        </div>
        {isOpen &&
          <div className="columns small-12">
            <div className="video-transcript__wrapper text-left">
              {props.children}
            </div>
            <div className="hr"></div>
          </div>
        }
      </div>
    </>
  )
}

export default VideoTranscript
