import React from 'react';
import SVG from 'react-inlinesvg';
import PropTypes from "prop-types"
import { StaticQuery, graphql } from 'gatsby';
import { Link } from "gatsby"

const MainLogo = props => (
    <StaticQuery
        query={graphql`
            query MainLogoQuery {
                file(relativePath: { eq: "imcivree-logo.svg" }) {
                    publicURL
                }
            }
        `}
        render={data => {
            return (
              <>
                <Link to={props.to} className={props.location ? `main-logo main-logo--`+props.location : `main-logo`}>
                  <SVG src={data.file.publicURL} alt="Imcivree Logo"/>
                  <span className="visually-hidden">Imcivree (setmelanotide) Injection</span>
                </Link>
              </>
            );
        }}
    />
);

MainLogo.defaultProps = {
  location: false
};

MainLogo.propTypes = {
  location: PropTypes.string
};

export default MainLogo;
