import React from 'react'


const PemElisaTranscript = (props) => {

  return (
    <>
      <p><strong>Graphics:</strong></p>

        <p><strong>Elisa</strong></p>

        <p><strong>Rhythm InTune logo</strong></p>

        <p><strong>ELISA on-camera:</strong></p>

        <p>My name is Elisa Herrera, Patient Education Manager with Rhythm InTune; I live in Texas and support Spanish speaking patients all over the US and Puerto Rico.</p>

        <p>I have enjoyed working with patients and families like you throughout my 20 years in healthcare.</p>

        <p>When I’m not working, I enjoy the challenge of keeping small indoor plants alive and spending time with my family.</p>

        <p><strong>Graphic:</strong></p>

<p><strong>My Role</strong></p>

        <p>Whenever I meet a new family, new patients, that speak Spanish and they find out that I speak Spanish, it is very reassuring for them that they are able to reach out and communicate and ask for help because they know they’re going to get someone who understands them and can help them through their journey.</p>

        <p><strong>Graphic:</strong></p>

<p><strong>The Best Part of My Job</strong></p>

        <p>Being able to support patients and families like you is a wonderful privilege. Recently, a family shared photos of the transformation their child has experienced and what treatment has meant for them. That’s the kind of thing that makes it all worth it and it makes me so grateful to be in this role.</p>

        <p><strong>Graphic:</strong></p>

        <p className='text-center'><strong>Patient Education Managers (PEMs) are employees of Rhythm Pharmaceuticals, Inc. The information they provide is for educational purposes only and should not be construed as medical advice or healthcare treatment recommendations. The content on this website is not intended to be a substitute for professional medical advice, diagnosis, or treatment. Always seek the advice of your treating physician with any questions regarding your medical care or treatment plan.</strong></p>

        <p className='text-center'><strong>US-SET-2300066 06/2023</strong></p>


    </>
  )
}

export default PemElisaTranscript
