import React from "react"

import Layout from "../../../components/hcp-ppl-layout"
import Seo from "../../../components/seo"
import Button from "../../../components/button"
import Callout from "../../../components/callout"

import TypesOfObesityChart from "../../../components/types-of-obesity-chart"

const TypesOfObesity = () => {
  return (
    <Layout>
      <Seo
        title="Types Of Obesity | IMCIVREE® (setmelanotide) injection | For HCPs"
        description="Learn about the types of genetic obesity that are caused by rare MC4R pathway diseases. Please see full Prescribing Information and Important Safety Information."
        ogTitle="Types Of Obesity | IMCIVREE® (setmelanotide) injection"
        ogDesc="Learn more about the diseases that IMCIVREE® (setmelanotide) treats. Please see full Prescribing Information for Important Safety Information."
      />
      <div id="hcp-ppl-types-of-obesity" className="content-block">
        <h1 className="h2 h2--sm-mobile lg_mt-2 lg_mb-0">
            Genetic obesity caused by rare MC4R pathway diseases
        </h1>
        <p>
          Obesity is a multifactorial disease with a variety of phenotypes,
            clinical presentations, and treatment responses<sup>1,2</sup>
        </p>

        <div className="types-of-obesity-chart-img-container large">
          <TypesOfObesityChart />
        </div>
      </div>

      <div className="mt-2 lg_mt-0">
        <Callout left className="light-teal left text-left">
          <p>
            Per the 2023 AAP and 2023 OMA guidelines, it is important to treat
            obesity promptly, using the most intensive, comprehensive treatment
            available, including pharmacotherapy when indicated<sup>3,8</sup>
          </p>
        </Callout>
      </div>

      <div className="content-block">
        <div className="text-center mt-1 lg_mt-0">
          <Button
            large
            copy={
              <>
                Learn how to identify <br className="hide-for-medium"/> 
                hyperphagia&nbsp; 
                <br className="hide-for-medium"/>
                 in your patients
              </>
            }
            url="/hcp/ppl/identifying-hyperphagia/"
          />
        </div>
        <p className="footnote mt-3 md_mt-3">
          AAP=American Academy of Pediatrics; BBS=Bardet-Biedl syndrome;
          LEPR=leptin receptor; MC4R=melanocortin-4 receptor; OMA=Obesity
          Medicine Association; PCSK1=proprotein convertase subtilisin/kexin
          type 1; POMC=proopiomelanocortin.
        </p>
        <div className="references md_mt-1 mt-1 md_mb-3 mb-2">
            <b>References:</b>
            <b> 1.</b> Loos RJF et al. <em>Nat Rev Genet</em>. 2022;23(2):120-133. doi:10.1038/s41576-021-00414-z.
            <b> 2.</b> Huvenne H et al. <em>Obes Facts</em>. 2016;9(3):158-173.
            <b> 3.</b> Hampl SE et al. <em>Pediatrics</em>. 2023;151(2):e202206064. doi:10.1542/peds.2022-060640.
            <b> 4.</b> Eneli I et al. <em>Appl Clin Genet</em>. 2019;12:87-93.
            <b> 5.</b> Manara E et al. <em>Ital J Pediatr</em>. 2019;45(1):72.
            <b> 6.</b> Forsythe E et al. <em>Front Pediatr</em>. 2018;6:23. doi:10.3389/fped.2018.00023.
            <b> 7.</b> Vaisse C et al. <em>Cold Spring Harb Perspect Biol</em>. 2017;9(7):a028217.
            <b> 8.</b> Tondt J et al. <em>Obesity Algorithm<sup style={{fontStyle: "normal" }}>®</sup> 2023</em>. Obesity Medicine Association; 2023. Accessed August 11, 2023. https://obesitymedicine.org/obesity-algorithm.
        </div>
      </div>
    </Layout>
  )
}

export default TypesOfObesity
