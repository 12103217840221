import React from 'react'


const QualityOfLifeTranscript = (props) => {

  return (
    <>

      <p><strong>What is IMCIVREE?</strong></p>

      <p>IMCIVREE is a prescription medicine used in adults and children 6 years of age and older with obesity due to Bardet-Biedl syndrome (BBS) to help them lose weight and keep the weight off.</p>

      <p>IMCIVREE is not for use in people with the following conditions because it may not work:</p>

      <ul>
        <li>Other types of obesity not related to BBS or other FDA-approved uses of IMCIVREE, including obesity associated with other genetic conditions and general obesity It is not known if IMCIVREE is safe and effective in children under 6 years of age.</li>
      </ul>

      <br/>

      <p><strong>Do not use IMCIVREE if you have had a serious allergic reaction to it or any of its ingredients. Serious allergic reactions, including anaphylaxis, can happen.</strong></p>

      <p>Few years ago, we've learned about IMCIVREE, a drug that could potentially help Reed and kids like Reed with Bardet-Biedl syndrome lose weight.</p>

      <p>Managing Reed's hunger was so challenging, and it was never ending, and it was something that affected not just Reed, but every aspect of his life and ours. When we first found out that there is potentially a medication that can help not only Reed, but us manage this tough aspect of his diagnosis, we were extremely excited.</p>

      <p>There's a drug that could help me lose weight and... could possibly tell me that my stomach’s full.</p>

      <p>To address just one symptom that I've lived with my entire life of BBS ... Would be incredible.</p>

      <p>Patients and families that are living with BBS often express such gratitude and relief when they are started on IMCIVREE and see that the weight trajectories start to improve and the hyperphagia starts to improve.</p>

      <p>To have a medication that can come in and support correcting that biology that is dysregulated and to see positive outcomes that not only improve the immediate day-to-day quality of life but are also setting up that child for a healthier life over time, I think is very beneficial and hopeful for the patients and families that are on this agent.</p>

      <p>Patients come in, and that's all they can talk about is, “I'm not hungry all the time. I'm not seeking out food all the time. I'm able to do other things.”</p>

      <p>So that shift from the number on the scale to the quality of life, I really love that.</p>

      <p>Since starting IMCIVREE, I lost 23 pounds in the first seven months and my hunger has decreased.</p>

      <p>I never thought there would be a day where I didn't think about food all day long.</p>

      <p>Now I spend my days living life and being busy. Going, spending my days doing activities and not focused on what's going to be the next thing I eat.</p>

      <p>Since Reed has started on IMCIVREE, the main change has been that hunger is not the main player in his life. It's not something that rules his life. It is just a part of his life, which is quite a difference from before.</p>

      <p>And to see him lose weight and not just lose weight, but lose 14 pounds, which for 11-year-old boy is absolutely remarkable, is just so exciting. And it's not just about that, it's just how well he feels, and he's active, and it's just great.</p>

      <p>I get emotional when I talk about Reed's future because it takes me back to when he was eight months old and that appointment where a very bleak future was painted for us and now future's bright.</p>

      <p>What you're doing is making an impact. What you're doing is actually helping someone.</p>

      <p>These are patients that nothing else has ever worked before. And so, to see them so happy about having something that's helping them, it's amazing.</p>

      <p><strong>Important Safety Information</strong></p>

      <p>Before you use IMCIVREE, tell your healthcare provider about all your medical conditions, including if you:</p>

      <ul>
        <li>Have or have had areas of darkened skin, including skin discoloration (hyperpigmentation)</li>
        <li>Have or have had depression, or suicidal thoughts or behavior</li>
        <li>Have kidney problems</li>
        <li>Are pregnant or planning to become pregnant. Losing weight while pregnant may harm your unborn baby. Your healthcare provider may stop your treatment with IMCIVREE if you become pregnant. Tell your healthcare provider if you become pregnant or think you might be pregnant during treatment with IMCIVREE</li>
        <li>Are breastfeeding or plan to breastfeed. It is not known if IMCIVREE passes into your breast milk. You should not breastfeed during treatment with IMCIVREE</li>
      </ul>

      <br/>

      <p><strong>Tell your healthcare provider about all the medicines you take,</strong> including prescription and over-the-counter medicines, vitamins, and herbal supplements.</p>

      <p><strong>See the detailed Instructions for Use</strong> that come with your IMCIVREE to learn how to prepare and inject IMCIVREE, and how to properly throw away (dispose of) used syringes and needles.</p>

      <p><strong>What are the possible side effects of IMCIVREE?</strong></p>

      <p><strong>IMCIVREE may cause serious side effects, including:</strong></p>

      <ul>
        <li><strong>Male and female sexual function problems.</strong> IMCIVREE can cause an erection that happens without any sexual activity in males (spontaneous penile erection) and unwanted sexual reactions (changes in sexual arousal that happen without any sexual activity) in females. If you have an erection lasting longer than 4 hours, get emergency medical help right away</li>
        <li><strong>Depression and suicidal thoughts or actions.</strong> You or a caregiver should call your healthcare provider right away if you have any new or worsening symptoms of depression, suicidal thoughts or behaviors, or any unusual changes in mood or behavior</li>
        <li><strong>Serious allergic reactions.</strong> Stop taking IMCIVREE and get medical help right away if you have any symptoms of a serious allergic reaction including: swelling of your face, lips, tongue, or throat; problems breathing or swallowing; severe rash or itching; fainting or feeling dizzy; rapid heartbeat</li>
        <li><strong>Increased skin pigmentation and darkening of skin lesions (moles or nevi) you already have.</strong> These changes happen because of how IMCIVREE works in the body and will go away when you stop using IMCIVREE. You should have a full body skin exam before starting and during treatment with IMCIVREE to check for skin changes</li>
        <li><strong>Benzyl alcohol toxicity.</strong> Benzyl alcohol is a preservative in IMCIVREE. Benzyl alcohol can cause serious side effects, including death, in premature and low-birth weight infants who have received medicines that contain benzyl alcohol. IMCIVREE should not be used in premature and low-birth weight infants</li>
      </ul>

      <br/>

      <p><strong>The most common side effects of IMCIVREE include</strong> darkening of the skin, injection site reactions, nausea, headache, diarrhea, stomach pain, vomiting, depression, and an erection that happens without any sexual activity in males.</p>

      <p>These are not all the possible side effects of IMCIVREE. Call your doctor for medical advice about side effects. You may report side effects to FDA at 1-800-FDA-1088 or to Rhythm Pharmaceuticals at 1-833-789-6337.</p>

      <p><strong>Please see the full Prescribing Information at IMCIVREE.com.</strong></p>

    </>
  )
}

export default QualityOfLifeTranscript
