import React from 'react'

const LearnMoreAboutPemVideo = (props) => {
  return (
    <>
    <div className="row og">
      <div className={`columns og responsive-video-container ` + props.className}>
        <div class="responsive-embed widescreen">
          <iframe src="https://player.vimeo.com/video/877757628?h=38ed3113fa" width="100%" height="auto" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen title="Learn more about Patient Education Managers"></iframe>
        </div>
      </div>
    </div>
    </>
  )
}

export default LearnMoreAboutPemVideo 