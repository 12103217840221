import React from 'react'


const ManagingAdverseEventsVideoTranscript = (props) => {

  return (
    <>
    
      <p><strong>What is IMCIVREE?</strong></p>

      <p>IMCIVREE is a prescription medicine used in adults and children 6 years of age and older with obesity due to Bardet-Biedl syndrome (BBS) to help them lose weight and keep the weight off.</p>

      <p>IMCIVREE is not for use in people with the following conditions because it may not work:</p>

      <ul>
        <li>Other types of obesity not related to BBS or other FDA-approved uses of IMCIVREE, including obesity associated with other genetic conditions and general obesity</li>
      </ul>
      <br/>

      <p>It is not known if IMCIVREE is safe and effective in children under 6 years of age.</p>

      <p><strong>Do not use IMCIVREE if you have had a serious allergic reaction to it or any of its ingredients. Serious allergic reactions, including anaphylaxis, can happen.</strong></p>

      <p>When I think about starting a patient on IMCIVREE, I like to talk to them about all of the various side effects to expect. I think it's helpful for them to understand what are the most common that occur, and to understand the timeframe in which they might occur, and which ones we anticipate will resolve.</p>

      <p>I think it's so important to explain to patients that we constantly have to weigh the risk and the benefit. But what is so important to remember is that you're weighing the risk not against a risk of having no disease, but the risk of living with the disease and doing nothing. And I think that that is a really important differentiating factor.</p>

      <p>I have found that having these conversations open and honestly with families, regardless of the agent that's being started, can help them know what to expect, how to monitor for it, when to call for assistance, and have a really clear expectation of what their treatment course is going to look like.</p>

      <p>When you bring it to a place where the patient knows they can reach out if they're having side effects, they're not expected to just push through it, there are things that we can do. Or sometimes it's looking at other medications that they may be on that may promote a little nausea or GI distress. And we can kind of work with that.</p>

      <p>So my patients that are on IMCIVREE have done very well with initiation of treatments.</p>

      <p>Most commonly in the patients that I have started on IMCIVREE, I have seen hyperpigmentation, injection site reaction, and some mild nausea and vomiting. These symptoms, outside of the hyperpigmentation, have occurred with the initial titration period and have resolved over time.</p>

      <p>Certainly, in the seminal trials, there have been other reports of other GI-based symptoms, such as vomiting or diarrhea, some reports of headaches and fatigue, some mild behavior changes, and concerns around spontaneous penile erections.</p>

      <p>Certainly, most of the adverse effects are within the first four weeks of initiating the medicine. And then, as you gradually titrate them, if it's better tolerated, then you can gradually titrate to maintenance dose. But the nausea and the vomiting has a titration effect and flattens out as you approach maintenance, and as it's better tolerated by the patient. They typically resolve over time.</p>

      <p>So for the patients that I have that are on IMCIVREE, all of them have experienced hyperpigmentation.</p>

      <p>It seems to be a total-body hyperpigmentation. It could certainly be different for different patients based on their baseline skin tone.</p>

      <p>I've had a kind of a varying degree in how much hyperpigmentation I've seen from patient to patient. I've had one patient that was a little disconcerted because she had dark circles under her eyes at baseline. And so those circles became a little more pronounced.</p>

      <p>But, the hyperpigmentation, I just try to help them understand that that typically should plateau within one to two months of starting therapy, and it's not expected to continue to worsen. I've not had any patients that were willing to stop the medication due to hyperpigmentation.</p>

      <p>I don't see a lot accompanied with vomiting. But I'm very proactive in having the patient talk to me if they're feeling nauseated. So I typically will go ahead and go down on the dose, or you know, do something to help manage that. I do try to make sure they stay hydrated, and other things that you would do for nausea and vomiting.</p>

      <p>IMCIVREE is generally very well tolerated. We've had several years of clinical data.</p>

      <p>As with any medication, IMCIVREE comes with some potential side effects for Reed. The side effects that he has experienced has been darkening of his skin. And, initially at the beginning stages he had some spontaneous erection that we were told just a part of the normal side effects for boys. And those subsided after a few weeks.</p>

      <p>[We] were fortunate that we had access to videos that showed us how to administer injection. There were also booklets that we were sent that showed how to do it step by step, and that was very helpful for us.</p>

      <p>I was nervous that it was an injection because I hate needles.</p>

      <p>It was just getting through the first one or two and then realizing it wasn't nearly as bad as I thought it was going to be.</p>

      <p>My morning routine is, I get up, I shower, I take my morning meds. And IMCIVREE has become part of my morning medication routine. Side effects that I had from IMCIVREE were nausea, stomach pain, skin darkening, and injection site reactions. And now I have, on occasion, injection site reactions.</p>

      <p>Reed is a champion when it comes to injections and even more so because he's not a fan of anything medical related. So, we have decided that we would be very strategic. We would give Reed injections first thing in the morning. And, then after a few days it just became a routine and now I can stroll downstairs and give him a shot before I have my first cup of coffee. So it's a second nature now.</p>

      <p>The Rhythm team provides great educational materials to think through what these potential side effect profiles are that can help educate families and provide them with resources.</p>

      <p><strong>Important Safety Information</strong></p>

      <p>Before you use IMCIVREE, tell your healthcare provider about all your medical conditions, including if you:</p>

      <ul>
        <li>Have or have had areas of darkened skin, including skin discoloration (hyperpigmentation)</li>
        <li>Have or have had depression, or suicidal thoughts or behavior</li>
        <li>Have kidney problems</li>
        <li>Are pregnant or planning to become pregnant. Losing weight while pregnant may harm your unborn baby. Your healthcare provider may stop your treatment with IMCIVREE if you become pregnant. Tell your healthcare provider if you become pregnant or think you might be pregnant during treatment with IMCIVREE</li>
        <li>Are breastfeeding or plan to breastfeed. It is not known if IMCIVREE passes into your breast milk. You should not breastfeed during treatment with IMCIVREE</li>
      </ul>
      <br/>

      <p><strong>Tell your healthcare provider about all the medicines you take,</strong> including prescription and over-the-counter medicines, vitamins, and herbal supplements.</p>

      <p><strong>See the detailed Instructions for Use</strong> that come with your IMCIVREE to learn how to prepare and inject IMCIVREE, and how to properly throw away (dispose of) used syringes and needles.</p>

      <p><strong>What are the possible side effects of IMCIVREE?</strong></p>

      <p><strong>IMCIVREE may cause serious side effects, including:</strong></p>

      <ul>
        <li><strong>Male and female sexual function problems.</strong> IMCIVREE can cause an erection that happens without any sexual activity in males (spontaneous penile erection) and unwanted sexual reactions (changes in sexual arousal that happen without any sexual activity) in females. If you have an erection lasting longer than 4 hours, get emergency medical help right away</li>
        <li><strong>Depression and suicidal thoughts or actions.</strong> You or a caregiver should call your healthcare provider right away if you have any new or worsening symptoms of depression, suicidal thoughts or behaviors, or any unusual changes in mood or behavior</li>
        <li><strong>Serious allergic reactions.</strong> Stop taking IMCIVREE and get medical help right away if you have any symptoms of a serious allergic reaction including: swelling of your face, lips, tongue, or throat; problems breathing or swallowing; severe rash or itching; fainting or feeling dizzy; rapid heartbeat</li>
        <li><strong>Increased skin pigmentation and darkening of skin lesions (moles or nevi) you already have.</strong> These changes happen because of how IMCIVREE works in the body and will go away when you stop using IMCIVREE. You should have a full body skin exam before starting and during treatment with IMCIVREE to check for skin changes</li>
        <li><strong>Benzyl alcohol toxicity.</strong> Benzyl alcohol is a preservative in IMCIVREE. Benzyl alcohol can cause serious side effects, including death, in premature and low-birth weight infants who have received medicines that contain benzyl alcohol. IMCIVREE should not be used in premature and low-birth weight infants</li>
      </ul>
      <br/>

      <p><strong>The most common side effects of IMCIVREE include</strong> darkening of the skin, injection site reactions, nausea, headache, diarrhea, stomach pain, vomiting, depression, and an erection that happens without any sexual activity in males.</p>

      <p>These are not all the possible side effects of IMCIVREE. Call your doctor for medical advice about side effects. You may report side effects to FDA at 1-800-FDA-1088 or to Rhythm Pharmaceuticals at 1-833-789-6337.</p>

      <p><strong>Please see the full Prescribing Information at IMCIVREE.com.</strong></p>


    </>
  )
}

export default ManagingAdverseEventsVideoTranscript
