import React from 'react'

const hangingRef = ({ symbol, isAsterisk, copy, mb0, mt1 }) => {
  return (
    <p className={`hangingRef ${mb0 ? "mb-0" : ""} ${mt1 ? "md_mt-1" : ""}`}>
      <span className={`${isAsterisk ? "isAsterisk" : "isDagger"}`}>{symbol}</span>{copy}
    </p>
  )
}

export default hangingRef
