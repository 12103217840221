import React from "react"


const ManagingAdverseEventsVideo = props => {
  return (
    <>
    <div className="row og">
      <div className={`columns og responsive-video-container ` + props.className}>
        <div class="responsive-embed widescreen">
          <iframe src="https://player.vimeo.com/video/876112049?h=2c6ad64d04" width="100%" height="auto" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen title="Managing adverse events and injection training"></iframe>
        </div>
      </div>
    </div>
    </>
  )
}

export default ManagingAdverseEventsVideo

