import React, { useEffect } from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import AccessibilityText from "../components/accessibility-text"

const Accessibility = () => {
  let lastKnownScrollPosition = 0
  let ticking = false

  function animate(scrollPos) {
    if (scrollPos > 25) {
      document.querySelector(`.main-logo--header`).classList.add("scrolled");
    } else {
      document.querySelector(`.main-logo--header`).classList.remove("scrolled");
    }
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      document.addEventListener("scroll", e => {
        lastKnownScrollPosition = window.scrollY

        if (!ticking) {
          window.requestAnimationFrame(() => {
            animate(lastKnownScrollPosition)
            ticking = false
          })
          ticking = true
        }
      })
    }
  }, []);
  return (
    <Layout noNav>
      <Seo title="Accessibility Statement | IMCIVREE® (setmelanotide) injection" />
      <AccessibilityText/>
    </Layout>
  )
}

export default Accessibility
