import React from 'react'


const PemJulietTranscript = (props) => {

  return (
    <>
      <p><strong>Graphics:</strong></p>

<p><strong>Juliet</strong></p>

<p><strong>Rhythm InTune logo</strong></p>

<p><strong>JULIET on-camera:</strong></p>

<p>Hi, my name is Juliet and I live with my family outside of Boston.</p>

<p>I grew up in a small beach town where most people come to spend their vacation. I worked in a fish market and I would filet fish, shuck lobsters, but I really loved growing up at the beach and it's truly a part of my soul.</p>

<p><strong>Graphic:</strong></p>

<p><strong>My Role</strong></p>

<p>For 20 years I have been providing support and education to patients and families. The first part of my career I worked as a clinical dietician in a hospital. And now, since 2014, I've had the privilege to work with patients who have rare genetic diseases.</p>

<p><strong>Graphic:</strong></p>

<p><strong>The Best Part of My Job</strong></p>

<p>The most rewarding part of my job is making a connection to patients and families like yours and providing you with the information that you're looking for. Patients and families living with rare diseases may look for answers for years, and that can be all-consuming and also isolating.</p>

<p>Arming you with information about the disease and treatment. Helping you connect to others and letting you know that you're not alone is really why I love to do what I do.</p>

<p><strong>Graphic:</strong></p>

<p className='text-center'><strong>Patient Education Managers (PEMs) are employees of Rhythm Pharmaceuticals, Inc. The information they provide is for educational purposes only and should not be construed as medical advice or healthcare treatment recommendations. The content on this website is not intended to be a substitute for professional medical advice, diagnosis, or treatment. Always seek the advice of your treating physician with any questions regarding your medical care or treatment plan.</strong></p>

<p className='text-center'><strong>US-SET-2300068 06/2023</strong></p>


    </>
  )
}

export default PemJulietTranscript
