import React from "react"

import IconPhone from "../images/icons/icon-phone.svg"
import IconEmail from "../images/icons/icon-email.svg"

const ContactInfo = () => {
  return (
    <div className="contact-info row medium-unstack">
      <div className="column og large-4 medium-6 small-12 large-text-left text-center">
        <div className="row">
          <div className="columns small-12 lg_pl-1 large-text-left text-center">
            <img
              src={IconPhone}
              alt="Rhythm InTune phone"
              style={{ paddingLeft: ".5rem" }}
            />
          </div>
          <div className="columns small-12 large-text-left text-center">
            <a href="tel:18552060815" className="underline">
              <strong>1-855-206-0815</strong>
            </a>
          </div>
        </div>
      </div>
      <div className="column large-6 medium-6 small-12 text-center">
        <div className="row">
          <div className="columns small-12">
            <img src={IconEmail} alt="Rhythm InTune email" />
          </div>
          <div className="columns small-12">
            <a href="mailto:patientsupport@rhythmtx.com">
              <strong className="underline">patientsupport@rhythmtx.com</strong>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactInfo
