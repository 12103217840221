import * as React from "react"

import Layout from "../../components/bbs-layout"
import Seo from "../../components/seo"

import HearAboutRealExperiencesVideoBbs from "../../components/hear-about-real-experiences-video-bbs"
import VideoTranscript from "../../components/video-transcript"
import HearAboutRealExperiencesTranscriptBbs from "../../components/template-partials/patient/hear-about-real-experiences-transcript-bbs"

const HearAboutRealExperiencesWithInsatiableHunger = () => (
  <Layout noNav>

    <Seo title="Hear about real experiences with insatiable hunger | IMCIVREE® (setmelanotide) injection" />

    <div className="content-block">

      <div className="row og lg_mt-2 mt-2">
        <div className="columns og">
          <h2 className="h1">Hear about real experiences with insatiable hunger</h2>
          <h3 className="h2">Learn how families and their healthcare teams are managing it together.</h3>
        </div>
      </div>

      <HearAboutRealExperiencesVideoBbs/>
      <VideoTranscript>
        <HearAboutRealExperiencesTranscriptBbs/>
      </VideoTranscript>


    </div>

  </Layout>
)

export default HearAboutRealExperiencesWithInsatiableHunger