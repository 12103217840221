import React from 'react'
import vpl from "../images/video-placeholder.jpg";

const MoaVideo = (props) => {
  return (
    <>

    <div className="row og" style={{ marginBottom: `21px` }}>
      <div className={`columns  responsive-video-container ` + props.className}>
        <iframe src="https://player.vimeo.com/video/748955304?h=85cc230b4f&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" width="1920" height="1080" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen title="BBS_IMCIVREE_final_12-September-2022"></iframe>
        <img src={vpl} width="100%" height="auto" alt="Understanding BBS video placeholder"/>
      </div>
    </div>
    
    </>
  )
}

export default MoaVideo
