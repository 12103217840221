import React from 'react'

const HearAboutRealExperiencesVideobbs = (props) => {
  return (
    <>
    <div className="row og">
      <div className={`columns og responsive-video-container ` + props.className}>
        <div class="responsive-embed widescreen">
          <iframe src="https://player.vimeo.com/video/894269418?h=580db8c46f" width="100%" height="auto" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen title="Hear about real experiences with insatiable hunger"></iframe>
        </div>
      </div>
    </div>
    </>
  )
}

export default HearAboutRealExperiencesVideobbs

