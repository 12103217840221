import React from "react"

const NewModal = props => {
  const { children, heading, open, onClose, className, style, headingStyle } =
    props

  React.useEffect(() => {
    if (open) document.body.style.overflow = "hidden"
    else document.body.style.overflow = "scroll"

    return () => {
      document.body.style.overflow = "scroll"
    }
  }, [open])

  if (!open) return null

  return (
    <div className={`new-modal ${className}`} style={style}>
      <div className="new-modal-content">
        <div className="new-modal-header">
          <p style={headingStyle}>&nbsp;{heading}</p>

          <span className="close" onClick={onClose}>
            &times;
          </span>
        </div>

        {children}
      </div>
    </div>
  )
}

export default NewModal
