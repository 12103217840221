import React from "react"
import CalloutBox from "./callout-box"
import Accordion from "../components/template-partials/global-components/accordion/accordion"
import LineItem from "./template-partials/global-components/list-item"


const TypesOfObesityChart = props => {
  return (
    <div id="types-of-obesity-chart">
      <div className="row">
        <div className="columns small-12 large-3">
          <CalloutBox
            headerStyles={{ backgroundColor: "#70737B" }}
            contentStyles={{ backgroundColor: "#EFEFF0" }}
            headerCopy="General obesity<sup>2</sup>"
          >
            {/* <ul className="ul--standard">
              {[
                "Can be due to environmental factors and/or multiple common genes interacting together (polygenic)",
                "Can occur at any age, including later in life",
              ].map(item => (
                <LineItem copy={item} />
              ))}
            </ul> */}

            <ul className="ul--standard">
              <LineItem copy={"Can be due to:"} />
                <ul className="ul--standard circle obesity-chart">
                  <LineItem copy={" Environmental factors"} />
                  <LineItem copy={" Multiple common genes interacting together (polygenic)"} />
                </ul>
              <LineItem copy={"Can occur at any age, including later in life"} />
            </ul>
          </CalloutBox>
        </div>
        <div className="divider"></div>
        <div className="columns small-12 large-expand">
          <div className="row" style={{ gap: `50px` }}>
            <div className="columns small-12">
              <div className="CalloutBox__container">
                <div className="CalloutBox__header-wrapper color-teal-bg" >
                  <h3 className="CalloutBox__header">
                    Genetic obesity caused by <br class="hide-for-medium" />rare MC4R pathway&nbsp;
                    <span className="d-inline-block position-relative">
                      <Accordion className="plain plain--1">
                        <Accordion.Item isOpen={false}>
                          <Accordion.PlainDrawer></Accordion.PlainDrawer>
                          <Accordion.Expanded>
                            <div className="columns">
                              <h3 style={{ textAlign: `left` }}>
                                MC4R pathway
                              </h3>
                              <ul className="">
                                <li style={{ textAlign: `left` }}>
                                  The MC4R, or melanocortin-4 receptor, pathway
                                  is located within the hypothalamus and is
                                  involved in the regulation of satiety and
                                  energy utilization<sup>4</sup>
                                </li>
                              </ul>
                            </div>
                          </Accordion.Expanded>
                        </Accordion.Item>
                      </Accordion>
                    </span>
                    &nbsp;diseases<sup>2-4</sup>
                  </h3>
                </div>
                <div className="CalloutBox__content-wrapper">
                  <ul className="ul--standard">
                    {[
                      "Due to rare genetic variants/impairment of gene expression or function",
                      "Marked by <b>hyperphagia</b> and <b>early-onset</b>, <b>severe obesity</b>, both of which may develop as early as a few months old, but often appear in early childhood",
                      "These patients may benefit from targeted treatment for their specific disease",
                    ].map(item => (
                      <LineItem copy={item} />
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div
              className="row"
              style={{ gap: `50px` }}
              id="types-of-obesity-sub-boxes"
            >
              <div className="columns small-12 large-expand">
                <CalloutBox
                  headerStyles={{
                    backgroundColor: "#78BCC2",
                  }}
                  headerCopy="Monogenic obesity<sup>1</sup><br/>(POMC, PCSK1, LEPR deficiencies)"
                  tetherLine={true}
                >
                  <ul className="ul--standard">
                    {[
                      "Rare and early-onset, severe obesity associated with endocrine disorders",
                      "Mainly due to variants in the MC4R pathway involved in food intake regulation",
                    ].map(item => (
                      <LineItem copy={item} />
                    ))}
                  </ul>
                </CalloutBox>
              </div>
              <div className="columns small-12 large-expand">
                <CalloutBox
                  headerStyles={{
                    backgroundColor: "#78BCC2",
                  }}
                  headerCopy="Syndromic obesity (BBS)<sup>5-7</sup>"
                  tetherLine={true}
                >
                  <ul className="ul--standard">
                    {[
                      "Severe obesity associated with additional phenotypes (ie, dysmorphic features, organ-specific developmental abnormalities)",
                    ].map(item => (
                      <LineItem copy={item} />
                    ))}
                  </ul>
                </CalloutBox>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TypesOfObesityChart
