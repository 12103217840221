import * as React from "react"

import Layout from "../../components/bbs-layout"
import Seo from "../../components/seo"

import AdverseEventsVideo from "../../components/adverse-events-video"
import VideoTranscript from "../../components/video-transcript"
import AdverseEventsTranscript from "../../components/template-partials/patient/adverse-events-transcript.js"

const ManagingPossibleSideEffectsWithImcivree = () => (
  <Layout noNav>

    <Seo title="Managing possible side effects with IMCIVREE | IMCIVREE® (setmelanotide) injection" />

    <div className="content-block">

      <div className="row og lg_mt-2 mt-2">
        <div className="columns og">
          <h2 className="h1">Managing possible side effects with IMCIVREE</h2>
          <h3 className="h2">Hear from healthcare providers who have experience managing their patients' side effects</h3>
        </div>
      </div>

      <AdverseEventsVideo/>
      <VideoTranscript>
        <AdverseEventsTranscript/>
      </VideoTranscript>


    </div>

  </Layout>
)

export default ManagingPossibleSideEffectsWithImcivree