import React from "react"

import Layout from "../../../components/hcp-bbs-layout"
import Seo from "../../../components/seo"
import Button from "../../../components/button"
import Modal from "../../../components/modal"
import HangingRef from "../../../components/hanging-ref"

import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import SideEffect1Img from "../../../images/side-effect-hcp-bbs-1.png"
import SideEffect2Img from "../../../images/side-effect-hcp-bbs-2.png"
import SideEffect3Img from "../../../images/side-effect-hcp-bbs-3.png"
import SideEffect4Img from "../../../images/side-effect-hcp-bbs-4.png"

import SideEffect1ImgMobile from "../../../images/side-effect-hcp-bbs-1-mobile.png"
import SideEffect2ImgMobile from "../../../images/side-effect-hcp-bbs-2-mobile.png"
import SideEffect3ImgMobile from "../../../images/side-effect-hcp-bbs-3-mobile.png"
import SideEffect4ImgMobile from "../../../images/side-effect-hcp-bbs-4-mobile.png"

import ManagingAdverseEventsVideo from "../../../components/managing-adverse-events-video"
import VideoTranscript from "../../../components/video-transcript"
import ManagingAdverseEventsVideoTranscript from "../../../components/template-partials/hcp/bbs/managing-adverse-events-video-transcript"



const SafetyProfile = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  return (
    <Layout>
      <Seo
        title="Safety Profile | IMCIVREE® (setmelanotide) injection | For HCPs"
        description="See the safety and tolerability profile of IMCIVREE® (setmelanotide) in BBS. Please see full Prescribing Information for Important Safety Information."
        ogTitle="Safety Profile | IMCIVREE® (setmelanotide) injection"
        ogDesc="See the safety and tolerability profile of IMCIVREE® (setmelanotide). Please see full Prescribing Information for Important Safety Information."
      />
      <div className="content-block" id="hcp-bbs-safety-profile">
        <h1 className="h1 lg_mt-1">
        IMCIVREE has a well-established safety and tolerability profile<sup>1,2</sup>
        </h1>
        <h2 className="h2 text-center">
          Adverse reactions occurring in 2 or more IMCIVREE-treated patients
          (n=43)<sup>1</sup>*
        </h2>

        <table cellSpacing="0" cellPadding="0" id="table-hcp-bbs-safety-profile">
          <tr>
            <td className="color-neon-green-bg-light table-header-cell no-border-bottom">
              &nbsp;
            </td>
            <td className="color-teal-bg text-center table-header-cell no-border-bottom">
              <strong>(%)</strong>
            </td>
          </tr>
          <tr>
            <td>
              Hyperpigmentation disorders<sup>&dagger;</sup>
            </td>
            <td className="text-center border-left-teal color-teal-light-bg">
              <strong>63</strong>
            </td>
          </tr>
          <tr>
            <td>
              Injection site reactions<sup>&Dagger;</sup>
            </td>
            <td className="text-center border-left-teal color-teal-light-bg">
              <strong>51</strong>
            </td>
          </tr>
          <tr>
            <td>Nausea</td>
            <td className="text-center border-left-teal color-teal-light-bg">
              <strong>26</strong>
            </td>
          </tr>
          <tr>
            <td>
              Spontaneous penile erection<sup>&sect;</sup>
            </td>
            <td className="text-center border-left-teal color-teal-light-bg">
              <strong>25</strong>
            </td>
          </tr>
          <tr>
            <td>Vomiting</td>
            <td className="text-center border-left-teal color-teal-light-bg">
              <strong>19</strong>
            </td>
          </tr>
          <tr>
            <td>Diarrhea</td>
            <td className="text-center border-left-teal color-teal-light-bg">
              <strong>14</strong>
            </td>
          </tr>
          <tr>
            <td>Headache</td>
            <td className="text-center border-left-teal color-teal-light-bg">
              <strong>7</strong>
            </td>
          </tr>
          <tr>
            <td>Skin striae</td>
            <td className="text-center border-left-teal color-teal-light-bg">
              <strong>7</strong>
            </td>
          </tr>
          <tr>
            <td>Aggression</td>
            <td className="text-center border-left-teal color-teal-light-bg">
              <strong>5</strong>
            </td>
          </tr>
          <tr>
            <td>Fatigue</td>
            <td className="text-center border-left-teal color-teal-light-bg">
              <strong>5</strong>
            </td>
          </tr>
        </table>

        <div className="mt-2 mb-2 lg_mt-3">
          <HangingRef
            isAsterisk
            mb0
            symbol="*"
            copy={<>43 patients were treated with at least 1 dose of IMCIVREE; 1 patient initially randomized to placebo withdrew from the study prior to receiving IMCIVREE and is not included.<sup>1</sup></>}
          />
          <HangingRef
            mb0
            symbol={
              <>
                <sup>&dagger;</sup>
              </>
            }
            copy={<>Includes skin hyperpigmentation, hair color changes, melanoderma, and melanocytic nevus.<sup>1</sup></>}
          />
          <HangingRef
            mb0
            symbol={
              <>
                <sup>&Dagger;</sup>
              </>
            }
            copy={<>Includes injection site erythema, pruritus, induration, pain, bruising, edema, reaction, hemorrhage, irritation, and mass.<sup>1</sup></>}
          />
          <HangingRef
            mb0
            symbol={
              <>
                <sup>&sect;</sup>
              </>
            }
            copy={<>n=20 male patients.<sup>1</sup></>}
          />
        </div>
        <h2 className="md_mt-2 md_mb-0" style={{ fontSize: "1.375rem", fontWeight: "500" }}>
        AEs were generally mild and transient<sup>1-5</sup>
        </h2>
        <ul className="neon-green-bullets">
          <li>
            Reported incidences of nausea and vomiting primarily occurred within the first month of treatment, then sharply declined after 4 weeks
            <ul className="jost-semibold ">
              <li className="is-dash">
                Nearly all nausea or vomiting events were mild and none were serious
              </li>
              <li className="is-dash">
                Reported incidences of nausea and vomiting typically resolved within a few days in patients in IMCIVREE clinical trials
              </li>
              <li className="is-dash">
              Nausea and vomiting should be managed by dose titration and standard care
              </li>
            </ul>
          </li>
          <li>
            No serious AEs related to IMCIVREE were reported in the BBS trial      
          </li>
          <li>
            The safety of IMCIVREE has been evaluated in &gt;700 patients over ~10
            years of clinical trials
          </li>
        </ul>

        
        <div className="row">
          <div className="columns small-12 large-5 md_mt-1 mt-2 mb-2">
            <Modal id="warnings-and-precautions" />
          </div>
        </div>
        <h1 className="h1 lg_mt-3">
        Hyperpigmentation was a common side effect
          <sup>1,3</sup>
        </h1>
        <div className="row the-carousel">
          <div className="column">
            <Slider {...settings}>
              <div className="carousel-items">
                <p className="text-center fw-bold mb-2 md_mb-2">
                  Examples of hyperpigmentation<sup></sup>
                </p>
                <img src={SideEffect1Img} className="hide-for-small-only" alt="Examples of hyperpigmentation (before and during
treatment with IMCIVREE)" />
                <img src={SideEffect1ImgMobile} className="show-for-small-only" alt="Examples of hyperpigmentation (before and during
treatment with IMCIVREE)" />
              </div>
              <div className="carousel-items">
                <p className="text-center fw-bold mb-2 md_mb-2">
                  Examples of hyperpigmentation<sup></sup>
                </p>
                <img src={SideEffect2Img} className="hide-for-small-only" alt="Examples of hyperpigmentation (before and during
treatment with IMCIVREE)" />
                <img src={SideEffect2ImgMobile} className="show-for-small-only" alt="Examples of hyperpigmentation (before and during
treatment with IMCIVREE)" />
              </div>
              <div className="carousel-items">
                <p className="text-center fw-bold mb-2 md_mb-2">
                  Examples of hyperpigmentation<sup>6</sup>
                </p>
                <img src={SideEffect3Img} className="hide-for-small-only" alt="Examples of hyperpigmentation (before and during
treatment with IMCIVREE)" />
                <img src={SideEffect3ImgMobile} className="show-for-small-only" alt="Examples of hyperpigmentation (before and during
treatment with IMCIVREE)" />
              </div>
              <div className="carousel-items">
                <p className="text-center fw-bold mb-2 md_mb-2">
                  Examples of hyperpigmentation<sup>6</sup>
                </p>
                <img src={SideEffect4Img} className="hide-for-small-only" alt="Examples of hyperpigmentation (before and during
treatment with IMCIVREE)" />
                <img src={SideEffect4ImgMobile} className="show-for-small-only" alt="Examples of hyperpigmentation (before and during
treatment with IMCIVREE)" />
              </div>
            </Slider>
          </div>
        </div>
        <div className="row pt-3">
          <div className="column lg_pt-3">

            <ul className="neon-green-bullets">
              <li className="jost-reg">IMCIVREE is an MC4R agonist, but has some residual activity at the MC1R which commonly leads to hyperpigmentation</li>
              <li className="jost-reg">The activation of the MC1 receptor leads to accumulation of melanin which leads to hyperpigmentation</li>
              <li className="jost-reg">The degree of hyperpigmentation is highly variable</li>
              <li className="jost-reg">In a clinical trial, measures of hyperpigmentation increased throughout the dose escalation period, and plateaued in the initial months of treatment</li>
              <li className="jost-reg">Hyperpigmentation is reversible upon treatment discontinuation</li>
              <li className="jost-reg">If hyperpigmentation is a concern, assess patient response to treatment to optimize tolerability and efficacy as you would with other adverse events</li>

            </ul>
          </div>
        </div>

        <h1 className="h1 mt-2 lg_mt-3">Managing adverse events with IMCIVREE</h1>
        <p className="h2">Hear physicians, patients, and caregivers share their experiences with IMCIVREE</p>

        <ManagingAdverseEventsVideo />
        <VideoTranscript>
          <ManagingAdverseEventsVideoTranscript />
        </VideoTranscript>

        <div className="text-center lg_mt-2 mt-2">
          <Button
            copy="See dosing and administration"
            url="/hcp/bbs/dosing-administration/"
          />
        </div>

        <p className="footnote mt-2 lg_mt-2">
          AE=adverse event; MC4R=melanocortin-4 receptor; MC1R=melanocortin-1 receptor
        </p>
        <p className="references mt-1 lg_mt-1 lg_mb-2">
          <b>References: 1.</b> IMCIVREE [prescribing information]. Boston, MA. Rhythm Pharmaceuticals, Inc. <b>2.</b> Argente J et al. The Pediatric Endocrine Society Annual Meeting. Poster 155. April 28-May 1, 2022. <b>3.</b> Haqq AM et al. <em>Lancet Diabetes Endocrinol.</em> 2022;10(12):859-868. doi:10.1016/S2213-8587(22)00277-7. Supplemental appendix available at: https://www.thelancet.com/journals/landia/article/PIIS2213-8587(22)00277-7/fulltext. <b>4.</b> Data on file. Rhythm Pharmaceuticals, Inc. Boston, MA. <b>5.</b> U.S. National Library of Medicine. Identifier: NCT02431442. ClinicalTrials.gov. Accessed August 9, 2023. 
        </p>
      </div>

    </Layout>
  )
}

export default SafetyProfile
