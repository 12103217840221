import * as React from "react"

import Layout from "../../../components/hcp-ppl-layout"
import Seo from "../../../components/seo"
import Button from "../../../components/button"
import Callout from "../../../components/callout"

import HcpPplPrescribingDownload from "../../../images/prescribing-download.svg"
import HcpPplPrescribingFollow from "../../../images/prescribing-follow.svg"
import HcpPplPrescribingSubmit from "../../../images/prescribing-submit.svg"
import IconNumberOne from "../../../images/icon-number-1.svg"
import IconNumberTwo from "../../../images/icon-number-2.svg"
import IconNumberThree from "../../../images/icon-number-3.svg"

const PrescribingImcivree = () => (
  <Layout>
    <Seo
      title="Prescribing IMCIVREE | IMCIVREE® (setmelanotide) injection | For HCPs"
      description="Learn how to get your patients started on IMCIVREE® (setmelanotide). Please see full Prescribing Information for Important Safety Information."
      ogTitle="Prescribing IMCIVREE | IMCIVREE® (setmelanotide) injection"
      ogDesc="Learn how to get your patients started on IMCIVREE® (setmelanotide). Please see full Prescribing Information for Important Safety Information."
    />
    <div className="content-block">
      <h1 className="h1">Getting your patient started on IMCIVREE</h1>
      <h2 className="h2">3 simple steps to initiate treatment</h2>

      <div className="row og">
        <div className="columns og small-12 medium-1 medium-offset-0 text-center align-self-top">
          <img src={HcpPplPrescribingDownload} className="mb-2" alt="Download IMCIVREE start form"/>
        </div>
        <div className="columns og small-3 medium-1 text-center align-self-top px-0">
          <img src={IconNumberOne} alt="1" />
        </div>
        <div className="columns og small-8 medium-9 align-self-middle pt-1 lg_pt-1">
          <strong>Download the <a href="/Start_Form.pdf" target="_blank" className="textUnderlined fw-black">IMCIVREE Prescription Start Form</a> to start prescribing</strong><br/>
          <ul className="neon-green-bullets">
            <li>The preferred method of starting IMCIVREE is via our Prescription Start Form</li>
            <li>This form serves as your patient’s prescription and provides an opportunity for your patient to enroll in Rhythm InTune, a support program from Rhythm Pharmaceuticals</li>
            <li>If you prefer to e-subscribe, please look to <strong>PANTHERx Rare Pharmacy</strong></li>
          </ul>
        </div>
      </div>

      <div className="row og lg_mt-2 mt-2">
        <div className="columns og small-12 medium-1 medium-offset-0 text-center align-self-middle">
          <img src={HcpPplPrescribingFollow} className="mb-2" alt="Instructions to Complete Form"/>
        </div>
        <div className="columns og small-3 medium-1 text-center align-self-middle px-0">
          <img src={IconNumberTwo} alt="2" />
        </div>
        <div className="columns og small-8 medium-8 align-self-middle">
          Follow the instructions to complete the form
        </div>
      </div>

      <div className="row og lg_mt-2 mt-2 mb-1">
        <div className="columns og small-12 medium-1 medium-offset-0 text-center align-self-middle">
          <img src={HcpPplPrescribingSubmit} className="mb-2" alt="Submit via Fax"/>
        </div>
        <div className="columns og small-3 medium-1 text-center px-0">
          <img src={IconNumberThree} alt="3" />
        </div>
        <div className="columns og small-8 medium-8 align-self-middle">
          Submit all pages of the completed form via fax to <a className="textUnderlined word-wrap fw-black" href="tel:+1-877-805-0130">1-877-805-0130</a> or email <a href="mailto:patientsupport@rhythmtx.com" className="textUnderlined word-wrap fw-black">patientsupport@rhythmtx.com</a>
        </div>
      </div>
    </div>

    <Callout
      left
      className="light-teal left text-left"
    >
      <p>
        IMCIVREE is only available through PANTHERx Rare Pharmacy
      </p>
    </Callout>

    <div className="content-block">
      <div>
        <p>
          Financial support may be available to eligible patients for whom
          IMCIVREE treatment is indicated. For questions on IMCIVREE or how to
          start a patient, call Rhythm InTune at&nbsp;
          <a href="tel:+18552060815">1-855-206-0815</a> Monday&ndash;Friday, 8
          <span className="small-cap">&nbsp;am</span> to 8
          <span className="small-cap">&nbsp;pm</span> ET.
        </p>
      </div>
      <div className="mb-2 md_mb-2"></div>

      <div className="text-center">
        <Button
          textLeft
          copy="Learn about a personalized support program for your patients"
          url="/hcp/ppl/patient-support/"
        />
      </div>

      <div className="lg_mt-4 mt-4 lg_mb-2">
        <p className="h1">FDA-APPROVED GENETIC TEST</p>
        <p>
          IMCIVREE is indicated for chronic weight management in patients aged
          6+ with obesity due to POMC, PCSK1, or LEPR deficiency confirmed by an
          FDA-approved test. These diseases are biallelic, signifying that
          variants are present in both copies of the gene, and therefore include
          both homozygous and compound heterozygous forms.<sup>1,2</sup>
        </p>
      </div>
      <div className="text-center">
        <Button
          textLeft
          copy="Find more information on this FDA-approved test"
          target="_blank"
          type="external"
          url="https://www.preventiongenetics.com/sponsoredTesting/Rhythm/CDX"
        />
      </div>
      <p className="footnote mt-3 md_mt-3">LEPR=leptin receptor; PCSK1=proprotein convertase subtilisin/kexin type 1; POMC=proopiomelanocortin.</p>
      <p className="references footnote mt-1 md_mt-1">
        <b>References:</b> <b>1.</b> IMCIVREE [prescribing information]. Boston, MA. Rhythm Pharmaceuticals, Inc.
        <b> 2.</b> Eneli I et al. <i>Appl Clin Genet</i>. 2019;12:87-93.
      </p>
    </div>
  </Layout>
)

export default PrescribingImcivree
