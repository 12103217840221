import React, {useEffect} from "react"

const ScrollToISIDiv = () => {
  useEffect(() => {
	const hash = window.location.hash
	if (hash === "#isi") {
	  const isiDiv = document.getElementById("isi-wrapper")
	  if (isiDiv) {
		const offset = 140
		const topPosition = isiDiv.offsetTop - offset
		window.scrollTo({
		  top: topPosition,
		  behavior: "smooth",
		})
	  }
	}
  }, [])

  return null
}

export default ScrollToISIDiv