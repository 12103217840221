import React from "react"
import Quote from "./quote"

const PatientCaregiverQuote = ({ order, imgSrc, copy, signoff, alt }) => {
  return (
    <div
      className="row mt-2 md_mt-2"
      style={{
        gap: `40px`,
        flexDirection: `${order === "reverse" ? "row-reverse" : ""}`,
        justifyContent: `center`,
        alignItems: `center`,
      }}
    >
      <div className="columns small-12 large-3">
        <div
          style={{
            width: `300px`,
            height: `300px`,
            border: `5px solid #91D400`,
            borderRadius: `50%`,
            boxShadow: `0 4px 4px 0 rgba(0,0,0,0.25)`,
            margin: `auto`,
            overflow: `hidden`,
          }}
        >
          <img
            src={imgSrc}
            alt={alt}
            style={{ width: `290px`, height: `290px` }}
          />
        </div>
      </div>
      <div className="columns small-12 large-7">
        <Quote copy={copy} signoff={signoff} />
      </div>
    </div>
  )
}

export default PatientCaregiverQuote
