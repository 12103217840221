import * as React from "react"
import { Link } from "gatsby"

import Layout from "../../../components/hcp-ppl-layout"
import Seo from "../../../components/seo"
import Button from "../../../components/button"
import IconArticle from "../../../components/icon-article"

import TreatmentBrochure from "../../../images/icons/article/treatment-brochure.png"
import ImcivreeGuide from "../../../images/icons/article/imcivree-guide.png"

import TrainingVideo from "../../../images/icons/article/training-video.png"
import PlayBtn from "../../../images/icons/icon-play.svg"
import ConsentForm from "../../../images/icons/article/pt-consent-form.png"

import LearnMoreAboutPemThumbnail from "../../../images/icons/article/learn-more-about-pem-thumbnail.png"

const PatientResources = () => (
  <Layout pageId="patient-resources">
    <Seo
      title="Patient Resources | IMCIVREE® (setmelanotide) injection | For HCPs"
      description="View patient resources for IMCIVREE® (setmelanotide), including guides, training video and start form. Please see full Prescribing Information for Important Safety Information."
      ogTitle="Patient Resources | IMCIVREE® (setmelanotide) injection"
      ogDesc="View patient resources for IMCIVREE® (setmelanotide), including guides, training video and start form. Please see full Prescribing Information for Important Safety Information."
    />

    <div className="content-block hcp-ppl-resources">
      <h1 className="h1 mt-0 md_mt-2 pr-1 md_pr-1">
        Contact a representative to speak about IMCIVREE
      </h1>
      <Button copy="Contact a representative" url="/hcp/ppl/sign-up/" />
      <p className="h1 mt-2 md_mt-3">
        We are committed to providing support to you and your patients
      </p>
      <h2 className="h2">
        Additional resources are available on the IMCIVREE{" "}
        <Link to="/ppl/helpful-resources/" className="underline">
          patient website
        </Link>
      </h2>
      <IconArticle
        title="IMCIVREE Prescription Start Form"
        icon={ConsentForm}
        iconAlt="IMCIVREE Prescription Start Form"
        btnCopy="Download (English)"
        btnCopy2="Download (Spanish)"
        file="/Start_Form.pdf"
        file2="/Spanish_Version_IMCIVREE_Start_Form.pdf"
        gray
      >
        <p className="mb-0">
          Serves as your patient’s prescription and provides an opportunity for
          your patient to enroll in Rhythm InTune, a support program from Rhythm
          Pharmaceuticals.
        </p>
        <p>
          Note: The preferred method of starting IMCIVREE is via our
          Prescription Start Form. If you would prefer to e-prescribe,{" "}
          <strong>please be sure to select PANTHERx Rare Pharmacy</strong>.
        </p>
      </IconArticle>
      <IconArticle
        textBold
        title="Beginning Treatment Brochure"
        icon={TreatmentBrochure}
        iconAlt="IMCIVREE Beginning Treatment Brochure"
        btnCopy="Download"
        teal
        file="/IMCIVREE_PPL_Beginning_Treatment_Patient_Brochure.pdf"
      >
        <p className="small-text-center">
          Provides helpful information about what to expect during treatment.
        </p>
      </IconArticle>

      <IconArticle
        textBold
        title="How to Give IMCIVREE Guide"
        icon={ImcivreeGuide}
        iconAlt="IMCIVREE injection guide"
        btnCopy="Download (English)"
        btnCopy2="Download (Spanish)"
        file="/How_To_Give_IMCIVREE_Guide.pdf"
        file2="/How_To_Give_IMCIVREE_Guide_(Spanish).pdf"
        gray
      >
        <p>
          Outlines the steps patients need to take to understand their dose,
          safely inject IMCIVREE, and find support when needed.
        </p>
      </IconArticle>

      <h2 id="informational-videos" className="h1 mt-2 md_mt-2">
        Informational videos
      </h2>
      <div className="row md_mt-2">

        <div className="columns og small-12 large-4 informational-video-single md_mb-2 mb-2 lg_pr-0">
          <div className="card">
            <figure>
              <img src={TrainingVideo} alt="IMCIVREE Injection Video" />
            </figure>
            <div className="card-body">
              <h4 className="h4 jost-semibold mt-1 md_mt-2">
                Injection Training Video
              </h4>
              <p>
                Demonstrates how to inject and store IMCIVREE.
              </p>
            </div>
            <Link to="/hcp/ppl/taking-imcivree/" className="global-button color-gray row align-middle">
              <div className="column">Watch the video</div>
              <div className="column shrink">
                <img src={PlayBtn} />
              </div>
            </Link>
            <Link
              to="/hcp/ppl/injection-training-es/"
              className="global-button color-gray row align-middle mt-1 md_mt-2"
            >
              <div className="column">Watch the video (Spanish)</div>
              <div className="column shrink">
                <img src={PlayBtn} />
              </div>
            </Link>
          </div>
        </div>


        <div className="columns og small-12 large-4 informational-video-single md_mb-2 mb-2 lg_pr-0">
          <div className="card">
            <figure>
              <img src={LearnMoreAboutPemThumbnail} alt="Learn more about Patient Education Managers" />
            </figure>
            <div className="card-body">
              <h4 className="h4 jost-semibold mt-1 md_mt-2">
                Learn more about Patient Education Managers
              </h4>
              <p>
                See how they can provide optimal support for patients in your
                practice and help them start and stay on therapy.
              </p>
            </div>
            <Link to="/hcp/ppl/learn-more-about-patient-education-managers" className="global-button color-gray row align-middle">
              <div className="column">Watch the video</div>
              <div className="column shrink">
                <img src={PlayBtn} />
              </div>
            </Link>
            {/* <Link
              to="/"
              className="global-button color-gray row align-middle mt-2 md_mt-2"
            >
              <div className="column">Watch the video (Spanish)</div>
              <div className="column shrink">
                <img src={PlayBtn} />
              </div>
            </Link> */}
          </div>
        </div>
      </div>

      <div className="text-center lg_mt-3 mt-3">
        <Button
          textCenter
          copy="Frequently asked questions"
          url="/hcp/ppl/faq/"
        />
      </div>
    </div>
  </Layout>
)

export default PatientResources
