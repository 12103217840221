import React from 'react'
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";

const DropDownListsNoLink = ({  copy }) => {
  return (

    <span>
    {copy}
   
    </span>


  )
}

export default DropDownListsNoLink