import React from "react"
import RightArrow from "../images/green-arrow-right.svg"
import DownloadIcon from "../images/icons/icon-download-neongreen.svg"

const HeroCTAbutton = ({ icon, alt, copy, url, gray, type }) => {
  return (
    <a
      href={url}
      className={`hero_cta_button ${gray && "gray"}`}
      target="_blank"
      rel="noreferrer"
    >
      <img src={icon} alt={alt} />
      <span style={{ justifySelf: "start" }}>{copy}</span>
      {type == "download" ? (
        <img src={DownloadIcon} alt="Download" />
      ) : (
        <img src={RightArrow} alt="Right Arrow" />
      )}
    </a>
  )
}

export default HeroCTAbutton
