function getQueryString() {
    const searchURL = typeof window !== "undefined" ? window.location.search : "";
    const urlSearchParams = new URLSearchParams(searchURL);
    const params = Object.fromEntries(urlSearchParams.entries());
    return params
}

function getURL(){
    //return window.location.href;
    return typeof window !== "undefined" ? 'https://imcivree.com'+window.location.pathname+window.location.search : "";
}

function writeSession(sessionValue){
    if (sessionValue){
        window.sessionStorage['myUTM'] = sessionValue;
    } 
}

export function getUTM(){
    //check to be sure
    utmCheck()
    let readValue = "";
    
    if (typeof window !== "undefined"){
        if (window.sessionStorage.getItem('myUTM') != null){
            readValue = window.sessionStorage['myUTM']
            console.log('Session UTM found!')
            //console.log(encodeURI(readValue))
        }
    }
    return readValue;
}

export function utmCheck(){
    const queryObject = getQueryString()
    if (queryObject["utm_source"] != null){
        console.log('Live UTM found!');
        //write session
        writeSession(getURL());
    }
}