import React from "react"

const CalloutCircular = ({ className, children }, props) => {
  return (
    <div className="global-callout-circular-2-container" style={props.style}>
      <div className={`global-callout-circular-2 row text-center ${className}`}>
        {children}
      </div>
    </div>
  )
}

export default CalloutCircular
