import * as React from "react"

import Layout from "../../components/bbs-layout"
import Seo from "../../components/seo"
import Button from "../../components/button"

import VideoTranscript from "../../components/video-transcript"
import MoaVideo from "../../components/moa-video"
import MoaVideoTranscript from "../../components/template-partials/patient/moa-video-transcript"

import HowWorksSvgDesktop from "../../images/patient-bbs-how-imcivree-works-desktop.svg"
import HowWorksSvgMobile from "../../images/patient-bbs-how-imcivree-works-mobile.svg"

const HowImcivreeWorks = () => (
  <Layout>
    <Seo
      title="How IMCIVREE Works | IMCIVREE® (setmelanotide) injection"
      description="Learn how IMCIVREE® (setmelanotide) targets the MC4R pathway in patients with BBS. Please see full Prescribing Information and Important Safety Information."
      ogTitle="How IMCIVREE Works | IMCIVREE® (setmelanotide) injection"
      ogDesc="Learn how IMCIVREE® (setmelanotide) works to treat patients. Please see full Prescribing Information and Important Safety Information."
    />
    <div className="content-block">
      <div className="row og">
        <div className="columns large-10 og">
        <h1 className="h1">How does IMCIVREE work?</h1>
        <h2 className="h2">IMCIVREE is designed to re-establish function to the "road" (MC4R pathway) so trucks can deliver messages to the brain that the body is satisfied</h2>
        </div>
      </div>

      <div className="row og safety-profile-chart-img-container">
        <div className="columns og">
          <div>
            <img src={HowWorksSvgDesktop} alt="MC4R pathway on IMCIVREE" className="show-for-large" />
            <img src={HowWorksSvgMobile} alt="MC4R pathway on IMCIVREE" className="hide-for-large" />
          </div>
        </div>
      </div>

      <div className="row og lg_mt-1 mt-1">
        <div className="columns og large-12">
        <p className="md_mt-1 mt-1">Think of IMCIVREE as a traffic officer. When the traffic light is broken, the traffic officer directs trucks on the road (MC4R pathway), allowing them to deliver messages to the brain that the body is satisfied and doesn't need more food.</p>
        <p>This is how IMCIVREE works to help the pathway work properly.</p>
        </div>
      </div>

      <div className="row og lg_mt-3 mt-2">
        <div className="columns og">
          <h3 className="h1">A closer look at how IMCIVREE works in the brain</h3>
          <h4 className="h2">See how IMCIVREE targets an impaired MC4R pathway, a root cause of hunger and obesity in BBS</h4>
        </div>
      </div>

      <MoaVideo/>
      <VideoTranscript>
        <MoaVideoTranscript/>
      </VideoTranscript>

      <div className="text-center lg_mt-3 mt-3">
        <Button large copy="See how IMCIVREE was studied" url="/bbs/studied/" />
      </div>
    </div>
  </Layout>
)

export default HowImcivreeWorks
