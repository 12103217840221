import * as React from "react"

import Layout from "../../components/ppl-layout"
import Seo from "../../components/seo"

import InjectionTrainingPatientPplVideo from "../../components/injection-training-video"
import VideoTranscript from "../../components/video-transcript"
import InjectionTrainingPatientPplVideoTranscript from "../../components/template-partials/patient/how-to-inject-transcript-ppl"


const InjectionTrainingPatientPpl = () => (
  <Layout noNav>

    <Seo title="Injection Training Video | IMCIVREE® (setmelanotide) injection" />

    <div className="content-block">

      <div className="row og">
        <div className="columns og">
          <h1 className="h1">Injection Training Video</h1>
          <h2 className="h2">This video demonstrates how to inject and store your medicine.</h2>
        </div>
      </div>

      <InjectionTrainingPatientPplVideo />
        <VideoTranscript>
          <InjectionTrainingPatientPplVideoTranscript />
        </VideoTranscript>

    </div>

  </Layout>
)

export default InjectionTrainingPatientPpl