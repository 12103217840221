import React from 'react'

const InjectionTraingEsVideo = (props) => {
  return (
    <>
    <div className="row og">
      <div className={`columns og responsive-video-container ` + props.className}>
        <div class="responsive-embed widescreen">
          <iframe src="https://player.vimeo.com/video/882346831?h=0f822ff24b" width="100%" height="auto" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen title="FPO Video"></iframe>
        </div>
      </div>
    </div>
    </>
  )
}

export default InjectionTraingEsVideo