import React, {} from "react"

import Layout from "../../../components/hcp-bbs-layout"
import Seo from "../../../components/seo"
import Button from "../../../components/button"
import Tabs from "../../../components/template-partials/global-components/tabs/tabs"
import Accordion from "../../../components/template-partials/global-components/accordion/accordion"

import ImageFunctional from "../../../images/hcp/bbs/hcp-bbs-functional-mc4r.png"
import ImageFunctionalMobile from "../../../images/hcp/bbs/hcp-bbs-functional-mc4r-mobile.png"
import ImageMc4rNeuron from "../../../images/hcp/ppl/hcp-ppl-moa-neuron.png"
import ImageMc4rNeuronMobile from "../../../images/hcp/ppl/hcp-ppl-moa-neuron-mobile.png"
import ImageMoaImpaired from "../../../images/hcp/bbs/hcp-bbs-mod-impaired.png"
import ImageMoaImpairedMobile from "../../../images/hcp/bbs/hcp-bbs-mod-impaired-mobile.png"
import ImageGreenArrow from "../../../images/hcp/ppl/moa-green-arrow.png"
import ImageredArrow from "../../../images/hcp/ppl/moa-red-arrow.png"
import ImageBlueArrow from "../../../images/hcp/bbs/moa-blue-arrow.png"

const Moa = () => {

  return (
    <Layout>
      <Seo
        title="Mechanism of Action | IMCIVREE® (setmelanotide) injection | For HCPs"
        description="See how the MC4R pathway in the hypothalamus is a key neuronal pathway in regulating hunger, caloric intake, and energy expenditure. Please see full Prescribing Information for Important Safety Information."
        ogTitle="Mechanism of Action | IMCIVREE® (setmelanotide) injection"
        ogDesc="Learn about the mechanism of action for IMCIVREE® (setmelanotide). Please see full Prescribing Information for Important Safety Information."
      />

      <div className="content-block" id="hcp-bbs-mechanism">

        <h1 className="h1 h1--sm-mobile lg_mt-1 md_mb-3">The MC4R pathway in the hypothalamus is a key neuronal pathway in regulating hunger, caloric intake, and energy expenditure<sup>1</sup></h1>

        <div className="row og">
          <div className="columns og">
            <Tabs className=" tabs--hcp">
              <Tabs.Pane name={<>Functional MC4R pathway activity<sup>1-4</sup></>} key="1">
                <div className="row og md_mb-1 lg_mb-1">
                  <div className="column og small-12 px-0">
                    <ul className="mc4r-pathway-list ml-0 md_ml-0">
                      <li>The <b className="color-neon-blue">BBSome</b> 

                      <span className="d-inline-block position-relative">
                        <Accordion className="plain plain--1 open-right pop-1">
                          <Accordion.Item isOpen={false}>
                            <Accordion.PlainDrawer></Accordion.PlainDrawer>
                            <Accordion.Expanded>
                              <div className="column">
                                <h3 className="text-left">BBSome</h3>
                                <ul>
                                  <li className="text-left w-100">
                                    A complex of proteins formed by a host of BBS genes<sup>5</sup>
                                  </li>
                                </ul>
                              </div>
                            </Accordion.Expanded>
                          </Accordion.Item>
                        </Accordion>
                      </span> 
                       plays a central role in <b className="color-neon-blue">cilia</b> 
                      
                      <span className="d-inline-block position-relative">
                        <Accordion className="plain plain--1 open-right pop-2">
                          <Accordion.Item isOpen={false}>
                            <Accordion.PlainDrawer></Accordion.PlainDrawer>
                            <Accordion.Expanded>
                              <div className="column">
                                <h3 className="text-left">Cilia</h3>
                                <ul>
                                  <li className="text-left w-100">
                                  A structure that plays an important role in cell signaling, protein trafficking, tissue formation, cell motility, and homeostasis
                                  </li>
                                </ul>
                              </div>
                            </Accordion.Expanded>
                          </Accordion.Item>
                        </Accordion>
                      </span>
                       function, including trafficking of the <b className="color-neon-blue">leptin</b> 
                      
                      <span className="d-inline-block position-relative">
                        <Accordion className="plain plain--1 open-right pop-3">
                          <Accordion.Item isOpen={false}>
                            <Accordion.PlainDrawer></Accordion.PlainDrawer>
                            <Accordion.Expanded>
                              <div className="column">
                                <h3 className="text-left">Leptin</h3>
                                <ul>
                                  <li className="text-left w-100">
                                  A satiety hormone
                                  </li>
                                </ul>
                              </div>
                            </Accordion.Expanded>
                          </Accordion.Item>
                        </Accordion>
                      </span>
                       receptors (LEPR) to allow leptin activation and satiety signaling.</li>
                      <li className="arrow-item"> 
                        <img src={ImageBlueArrow} alt="" />
                      </li>
                      <li>Leptin binding to LEPR triggers a signaling cascade, including secretion of alpha-melanocyte-stimulating hormone (α-MSH) from the POMC neuron, which binds to the MC4 receptor to regulate energy expenditure and satiety.</li>
                    </ul>
                    <figure className="show-for-medium">
                      <img src={ImageFunctional} alt="Functional MC4R pathway activity" />
                    </figure>                 
                    <figure className="show-for-small hide-for-medium text-center mt-1">
                      <img src={ImageFunctionalMobile} className="show-for-small hide-for-medium" alt="Functional MC4R pathway activity" />
                    </figure>
                  </div>
                </div>
              </Tabs.Pane>
              <Tabs.Pane name={<>Impaired MC4R pathway activity<sup>1-4</sup></>} key="2">
                <div className="row og">
                  <div className="column small-12 og">
                    <p style={{ fontSize: '1.25rem', lineHeight: '1.5rem' }}>Ciliary dysfunction in the hypothalamus leads to MC4R pathway impairment, which is a root cause of hyperphagia and obesity in BBS</p>
                    <ul className="mc4r-pathway-list ml-0 md_ml-0">
                      <li>In people with BBS, a variant in one or more BBS genes can disrupt the BBSome, leading to ciliary dysfunction and disruption of LEPR signaling.</li>
                      <li className="arrow-item"> 
                        <img src={ImageredArrow} />
                      </li>
                      <li>Alpha-melanocyte-stimulating hormone (α-MSH) production is impaired or deficient, preventing activation of the MC4 receptor, therefore impairing regulation of energy expenditure and satiety signaling.</li>
                    </ul>
                  </div>
                </div>
                <div className="row og">
                  <div className="column small-12 og">
                    <figure className="show-for-medium">
                      <img src={ImageMoaImpaired} alt="Impaired MC4R pathway activity" />
                    </figure>
                    <figure className="show-for-small hide-for-medium text-center mt-1">
                      <img src={ImageMoaImpairedMobile} alt="Impaired MC4R pathway activity" />
                    </figure>
                  </div>
                </div>
              </Tabs.Pane>
            </Tabs>
          </div>
        </div>

        {/* <div className="row mt-2 md_mt-3">
          <div className="column small-12">
            <h2 className="h1 large-10 small-12">A deeper dive into the MC4R pathway</h2>
            <h3 className="h2">See how the MC4R pathway plays a key role in hyperphagia and obesity in BBS</h3>
          </div>
        </div>

          <MoaMc4rPathwayVideo />
          <VideoTranscript02>
            <MoaMc4rPathwayVideoTranscript />
          </VideoTranscript02> */}

        <h2 className="h1 h1--sm-mobile mt-3 mb-2   md_mt-3 md_mb-2">IMCIVREE is the first and only precision medicine to target impairment in the hypothalamic MC4R pathway, a root cause of hyperphagia and obesity in BBS<sup>1,6</sup></h2>

        <div className="row og">
          <div className="columns og">
            <ul className="mc4r-pathway-list ml-0 md_ml-0">
              <li>IMCIVREE, an MC4R agonist, acts in place of alpha-melanocyte-stimulating hormone (α-MSH) to activate the MC4 receptor, to reestablish MC4R pathway activity.<sup>6-8</sup></li>
              <li className="arrow-item"> <img src={ImageGreenArrow} alt="" /></li>
              <li>Activation of the MC4R pathway can help to increase satiety signals and energy expenditure, therefore reducing hunger, and consequently, food intake and weight.<sup>6</sup></li>
            </ul>
          </div>
        </div>

        <div className="row og">
          <div className="columns og text-center">
            <figure className="md_mt-2 md_mb-2 show-for-medium">
              <img src={ImageMc4rNeuron} alt="IMCIVREE activates the MC4 receptor in the MC4R pathway" />
            </figure>
            <figure className="mt-2 mb-2 show-for-small hide-for-medium">
              <img src={ImageMc4rNeuronMobile} alt="IMCIVREE activates the MC4 receptor in the MC4R pathway" />
            </figure>
          </div>
        </div>

        <div className="text-center mt-2 mb-2 md_mt-2 md_mb-3">
          <Button copy="Learn more about the efficacy of IMCIVREE" url="/hcp/bbs/study-design/" />
        </div>

        <p className="footnote mb-1 md_mb-1">MC4R=melanocortin-4 receptor; POMC=proopiomelanocortin.</p>
        <p className="references footnote">
          <strong>References: 1.</strong> Eneli I et al. <em>Appl Clin Genet.</em> 2019;12:87-93. <strong>2.</strong> Seo S et al. <em>Hum Mol Genet.</em> 2009;18(7):1323-1331. <strong>3.</strong> Blaess S et al. <em>J Clin Invest.</em> 2021;131(8):e148903. doi:10.1172/JCI148903. <strong>4.</strong> Huvenne H et al. <em>Obes Facts.</em> 2016;9(3):158-173. <strong>5.</strong> Focșa IO et al. <em>Biomed Rep.</em> 2021;15(6):103. doi:10.3892/br.2021.1479. <strong>6.</strong> IMCIVREE [prescribing information]. Boston, MA. Rhythm Pharmaceuticals, Inc. <strong>7.</strong> Trapp CM et al. <em>Curr Opin Endocrinol Diabetes Obes.</em> 2023;30(2):136-140. <strong>8.</strong> Haws R et al. <em>Diabetes Obes Metab.</em> 2020;22(11):2133-2140. doi:10.1111/dom.14133.
        </p>
      </div>

    </Layout>
  )
}

export default Moa