
import * as React from "react"

import Layout from "../../components/ppl-layout"
import Seo from "../../components/seo"
import Tabs from "../../components/template-partials/global-components/tabs/tabs"
import Button from "../../components/button"
import HangingRef from "../../components/hanging-ref"
import Quote from "../../components/quote"

import ObesityPOMCFigureImg from "../../images/effect-obesity-pomc-figure.svg"
import ObesityPOMCChartImg from "../../images/effect-obesity-pomc-chart.svg"
import HungerPOMCImg from "../../images/effect-hunger-pomc.svg"
import ObesityLEPRFigureImg from "../../images/effect-obesity-lepr-figure.svg"
import ObesityLEPRChartImg from "../../images/effect-obesity-lepr-chart.svg"
import HungerLEPRImg from "../../images/effect-hunger-lepr.svg"


const EffectPage = () => (
  <Layout>
    <Seo
      title="Weight and Hunger Reduction | IMCIVREE® (setmelanotide) injection"
      description="Learn about weight and hunger reduction in people with obesity due to POMC, PCSK1, or LEPR deficiency. Please see full Prescribing Information for Important Safety Information."
      ogTitle="Effects of IMCIVREE® (setmelanotide) injection"
      ogDesc="Learn about the effects of IMCIVREE® (setmelanotide) in 2 clinical studies. Please see full Prescribing Information for Important Safety Information."
    />
    <div id="effect" className="content-block md_pb-0">
      <h1 className="h1">How did IMCIVREE help reduce measures of weight and hunger in the POMC or PCSK1 and LEPR trials?</h1>
      <div className="row og">
        <div className="columns og">
          <Tabs>
            <Tabs.Pane name="POMC or PCSK1 deficiency" key="1">
              <h2 className="h2">In clinical studies, IMCIVREE reduced weight and hunger for people with obesity due to POMC or PCSK1 deficiency</h2>
              <div className="row og text-center">
                <div className="column og og--xp small-12 medium-6 pl-0 pr-0">
                  <p className="flag"><span>WEIGHT</span></p>
                  <p>STUDY 1</p>
                  <p>People with obesity due to POMC or PCSK1 deficiency</p>
                  <img src={ObesityPOMCFigureImg} alt="POMC/PCSK1 weight data" />
                  <p className="md_mb-2">8 out of 10 people lost at least 10% of <br className="hide-for-small-only"/>their body weight at <span className="widow-x">1 year</span></p>
                  <img src={ObesityPOMCChartImg} alt="POMC/PCSK1 study weight chart" />
                  <p className="text-left"><HangingRef mb0 symbol="*" isAsterisk copy={<>Participants who experienced weight loss of 5 kg/11 lb (or 5% if their starting <br className="hide-for-small-only"/>weight was less than 100 kg/220 lb) during the first 10 weeks.</>} /></p>
                </div>
                <div className="column og og--xp small-12 medium-6 yellow-left">
                  <p className="flag"><span>HUNGER</span></p>
                  <p>STUDY 1</p>
                  <p>People with obesity due to POMC or PCSK1 deficiency</p>
                  <img src={HungerPOMCImg} alt="Decrease in hunger" />
                  <p className="text-center">After 1 year, participants (n=8) <br className="hide-for-small-only"/>experienced a decrease from 7.9 to 5.5 <br className="hide-for-small-only"/>in the median<sup>†</sup> daily hunger score.</p>
                  <p className="text-left">Changes in hunger were evaluated using a questionnaire that was completed each day for 1 year, by participants who were 12 years of age or older. The questionnaire measured hunger using a score ranging from 0 ("not hungry at all") to 10 ("hungriest possible").</p>

                  <p className="text-left"><HangingRef mb0 symbol={<><sup>†</sup></>} isDagger copy={<>A median is the middle value of a set of data that have been put into numerical order. The median is the value that divides the data into 2 halves.</>} /></p>

                  <p className="color-teal text-left"><strong>Impact of stopping treatment</strong></p>
                  <p className="text-left">Treatment was stopped for a period of time to see how it impacted weight and hunger.</p>
                  <p className="text-left color-gray">Over this withdrawal period:
                    <ul className="neon-green-bullets text-left lg_mt-1 mt-1">
                      <li className="color-gray">Weight increased; when the withdrawal period ended and treatment was restarted, weight loss continued</li>
                      <li className="color-gray">Hunger scores generally worsened; hunger scores improved once treatment was restarted</li>
                    </ul>
                  </p>
                </div>
              </div>
            </Tabs.Pane>
            <Tabs.Pane name="LEPR deficiency" key="2">
              <h2 className="h2">In clinical studies, IMCIVREE reduced weight and hunger for people with obesity due to LEPR deficiency</h2>
              <div className="row og text-center">
                <div className="column og og--xp small-12 medium-6 pl-0 pr-0">
                  <p className="flag--green"><span>WEIGHT</span></p>
                  <p>STUDY 2</p>
                  <p>People with obesity due to LEPR deficiency</p>
                  <img src={ObesityLEPRFigureImg} alt="LEPR weight loss data" />
                  <p className="md_mb-2">5 out of 11 people lost at least 10% of their body weight at 1 year</p>
                  <img src={ObesityLEPRChartImg} alt="LEPR study weight chart" className="md_mb-0" style={{ width: "376px" }} />
                  <p className="text-left"><HangingRef mb0 symbol="*" isAsterisk copy={<>Participants who experienced weight loss of 5 kg/11 lb (or 5% if their starting <br className="hide-for-small-only"/>weight was less than 100 kg/220 lb) during the first 10 weeks.</>} /></p>
                </div>
                <div className="column og og--xp small-12 medium-6 yellow-left">
                  <p className="flag--green"><span>HUNGER</span></p>
                  <p>STUDY 2</p>
                  <p>People with obesity due to LEPR deficiency</p>
                  <img src={HungerLEPRImg} alt="Reduce hunger" />
                  <p className="text-center">After 1 year, participants (n=8) experienced a decrease from 7.0 to 4.4 in the median<sup>†</sup> daily hunger score.</p>
                  <p className="text-left">Changes in hunger were evaluated using a questionnaire that was completed each day for 1 year, by participants who were 12 years of age or older. The questionnaire measured hunger using a score ranging from 0 ("not hungry at all") to 10 ("hungriest possible").</p>

                  <p className="text-left"><HangingRef mb0 symbol={<><sup>†</sup></>} isDagger copy={<>A median is the middle value of a set of data that have been put into numerical order. The median is the value that divides the data into 2 halves.</>} /></p>

                  <p className="text-left color-teal"><b>Impact of stopping treatment</b></p>
                  <p className="text-left">Treatment was stopped for a period of time to see how it impacted weight and hunger.</p>
                  <p className="text-left">Over this withdrawal period:</p>
                    <ul className="neon-green-bullets text-left lg_mt-1 mt-1">
                      <li className="color-gray">Weight increased; when the withdrawal period ended and treatment was restarted, weight loss continued</li>
                      <li className="color-gray">Hunger scores generally worsened; hunger scores improved once treatment was restarted</li>
                    </ul>
                </div>
              </div>
            </Tabs.Pane>
          </Tabs>
          <div className="row mt-2 md_mt-2 lg_mt-2 md_ml-2 lg_ml-2">
            <div className="column medium-8">
            <Quote 
              copy={<>A lot has changed, and when I look at the hunger today, I’m assuming it’s on the same level as other people.</>}
              signoff={<>— Person who is living with POMC deficiency</>}>
            </Quote>
            </div>
          </div>
          <div className="row mt-2 md_mt-2 lg_mt-2 md_mr-2 lg_mr-2">
            <div className="column medium-4 desktop-only">
              </div>
            <div className="column medium-8">
            <Quote 
              copy={<>I’m very satisfied  I’ve lost a lot of weight, and I have a lot more energy.  It’s nice to see that someone is paying attention to this, and there’s a medication for this, that they’re deploying people to deal with this problem, when people used to have to go without care.</>}
              signoff={<>— Person who is living with POMC deficiency</>}>
            </Quote>
            </div>
          </div>
          <div className="row mt-2 md_mt-2 lg_mt-2 md_ml-2 lg_ml-2">
            <div className="column medium-8">
              <Quote copy={<>
                Now, I really feel it. My stomach growls and I notice that I am hungry. 
                Experiencing both fullness and hunger is something I have never felt before. It's funny.</>}
              signoff={<>— Person who is living with LEPR deficiency</>}></Quote>
            </div>
          </div>

        </div>
      </div>
      <div className="text-center pt-2 md_pt-1">
        <Button large copy={<>Learn about the possible side effects with IMCIVREE</>} url="/ppl/side-effects/" />
      </div>
    </div>
  </Layout>
)

export default EffectPage
