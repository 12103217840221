import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/ppl-layout"
import Seo from "../../components/seo"
import Accordion from "../../components/template-partials/global-components/accordion/accordion"
import Callout from "../../components/callout"
import ContactInfoPpl from "../../components/contact-info-ppl"

const Faq = () => {
  return (
    <Layout>
      <Seo
        title="Frequently Asked Questions | IMCIVREE® (setmelanotide) injection"
        description="View frequently asked questions about IMCIVREE® (setmelanotide). Please see full Prescribing Information for Important Safety Information."
        ogTitle="Frequently Asked Questions | IMCIVREE® (setmelanotide) injection"
        ogDesc="View frequently asked questions about IMCIVREE® (setmelanotide). Please see full Prescribing Information for Important Safety Information."
      />
      <div id="faq-accordian-container" className="content-block" style={{ paddingBottom: '0' }}>
        <h1 className="h1">Frequently asked questions about IMCIVREE</h1>

        <Accordion className="mb-2 md_mb-3">
          <Accordion.Item isOpen="true">
            <Accordion.Drawer>
              I’ve always struggled with obesity. Can I take IMCIVREE?
            </Accordion.Drawer>
            <Accordion.Expanded>
              <div className="columns">
                <p className="mb-0">
                  IMCIVREE is a treatment for obesity due to POMC, PCSK1, or
                  LEPR deficiency confirmed through a genetic test. The test
                  must show that the change, or variant, is considered
                  pathogenic, likely pathogenic, or uncertain. IMCIVREE should
                  not be used by someone with obesity due to suspected POMC,
                  PCSK1, or LEPR deficiency not confirmed by genetic testing
                  (benign or likely benign result) or someone with another type
                  of obesity not related to POMC, PCSK1, or LEPR deficiency,
                  including obesity associated with other genetic conditions and
                  general obesity. It’s always best to talk to your healthcare
                  provider to determine if a treatment is right for you.
                </p>
              </div>
            </Accordion.Expanded>
          </Accordion.Item>
          <Accordion.Item>
            <Accordion.Drawer>
              How much weight can I expect to lose?
            </Accordion.Drawer>
            <Accordion.Expanded>
              <div className="columns">
                <p>
                  Each individual is different, and results can vary from person
                  to person. In clinical studies of IMCIVREE:
                </p>
                <ul className="neon-green-bullets mb-1 md_mb-1">
                  <li>
                    80% of people (8 out of 10) with obesity due to POMC or
                    PCSK1 deficiency lost at least 10% of their body weight at 1
                    year; people with obesity due to POMC or PCSK1 deficiency
                    had a 23.1% average reduction in weight from baseline after
                    1 year
                  </li>
                  <li>
                    46% of people (5 out of 11) with obesity due to LEPR
                    deficiency lost at least 10% of their body weight at 1 year;
                    people with obesity due to LEPR deficiency had a 9.7%
                    average reduction in weight from baseline after 1 year
                  </li>
                </ul>
                <p className="mb-0">
                  When treatment was stopped, weight increased. When the
                  withdrawal period ended and treatment was restarted, weight
                  loss continued. You can find more information on IMCIVREE
                  clinical studies&nbsp;
                  <Link to="/ppl/clinical-study-results/">
                    <u>here</u>
                  </Link>
                  . To determine what may be reasonable for you to expect from
                  treatment, speak to your healthcare provider.
                </p>
              </div>
            </Accordion.Expanded>
          </Accordion.Item>
          <Accordion.Item>
            <Accordion.Drawer>Is it safe?</Accordion.Drawer>
            <Accordion.Expanded>
              <div className="columns">
                <p>
                  <strong>
                    IMCIVREE may cause serious side effects, including:
                  </strong>
                </p>
                <ul className="neon-green-bullets mb-1 md_mb-1">
                  <li>
                    <strong>Male and female sexual function problems.</strong> IMCIVREE can cause an erection that happens without any
                    sexual activity in males (spontaneous penile erection) and
                    unwanted sexual reactions (changes in sexual arousal that
                    happen without any sexual activity) in females. If you have
                    an erection lasting longer than 4 hours, get emergency
                    medical help right away
                  </li>
                  <li>
                    <strong>
                      Depression and suicidal thoughts or actions.
                    </strong>&nbsp;You or a caregiver should call your healthcare provider
                    right away if you have any new or worsening symptoms of
                    depression
                  </li>
                  <li>
                  <strong>Serious allergic reactions.</strong> Stop taking IMCIVREE and get medical help right away if you have any symptoms of a serious allergic reaction including: swelling of your face, lips, tongue or throat; problems breathing or swallowing; severe rash or itching; fainting or feeling dizzy; rapid heartbeat
                  </li>
                  <li>
                    <strong>
                      Increased skin pigmentation and darkening of skin lesions
                      (moles or nevi) you already have.</strong>&nbsp;These changes happen due to how IMCIVREE works in the body
                    and will go away when you stop using IMCIVREE. You should
                    have a full body skin exam before starting and during
                    treatment with IMCIVREE to check for skin changes
                  </li>
                  <li>
                    <strong>Benzyl alcohol toxicity.</strong> Benzyl alcohol is
                    a preservative in IMCIVREE. Benzyl alcohol can cause serious
                    side effects, including death, in premature and low-birth
                    weight infants, who have received medicines that contain
                    benzyl alcohol. IMCIVREE should not be used in premature and
                    low-birth weight infants
                  </li>
                </ul>
                <p className="mb-0">
                  The most common side effects of IMCIVREE include darkening of
                  the skin, injection site reactions, nausea, headache,
                  diarrhea, stomach pain, vomiting, depression, and an erection
                  that happens without any sexual activity in males. These are
                  not all the possible side effects of IMCIVREE. Call your
                  doctor for medical advice about side effects. You may report
                  side effects to FDA at 1-800-FDA-1088 or Rhythm
                  Pharmaceuticals at 1-833-789-6337.
                </p>
              </div>
            </Accordion.Expanded>
          </Accordion.Item>
          <Accordion.Item>
            <Accordion.Drawer>
              Will nausea and vomiting get better the longer I take IMCIVREE?
            </Accordion.Drawer>
            <Accordion.Expanded>
              <div className="columns">
                <p className="mb-0">
                  It's best to reach out to your current healthcare provider for
                  more information. In phase 3 clinical studies evaluating
                  IMCIVREE for the treatment of obesity due to POMC, PCSK1, and
                  LEPR deficiency, nausea was reported by 56% of people taking
                  IMCIVREE, and vomiting was reported by 30% of people taking
                  IMCIVREE. Responses vary, but these 2 effects were most often
                  reported in the first month after starting treatment, and were
                  reported less frequently over time.
                </p>
              </div>
            </Accordion.Expanded>
          </Accordion.Item>
          <Accordion.Item>
            <Accordion.Drawer>
              How long should I expect to take IMCIVREE?
            </Accordion.Drawer>
            <Accordion.Expanded>
              <div className="columns">
                <p className="mb-0">
                  IMCIVREE must be taken daily to be effective. In clinical
                  trials, when IMCIVREE was stopped, individuals experienced
                  weight gain. When the withdrawal period ended and treatment
                  was restarted, weight loss continued. Speak to your healthcare
                  provider to determine the best treatment plan for you.
                </p>
              </div>
            </Accordion.Expanded>
          </Accordion.Item>
          <Accordion.Item>
            <Accordion.Drawer>
              How do I know if this is covered by insurance?
            </Accordion.Drawer>
            <Accordion.Expanded>
              <div className="columns">
                <p className="mb-0">
                  Insurance coverage varies from plan to plan. We invite you to
                  contact Rhythm InTune at&nbsp;
                  <a href="tel:18552060815" className="underline">1-855-206-0815</a>. Our team members
                  would be happy to help you look into options and plan next
                  steps.
                </p>
              </div>
            </Accordion.Expanded>
          </Accordion.Item>
          <Accordion.Item>
            <Accordion.Drawer>
              Are there options to help with the cost?
            </Accordion.Drawer>
            <Accordion.Expanded>
              <div className="columns">
                <p className="mb-0">
                  We invite you to contact Rhythm InTune at&nbsp;
                  <a href="tel:18552060815" className="underline">1-855-206-0815</a> to learn more
                  about financial support options that may be available.
                </p>
              </div>
            </Accordion.Expanded>
          </Accordion.Item>
          <Accordion.Item>
            <Accordion.Drawer>
              Where can I go if I have more questions about IMCIVREE?
            </Accordion.Drawer>
            <Accordion.Expanded>
              <div className="columns">
                <p className="mb-0">
                  The first place to turn is always your healthcare provider,
                  who can determine the right treatment for you. Rhythm InTune
                  may also be able to help with questions about taking IMCIVREE,
                  insurance coverage, financial support programs, and ongoing
                  support. Contact Rhythm InTune at&nbsp;
                  <a href="tel:18552060815" className="underline">1-855-206-0815</a>.
                </p>
              </div>
            </Accordion.Expanded>
          </Accordion.Item>
        </Accordion>
        <div className="row">
          <div className="columns large-8 medium-10 small-12">
            <Callout fullWidth className="lime-teal left text-left">
              <p>
                If you have additional questions, contact your doctor or Rhythm
                InTune
              </p>
            </Callout>
          </div>
        </div>
      </div>
      <div className="content-block" style={{ paddingTop: '0', paddingBottom: '0' }}>
        <div>&nbsp;</div>
        <ContactInfoPpl />
      </div>
    </Layout>
  )
}

export default Faq
