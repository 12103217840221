import * as React from "react"
import { Link } from "gatsby"

import Layout from "../../components/bbs-layout"
import Seo from "../../components/seo"
import Button from "../../components/button"
import Callout from "../../components/callout"

import ImageDeciding from "../../images/deciding.svg"
import ImageLearning from "../../images/learning.svg"
import ImageStarting from "../../images/starting.svg"
import ImageStaying from "../../images/staying.svg"

const GettingStarted = () => (
  <Layout>
    <Seo
      title="Getting Started on IMCIVREE | IMCIVREE® (setmelanotide) injection"
      description="Injection training support is available from your healthcare professional or Rhythm InTune. Please see full Prescribing Information and Important Safety Information."
      ogTitle="Getting Started on IMCIVREE| IMCIVREE® (setmelanotide) injection"
      ogDesc="Injection training support is available from your healthcare professional or Rhythm InTune. Please see full Prescribing Information and Important Safety Information."
    />
    <div className="content-block">
      <div className="row og">
        <div className="columns og">
          <h1 className="h1">Getting started on IMCIVREE</h1>
          <h3 className="h3 color-gray">Deciding on IMCIVREE</h3>
        </div>
      </div>

      <div className="row og">
        <div className="columns small-12 large-11">
          <div className="row og align-top align-center lg_mt-1 mt-1 mb-2 md_mb-2">
            <div className="columns og large-2 small-12 text-center">
              <img
                src={ImageDeciding}
                alt="Deciding on IMCIVREE"
                className="mb-1"
              />
            </div>
            <div className="columns small-12 large-10 og">
              <p className="mb-0 ml-1">
                You and your doctor decide whether IMCIVREE is an appropriate
                treatment. If you agree on IMCIVREE, your doctor will submit a
                Start Form along with your consent to enroll in&nbsp;
                <Link to="/bbs/support-resources/" className="underline">
                  Rhythm InTune
                </Link>
                , if you choose to. Rhythm InTune is your dedicated source for
                personalized support, which includes helping you understand
                insurance coverage and exploring financial assistance options
                for eligible patients.
              </p>
            </div>
          </div>

          <h3 className="ml-1 md_ml-1 h3 color-gray">
            Learning how to inject IMCIVREE
          </h3>
          <div className="row og align-top align-center lg_mt-1 mt-1 mb-2 md_mb-2">
            <div className="columns og large-2 small-12 text-center">
              <img
                src={ImageLearning}
                alt="Learning how to inject IMCIVREE"
                className="mb-1"
              />
            </div>
            <div className="columns og small-12 large-10">
              <p className="mb-0 ml-1">
                Injection training is offered before you start IMCIVREE.
                Training can be given by your doctor or coordinated through
                Rhythm InTune.
              </p>
            </div>
          </div>

          <h3 className="ml-1 md_ml-1 h3 color-gray">Starting on IMCIVREE</h3>

          <div className="row og align-top align-center lg_mt-1 mt-1 mb-2 md_mb-2">
            <div className="columns og large-2 small-12 text-center">
              <img
                src={ImageStarting}
                alt="Starting on IMCIVREE"
                className="mb-1"
              />
            </div>
            <div className="columns og small-12 large-10">
              <p className="mb-0 ml-1">
                IMCIVREE is only available through PANTHERx Rare Pharmacy.
                Rhythm InTune will coordinate delivery of IMCIVREE. You should
                start IMCIVREE at your starting dose, as directed by your
                doctor.
              </p>
            </div>
          </div>

          <h3 className="ml-1 md_ml-1 h3 color-gray">Staying on IMCIVREE</h3>

          <div className="row og align-top align-center lg_mt-1 mt-1 mb-2 md_mb-2">
            <div className="columns og large-2 small-12 text-center">
              <img
                src={ImageStaying}
                alt="Staying on IMCIVREE"
                className="mb-1"
              />
            </div>
            <div className="columns og small-12 large-10">
              <p className="mb-0 ml-1">
                Your doctor will monitor you for the first few weeks while you
                are on IMCIVREE. During this time, they may increase or decrease
                your dose until you reach your target dose.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="row">
      <div className="columns large-10 medium-10 small-12">
        <Callout fullWidth className="lime-teal left text-left">
          <p>
            Rhythm InTune provides personalized support and educational
            resources and programs throughout treatment to help you start and
            stay on IMCIVREE
          </p>
        </Callout>
      </div>
    </div>

    <div className="text-center lg_mt-3 mt-3">
      <Button
        copy="See dosing information"
        url="/bbs/understanding-your-dose/"
      />
    </div>
  </Layout>
)

export default GettingStarted
