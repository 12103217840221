import React from "react"

const Callout = props => {
  let w = "medium-12 large-4"
  if (props.fullWidth) {
    w = "medium-12 large-12"
  } else if (props.qolWidth) {
    w = "medium-12 large-10"
  }
  return (
    <div className="row">
      <div
        className="medium-2 large-8"
        style={props.right ? { display: `block` } : { display: `none` }}
      >
        &nbsp;
      </div>
      <div className={`small-12 ${w}`}>
        <div
          className={
            "global-callout-container-2 content-block row " + props.className
          }
          style={props.style}
          id={props.id}
        >
          <div className={"global-callout-content column " + props.className}>
            {props.children}
          </div>
        </div>
      </div>
      <div
        className="show-for-medium medium-2 large-4"
        style={props.left ? { display: `block` } : { display: `none` }}
      >
        &nbsp;
      </div>
    </div>
  )
}

export default Callout
