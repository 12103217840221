import React from "react"

const CalloutBox = ({
  headerStyles,
  contentStyles,
  headerCopy,
  tetherLine,
  style,
  children,
}) => {
  return (
    <div
      className={`CalloutBox__container ${tetherLine ? "tether" : ""}`}
      style={style}
    >
      <div className="CalloutBox__header-wrapper" style={headerStyles}>
        <h3
          className="CalloutBox__header"
          dangerouslySetInnerHTML={{ __html: headerCopy }}
        />
      </div>
      <div className="CalloutBox__content-wrapper" style={contentStyles}>
        {children}
      </div>
    </div>
  )
}

export default CalloutBox
