import React from 'react'


const HearAboutRealExperiencesTranscriptBbs = (props) => {

  return (
    <>
      <p>When we think about diagnosing hyperphagia in an individual living in a larger body, it can certainly be challenging.</p>

      <p>People living with pediatric obesity and early-onset obesity do have very high levels of hunger. We also know that many people living with obesity have disordered eating behaviors.</p>

      <p>However, hyperphagia is its own type of disordered eating that really comes with a persistent and pervasive experience of hunger, to the point of distraction and disruption of daily life function.</p>

      <p>I encourage clinicians to really ask specific questions about how the feeling of hunger is impacting their patient's quality of life and their patient's ability to function in their life. And I would encourage clinicians to really look at function as it comes to daily routine function around school, work, caregiving relationships, as well as around pleasure and fun. Because I think that when you can see that hunger is negatively impacting or preoccupying all of those spaces, that is a really good cue that an individual is experiencing hyperphagia.</p>

      <p>Hyperphagia is different from binge eating, overeating, or the occasional overeating that we may see. It takes a long time to reach satiety.</p>

      <p>It's this prolonged, persistent, severe preoccupation and an insatiable hunger that is hyperphagia.</p>

      <p>Hyperphagia typically is very clear for me because the patient describes it as a constant feeling. It's not something that they feel a couple times a week. This is all the time, even after they've eaten. Sometimes while they're eating, they'll tell me that they just feel like they need to keep going.</p>

      <p>Now, when we start thinking about binge eating disorder, that's a very different thing from hyperphagia. I think those two get confused a lot.</p>

      <p>Binge eating is generally a large amount of food with a loss of control. With hyperphagia, sometimes I feel like they will describe it as a loss of control. But it's more of they're not able to control the impulse to continue to eat or to eat through the day. With hyperphagia, what we see is that pathological, insatiable hunger.</p>

      <p>I absolutely see varying degrees of hyperphagia in patients.</p>

      <p>I kind of talked about it being extreme cases with locks on cabinets, and not being able to eat and keep food in the home. There are other instances where I've seen patients that did not have those extreme cases. Maybe they've learned to adapt around it.</p>

      <p>I do have one patient that has never really admitted to hyperphagia. In her words, sometimes she just suffers through it, or does other things, like drinks way too much water, or things like that, to try to feel full.</p>

      <p>So even the intensity of the hyperphagia can be quite variable. Keeping in mind the family's perception of it, and what they have, what they're comparing it to. I have had quite young patients who, even during the clinical encounter, it's difficult to focus them. And you're constantly needing to eat, even to be examined. We've had families say they find candy bars at bedtimes, waking up at night to access the refrigerator and eat some more. Grandparents, who have to put food out of reach when their grandchildren visit, because they are concerned about this constant eating.</p>

      <p>The full impact and what it does to the families has truly become apparent to me. For several years interacting in clinical Bardet-Biedl syndrome families, would be the incredibly emotional response that happens following acknowledging that the hyperphagia is real. It's like, "Oh yeah. I've heard this before. It's not unique to your child." And parents looking, and saying, "You mean you understand this? "Like, Yes. And it's real." And families choking up, tears in their eyes.</p>

      <p>And I think when we think about why it's important to make a diagnosis of BBS, why it's important to provide treatment for BBS, we want to understand that we're not only trying to prevent life-limiting complications, but we're also trying to treat hyperphagia so that we can improve health and quality of life over time.</p>

      <p>As any parent, we enjoyed raising our children, and part of a happy childhood is celebrations. One of our favorite things has always been birthday parties. However, we very quickly found out how stressful a joyful occasion like a birthday party for a child could be because when we would bring Reed to a birthday party, when all the kids would be socializing and talking, the only thing Reed was concerned about was food and not just concerned, he was infatuated with it. He wanted to have cake, he wanted to have lemonade, he wanted to have more and more and more.</p>

      <p>The most challenging symptom that we had to work with was hyperphagia, which is a medical term for hard-to-control insatiable appetite. The hunger was something that we were not prepared for. We, of course, knew what hunger means, we're all humans. We experience hunger as anybody else does.</p>

      <p>This parent actually said, what he worried most about was not really other health consequences, but the child's ability to learn. And that's resonated for me. I was like, if my kid is constantly thinking about food, how in the world is this child going to learn? It really drove it home for me.</p>

      <p>I would be hungry 24/7. All my energy would be focused on what I could eat next or what we're going to have for dinner.</p>

      <p>My constant hunger led to me eating all the time, which made me gain weight and that was really frustrating.</p>

      <p>Sometimes my hunger interrupted my sleep because I would get up in the middle of the night to go get food.</p>

      <p>Hearing about patients that are not able to just carry out their daily activities because of hyperphagia, that are having to constantly plan around eating, and make sure they always have food with them so they're not searching or having that seeking behavior, it's hard to sit there and listen to patients really describe that. Because they get very emotional.</p>

      <p>We did not anticipate the severity of the hunger when we first learned about BBS. We understood our kid has hunger that is much more intense and that's ever-present. It impacted everything we did, every event that we were a part of. For example, I couldn't go grocery shopping with Reed anymore because it was extremely stressful and agitating for him. We had to have food put away at all times. We only used the kitchen for meals, never for socializing.</p>

      <p>I think that as clinicians, it's incredibly important for us to understand how much hyperphagia impacts patients' lives. It impacts them not only around mealtime, but in all of the planning that they do. So, I think that it needs to be high on our priority list when we think about how we are going to help our patients, not only with their weight trajectories, but also with their hyperphagia.</p>

    </>
  )
}

export default HearAboutRealExperiencesTranscriptBbs
