import React from "react"
import RhythmLogo from "../../../images/rhythm-logo.svg"
import { Link } from "gatsby"

const GlobalFooter = ({code, type}) => {
  return (
    <footer className="global-footer">
      <div>
        <div className="row expanded og align-middle global-footer__container">
          <div className="columns og global-footer__gray">
            <div className="row og">
              <div className="small-12 medium-9 columns og left">
                <ul className="text-center medium-text-left jost-semibold global-footer__menu mt-2">
                  {type === 'bbs' &&
                    <>
                    <li><a href="https://www.rhythmtx.com/contact-us" target="_blank" rel="noopener noreferrer">Contact Us</a></li>
                    <li><a href="https://www.rhythmtx.com/privacy-policy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a></li>
                    <li><a href="https://www.rhythmtx.com/terms-of-use/" target="_blank" rel="noopener noreferrer">Terms of Use</a></li>
                    <li><Link href="/bbs/sitemap/">Sitemap</Link></li>
                    <li><Link href="/bbs/accessibility/">Accessibility</Link></li>
                    </>
                  }
                  {type === 'ppl' &&
                    <>
                    <li><a href="https://www.rhythmtx.com/contact-us" target="_blank" rel="noopener noreferrer">Contact Us</a></li>
                    <li><a href="https://www.rhythmtx.com/privacy-policy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a></li>
                    <li><a href="https://www.rhythmtx.com/terms-of-use/" target="_blank" rel="noopener noreferrer">Terms of Use</a></li>
                    <li><Link to="/ppl/sitemap/">Sitemap</Link></li>
                    <li><Link to="/ppl/accessibility/">Accessibility</Link></li>
                    </>
                  }
                  {type === 'hcp-bbs' &&
                    <>
                    <li><a href="https://www.rhythmtx.com/contact-us" target="_blank" rel="noopener noreferrer">Contact Us</a></li>
                    <li><a href="https://www.rhythmtx.com/privacy-policy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a></li>
                    <li><a href="https://www.rhythmtx.com/terms-of-use/" target="_blank" rel="noopener noreferrer" >Terms of Use</a></li>
                    <li><Link to="/hcp/bbs/sitemap/">Sitemap</Link></li>
                    <li><Link to="/hcp/bbs/accessibility/">Accessibility</Link></li>
                    </>
                  }
                  {type === 'hcp-ppl' &&
                    <>
                    <li><a href="https://www.rhythmtx.com/contact-us" target="_blank" rel="noopener noreferrer">Contact Us</a></li>
                    <li><a href="https://www.rhythmtx.com/privacy-policy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a></li>
                    <li><a href="https://www.rhythmtx.com/terms-of-use/" target="_blank" rel="noopener noreferrer">Terms of Use</a></li>
                    <li><Link to="/hcp/ppl/sitemap/">Sitemap</Link></li>
                    <li><Link to="/hcp/ppl/accessibility/">Accessibility</Link></li>
                    </>
                  }
                  {type === 'generic' &&
                    <>
                    <li><a href="https://www.rhythmtx.com/contact-us" target="_blank" rel="noopener noreferrer">Contact Us</a></li>
                    <li><a href="https://www.rhythmtx.com/privacy-policy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a></li>
                    <li><a href="https://www.rhythmtx.com/terms-of-use/" target="_blank" rel="noopener noreferrer">Terms of Use</a></li>
                    <li><Link to="/sitemap/">Sitemap</Link></li>
                    <li><Link to="/accessibility/">Accessibility</Link></li>
                    </>
                  }
                </ul>
                <p className="footer-copyright small text-center medium-text-left lg_mt-1 mt-1">&copy; {new Date().getFullYear()}, Rhythm Pharmaceuticals, Inc. All rights reserved. Rhythm, IMCIVREE, and their logos are trademarks of <span className="nowrap">Rhythm Pharmaceuticals, Inc.</span> <span className="nowrap">{code ?? "US-SET-2200003 11/2023"}</span></p>
              </div>
              <div className="columns og small-12 medium-3 text-center medium-text-right content-block mb-2"><a href="https://www.rhythmtx.com/" target="_blank" rel="noopener noreferrer"><img src={RhythmLogo} alt="Rhythm Logo" /></a></div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default GlobalFooter
