import React from "react"

import Layout from "../../../components/hcp-ppl-layout"
import Seo from "../../../components/seo"
import Button from "../../../components/button"
import Tabs from "../../../components/template-partials/global-components/tabs/tabs"
import Callout from "../../../components/callout"
import HangingRef from "../../../components/hanging-ref"

import WeightReductionChart1 from "../../../images/hcp/ppl/chart-weight-reduction-pomc-pcsk1.png"
import WeightReductionChart2 from "../../../images/hcp/ppl/chart-weight-reduction-2.png"

const WeightReduction = () => {
  return (
    <Layout>
      <Seo
        title="Weight Reduction | IMCIVREE® (setmelanotide) injection | For HCPs"
        description="Learn about weight reduction in patients with obesity due to POMC, PCSK1, or LEPR deficiency. Please see full Prescribing Information for Important Safety Information."
        ogTitle="Clinical Study Weight Results | IMCIVREE® (setmelanotide) injection"
        ogDesc="Learn about the clinical study weight results of IMCIVREE® (setmelanotide). Please see full Prescribing Information for Important Safety Information."
      />
      <div className="content-block">
        <p className="h3-hcp lg_mt-1" style={{ marginBottom: "0" }}>
          In patients with obesity due to POMC, PCSK1, or LEPR deficiency
        </p>
        <h1 className="h1">
          IMCIVREE delivered significant, clinically meaningful weight loss over
          1 year<sup>1</sup>
        </h1>

        <div className="row og md_mt-2">
          <div className="columns og">
            <Tabs className=" tabs--hcp">
              <Tabs.Pane
              name={
                <>
                POMC or PCSK1 deficiency<sup>1</sup>
                </>
              }
              key="1"
              >
                <div className="row og md_mb-1 lg_mb-1">
                  <div className="column og small-12 medium-8 medium-offset-2">
                    <h5 className="color-gray text-center md_mt-1">
                      <strong>Mean % change in body weight over 1 year</strong>
                    </h5>
                    <h5 className="color-gray text-center md_mb-2">
                      <strong>POMC/PCSK1 (n=9)*</strong>
                    </h5>
                    <img src={WeightReductionChart1} alt="POMC/PCSK1 study weight chart" />
                  </div>
                </div>
                <div className="row og md_mb-3 md_mt-3 lg_mb-2">
                  <div className="column og small-12 medium-6">
                    <ul className="neon-green-bullets mt-1">
                      <li className="mb-1">
                        <b>23.1% mean reduction in weight&nbsp;</b>
                        from baseline after 1 year
                        <br /> (95% CI: -31.9%, -14.4%); <em>P</em>=0.0003; N=10
                      </li>
                    </ul>
                  </div>
                  <div className="column og small-12 medium-6">
                    <ul className="neon-green-bullets">
                      <li>
                        <b>
                          80% of patients with obesity due to POMC or PCSK1
                          deficiency achieved a ≥10% weight loss&nbsp;
                        </b>
                        from baseline after{" "}
                        <span className="nowrap">1 year</span>
                        <br /> (95% CI: 44.4%, 97.5%); <em>P</em>&lt;0.0001;
                        N=10
                      </li>
                    </ul>
                  </div>
                  <div
                    className="columns small-12 mt-2 md_mt-2"
                    style={{ paddingLeft: "0.75rem" }}
                  >
                    <HangingRef
                      mb0
                      symbol="*"
                      isAsterisk
                      copy={
                        <>
                          Participants who achieved weight loss threshold (&ge;5
                          kg or 5% if baseline body weight was &lt;100 kg)
                          during the 10-week open-label period.
                        </>
                      }
                    />
                    <HangingRef
                      symbol={
                        <>
                          <sup>&dagger;</sup>
                        </>
                      }
                      copy={
                        <>
                          The withdrawal period lasted 8 weeks, which included 4 weeks of IMCIVREE followed by 4 weeks of placebo.
                        </>
                      }
                    />
                  </div>
                </div>
              </Tabs.Pane>
              <Tabs.Pane
              name={
                <>
                LEPR deficiency<sup>1</sup>
                </>
              }
              key="2">
                <div className="row og">
                  <div className="column small-12 og medium-8 medium-offset-2">
                    <h5 className="color-gray text-center md_mt-1">
                      <strong>Mean % change in body weight over 1 year</strong>
                    </h5>
                    <h5 className="color-gray text-center md_mb-2">
                      <strong>LEPR (n=7)</strong>
                    </h5>
                    <img src={WeightReductionChart2} alt="LEPR study weight chart" />
                  </div>
                </div>
                <div className="row og md_mb-2 md_mt-3 lg_mb-1">
                  <div className="column og small-12 medium-6">
                    <ul className="neon-green-bullets">
                      <li className="mb-1">
                        <b>9.7% mean reduction in weight</b>&nbsp;from baseline
                        after 1 year
                        <br /> (95% CI: -16%, -3.3%); <em>P</em>=0.0074; N=11
                      </li>
                    </ul>
                  </div>
                  <div className="column og small-12 medium-6">
                    <ul className="neon-green-bullets">
                      <li>
                        <b>
                          45.5% of patients with obesity due to LEPR deficiency
                          achieved a ≥10% weight loss
                        </b>
                        &nbsp;from baseline after 1 year
                        <br /> (95% CI: 16.8%, 76.6%); <em>P</em>=0.0002; N=11
                      </li>
                    </ul>
                  </div>
                  <div
                    className="columns small-12 mt-2 md_mt-2"
                    style={{ paddingLeft: "1rem" }}
                  >
                    <HangingRef
                      mb0
                      symbol="*"
                      isAsterisk
                      copy={
                        <>
                          Participants who achieved weight loss threshold (&ge;5
                          kg or 5% if baseline body weight was &lt;100 kg)
                          during the 10-week open-label period.
                        </>
                      }
                    />
                    <HangingRef
                      symbol={
                        <>
                          <sup>&dagger;</sup>
                        </>
                      }
                      copy={
                        <>
                          The withdrawal period lasted 8 weeks, which included 4 weeks of IMCIVREE followed by 4 weeks of placebo.
                        </>
                      }
                    />
                  </div>
                </div>
              </Tabs.Pane>
            </Tabs>
          </div>
        </div>
      </div>

      <Callout left className="light-teal left text-left">
        <p>
          In both studies, weight increased during the withdrawal period, then
          decreased once treatment was reinitiated<sup>1</sup>
        </p>
      </Callout>
      
      <div className="content-block pt-0 md_pt-2">
        <div className="text-center mt-3 md_mt-2 mb-3 md_mb-2">
          <Button
            textCenter
            copy="See hunger reduction"
            url="/hcp/ppl/hunger-reduction/"
          />
        </div>

        <HangingRef
          mb0
          symbol=""
          isAsterisk
          copy={
            <>
              CI=confidence interval; FV=final visit; LEPR=leptin receptor;
              PCSK1=proprotein convertase subtilisin/kexin type 1;
              POMC=proopiomelanocortin; V=visit.
            </>
          }
        />
        <p className="small mt-1 md_mt-1" style={{ fontWeight: "400" }}>
          <strong>Reference: 1.</strong> IMCIVREE [prescribing information].
          Boston, MA. Rhythm Pharmaceuticals, Inc.
        </p>
      </div>
    </Layout>
  )
}

export default WeightReduction
