import React from "react"
import LineItem from "./template-partials/global-components/list-item"

import Button from "./button"
import { Link } from "gatsby"

import JumpArrowImg from "../images/jump-arrow.png"

const DiagnosisTable = props => {
  return (
    <div id="diagnosis-table">
      <div className="row">
        <div className="column small-12 large-6">
          <div className="table-quadrant" id="clinical-manifestations">
            <div className="row content-wrapper">
              <div className="column small-12 large-10">
                <h3 className="h3">Clinical manifestations<sup>1-3</sup></h3>
                <ul className="ul--standard">
                  {[
                    "BBS is a ciliopathy with a highly variable phenotype and clinical features that vary greatly across individuals and evolve over time",
                    "Some features may present more mildly or slowly depending on gene variant and other factors",
                  ].map(item => (
                    <LineItem copy={item} />
                  ))}
                </ul>
                <strong className="color-teal d-block mt-1 mb-1 md_mt-1 md_mb-1">Download the brochure below to find out more about how BBS may present in your practice</strong>
                {/* <Modal id="clinically-diagnosing-bbs" /> */}
                <Button type="download" copy="Download" file="/Patient_Diagnosis_Tool.pdf"/>
              </div>
            </div>
          </div>
        </div>

        <div className="column small-12 large-6">
          <div className="table-quadrant" id="genetics">
            <div className="row content-wrapper right">
              <div className="column small-12 large-9">
                <h3 className="h3">Genetics<sup>3</sup></h3>
                <ul className="ul--standard">
                  {[
                    "Genetic testing for BBS can provide additional diagnostic information to help inform your diagnosis. For more information, visit <a href='https://www.uncoveringrareobesity.com' style='color:#000; text-decoration: underline;' target='_blank'>UncoveringRareObesity.com</a>",
                    "Results should be integrated into the overall clinical assessment of the patient and do not equate to a diagnosis on their own. Additionally, variant interpretation may change over time, as the information around the genetics of BBS continue to evolve",
                  ].map(item => (
                    <LineItem copy={item} />
                  ))}
                </ul>
                <div class="jump-link mt-1 md_mt-1">
                  <img src={JumpArrowImg} className="jump-arrow" alt=""/>
                  <Link className="jump-links fw-bold underline" href="#extensive-genetics">Learn more about genetic testing for BBS</Link>
                </div>
                {/* <Modal id="genetic-testing-hcp-bbs" /> */}
              </div>
            </div>
          </div>
        </div>

        <div className="column small-12 large-6">
          <div className="table-quadrant" id="patient-history">
            <div className="row content-wrapper">
              <div className="column small-12 large-10">
                <h3 className="h3">Patient history</h3>
                <ul className="ul--standard">
                  {[
                    "Review patient’s complete medical history. Some clinical manifestations of BBS may have been previously treated and/or not recognized as a symptom of BBS ",
                  ].map(item => (
                    <LineItem copy={item} />
                  ))}
                </ul>
                <div class="jump-link mt-1 md_mt-1">
                  <img src={JumpArrowImg} className="jump-arrow" alt=""/>
                  <Link className="jump-links fw-bold underline" href="#variable-phenotype">Take a closer look at how BBS can evolve over time</Link>
                </div>
                {/* <Modal id="key-identifiable-features" /> */}
              </div>
            </div>
          </div>
        </div>

        <div className="column small-12 large-6">
          <div className="table-quadrant" id="family-findings">
            <div className="row content-wrapper right">
              <div className="column small-12 large-9">
                <h3 className="h3">Family findings<sup>1,4</sup></h3>
                <ul className="ul--standard">
                  {[
                    "Family members have an increased risk of inheriting a pathogenic BBS gene, and siblings are generally diagnosed earlier",
                    "Once one family member is diagnosed, others should be evaluated for BBS as well",
                    "Phenotype can vary between siblings",
                  ].map(item => (
                    <LineItem copy={item} />
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DiagnosisTable
