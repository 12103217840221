import React from 'react'


const PemCarynTranscript = (props) => {

  return (
    <>
      <p><strong>Graphics:</strong></p>

      <p><strong>Caryn</strong></p>

      <p><strong>Rhythm InTune logo</strong></p>

      <p><strong>CARYN on-camera:</strong></p>

      <p>My name is Caryn. My background is that I’m a diabetes educator, dietician and health coach, and I have been providing support and education to patients and families for around 20 years now.</p>

      <p>I started working with Rhythm in August of 2021.</p>

      <p><strong>Graphic:</strong></p>

<p><strong>My Role</strong></p>

      <p>The most rewarding thing about my job is truly just getting to know all of the families. I really love having that opportunity to get to learn more about you, to figure out what I can do to help support you, to be there throughout your journey of getting access to treatment.</p>

      <p><strong>Graphic:</strong></p>

<p><strong>The Best Part of My Job</strong></p>

      <p>As a mother of a child with special needs, I do know how isolating and lonely it can be at times, especially when you are trying to get access to a treatment or therapy that you and your doctor know is really important for you and your family,</p>

      <p>It truly just brings me so much joy to be able to take a little bit of that stress away from you and your family, and hopefully provide a little bit of hope.</p>

      <p><strong>Graphic:</strong></p>

      <p className='text-center'><strong>Patient Education Managers (PEMs) are employees of Rhythm Pharmaceuticals, Inc. The information they provide is for educational purposes only and should not be construed as medical advice or healthcare treatment recommendations. The content on this website is not intended to be a substitute for professional medical advice, diagnosis, or treatment. Always seek the advice of your treating physician with any questions regarding your medical care or treatment plan.</strong></p>

      <p className='text-center'><strong>US-SET-2300065 06/2023</strong></p>

    </>
  )
}

export default PemCarynTranscript
