import React from "react"
import CalloutBox from "./callout-box"
import Divider from "./Divider"
import BoxCTA from "./BoxCTA"
import NewModal from "./NewModal"
import MC4RPathway from "../images/mc4r-pathway-impairment.png"

const TypesOfObesityChart = props => {
  const [showModal, setShowModal] = React.useState(false)

  return (
    <div id="types-of-obesity-chart">
      <div className="row">
        <div className="small-12 large-expand">
          <CalloutBox
            headerStyles={{ backgroundColor: "#4D4D4D" }}
            contentStyles={{ backgroundColor: "#EFF5F7" }}
            headerCopy="General obesity<sup>5,6</sup>"
          >
            <p>
              <strong>Occurrence:</strong> Can occur at any age, including later
              in life
            </p>
            <p>
              <strong>Cause:</strong> Interaction of multiple factors,
              including:
            </p>
            <ul className="ul--standard" style={{ columns: 2 }}>
              <li>Age/race/gender</li>
              <li>Concurrent illnesses </li>
              <li>Common genetic variants </li>
              <li>Concomitant medications </li>
              <li>Environmental factors</li>
              <li>Nutrition and physical activity</li>
            </ul>
          </CalloutBox>
        </div>

        <Divider />
        {/* <div className="divider" /> */}

        <div className="small-12 large-expand">
          <CalloutBox
            headerStyles={{ backgroundColor: "#DF5F48" }}
            contentStyles={{ backgroundColor: "#FDF7F6" }}
            headerCopy="MC4R pathway driven obesity<sup>2,5,7</sup>"
          >
            <p>
              <strong>Occurrence: </strong>
              Impairment is present at birth and leads to{" "}
              <strong>hyperphagia </strong>
              (insatiable hunger) and <strong>early-onset obesity</strong>
            </p>
            <ul className="ul--standard">
              <li>
                May develop as early as a few months old; often appears in early
                childhood and continues into adolescence and adulthood
              </li>
            </ul>
            <p style={{ marginTop: 12 }}>
              <strong>Cause: </strong>
              Rare genetic variants in the MC4R pathway, a signaling pathway in
              the hypothalamus
            </p>

            <BoxCTA
              previewText={
                "Understanding the role of the MC4R pathway in obesity and hunger"
              }
              modalText={""}
              onPlusClick={() => setShowModal(true)}
            />
          </CalloutBox>
        </div>
      </div>

      <NewModal
        heading="THE ROLE OF THE MC4R PATHWAY IN HYPERPHAGIA AND OBESITY"
        open={showModal}
        onClose={() => setShowModal(false)}
      >
        <img src={MC4RPathway} alt="MC4R Pathway" />
        <p style={{ textAlign: "center", marginTop: 24, fontWeight: "bold" }}>
          MC4R pathway impairment impacts your patient's obesity trajectory and
          hyperphagia
        </p>
      </NewModal>
    </div>
  )
}

export default TypesOfObesityChart
