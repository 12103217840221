import React, {useEffect} from "react"
import PropTypes from "prop-types"

import Layout from "../../components/bbs-layout"
import Seo from "../../components/seo"
import Button from "../../components/button"
import Helmet from 'react-helmet'

export default function ThankYouBBS(props) {

return (
    <>
    <Layout>
        <Helmet bodyAttributes={{ class: 'patient-bbs thankyou'}}/>
        <Seo
          title="Sign Up For Updates - Thank you! | IMCIVREE® (setmelanotide) injection"
          description="Learn about Rhythm InTune, a support program designed for caregivers and people living with rare genetic diseases of obesity."
          ogTitle="Sign Up For Updates - Thank you!| IMCIVREE® (setmelanotide) injection"
          ogDesc="Learn about Rhythm InTune, a support program designed for caregivers and people living with rare genetic diseases of obesity."
        />
        <div className="inner-container mt-2 md_mt-2">
            <section className="register-section">
                <div className="register-wrapper">
                    <div id="bbs-section" className="text-center">
                        <h1>Thank You</h1>
                        <p className="mb-3 md_mb-3">Thank you for signing up for updates about IMCIVREE!</p>
                        <Button copy="Go back to IMCIVREE homepage" url="/bbs/" />
                    </div>
                </div>
            </section>
        </div>
    </Layout>
    </>
  )
}

ThankYouBBS.prototype ={
    siteTitle: PropTypes.string,
}

ThankYouBBS.defaultProps = {
  siteTitle: ``,
}
