import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/ppl-layout"
import Seo from "../components/seo"
import VideoTranscript from "../components/video-transcript"
import TakingImcivreeVideo from "../components/taking-imcivree-video"
import HowToInjectTranscript from "../components/template-partials/patient/how-to-inject-transcript"

const Sitemap = () => (
  <Layout noNav>
    <Seo title="Taking IMCIVREE | IMCIVREE® (setmelanotide) injection" />
    <div className="injection-video content-block">
      <div className="row og">
        <div className="columns og">
          <h1 className="h1">How to Inject IMCIVREE</h1>
        </div>
      </div>
      <TakingImcivreeVideo/>
      <VideoTranscript className="lg_mt-2 mt-2">
        <HowToInjectTranscript/>
      </VideoTranscript>
    </div>
  </Layout>
)

export default Sitemap
