import * as React from "react"
import { Link } from "gatsby"

import Layout from "../../components/ppl-layout"
import Seo from "../../components/seo"

const Sitemap = () => (
  <Layout noNav>
    <Seo
      title="Sitemap | IMCIVREE® (setmelanotide) injection"
      description="Explore the IMCIVREE® (setmelanotide) patient sitemap."
      ogTitle="Sitemap | IMCIVREE® (setmelanotide) injection"
      ogDesc="Explore the IMCIVREE® (setmelanotide) patient sitemap."
    />
    <div className="sitemap content-block">
      <h1 className="h1">Sitemap</h1>
      <ul style={{ marginLeft: '0.3rem' }}>
        <li><Link to="/ppl/">Home</Link></li>
        <li><Link to="/ppl/disease-overview/">Diseases Overview</Link></li>
        <li className="lg_mt-1 mt-1"><span>About IMCIVREE</span>
          <ul>
            <li><Link to="/ppl/how-it-works/">How does IMCIVREE work?</Link></li>
            <li><Link to="/ppl/clinical-study-results/">How was IMCIVREE studied?</Link></li>
            <li><Link to="/ppl/effect-of-imcivree/">Weight & hunger reduction</Link></li>
            <li><Link to="/ppl/side-effects/">Side effects</Link></li>
          </ul>
        </li>
        <li className="mt-1 lg_mt-1"><span>Starting IMCIVREE</span>
          <ul>
            <li><Link to="/ppl/how-imcivree-is-given/">How is IMCIVREE given?</Link></li>
            <li><Link to="/ppl/getting-started/">Getting started on IMCIVREE</Link></li>
            <li><Link to="/ppl/understanding-your-dose/">Understanding your dose</Link></li>
          </ul>
        </li>
        <li className="mt-1 lg_mt-1"><span>Support &amp; Resources</span>
          <ul>
            <li><Link to="/ppl/rhythm-intune/">Rhythm InTune</Link></li>
            <li><Link to="/ppl/patient-access/">Patient access </Link></li>
            <li><Link to="/ppl/helpful-resources/">Helpful resources</Link></li>
            <li><Link to="/ppl/faq/">Frequently asked questions</Link></li>
          </ul>
        </li>
      </ul>
    </div>
  </Layout>
)

export default Sitemap
