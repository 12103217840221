import React from "react"

import IconPhone from "../images/icons/icon-phone.svg"
import IconEmail from "../images/icons/icon-email.svg"

const ContactInfoPpl = () => {
  return (
    <div className="contact-info row">
      <div className="columns small-12 medium-2 medium-offset-3 text-center">
        <img src={IconPhone} alt="Rhythm InTune phone" />
        <a href="tel:18552060815" className="underline d-block">
          <strong className="nowrap">1-855-206-0815</strong>
        </a>
      </div>
      <div className="columns medium-3 medium-offset-1 small-12 text-center mt-1">
        <img src={IconEmail} alt="Rhythm InTune email" />
        <a href="mailto:patientsupport@rhythmtx.com" className="underline d-block">
          <strong>patientsupport@rhythmtx.com</strong>
        </a>
      </div>
    </div>
  )
}

export default ContactInfoPpl
