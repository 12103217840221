import * as React from "react"
import { Link } from "gatsby"

import Layout from "../../../components/hcp-bbs-layout"
import Seo from "../../../components/seo"

const Sitemap = () => (
  <Layout noNav>
    <Seo
      title="Sitemap | IMCIVREE® (setmelanotide) injection"
      description="Explore the IMCIVREE® (setmelanotide) sitemap."
      ogTitle="Sitemap | IMCIVREE® (setmelanotide) injection"
      ogDesc="Explore the IMCIVREE® (setmelanotide) sitemap."
    />
    <div className="sitemap content-block">
      <h1 className="h1">Sitemap</h1>
      <ul style={{ marginLeft: '0.3rem' }}>
        <li><Link to="/hcp/bbs/">Home</Link></li>
        <li className="lg_mt-1 mt-1">BBS Diagnosis
          <ul>
          <li><Link to="/hcp/bbs/diagnosis/">BBS diagnosis</Link></li>
          <li><Link to="/hcp/bbs/overview/">BBS overview</Link></li>
          <li><Link to="/hcp/bbs/types-of-obesity/">Types of obesity</Link></li>
          <li><Link to="/hcp/bbs/hyperphagia/">Hyperphagia in BBS</Link></li>
          </ul>
        </li>
        <li style={{marginTop: '1rem'}}><Link to="/hcp/bbs/moa/">Mechanism of Action</Link></li>
        <li className="lg_mt-1 mt-1">Efficacy
          <ul>
            <li><Link to="/hcp/bbs/study-design/">Study design</Link></li>
            <li><Link to="/hcp/bbs/pediatric-bmi-z-score-reduction/">Pediatric BMI Z-score reduction</Link></li>
            <li><Link to="/hcp/bbs/adult-weight-reduction/">Adult weight reduction</Link></li>
            <li><Link to="/hcp/bbs/hunger-reduction/">Hunger reduction</Link></li>
            <li><Link to="/hcp/bbs/quality-of-life/">Quality of life</Link></li>

          </ul>
        </li>
        <li className="lg_mt-1 mt-1"><Link to="/hcp/bbs/safety-profile/">Safety Profile</Link></li>

        <li className="lg_mt-1 mt-1"><Link to="/hcp/bbs/dosing/">Dosing &amp; Administration</Link></li>
        <li><Link to="/hcp/bbs/prescribing-imcivree/">Prescribing IMCIVREE</Link></li>
        <li className="lg_mt-1 mt-1"><span>Patient Support &amp; Resources</span>
          <ul>
            <li><Link to="/hcp/bbs/patient-support/">Patient support</Link></li>
            <li><Link to="/hcp/bbs/webinars-downloadable-resources/">Webinars &amp; downloadable resources</Link></li>
            <li><Link to="/hcp/bbs/faq/">Frequently asked questions</Link></li>
          </ul>
        </li>
      </ul>
    </div>
  </Layout>
)

export default Sitemap
