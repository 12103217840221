import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Layout from "../../../components/hcp-bbs-layout"
import Seo from "../../../components/seo"
import HangingRef from "../../../components/hanging-ref"
import * as Utils from "../../../helpers/utils"

export default function SignUp(props) {
  const [utm, setUTM] = useState("")

  useEffect(() => {
    //check for utm
    setUTM(Utils.getUTM())
  }, [])

  const handleSubmit = event => {
    // Fetch all the forms we want to apply custom Bootstrap validation styles to
    let form = document.getElementById("hcp-register")
    let errorElement = document.getElementById("error-message")
    let redirectURLField = document.getElementById("tfa_59")
    let submitBtn = document.getElementById("submit")

    //fix - manually set invalid
    /*
    const cred = document.querySelector("#tfa_13")
    if (cred.selectedIndex == 0) {
      cred.setCustomValidity("invalid")
    } else {
      cred.setCustomValidity("")
    }
    */

    const sp = document.querySelector("#tfa_22")
    if (sp.selectedIndex == 0) {
      sp.setCustomValidity("invalid")
    } else {
      sp.setCustomValidity("")
    }

    const certified = document.querySelector("#tfa_111")
    if (form.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()
      errorElement.classList.remove("hide-error")
      errorElement.classList.add("show-error")
      form.classList.add("submit-attempted")
      window.scrollTo(0, 0)
    } else {
      event.preventDefault()
      event.stopPropagation()
      errorElement.classList.remove("hide-error")
      submitBtn.setAttribute("disabled", "")

      let submitFormFunction = Object.getPrototypeOf(form).submit
      submitFormFunction.call(form)
    }
  }

  return (
    <>
      <Layout>
        <Seo
          title="Contact a Representative | IMCIVREE® (setmelanotide) injection | For HCPs"
          description="Sign up to be contacted by a representative and receive updates about IMCIVREE. Please see full Prescribing Information for Important Safety Information."
          ogTitle="Contact a Representative | IMCIVREE® (setmelanotide) injection | For HCPs"
          ogDesc="Sign up to be contacted by a representative and receive updates about IMCIVREE. Please see full Prescribing Information for Important Safety Information."
        />
        <div className="inner-container">
          <section className="register-section">
            <div className="register-wrapper">
              <div className="row lg_mt-2 mt-1">
                <div className="hidden-mobile col-lg-2"></div>
                <div className="col-12 col-lg-8">
                  <div id="register-form-section">
                    <h1>Sign up to be contacted by a Rhythm representative</h1>
                    <h4>and receive updates about IMCIVREE</h4>
                    <p>
                    All fields required unless otherwise noted. For US healthcare professional only.
                    </p>
                    <p id="error-message" className="hide-error">
                      It seems one or more required fields are empty or
                      incorrect. Please check below and try again.
                    </p>

                    <div>
                      <form
                        id="hcp-register"
                        action="https://www.tfaforms.com/api_v2/workflow/processor"
                        noValidate
                        method="post"
                      >
                        <fieldset>
                          <input type="checkbox" id="tfa_89" name="tfa_89" />
                          <label htmlFor="tfa_89">
                          <strong>I’d like to be contacted by a Rhythm representative.*</strong> Whether you have questions about IMCIVREE or want to know more about diagnosing BBS, we are here to help. (optional)
                          </label>
                        </fieldset>
                        <fieldset>
                          <input type="checkbox" id="tfa_93" name="tfa_93" />
                          <label htmlFor="tfa_93">
                            <strong>I'd like to receive email updates about IMCIVREE.*</strong> Opt in to receive information that may support you along the diagnosis and treatment continuum. (optional)
                          </label>
                        </fieldset>
                        <fieldset>
                          <label htmlFor="tfa_3">First name</label>
                          <br />
                          <input
                            defaultValue=""
                            required
                            aria-required="true"
                            type="text"
                            id="tfa_3"
                            name="tfa_3"
                            pattern="[A-Za-z-_ ]{1,32}"
                            title="First name"
                            className="required"
                          />
                          <br />
                        </fieldset>
                        <fieldset>
                          <label htmlFor="tfa_5">Last name</label>
                          <br />
                          <input
                            defaultValue=""
                            required
                            aria-required="true"
                            type="text"
                            id="tfa_5"
                            name="tfa_5"
                            pattern="[A-Za-z-_ ]{1,32}"
                            title="Last name"
                            className="required"
                          />
                          <br />
                        </fieldset>
                        <fieldset>
                          <label htmlFor="tfa_7">Email</label>
                          <br />
                          <input
                            defaultValue=""
                            required
                            aria-required="true"
                            type="text"
                            id="tfa_7"
                            name="tfa_7"
                            pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,100}$"
                            title="Email "
                            className="validate-email required"
                          />
                          <br />
                        </fieldset>
                        <fieldset>
                          <label htmlFor="tfa_9">Phone (optional)</label>
                          <br />
                          <input
                            defaultValue=""
                            type="text"
                            id="tfa_9"
                            name="tfa_9"
                            maxLength="12"
                            placeholder="###-###-####"
                            pattern="[0-9]{3}-?[0-9]{3}-?[0-9]{4}"
                            autoformat="###-###-####"
                            title="Phone (optional)"
                            className="validate-custom ^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$"
                          />
                          <br />
                        </fieldset>
                        <fieldset>
                          <label htmlFor="zip">ZIP</label>
                          <br />
                          <input
                            defaultValue=""
                            required
                            aria-required="true"
                            type="text"
                            id="tfa_12"
                            name="tfa_12"
                            pattern="[0-9]{5}"
                            maxLength="5"
                            title="ZIP"
                            className="validate-integer required"
                          />
                          <br />
                        </fieldset>
                        <fieldset>
                          <label htmlFor="tfa_22">
                            Please select a specialty
                          </label>
                          <br />
                          <select
                            required
                            aria-required="true"
                            id="tfa_22"
                            name="tfa_22"
                            title="Please select a specialty"
                            className="required"
                          >
                            <option>Select a specialty</option>

                            <option value="tfa_52" id="tfa_52" className="">
                              Bariatric surgery
                            </option>

                            <option value="tfa_55" id="tfa_55" className="">
                              Bariatrics
                            </option>

                            <option value="tfa_53" id="tfa_53" className="">
                              Cardiologist
                            </option>

                            <option value="tfa_85" id="tfa_85" className="">
                              Endocrinologist
                            </option>

                            <option value="tfa_56" id="tfa_56" className="">
                              Family medicine
                            </option>

                            <option value="tfa_86" id="tfa_86" className="">
                              Gastroenterologist
                            </option>

                            <option value="tfa_57" id="tfa_57" className="">
                              General practitioner
                            </option>

                            <option value="tfa_94" id="tfa_94" className="">
                              General surgeon
                            </option>

                            <option value="tfa_95" id="tfa_95" className="">
                              Genetic counselor
                            </option>

                            <option value="tfa_96" id="tfa_96" className="">
                              Internal medicine
                            </option>

                            <option value="tfa_97" id="tfa_97" className="">
                              Medical geneticist
                            </option>

                            <option value="tfa_98" id="tfa_98" className="">
                              Nephrologist
                            </option>

                            <option value="tfa_99" id="tfa_99" className="">
                              Neurologist
                            </option>

                            <option value="tfa_100" id="tfa_100" className="">
                              Nutritionist
                            </option>

                            <option value="tfa_101" id="tfa_101" className="">
                              Obstetrician/Gynecologist
                            </option>

                            <option value="tfa_102" id="tfa_102" className="">
                              Pediatric cardiologist
                            </option>

                            <option value="tfa_103" id="tfa_103" className="">
                              Pediatric endocrinologist
                            </option>

                            <option value="tfa_104" id="tfa_104" className="">
                              Pediatric nephrologist
                            </option>

                            <option value="tfa_105" id="tfa_105" className="">
                              Pediatrician
                            </option>

                            <option value="tfa_58" id="tfa_58" className="">
                              Other
                            </option>
                          </select>
                        </fieldset>
                        <fieldset>
                          <input type="checkbox" id="tfa_111" name="tfa_111" />
                          <label htmlFor="tfa_111">
                              <strong>I am a certified obesity treater. (optional)</strong>
                          </label>
                        </fieldset>
                        <fieldset>
                          <label htmlFor="tfa_84">
                            Please enter your NPI (optional)
                          </label>
                          <br />
                          <input
                            defaultValue=""
                            aria-required="true"
                            type="text"
                            id="tfa_84"
                            name="tfa_84"
                            pattern="[0-9]{10}"
                            maxLength="10"
                            title="NPI"
                          />
                          <br />
                          <a
                            rel="noopener noreferrer"
                            href="https://npiregistry.cms.hhs.gov/search"
                            target="_blank"
                          >
                            <strong>
                              <u>Lookup NPI</u>
                            </strong>
                          </a>
                          <br />
                        </fieldset>
                        <fieldset>
                          <div className="mb-0 md_mb-0 text-left">
                            <strong>
                              Tell us how we can best support your needs
                              (optional)
                            </strong>
                          </div>
                          <input
                            type="checkbox"
                            value="tfa_107"
                            className=""
                            id="tfa_107"
                            name="tfa_107"
                            aria-labelledby="tfa_107-L"
                            data-tfa-labelledby="tfa_107-L tfa_107-L"
                          />
                          <label
                            className="label postField"
                            id="tfa_107-L"
                            htmlFor="tfa_107"
                          >
                            I have patients in my practice who may benefit from
                            IMCIVREE
                          </label>
                          <input
                            type="checkbox"
                            value="tfa_108"
                            className=""
                            id="tfa_108"
                            name="tfa_108"
                            aria-labelledby="tfa_108-L"
                            data-tfa-labelledby="tfa_108-L tfa_108-L"
                          />
                          <label
                            className="label postField"
                            id="tfa_108-L"
                            htmlFor="tfa_108"
                          >
                            I would like to know more about this treatment
                            option
                          </label>
                          <input
                            type="checkbox"
                            value="tfa_109"
                            className=""
                            id="tfa_109"
                            name="tfa_109"
                            aria-labelledby="tfa_109-L"
                            data-tfa-labelledby="tfa_109-L tfa_109-L"
                          />
                          <label
                            className="label postField"
                            id="tfa_109-L"
                            htmlFor="tfa_109"
                          >
                            I want to expand my knowledge about BBS and its
                            diagnosis
                          </label>
                        </fieldset>
                        {/* <fieldset>
                          <input
                            required
                            type="checkbox"
                            defaultValue="tfa_43"
                            className=""
                            id="tfa_43"
                            name="tfa_43"
                            aria-labelledby="tfa_43-L"
                            data-tfa-labelledby="tfa_42-L tfa_43-L"
                          />
                          <label htmlFor="tfa_43">
                             <>
                                I agree to receive information from Rhythm Pharmaceuticals about its products and services by email, text, or phone. I understand that I may opt-out of email messages by following the unsubscribe link in the message or as detailed in Rhythm Pharmaceuticals<sup>&trade;</sup> <a href="https://rhythmtx.com/privacy-policy/" target="_blank">Privacy Policy</a>. By providing my phone number, I agree to receive phone calls and/or text messages from Rhythm Pharmaceuticals. I understand that, once enrolled, the frequency of text alerts from Rhythm Pharmaceuticals will vary. I understand that consent is not required to purchase goods or services and that standard message and data rates of my carrier may apply. To stop text messages from Rhythm Pharmaceuticals, reply STOP.
                              </>
                          </label>
                          <br />
                        </fieldset> */}
                        {/*
                        <fieldset>
                          <input
                            type="checkbox"
                            value="tfa_83"
                            className=""
                            id="tfa_83"
                            name="tfa_83"
                            aria-labelledby="tfa_83-L"
                            data-tfa-labelledby="tfa_82-L tfa_83-L"
                          />
                          <label
                            className="label postField"
                            id="tfa_83-L"
                            htmlFor="tfa_83"
                          >
                            I would like to be put in communication with an
                            IMCIVREE representative.
                          </label>
                        </fieldset>
                        */}
                        <fieldset>
                          <span htmlFor="submit" className="submit-btn">
                            <input
                              type="submit"
                              id="submit"
                              className="btn"
                              onClick={e => handleSubmit(e)}
                              name="submit"
                              //defaultValue="Submit"
                              value="Submit"
                            />
                          </span>
                          <input
                            type="hidden"
                            defaultValue="5080096"
                            name="tfa_dbFormId"
                            id="tfa_dbFormId"
                          />
                          <input
                            type="hidden"
                            defaultValue=""
                            name="tfa_dbResponseId"
                            id="tfa_dbResponseId"
                          />
                          <input
                            type="hidden"
                            defaultValue="03073af111ae407f044edab7ac55283e"
                            name="tfa_dbControl"
                            id="tfa_dbControl"
                          />
                          <input
                            type="hidden"
                            defaultValue=""
                            name="tfa_dbWorkflowSessionUuid"
                            id="tfa_dbWorkflowSessionUuid"
                          />
                          <input
                            type="hidden"
                            defaultValue="1691598372"
                            name="tfa_dbTimeStarted"
                            id="tfa_dbTimeStarted"
                            autoComplete="off"
                          />
                          <input
                            type="hidden"
                            defaultValue="7"
                            name="tfa_dbVersionId"
                            id="tfa_dbVersionId"
                          />
                          <input
                            type="hidden"
                            defaultValue=""
                            name="tfa_switchedoff"
                            id="tfa_switchedoff"
                          />
                          <input
                            type="hidden"
                            id="tfa_73"
                            name="tfa_73"
                            defaultValue="BBS"
                            className=""
                          ></input>
                          <input
                            type="hidden"
                            id="tfa_59"
                            name="tfa_59"
                            defaultValue="https://imcivree.com/hcp/bbs/thankyou/"
                            className=""
                          />
                          <input
                            type="hidden"
                            id="tfa_87"
                            name="tfa_87"
                            defaultValue={utm}
                          />
                        </fieldset>
                        <br />
                      </form>
                      <div className="row">
                        <div className="column small-12" id="signup-references">
                          <HangingRef
                            mb0
                            symbol="*"
                            isAsterisk
                            copy={
                              <>
                              I agree to receive information from Rhythm Pharmaceuticals about its products and services by email, text, or phone. I understand that I may opt-out of email messages by following the unsubscribe link in the message or as detailed in Rhythm Pharmaceuticals<sup>&trade;</sup> <a href="https://rhythmtx.com/privacy-policy/" target="_blank">Privacy Policy</a>. By providing my phone number, I agree to receive phone calls and/or text messages from Rhythm Pharmaceuticals. I understand that, once enrolled, the frequency of text alerts from Rhythm Pharmaceuticals will vary. I understand that consent is not required to purchase goods or services and that standard message and data rates of my carrier may apply. To stop text messages from Rhythm Pharmaceuticals, reply STOP.
                              </>
                            }
                          />
                         
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="hidden-mobile col-lg-2"></div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    </>
  )
}

SignUp.prototype = {
  siteTitle: PropTypes.string,
}

SignUp.defaultProps = {
  siteTitle: ``,
}
