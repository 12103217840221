import * as React from "react"

import Layout from "../../../components/hcp-ppl-layout"
import Seo from "../../../components/seo"

import Callout from "../../../components/callout"
import Button from "../../../components/button"
import ContactInfoHcpPpl from "../../../components/contact-info-hcp-ppl"
import Modal from "../../../components/modal"
import HangingRef from "../../../components/hanging-ref"

import RhythmInTuneImg from "../../../images/hcp/ppl/rhythm-intune-hcp.png"
import Book from "../../../images/icons/icon-book.svg"
import Community from "../../../images/icons/icon-community.svg"
import Insurance from "../../../images/icons/icon-insurance.svg"
import Calendar from "../../../images/icons/icon-calendar.svg"

import LearnMoreAboutPemVideo from "../../../components/learn-more-about-patient-education-managers-video"
import VideoTranscript from "../../../components/video-transcript"
import LearnMoreAboutPemVideoTranscript from "../../../components/template-partials//hcp/bbs/learn-more-about-patient-education-managers-transcript"

const PatientSupport = () => (
  <Layout pageId="patient-support">
    <Seo
      title="Rhythm InTune Patient Support | IMCIVREE® (setmelanotide) injection | For HCPs"
      description="Rhythm InTune is a support program designed for people living with a rare genetic disease of obesity and their caregivers. Please see full Prescribing Information for Important Safety Information."
      ogTitle="Patient Support | IMCIVREE® (setmelanotide) injection"
      ogDesc="Rhythm InTune is a support program designed for people living with a rare genetic disease of obesity and their caregivers. Please see full Prescribing Information for Important Safety Information."
    />
    <div className="content-block">
      <div className="row og align-left align-middle mb-1 md_mb-3">
        <div className="columns og small-12 medium-3 text-center">
          <img
            src={RhythmInTuneImg}
            alt="Rhythm InTune Logo"
            width="250"
            className="mt-1 mb-1"
          />
        </div>
        <div className="columns og small-12 medium-9">
          <h1 className="h1 mb-0 md_mb-0" style={{ lineHeight: `1` }}>
          Personalized support for people living with rare genetic diseases of obesity
          </h1>
        </div>
      </div>
      <div className="row og align-left">
        <div className="columns og small-12">
          <h1 className="h1">
            Rhythm InTune is here to help every person with a rare genetic disease of obesity—regardless of whether they're on IMCIVREE
          </h1>
        </div>
      </div>

      <div className="row og align-left">
        <div className="columns og large-6 small-12">
          <h2 className="h2">
            A Rhythm InTune Patient Education Manager* is a single point of contact who can help people living with a rare genetic disease of obesity and their caregivers:
          </h2>
          <div className="row og align-middle align-center">
            <div className="columns og large-4 small-12 text-left text-sm-center mb-2">
              <img src={Book} alt="Rhythm InTune resources" />
            </div>
            <div className="columns og large-8 mb-2">
              <p className="mb-0 text-left">
                <strong>Access educational resources,</strong> such as virtual education programs about treatment with IMCIVREE.
              </p>
            </div>
          </div>

          <div className="row og lg_mt-2 mt-2 align-middle align-center">
            <div className="columns og large-4 small-12 text-left text-sm-center mb-2">
              <img src={Community} alt="Rhythm InTune community" />
            </div>
            <div className="columns og large-8 mb-2">
              <p className="mb-0 text-left">
                <strong>Connect to a community</strong> where they can learn
                from the experiences of others.
              </p>
            </div>
          </div>

          <div className="row og lg_mt-2 mt-2 align-middle align-center">
            <div className="columns og large-4 small-12 text-left text-sm-center mb-2">
              <img src={Insurance} alt="Rhythm InTune coverage" />
            </div>
            <div className="columns og large-8 mb-2">
              <p className="mb-0 text-left">
                <strong>Access treatment</strong> by helping to understand drug
                coverage, prior authorizations, appeals support, and, for
                eligible patients, copay support and financial assistance.
              </p>
            </div>
          </div>

          <div className="row og lg_mt-2 mt-2 align-middle align-center mb-2">
            <div className="columns og large-4 small-12 text-left text-left text-sm-center mb-2">
              <img src={Calendar} alt="Rhythm InTune calendar" />
            </div>
            <div className="columns og large-8 mb-2">
              <p className="mb-0 text-left">
                <strong>Get started on treatment</strong> by coordinating
                IMCIVREE deliveries and injection support and training with the
                specialty pharmacy.
              </p>
            </div>
          </div>
        </div>
        <div className="columns og large-6 small-12  md_pl-2">
          <h2 className="h2">
            For more information about the services Rhythm InTune provides,
            contact us at:
          </h2>
          <div className="lg_mt-3 mt-2">
            <ContactInfoHcpPpl />
          </div>
        </div>
        <div className="md_ml-1 md_mt-3 md_mb-3">
          <Modal id="patient-access-hcp-ppl" className="color-black-bg" />
        <br className="show-for-small-only" />
      </div>
      </div>

      <Callout left className="light-teal left text-left">
        <p>
          Rhythm InTune is committed to providing people living with a rare
          genetic disease of obesity and their caregivers with support and tools
          throughout their treatment journey
        </p>
      </Callout>

      <div className="lg_mt-3 mt-2">
        <HangingRef
          isAsterisk
          mb0
          symbol="*"
          copy="Patient Education Managers are
        employees of Rhythm Pharmaceuticals and do not provide medical care or
        advice. We encourage your patients to always speak to their healthcare
        providers regarding their medical care."
        />
      </div>
    </div>

    <div className="content-block">
      <div className="row og">
        <div className="columns og">
          <h3 className="h1">Learn more about Patient Education Managers</h3>
          <p className="h2">
            See how they can provide optimal support for your patients and help
            them start and stay on therapy
          </p>
          <LearnMoreAboutPemVideo />
          <VideoTranscript>
            <LearnMoreAboutPemVideoTranscript />
          </VideoTranscript>
        </div>
      </div>
      <div className="text-center mt-6 md_mt-6">
        <Button
          copy="See patient resources"
          url="/hcp/ppl/patient-resources/"
        />
      </div>
    </div>
  </Layout>
)

export default PatientSupport
