import React from 'react'

const AdverseEventsVideo = (props) => {
  return (
    <>
    <div className="row og">
      <div className={`columns og responsive-video-container ` + props.className}>
        <div class="responsive-embed widescreen">
          <iframe src="https://player.vimeo.com/video/876123305?h=12d1e50721" width="100%" height="auto" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen title="Managing possible side effects with IMCIVREE"></iframe>
        </div>
      </div>
    </div>
    </>
  )
}

export default AdverseEventsVideo

