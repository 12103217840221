import React from "react"

import Layout from "../../components/bbs-layout"
import Seo from "../../components/seo"
import Accordion from "../../components/template-partials/global-components/accordion/accordion"
import Callout from "../../components/callout"
import ContactInfo from "../../components/contact-info"

const Faq = () => {
  return (
    <Layout>
      <Seo
        title="Frequently Asked Questions | IMCIVREE® (setmelanotide) injection"
        description="Get answers to frequently asked questions about treatment with IMCIVREE® (setmelanotide). Please see full Prescribing Information and Important Safety Information."
        ogTitle="Frequently Asked Questions | IMCIVREE® (setmelanotide) injection"
        ogDesc="Get answers to frequently asked questions about treatment with IMCIVREE® (setmelanotide). Please see full Prescribing Information and Important Safety Information."
      />
      <div id="faq-accordian-container" className="content-block">
        <h1 className="h1">Frequently asked questions about IMCIVREE</h1>

        <Accordion className="mb-2 md_mb-3">
          <Accordion.Item isOpen="true">
            <Accordion.Drawer>
              Do I need to take my IMCIVREE every day?
            </Accordion.Drawer>
            <Accordion.Expanded>
              <div className="columns">
                <p className="mb-0">
                  Yes, IMCIVREE should be injected once daily at the beginning
                  of the day.
                </p>
              </div>
            </Accordion.Expanded>
          </Accordion.Item>
          <Accordion.Item>
            <Accordion.Drawer>
              What time of day should I take IMCIVREE?
            </Accordion.Drawer>
            <Accordion.Expanded>
              <div className="columns">
                <p className="mb-0">
                  IMCIVREE should be injected once daily, at the beginning of
                  the day, and can be given at home, with or without food.
                </p>
              </div>
            </Accordion.Expanded>
          </Accordion.Item>
          <Accordion.Item>
            <Accordion.Drawer>
              Why is it important to take IMCIVREE every day?
            </Accordion.Drawer>
            <Accordion.Expanded>
              <div className="columns">
                <p className="mb-0">
                  IMCIVREE was studied and approved as a daily injection.
                </p>
              </div>
            </Accordion.Expanded>
          </Accordion.Item>
          <Accordion.Item>
            <Accordion.Drawer>
              What should I do if I miss a dose?
            </Accordion.Drawer>
            <Accordion.Expanded>
              <div className="columns">
                <p className="mb-0">
                  If a dose of IMCIVREE is missed, inject the next one at the
                  regularly scheduled time the next day. Do not take the missed
                  dose of IMCIVREE.
                </p>
              </div>
            </Accordion.Expanded>
          </Accordion.Item>
          <Accordion.Item>
            <Accordion.Drawer>
              Do I have to take IMCIVREE for an entire year before I start to
              lose weight?
            </Accordion.Drawer>
            <Accordion.Expanded>
              <div className="columns">
                <p className="mb-0">
                  Please note that each person is different, and results can
                  vary from one person to another. Please talk to your doctor
                  about your own treatment expectations. In the yearlong
                  clinical trial that studied IMCIVREE for weight reduction in
                  people with obesity and a clinical diagnosis of BBS, people
                  taking IMCIVREE started losing weight early on in treatment
                  and continued to lose weight over the course of their
                  treatment.
                </p>
              </div>
            </Accordion.Expanded>
          </Accordion.Item>
          <Accordion.Item>
            <Accordion.Drawer>
              Will nausea and vomiting get better the longer I take IMCIVREE?
            </Accordion.Drawer>
            <Accordion.Expanded>
              <div className="columns">
                <p>
                  We encourage you to reach out to your doctor for more
                  information about nausea and vomiting. Responses vary, but
                  reports of nausea and vomiting side effects in the clinical
                  trials mostly occurred within the first month of treatment,
                  and then sharply declined after 4 weeks. These side effects
                  typically lasted a few days.
                </p>
                <p>
                  In the yearlong clinical trial that studied IMCIVREE for
                  weight reduction in people with obesity and a clinical
                  diagnosis of BBS, nausea was reported by 26% of people taking
                  IMCIVREE, and vomiting was reported by 19% of people taking
                  IMCIVREE.
                </p>
                <p className="mb-0">
                  It’s also important to know that most nausea or vomiting
                  events in the clinical trials of IMCIVREE were mild and none
                  were severe.
                </p>
              </div>
            </Accordion.Expanded>
          </Accordion.Item>
          <Accordion.Item>
            <Accordion.Drawer>
              I have questions. Who should I contact?
            </Accordion.Drawer>
            <Accordion.Expanded>
              <div className="columns">
                <p className="mb-0">
                  Please reach out to your doctor if you have any questions
                  about IMCIVREE. For questions or personalized support, please
                  contact Rhythm InTune.
                </p>
              </div>
            </Accordion.Expanded>
          </Accordion.Item>
          <Accordion.Item>
            <Accordion.Drawer>
              How do I get more supplies if I’m running low?
            </Accordion.Drawer>
            <Accordion.Expanded>
              <div className="columns">
                <p className="mb-0">
                  If you're low on supplies, contact Rhythm InTune to order
                  more.
                </p>
              </div>
            </Accordion.Expanded>
          </Accordion.Item>
        </Accordion>
      </div>

      <div className="row">
        <div className="columns large-8 medium-10 small-12">
          <Callout fullWidth className="lime-teal left text-left">
            <p>
              If you have additional questions, contact your doctor or Rhythm
              InTune
            </p>
          </Callout>
        </div>
      </div>

      <div className="content-block">
        <div>&nbsp;</div>
        <div className="row">
          <div className="columns large-8 large-offset-3">
          <ContactInfo />
          </div>
        </div>

      </div>
    </Layout>
  )
}

export default Faq
