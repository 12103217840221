import React from "react"
import LineItem from "./template-partials/global-components/list-item"

const MostCommonClinicalFeaturesTable = props => {
  return (
    <table
      id="most-common-clinical-features-table"
      cellPadding={"0"}
      cellSpacing={"0"}
    >
      <thead>
        <tr>
          <th>Most common clinical features</th>
          <th>Clincial manifestations</th>
          <th>Potential assessments</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Hyperphagia</td>
          <td>
            <div className="row">
              <div className="columns">
                <ul className="ul--standard">
                  {[
                    "Pathological, insatiable hunger",
                    "Long time to satiation",
                    "Shorter duration of satiation",
                  ].map(item => {
                    return <LineItem copy={item} />
                  })}
                </ul>
              </div>
              <div className="columns">
                <ul className="ul--standard">
                  {[
                    "Prolonged feeling of hunger",
                    "Severe preoccupation with food and distress if denied food, often associated with abnormal food-seeking behaviors",
                  ].map(item => {
                    return <LineItem copy={item} />
                  })}
                </ul>
              </div>
            </div>
          </td>
          <td>
            <ul className="ul--standard">
              {[
                "Utilizing hyperphagia questionnaires, following up with patients/caregivers regarding their behaviors around food",
              ].map(item => {
                return <LineItem copy={item} />
              })}
            </ul>
          </td>
        </tr>

        <tr>
          <td>Obesity</td>
          <td>
            <ul className="ul--standard">
              {[
                "Early-onset truncal obesity",
                "Normal birth weight, followed by rapid weight gain",
              ].map(item => {
                return <LineItem copy={item} />
              })}
            </ul>
          </td>
          <td>
            <ul className="ul--standard">
              {[
                "Growth chart, tracking patients' BMI/BMI Z-score over time",
              ].map(item => {
                return <LineItem copy={item} />
              })}
            </ul>
          </td>
        </tr>

        <tr>
          <td>Visual impairment</td>
          <td>
            <div className="row">
              <div className="columns">
                <ul className="ul--standard">
                  {[
                    "Rod-cone dystrophy/retinitis pigmentosa (including night blindness, photophobia, legal blindness, diminution of color, overall loss of visual acuity)",
                  ].map(item => {
                    return <LineItem copy={item} />
                  })}
                </ul>
              </div>
              <div className="columns">
                <ul className="ul--standard">
                  {[
                    "Less common features may include:",
                    [
                      "Strabismus",
                      "Astigmatism",
                      "Cataracts",
                      "Color blindness",
                      "Macular edema and degeneration",
                      "Optic atrophy",
                    ],
                  ].map(item => {
                    return <LineItem copy={item} />
                  })}
                </ul>
              </div>
            </div>
          </td>
          <td>
            <ul className="ul--standard">
              {["Electroretinography test (for retinitis pigmentosa only)"].map(
                item => {
                  return <LineItem copy={item} />
                }
              )}
            </ul>
          </td>
        </tr>

        <tr>
          <td>Cognitive impairment</td>
          <td>
            <div className="row">
              <div className="columns">
                <ul className="ul--standard">
                  {[
                    "Developmental delay (gross motor, ﬁne motor, speech/language)",
                    "Mild to moderate learning difficulties",
                    "Speech delays, poor articulation, poor language interpretation",
                  ].map(item => {
                    return <LineItem copy={item} />
                  })}
                </ul>
              </div>
              <div className="columns">
                <ul className="ul--standard">
                  {[
                    "Behavioral problems (immaturity, frustration, obsessive/compulsive nature, poor concentration/hyperactivity)",
                    "Gaze avoidance",
                    "Lack of abstract thought",
                  ].map(item => {
                    return <LineItem copy={item} />
                  })}
                </ul>
              </div>
            </div>
          </td>
          <td>
            <ul className="ul--standard">
              {[
                "Developmental and/or neurocognitive assessment",
                "Routine developmental assessments from early childhood to adulthood",
                "Neuropsychiatric evaluation if signs/symptoms of atypical behaviors or mood disorder",
              ].map(item => {
                return <LineItem copy={item} />
              })}
            </ul>
          </td>
        </tr>

        <tr>
          <td>Renal anomalies</td>
          <td>
            <div className="row">
              <div className="columns">
                <ul className="ul--standard">
                  {[
                    "Cystic tubular disease",
                    "Anatomical malformations",
                    "Urinary tract abnormalities",
                    "Hypertension",
                    "Chronic renal failure",
                    "Transplantation",
                    "Polyuria/polydipsia",
                    "Chronic tubulointerstitial nephritis",
                    "Glomerular defects",
                  ].map(item => {
                    return <LineItem copy={item} />
                  })}
                </ul>
              </div>
              <div className="columns">
                <ul className="ul--standard">
                  {[
                    "Urinary concentrating defects",
                    "Anatomical malformations at birth, including parenchymal cysts, calyceal cysts, calyceal clubbing and blunting, horseshoe kidney, fetal lobulation, scarring, unilateral renal agenesis, dysplastic kidneys, bladder obstruction, hydronephrosis, ectopic kidney, renal calculi, and vesicoureteral reﬂux",
                  ].map(item => {
                    return <LineItem copy={item} />
                  })}
                </ul>
              </div>
            </div>
          </td>
          <td>
            <ul className="ul--standard">
              {[
                "Regular monitoring/testing of renal function is recommended to diagnose and treat CKD early to prevent morbidity and mortality",
                "Ultrasound scan, isotope renography, labs (raised plasma urea and creatinine levels), intravenous pyelogram, renal ultrasonography, renal biopsy",
              ].map(item => {
                return <LineItem copy={item} />
              })}
            </ul>
          </td>
        </tr>

        <tr>
          <td>Digit abnormalities</td>
          <td>
            <div className="row">
              <div className="columns">
                <ul className="ul--standard">
                  {["Postaxial polydactyly", "Brachydactyly", "Syndactyly"].map(
                    item => {
                      return <LineItem copy={item} />
                    }
                  )}
                </ul>
              </div>
            </div>
          </td>
          <td>
            <ul className="ul--standard">
              {[
                "Physical examination or discussion with older patients/caregivers because extra digits are typically surgically removed in early childhood",
              ].map(item => {
                return <LineItem copy={item} />
              })}
            </ul>
          </td>
        </tr>

        <tr>
          <td>Genitourinary abnormalities</td>
          <td>
            <div className="row">
              <div className="columns">
                <ul className="ul--standard">
                  In males:
                  {[
                    "Hypogonadism",
                    "Micropenis, small-volume testes, maldescent of testes, cryptorchidism, hypogonadotropic hypogonadism, delayed puberty, infertility",
                  ].map(item => {
                    return <LineItem copy={item} />
                  })}
                </ul>
              </div>
              <div className="columns">
                <ul className="ul--standard">
                  In females:
                  {[
                    "Uterine, fallopian, ovarian, or vaginal hypoplasia or atresia",
                    "Low fertility rates",
                  ].map(item => {
                    return <LineItem copy={item} />
                  })}
                </ul>
              </div>
            </div>
          </td>
          <td>
            <ul className="ul--standard">
              {[
                "Check follicle-stimulating hormone, luteinizing hormone, estrogen, and testosterone levels if indicated due to delayed puberty",
                "Pelvic ultrasound in females to assess for malformations of uterus, fallopian tubes, ovaries, and vagina",
              ].map(item => {
                return <LineItem copy={item} />
              })}
            </ul>
          </td>
        </tr>
      </tbody>
    </table>
  )
}

export default MostCommonClinicalFeaturesTable
