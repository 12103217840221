import * as React from "react"
import Helmet from "react-helmet"

import Layout from "../../components/ppl-layout"
import Seo from "../../components/seo"
import Callout from "../../components/callout"
import Button from "../../components/button"
import Tabs from "../../components/template-partials/global-components/tabs/tabs"
import Modal from "../../components/modal"
//import Hypothalamus from "../../components/gatsby-images/hypothalamus"

import HypothalamusDesktop from "../../images/hypothalamus.png"
import HypothalamusMobile from "../../images/hypothalamus-mobile.png"

import IconDiabetes from "../../images/icon-diabetes.png"
import IconHyperphagia from "../../images/icon-hyperphagia.png"
import IconUpsetStomach from "../../images/icon-upset-stomach.png"
import IconKidneyDisease from "../../images/icon-kidney-disease.png"
import IconGenital from "../../images/icon-genital.png"

import iconRoad from "../../images/icons/icon-road.svg"
import iconTruck from "../../images/icons/icon-truck.svg"
import iconTrafficLight from "../../images/icons/icon-traffic-light.svg"

import HungerSignalsSvgDesktop from "../../images/patient-ppl-overview-hunger-signals-desktop.svg"
import HungerSignalsSvgMobile from "../../images/patient-ppl-overview-hunger-signals-mobile.svg"
import HungerTrafficSvgDesktop from "../../images/patient-ppl-hunger-traffic-light-desktop.svg"
import HungerTrafficSvgMobile from "../../images/patient-ppl-hunger-traffic-light-mobile.svg"

const DiseaseOverview = () => (
  <Layout>
    <Helmet bodyAttributes={{ class: "patient-ppl ppl-disease" }} />
    <Seo
      title="What are POMC, PCSK1, and LEPR deficiency? | IMCIVREE® (setmelanotide) injection"
      description="Learn about IMCIVREE® (setmelanotide), a prescription medicine used to treat obesity due to POMC, PCSK1, or LEPR deficiency. Please see full Prescribing Information for Important Safety Information."
      ogTitle="What is IMCIVREE? | IMCIVREE® (setmelanotide) injection"
      ogDesc="Learn about the diseases that IMCIVREE® (setmelanotide) treats, including causes and symptoms. Please see full Prescribing Information and Important Safety Information."
    />
    <div id="disease" className="content-block md_pb-0">
      <h1 className="h1">What are POMC, PCSK1, and LEPR deficiency?</h1>
      <h2 className="h2">
        POMC, PCSK1, and LEPR deficiency are all rare genetic diseases of
        obesity
      </h2>
      <div className="reasons row">
        <div className="column small-12 medium-6 large-4">
          <div className="row align-middle">
            <div className="column small-4 medium-5 border-0101">
              <div className="uppercase">POMC</div>
              <div>deficiency</div>
            </div>
            <div className="column small-1"></div>
            <div className="column small-7 medium-6">
              <p className="mb-0">POMC stands for proopiomelanocortin</p>
            </div>
          </div>
        </div>
        <div className="column small-12 medium-6 large-4 mt-1 md_mt-0 s">
          <div className="row align-middle">
            <div className="column small-4 medium-5 border-0101">
              <div className="uppercase">PCSK1</div>
              <div>deficiency</div>
            </div>
            <div className="column small-1"></div>
            <div className="column small-7 medium-6">
              <p className="mb-0">PCSK1 stands for proprotein convertase subtilisin/kexin type 1</p>
            </div>
          </div>
        </div>
        <div className="column small-12 medium-6 large-4 mt-1">
          <div className="row align-middle">
            <div className="column small-4 medium-5 border-0101">
              <div className="uppercase">LEPR</div>
              <div>deficiency</div>
            </div>
            <div className="column small-1"></div>
            <div className="column small-7 medium-6">
              <p className="mb-0">LEPR stands for leptin receptor</p>
            </div>
          </div>
        </div>
      </div>
      <p>
        These conditions occur when both copies of the relevant gene (one
        inherited from the mother and one from the father) have a specific
        change, or variant. These genetic variants affect how the brain controls
        feelings of hunger, which leads to weight gain.
      </p>
      <h2 className="h2 mt-0 md_mt-2">
        POMC, PCSK1, and LEPR deficiency have a wide range of symptoms that can
        impact many systems in the body
      </h2>
      <div className="row og">
        <div className="columns og">
          <Tabs>
            <Tabs.Pane name="POMC deficiency" key="1">
              <div className="row og md_mb-1 lg_mb-1">
                <div className="column og small-12 medium-3 left">
                  <img src={IconHyperphagia} alt="Brain icon" />
                  <p className="color-teal">
                    <strong>Brain</strong>
                  </p>
                  <ul className="neon-green-bullets">
                    <li>
                      Insatiable hunger (hyperphagia) and resulting obesity
                    </li>
                  </ul>
                </div>
                <div className="column og small-12 medium-3 center pt-1">
                  <img src={IconDiabetes} className="ppl-endo" alt="Endocrine icon" />
                  <p className="color-teal">
                    <strong>Endocrine</strong>
                  </p>
                  <ul className="neon-green-bullets">
                    <li>Low levels of the hormone that manages blood sugar and metabolism (adrenal insufficiency)</li>
                  </ul>
                </div>
                <div className="column og small-12 medium-6 right mt-1">
                  <p>
                    <b>Other symptoms may include:</b>
                  </p>
                  <ul className="neon-green-bullets">
                    <li>
                      <b>Endocrine:</b> Low thyroid levels (hypothyroidism),
                      adolescent-onset growth hormone deficiency, low blood sugar (hypoglycemia)
                    </li>
                    <li>
                      <b>Reproductive:</b> Delayed puberty caused by hormonal
                      imbalances (hypogonadotropic hypogonadism)
                    </li>
                    <li>
                      <b>Immune:</b> Red hair and light skin pigmentation
                    </li>
                    <li>
                      <b>Hepatic:</b> Liver failure
                    </li>
                  </ul>
                </div>
              </div>
            </Tabs.Pane>
            <Tabs.Pane name="PCSK1 deficiency" key="2">
              <div className="row og md_mb-1 lg_mb-1 space">
                <div className="column og small-12 medium-2">
                  <img src={IconHyperphagia} alt="Brain icon" />
                  <p className="color-teal">
                    <strong>Brain</strong>
                  </p>
                  <ul className="neon-green-bullets">
                    <li>
                      Insatiable hunger (hyperphagia) and resulting obesity
                    </li>
                  </ul>
                </div>
                <div className="column og small-12 medium-2 pt-1">
                  <img src={IconUpsetStomach} alt="Digestive icon" />
                  <p className="color-teal">
                    <strong>Digestive</strong>
                  </p>
                  <ul className="neon-green-bullets">
                    <li>
                      Upset stomach, including diarrhea within the first weeks
                      of life
                    </li>
                  </ul>
                </div>
                <div className="column og small-12 medium-2 pt-1">
                  <img src={IconKidneyDisease} alt="Kidneys icon" />
                  <p className="color-teal">
                    <strong>Kidneys</strong>
                  </p>
                  <ul className="neon-green-bullets">
                    <li>Build up of acid in the body</li>
                    <li>Excessive thirst</li>
                  </ul>
                </div>
                <div className="column og small-12 medium-2 pt-1">
                  <img src={IconDiabetes} className="ppl-endo" alt="Endocrine icon" />
                  <p className="color-teal">
                    <strong>Endocrine</strong>
                  </p>
                  <ul className="neon-green-bullets">
                    <li>Diabetes</li>
                    <li>High insulin levels in the blood</li>
                    <li>Low blood sugar (hypoglycemia)</li>
                  </ul>
                </div>
                <div className="column og small-12 medium-3 mt-1">
                  <p>
                    <b>Other symptoms may include:</b>
                  </p>
                  <ul className="neon-green-bullets">
                    <li>
                      <strong>Endocrine:</strong> Low thyroid levels
                      (hypothyroidism), decreased cortisol production
                    </li>
                    <li>
                      <strong>Other:</strong> Failure to thrive in infancy
                    </li>
                  </ul>
                </div>
              </div>
            </Tabs.Pane>
            <Tabs.Pane name="LEPR deficiency" key="3">
              <div className="row og md_mb-1 lg_mb-1">
                <div className="column og small-12 medium-3">
                  <img src={IconHyperphagia} alt="Brain icon" />
                  <p className="color-teal">
                    <strong>Brain</strong>
                  </p>
                  <ul className="neon-green-bullets">
                    <li>
                      Insatiable hunger (hyperphagia) and resulting obesity
                    </li>
                  </ul>
                </div>
                <div className="column og small-12 medium-3 pt-1">
                  <img src={IconDiabetes} className="ppl-endo" alt="Endocrine icon" />
                  <p className="color-teal">
                    <strong>Endocrine</strong>
                  </p>
                  <ul className="neon-green-bullets">
                    <li>High insulin levels in the blood</li>
                  </ul>
                </div>
                <div className="column og small-12 medium-3 pt-1">
                  <img src={IconGenital} alt="Reproductive icon" />
                  <p className="color-teal">
                    <strong>Reproductive</strong>
                  </p>
                  <ul className="neon-green-bullets">
                    <li>
                      Delayed puberty caused by hormonal imbalances
                      (hypogonadotropic hypogonadism)
                    </li>
                  </ul>
                </div>
                <div className="column og small-12 medium-3 mt-1">
                  <p>
                    <b>Other symptoms may include:</b>
                  </p>
                  <ul className="neon-green-bullets">
                    <li>
                      <strong>Endocrine:</strong> Diabetes
                    </li>
                  </ul>
                </div>
              </div>
            </Tabs.Pane>
          </Tabs>
        </div>
      </div>

      <div className="row og lg_mb-2">
        <div className="columns og" style={{ marginLeft: `20px` }}>
          <Modal id="hyperphagia-pat-ppl" className="color-black-bg" />
        </div>
      </div>
    </div>
    <div className="row" id="patient-bbs-overview-callout">
        <div className="columns large-10 medium-10 small-12">
          <Callout fullWidth className="lime-teal left text-left">
            <p>
                In people living with POMC, PCSK1, or LEPR deficiency, insatiable
                hunger and obesity are caused by a genetic change that makes it
                difficult for the brain's hunger signaling pathway to help you feel
                full. That's why medicine like IMCIVREE can help
            </p>
          </Callout>
        </div>
      </div>

    <div className="content-block pb-1 md_pb-1">
      <div className="lg_mt-0 mt-0" id="the-hypothalamus">
        <h2 className="h1 large-10 small-12">
          How does my brain control hunger?
        </h2>

        <div className="row og">
          <div className="columns large-10 small-12 og"></div>
        </div>
        <div className="row og align-middle align-center mb-2 md_mb-3 mt-2">
          <div className="column og large-4">
            <p className="mb-0 lead">
              The key area of the brain that communicates being hungry or full
              is known as the hypothalamus.
              <br />
              Think of the hypothalamus as a neighborhood within the brain.
            </p>
          </div>
          <div className="column og large-3">
          <img
          src={HypothalamusDesktop}
          alt="Hypothalamus in Brain"
          className="hide-for-small-only"
        />

        <img
        src={HypothalamusMobile}
        alt="Hypothalamus in Brain"
        className="hide-for-medium hide-for-large column og large-3"
      />
          </div>
        </div>

        <div className="row og align-middle mt-3">
          <div className="columns og large-2 small-3 text-center">
            <img src={iconRoad} alt="MC4R pathway road" />
          </div>
          <div className="columns og large-10">
            <p className="mb-0">
              In this neighborhood, there are roads that allow trucks to travel
              to and from the brain. One road is called the melanocortin-4
              receptor, or <strong>MC4R, pathway</strong>.
            </p>
          </div>
        </div>

        <div className="row og lg_mt-2 mt-2 align-middle">
          <div className="columns og large-2 small-3 text-center">
            <img src={iconTruck} alt="Truck delivering Messages" />
          </div>
          <div className="columns og large-10">
            <p className="mb-0">
              The trucks on this road deliver messages between the body and the
              brain. These messages control hunger.
            </p>
          </div>
        </div>

        <div className="row og lg_mt-2 mt-2 align-middle">
          <div className="columns og large-2 small-3 text-center">
            <img src={iconTrafficLight} alt="Traffic Light POMC, PCSK1, or LEPR gene" />
          </div>
          <div className="columns og large-10">
            <p className="mb-0">
              On this road, there is a traffic light, or a POMC, PCSK1, or LEPR
              gene, that helps guide the trucks to deliver their messages.
            </p>
          </div>
        </div>

        <div className="row og lg_mt-4 mt-3">
          <div className="large-10 og">
            <h2 className="h1" id="what-causes">
              What causes hunger and obesity in POMC, PCSK1, and LEPR
              deficiency?
            </h2>
            <h3 className="h2--no-margin-bottom">
              In people living with POMC, PCSK1, or LEPR deficiency, the signals
              that turn hunger “off” don’t work correctly in the brain
            </h3>
          </div>
        </div>

        <div>
          <img
            src={HungerSignalsSvgDesktop}
            alt="MC4R pathway open"
            className="show-for-large"
          />
          <img src={HungerSignalsSvgMobile} alt="MC4R pathway open" className="hide-for-large" />
        </div>

        <div className="row og">
          <div className="large-12 og">
            <p className="lg_mt-0 mt-0">
              When the POMC, PCSK1, or LEPR gene is normal, without a variant
              (or change), the traffic light works correctly. The road (MC4R
              pathway) is open, and the trucks can deliver messages to the brain
              that the body is satisfied and doesn't need more food.
            </p>
          </div>
        </div>

        <div className="row og lg_mt-4 mt-3">
          <div className="large-10 og">
            <h2 className="h1 mb-0 md_mb-0">
              In people living with POMC, PCSK1, or LEPR deficiency, the traffic
              light is broken due to the change in the relevant gene
            </h2>
          </div>
        </div>

        <div>
          <img
            src={HungerTrafficSvgDesktop}
            alt="MC4R pathway broken"
            className="show-for-large"
          />
          <img src={HungerTrafficSvgMobile} alt="MC4R pathway broken" className="hide-for-large" />
        </div>

        <div className="row og">
          <div className="large-12 og">
            <p className="">
              When the POMC, PCSK1, or LEPR gene has a variant (or change), the
              traffic light is broken, which causes a traffic jam. The trucks
              can no longer travel along the road (MC4R pathway) to properly
              deliver messages to the brain that the body is satisfied and
              doesn’t need more food. Instead, the body believes that it’s still
              hungry.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="columns large-8 medium-10 small-12">
        <Callout fullWidth className="lime-teal left text-left">
          <p>
            IMCIVREE is designed to target an impaired MC4R pathway in the
            brain, a root cause of hunger and obesity in people living with
            POMC, PCSK1, or LEPR deficiency
          </p>
        </Callout>
      </div>
    </div>
    <div className="text-center lg_mt-3 mt-3">
      <Button
        large
        copy={<>See how IMCIVREE works</>}
        url="/ppl/how-it-works/"
      />
    </div>
    <div>&nbsp;</div>
  </Layout>
)

export default DiseaseOverview
