import React, { useEffect } from "react"

import Layout from "../../components/bbs-layout"
import Seo from "../../components/seo"
import Callout from "../../components/callout"
import Button from "../../components/button"

import { faAngleDown } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import ChapterMenu from "../../components/template-partials/global-components/chapter-menu/chapter-menu"

import Modal from "../../components/modal"


import Hypothalamus from "../../components/gatsby-images/hypothalamus"
import iconRoad from "../../images/icons/icon-road.svg"
import iconTruck from "../../images/icons/icon-truck.svg"
import iconTrafficLight from "../../images/icons/icon-traffic-light.svg"


import BbsSymptomsMobile from "../../images/bbs-symptoms-mobile_pat.png"
import BbsSymptomsDesktop from "../../images/bbs-symptoms.png"

import HypothalamusDesktop from "../../images/hypothalamus.png"
import HypothalamusMobile from "../../images/hypothalamus-mobile.png"



import HearAboutRealExperiencesVideoBbs from "../../components/hear-about-real-experiences-video-bbs"
import VideoTranscript from "../../components/video-transcript"
import HearAboutRealExperiencesTranscriptBbs from "../../components/template-partials/patient/hear-about-real-experiences-transcript-bbs"

import BbsDiagnoseVideo from "../../components/bbs-diagnose-video"
import BbsDiagnoseTranscript from "../../components/template-partials/patient/bbs-diagnose-transcript"

import HungerSignalsSvgDesktop from "../../images/patient-bbs-overview-hunger-signals-desktop.svg"
import HungerSignalsSvgMobile from "../../images/patient-bbs-overview-hunger-signals-mobile.svg"
import HungerTrafficSvgDesktop from "../../images/patient-bbs-hunger-traffic-light-desktop.svg"
import HungerTrafficSvgMobile from "../../images/patient-bbs-hunger-traffic-light-mobile.svg"

const Overview = () => {

 // Function to handle smooth scrolling to the target element with an offset
 const smoothScrollTo = (target, offset) => {
  const targetElement = document.querySelector(target);
  if (!targetElement) return;

  const startPosition = window.pageYOffset;
  const targetPosition = targetElement.getBoundingClientRect().top + startPosition - offset;
  const distance = targetPosition - startPosition;
  const duration = 800; // Change the duration (in milliseconds) to adjust the scroll speed

  let start = null;
  function animation(currentTime) {
    if (start === null) start = currentTime;
    const timeElapsed = currentTime - start;
    const scrollStep = Math.min(distance, (timeElapsed / duration) * distance);

    window.scrollTo(0, startPosition + scrollStep);

    if (timeElapsed < duration) requestAnimationFrame(animation);
  }

  requestAnimationFrame(animation);
};

// Function to handle smooth scroll when the link is clicked
const handleScrollClick = (event) => {
  event.preventDefault();
  const target = event.currentTarget.getAttribute('href');
  const offset = 200; // Set the desired offset here (200px in this case)
  smoothScrollTo(target, offset);
};

useEffect(() => {
  document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
    anchor.addEventListener('click', handleScrollClick);

    // Clean up event listener on unmount
    return () => {
      anchor.removeEventListener('click', handleScrollClick);
    };
  });
}, []);


  return (
    <Layout noNav>
      <Seo
        title="BBS Overview | IMCIVREE® (setmelanotide) injection"
        description="Learn about Bardet-Biedl syndrome (BBS), a rare genetic disease of obesity, including causes and symptoms. Please see full Prescribing Information and Important Safety Information."
        ogTitle="Disease Overview | IMCIVREE® (setmelanotide) injection"
        ogDesc="Learn about the disease that IMCIVREE® (setmelanotide) treats, including causes and symptoms. Please see full Prescribing Information and Important Safety Information."
      />

      <div id="bbs-overview" className="content-block">
        <div className="row og col-reverse">
          <div className="column og small-12 medium-12 large-8 lg_pl-0">
            <h1 className="h1" id="whatis-bbs">
              What is BBS?
            </h1>
            <h2 className="h2">
              Bardet-Biedl (pronounced BAR-day BEED-el) syndrome, also known as
              BBS, is a rare genetic disease of obesity
            </h2>
            <h3 className="h3 text-center lg_mt-3 md_mb-2">
              BBS has a wide range of symptoms that can
              <br className="show-for-medium-up" /> impact many systems in the
              body
            </h3>

            <img
                  src={BbsSymptomsDesktop}
                  alt="Body diagram identifying BBS symptoms in the brain, kidney, eyes, heart, reproductive, endocrine, and skeletal systems"
                  className="hide-for-small-only"
                />

                <img
                src={BbsSymptomsMobile}
                alt="Body diagram identifying BBS symptoms in the brain, kidney, eyes, heart, reproductive, endocrine, and skeletal systems"
                className="hide-for-medium hide-for-large"
              />

            
          </div>
          <div className="column og small-12 medium-12 large-4 mt-1 mb-2">
            <ChapterMenu title={`Jump to:`}>
              <li>
                <span className="interior-menu__icon">
                  <FontAwesomeIcon icon={faAngleDown} />
                </span>
                <a href="#whatis-bbs">What is BBS?</a>
              </li>
              <li>
                <span className="interior-menu__icon">
                  <FontAwesomeIcon icon={faAngleDown} />
                </span>
                <a href="#the-hypothalamus">The hypothalamus</a>
              </li>
              <li>
                <span className="interior-menu__icon">
                  <FontAwesomeIcon icon={faAngleDown} />
                </span>
                <a href="#what-causes">
                  What causes hunger &amp; obesity in BBS?
                </a>
              </li>
              <li>
                <span className="interior-menu__icon">
                  <FontAwesomeIcon icon={faAngleDown} />
                </span>
                <a href="#video-hyperphagia">Watch a video about hyperphagia</a>
              </li>
              <li>
                <span className="interior-menu__icon">
                  <FontAwesomeIcon icon={faAngleDown} />
                </span>
                <a href="#video-diagnosed">Watch a video on how BBS is diagnosed</a>
              </li>
            </ChapterMenu>
          </div>
        </div>
        <div className="columns small-12 mt-2">
          <Modal id="what-is-hyperphagia" />
        </div>
      </div>

      <div className="content-block content-block--no-pad md_mb-1">
        <div className="row og">
          <div className="columns og">
            <p className="mt-2 jost-medium">
              Unlike other forms of obesity, obesity due to BBS is caused by variants, or changes, in a BBS gene. BBS genes are inherited, meaning they are passed down from parent to child.
            </p>
            <p>
              It is important to know that BBS can be diagnosed at any age.
              BBS can cause a range of symptoms that may appear at different stages in life.
              Not everyone living with BBS experiences the same symptoms, even between family members!
            </p>
          </div>
        </div>
      </div>

      <div className="row pt-2" id="patient-bbs-overview-callout">
        <div className="columns large-10 medium-10 small-12">
          <Callout fullWidth className="lime-teal left text-left ">
            <p>
            In BBS, insatiable hunger and obesity are caused by a genetic change that makes it difficult for the brain's hunger signaling pathway to help you feel full. That's why medicine like IMCIVREE can help
            </p>
          </Callout>
        </div>
      </div>

      <div className="content-block content-block--no-pad lg_mt-3 mt-3" id="the-hypothalamus" >
        <div className="row og">
          <div className="columns large-10 small-12 og">
            <h2 className="h1">How does my brain control hunger?</h2>
          </div>
        </div>
        <div className="row og align-middle align-center mb-2 md_mb-3 mt-2">
          <div className="column og large-4">
            <p className="mb-0 lead">
              The key area of the brain that communicates being hungry or full
              is known as the hypothalamus. Think of the hypothalamus as a
              neighborhood within the brain.
            </p>
          </div>
          
          

          <img
          src={HypothalamusDesktop}
          alt="Hypothalamus in Brain"
          className="hide-for-small-only"
        />

        <img
        src={HypothalamusMobile}
        alt="Hypothalamus in Brain"
        className="hide-for-medium hide-for-large column og large-3"
      />



        </div>

        <div className="row og align-middle align-center mt-3">
          <div className="columns og large-2 small-3 text-center">
            <img src={iconRoad} alt="MC4R pathway road" />
          </div>
          <div className="columns og large-10">
            <p className="mb-0">
              In this neighborhood, there are roads that allow trucks to travel
              to and from the brain. One road is called the melanocortin-4
              receptor, or <strong>MC4R, pathway</strong>.
            </p>
          </div>
        </div>

        <div className="row og lg_mt-2 mt-2 align-middle align-center">
          <div className="columns og large-2 small-3 text-center">
            <img src={iconTruck} alt="Truck delivering Messages" />
          </div>
          <div className="columns og large-10">
            <p className="mb-0">
              The trucks on this road deliver messages between the body and the
              brain. These messages control hunger.
            </p>
          </div>
        </div>

        <div className="row og lg_mt-2 mt-2 align-middle align-center">
          <div className="columns og large-2 small-3 text-center">
            <img src={iconTrafficLight} alt="Traffic Light BBS Gene" />
          </div>
          <div className="columns og large-10">
            <p className="mb-0">
              On this road, there is a traffic light, or a BBS gene, that helps
              guide the trucks to deliver their messages.
            </p>
          </div>
        </div>

        <div className="row og lg_mt-4 mt-3">
          <div className="columns og">
            <div className="row og mb-1">
              <div className="columns large-10 og md_py-0" >
                <h2 className="h1">
                  What causes hunger and obesity in BBS?
                </h2>
                <h3 className="h2--no-margin-bottom"  id="what-causes">
                  In people living with BBS, the signals that turn hunger “off”
                  don’t work correctly in the brain
                </h3 >
              </div>
            </div>

            <div >
              <img
                src={HungerSignalsSvgDesktop}
                alt="MC4R pathway open"
                className="show-for-large"
              />
              <img
                src={HungerSignalsSvgMobile}
                alt="MC4R pathway broken"
                className="hide-for-large"
              />
            </div>

            <div className="row og">
              <div className="columns og">
                <p className="lg_mt-0 mt-1">
                  When the BBS gene is normal, without a variant (or change),
                  the traffic light works correctly. The road (MC4R pathway) is
                  open, and the trucks can deliver messages to the brain that
                  the body is satisfied and doesn't need more food.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="row og lg_mt-4 mt-3">
          <div className="columns og">
            <div className="row og mb-1">
              <div className="columns large-10 og md_py-0">
                <h2 className="h1 mb-0 md_mb-0">
                  In people living with BBS, the traffic light is broken due to
                  the change in the BBS gene
                </h2>
              </div>
            </div>

            <div>
              <img
                src={HungerTrafficSvgDesktop}
                alt="When the BBS gene has a variant (or change), the traffic light
                is broken, which causes a traffic jam."
                className="show-for-large"
              />
              <img
                src={HungerTrafficSvgMobile}
                alt="When the BBS gene has a variant (or change), the traffic light
                is broken, which causes a traffic jam."
                className="hide-for-large"
              />
            </div>

            <div className="row og">
              <div className="columns og md_py-0">
                <p className="mt-1 md_mt-0">
                  When the BBS gene has a variant (or change), the traffic light
                  is broken, which causes a traffic jam. The trucks can no
                  longer travel along the road (MC4R pathway) to properly
                  deliver messages to the brain that the body is satisfied and
                  doesn’t need more food. Instead, the body believes that it’s
                  still hungry.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="columns large-8 medium-10 small-12">
          <Callout fullWidth className="lime-teal left text-left">
            <p>
              IMCIVREE is designed to target an impaired MC4R pathway in the
              brain, a root cause of hunger and obesity in people living with
              BBS
            </p>
          </Callout>
        </div>
      </div>

      <div className="content-block content-block--no-pad">
        <div className="row og lg_mt-3 mt-2">
          <div className="columns og">
            <h2 className="h1">
              How is BBS diagnosed?
            </h2>
            <h3 className="h2">
            Learn more about the signs of BBS from healthcare providers, patients, and families
            </h3>
          </div>
        </div>
      </div>

      <div className="content-block md_pt-0"  id="video-diagnosed">
        <BbsDiagnoseVideo />
        <VideoTranscript>
          <BbsDiagnoseTranscript />
        </VideoTranscript>
      </div>

      <div className="content-block content-block--no-pad">
        <div className="row og lg_mt-3 mt-2">
          <div className="columns og">
            <h2 className="h1">
              Hear about real experiences with insatiable hunger
            </h2>
            <h3 className="h2">
              Learn how families and their healthcare teams are managing it together
            </h3>
          </div>
        </div>
      </div>

      <div className="content-block md_pt-0"  id="video-hyperphagia">
        <HearAboutRealExperiencesVideoBbs/>
        <VideoTranscript>
          <HearAboutRealExperiencesTranscriptBbs/>
        </VideoTranscript>
      </div>

      <div className="text-center lg_mt-2 mt-2">
        <Button
          textCenter={false}
          large
          copy="See how IMCIVREE works"
          url="/bbs/how-imcivree-works/"
        />
      </div>
    </Layout>
  )
}

export default Overview
