import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import MainLogoSvg from "../images/imcivree-logo.svg"
import MainLogo from "./template-partials/global-components/main-logo"
import * as Utils from "../helpers/utils"

const Header = ({ data }) => <></>
export default function MyHeader(props) {
  const [navOpen, setNavOpen] = useState(false)
  const url = typeof window !== "undefined" ? window.location.pathname : ""
  const toggleNav = e => {
    let width = document.body.clientWidth
    if (width <= 1024) {
      e.currentTarget.classList.toggle("isOpen")
      document
        .querySelector(`.main-header__mobile-menu-wrapper`)
        .classList.toggle("isOpen")
      if (navOpen) {
        setNavOpen(false)
      } else {
        setNavOpen(true)
      }
      e.preventDefault()
    } else {
      return true
    }
  }

  const setCookie = (cname, cvalue, exdays) => {
    const d = new Date()
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
    let expires = "expires=" + d.toUTCString()
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/"
  }

  const getCookie = cname => {
    let name = cname + "="
    let decodedCookie = decodeURIComponent(document.cookie)
    let ca = decodedCookie.split(";")
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i]
      while (c.charAt(0) == " ") {
        c = c.substring(1)
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length)
      }
    }
    return ""
  }

  const initFontSize = () => {
    // initialize font-size cookie
    if (getCookie("font-size")) {
      document.querySelector(`html`).className = getCookie("font-size")
    }
  }

  const fontResize = e => {
    if (e) {
      setCookie("font-size", e, 1) //expires in 1 day
      initFontSize()
    }
  }

  const toggleActive = e => {
    e.currentTarget.classList.toggle("active")

    //remove active class from dom that's not current target
    let elems = document.querySelectorAll(".active")
    ;[].forEach.call(elems, function (el) {
      if (e.currentTarget !== el) {
        el.classList.remove("active")
      }
    })
    e.stopPropagation()
  }

  const toggleMobileDrawer = e => {
    let width = document.body.clientWidth
    e.currentTarget.parentNode.classList.add("isActive")
    //remove active class from dom that's not current target
    let elems = document.querySelectorAll(".isActive")
    ;[].forEach.call(elems, function (el) {
      if (e.currentTarget.parentNode !== el) {
        el.classList.remove("isActive")
      }
    })
    e.stopPropagation()
    //console.log(e.currentTarget.parentNode)
    if (width <= 1024) {
      let parent = e.currentTarget.parentNode
      let target = e.currentTarget

      //console.log(target)
      if (target.classList.contains("hasChild")) {
        parent.classList.toggle("isOpen")
        e.preventDefault()
      } else {
        document
          .querySelector(`.main-header__mobile-menu-wrapper`)
          .classList.toggle("isOpen")
      }
    } else {
      return true
    }
  }

  const setActive = () => {
    let current = window.location.pathname
    let elems = document.querySelectorAll("header ul li > a[href]")
    ;[].forEach.call(elems, function (el) {
      // if the current path is like this link, make it active
      if (el.getAttribute("href") === current) {
        //console.log(current)
        //console.log(el.getAttribute('href'));
        el.parentNode.classList.add("isSelected")
        el.classList.add("isSelected")
        let parentClass = el.getAttribute("parentClass")
        if (parentClass) {
          let parentActive = document.querySelector("." + parentClass)
          parentActive.classList.add("isSelected")
        }
      }
    })
  }
 
  useEffect(() => {
    //check for utm
    Utils.utmCheck()
    setActive()
    // initialize font-size cookie
    initFontSize()
    const onClick = e => {
      let elems = document.querySelectorAll(".active")
      ;[].forEach.call(elems, function (el) {
        el.classList.remove("active")
      })
    }
    document.body.addEventListener("click", onClick)

    return () => {
      document.body.removeEventListener("click", onClick)
    }
  }, [])

  return (
    <>
      {/* Use helmet to dynamically add a body class*/}
      {navOpen && <Helmet bodyAttributes={{ class: "gateway nav-lock" }} />}
      {!navOpen && <Helmet bodyAttributes={{ class: "gateway" }} />}
      {/* Use helmet to dynamically add a body class*/}
      <header className="main-header">
        <div className="row mobile-only">
          <div className="columns">
            <ul className="mobile-only-tertiary">
              <li className="text-center">
                <a
                  href="https://rhythm-vault-digital-publishing-production.s3.amazonaws.com/IMCIVREEPatientPrescribingInformation.pdf"
                  target="_blank"
                >
                  Patient
                  <br />
                  Information
                </a>
              </li>
              <li className="text-center">
                <a
                  href="https://rhythm-vault-digital-publishing-production.s3.amazonaws.com/IMCIVREEPrescribingInformation.pdf"
                  target="_blank"
                >
                  Prescribing
                  <br />
                  Information
                </a>
              </li>
              <li className="text-center">
                <a href="#isiContainer">
                  Important Safety
                  <br />
                  Information
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="row row--inner main-header__mobile-tray">
          <div className="columns large-3">
            <div className="show-for-large">
              <img
                src={MainLogoSvg}
                alt="Imcivree Logo"
                style={{ marginLeft: 20 }}
                width={150}
              />
            </div>

            <div className="hide-for-large">
              <MainLogo to="/" location="header" />
            </div>
            <button
              className="main-nav-toggle hide-for-large"
              onClick={e => toggleNav(e)}
            >
              <span></span>
              <span></span>
              <span></span>
              <span className="visually-hidden">toggle mobile menu</span>
            </button>
          </div>
          <div className="columns large-9">
            <div className="utility-nav-wrapper">
              <div className="tertiary-nav desktop-only">
                <ul className="nav-items">
                  <li className="">
                    <div className="site-label">
                      Site intended for US residents only.
                    </div>
                  </li>
                  <li className="slanted">
                    <a
                      href="https://rhythm-vault-digital-publishing-production.s3.amazonaws.com/IMCIVREEPatientPrescribingInformation.pdf"
                      target="_blank"
                    >
                      Patient Information
                    </a>
                  </li>
                  <li className="slanted">
                    <a
                      href="https://rhythm-vault-digital-publishing-production.s3.amazonaws.com/IMCIVREEPrescribingInformation.pdf"
                      target="_blank"
                    >
                      Prescribing Information
                    </a>
                  </li>
                  <li className="slanted">
                    <a href="#isiContainer">Important Safety Information</a>
                  </li>
                </ul>
              </div>
              <div className="secondary-nav desktop-only">
                <ul className="nav-items">
                  <li className="font-size">
                    <span className="small" onClick={e => fontResize("gsmall")}>
                      A
                    </span>{" "}
                    <span className="pipe">|</span>{" "}
                    <span
                      className="normal"
                      onClick={e => fontResize("gmedium")}
                    >
                      A
                    </span>{" "}
                    <span className="pipe">|</span>{" "}
                    <span className="large" onClick={e => fontResize("glarge")}>
                      A
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="row row--inner main-header__mobile-menu-wrapper">
          <div className="columns">
            <nav className="main-nav">
              <div className="mobile-only-secondary-utility-nav">
                <ul className="nav-items">
                  <li className="">
                    <div className="site-label">
                      Site intended for US residents only.
                    </div>
                  </li>
                  <li className="slanted">
                    <a
                      href="https://rhythm-vault-digital-publishing-production.s3.amazonaws.com/IMCIVREEPatientPrescribingInformation.pdf"
                      target="_blank"
                    >
                      Patient Information
                    </a>
                  </li>
                  <li className="slanted">
                    <a
                      href="https://rhythm-vault-digital-publishing-production.s3.amazonaws.com/IMCIVREEPrescribingInformation.pdf"
                      target="_blank"
                    >
                      Prescribing Information
                    </a>
                  </li>
                  <li className="slanted">
                    <a href="#isiContainer">Important Safety Information</a>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </header>
    </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

/*
import React, {useState} from 'react'
import PropTypes from "prop-types"
import { Link } from "gatsby"
import imcivreeLogo from "../images/imcivree-logo.svg"
import Helmet from 'react-helmet'


const Header = ({ siteTitle }) => {

  const [navOpen, setNavOpen] = useState(false);

  const toggleNav =(e)=>{
    let width = document.body.clientWidth;
    if(width <= 1024){
      e.currentTarget.classList.toggle('isOpen');
      document.querySelector(`body`).classList.toggle('isOpen');
      if(navOpen){
        setNavOpen(false);
      }else{
        setNavOpen(true);
      }
      e.preventDefault();
    }else{
      return true;
    }
  }

  const toggleMobileDrawer =(e)=>{
    let width = document.body.clientWidth;
    if(width <= 1024){
      let parent = e.currentTarget.parentNode;
      parent.classList.toggle('isOpen');
      e.preventDefault();
    }else{
      return true;
    }
  }

  return (
    <>
  <Helmet bodyAttributes={{ class: 'gateway'}}/>
  <header>
    <nav>
      <div className="nav-container">
        <div className='mobile-fixed'>
          <div className="logo"><Link to="/"><img src={imcivreeLogo} /></Link></div>
          <div className="nav-button hidden-desktop" id="mobile-menu" onClick={(e) => toggleNav(e)}>
              <span></span>
              <span></span>
              <span></span>
          </div>
          <div className="utility-nav-wrapper">
            <div className="tertiary-nav">
                <ul className="nav-items">
                  <li className="desktop-only"><div className="site-label">Site intended for US residents only.</div></li>
                  <li className='slanted'><Link to="#">Patient Information</Link></li>
                  <li className='slanted'><Link to="#">Prescribing Information</Link></li>
                  <li className='slanted right'><Link to="#">Important Safety Information</Link></li>
                </ul>

            </div>
            <div className="secondary-nav">
                <ul className="nav-items">
                    <li className="font-size"><span className="small">A</span></li>
                    <li className="font-size"><span className="normal">A</span></li>
                    <li className="font-size"><span className="large">A</span></li>
                </ul>
              </div>
          </div>
        </div>
      </div>
    </nav>
  </header>
  </>
  );
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
*/
