import React from 'react'


const HyperphagiaVideoTranscript = (props) => {

  return (
    <>
      <p>Few years ago, we've learned about IMCIVREE, a drug that could potentially help Reed and kids like Reed with Bardet-Biedl syndrome lose weight.</p>

      <p>Managing Reed's hunger was so challenging, and it was never ending, and it was something that affected not just Reed, but every aspect of his life and ours. When we first found out that there is potentially a medication that can help not only Reed, but us manage this tough aspect of his diagnosis, we were extremely excited.</p>

      <p>There's a drug that could help me lose weight and... could possibly tell me that my stomach’s full.</p>

      <p>To address just one symptom that I've lived with my entire life of BBS ... Would be incredible.</p>

      <p>Patients and families that are living with BBS often express such gratitude and relief when they are started on IMCIVREE and see that the weight trajectories start to improve and the hyperphagia starts to improve.</p>

      <p>To have a medication that can come in and support correcting that biology that is dysregulated and to see positive outcomes that not only improve the immediate day-to-day quality of life but are also setting up that child for a healthier life over time, I think is very beneficial and hopeful for the patients and families that are on this agent.</p>

      <p>Patients come in, and that's all they can talk about is, "I'm not hungry all the time. I'm not seeking out food all the time. I'm able to do other things."</p>

      <p>So that shift from the number on the scale to the quality of life, I really love that.</p>

      <p>Since starting IMCIVREE, I lost 23 pounds in the first seven months and my hunger has decreased.</p>

      <p>I never thought there would be a day where I didn't think about food all day long.</p>

      <p>Now I spend my days living life and being busy. Going, spending my days doing activities and not focused on what's going to be the next thing I eat.</p>

      <p>Since Reed has started on IMCIVREE, the main change has been that hunger is not the main player in his life. It's not something that rules his life. It is just a part of his life, which is quite a difference from before.</p>

      <p>And to see him lose weight and not just lose weight, but lose 14 pounds, which for 11-year-old boy is absolutely remarkable, is just so exciting. And it's not just about that, it's just how well he feels, and he's active, and it's just great.</p>

      <p>I get emotional when I talk about Reed's future because it takes me back to when he was eight months old and that appointment where a very bleak future was painted for us and now future's bright.</p>

      <p>What you're doing is making an impact. What you're doing is actually helping someone.</p>

      <p>These are patients that nothing else has ever worked before. And so, to see them so happy about having something that's helping them, it's amazing.</p>

    </>
  )
}

export default HyperphagiaVideoTranscript
