import React from "react"

// import Layout from "../../../components/hcp-ppl-layout"
import Layout from "../../../components/hcp-ppl-layout"
import Seo from "../../../components/seo"

import "../../../components/scss-pages/hcp/ppl/_index.scss"
import Helmet from "react-helmet"

import IconData from "../../../images/hcp/ppl/icon-data.png"
import IconDiamond from "../../../images/hcp/ppl/icon-diamond.png"
import IconRaR from "../../../images/hcp/ppl/icon-email.svg"
import HCPHero from "../../../components/HCPPPLHero"

const icons = [
  {
    svg: IconData,
    url: "/hcp/ppl/study-design/",
    alt: "See the efficacy of IMCIVREE",
    copy: "See the efficacy of IMCIVREE",
  },
  {
    svg: IconDiamond,
    url: "/Start_Form.pdf",
    alt: "Download a Prescription Start Form to prescribe IMCIVREE",
    copy: "Download a Prescription Start Form to prescribe IMCIVREE",
    type: "download",
  },
  {
    svg: IconRaR,
    url: "/hcp/ppl/sign-up/",
    alt: "Contact a Rhythm representative",
    copy: "Contact a Rhythm representative",
  },
]

const IndexPage = () => (
  <Layout ppl>
    <Seo
      title="POMC, PCSK1, LEPR Deficiency | IMCIVREE® (setmelanotide) injection | For HCPs"
      description="IMCIVREE is the first and only PRECISION MEDICINE to target impairment in the hypothalamic MC4R pathway, a root cause of hyperphagia and obesity in POMC, PCSK1, or LEPR deficiency. Sign up for updates to learn more. Please see full Prescribing Information and Important Safety Information."
      ogTitle="IMCIVREE® (setmelanotide) injection"
      ogDesc="Learn about IMCIVREE® (setmelanotide) injection and the diseases it treats. Sign up for updates to learn more. Please see full Prescribing Information for Important Safety Information."
    />
    <Helmet
      bodyAttributes={{ id: "hcp-ppl-gateway", class: "hcp-ppl-home hcp-ppl" }}
    />
    <HCPHero ppl icons={icons} />
  </Layout>
)

export default IndexPage
