import React, {useState, useEffect} from 'react'
import Wrapper from './accordionWrapper'
import Drawer from './accordionDrawer'
import PlainDrawer from './accordionPlainDrawer'
import Expanded from './accordionExpanded'
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Accordion = (props) => {

  const [allOpen, setAllOpen] = useState(false);
  const [totalOpen, setTotalOpen] = useState(0);
  const [initial, setInitial] = useState(false);

  const handleAllOpen = (e) => {
    setAllOpen(!allOpen);
    setInitial(true);
  }

  const incrementOpen= (e)=>{
    setTotalOpen(totalOpen + 1);
    setInitial(true);
  }

  const decrementOpen= (e)=>{
    setTotalOpen(totalOpen - 1);
    setInitial(true);
  }

  useEffect(()=>{
    setTotalOpen();
    if(allOpen){
      setTotalOpen(props.children.length);
    }else{
      setTotalOpen(0);
    }
  },[allOpen]);

  useEffect(()=>{
    if(totalOpen === props.children.length){
      setAllOpen(true);
    }
    if(totalOpen === 0){
      setAllOpen(false);
    }
    
  },[totalOpen]);

  return (
    <ul className={`accordion ` + props.className}>
      <li className="accordion__expand-all">
        <button onClick={(e)=>handleAllOpen(e)}>
          {allOpen &&
            <>
              <FontAwesomeIcon icon={faMinus} /> Collapse all questions
            </>
          }
          {!allOpen &&
            <>
              <FontAwesomeIcon icon={faPlus} /> Expand all questions
            </>
          }
        </button>
      </li>
      {React.Children.map(props.children, child => {
        return (
          <Wrapper initial={initial} allOpen={allOpen} addToOpen={incrementOpen} removeFromOpen={decrementOpen}>
            {child}
          </Wrapper>
        )
      })}
    </ul>

  )
}

Accordion.Drawer = Drawer;
Accordion.PlainDrawer = PlainDrawer;
Accordion.Expanded = Expanded;
Accordion.Item = ({ children }) => children

export default Accordion
