import React from "react"

import Layout from "../../components/bbs-layout"
import Seo from "../../components/seo"
import Tabs from "../../components/template-partials/global-components/tabs/tabs"
import HangingRef from "../../components/hanging-ref"
import Callout from "../../components/callout"
import Button from "../../components/button"
import Quote from "../../components/quote"

import ActorPortrayal from "../../components/gatsby-images/actor-portrayal"


import ActorKid from "../../images/actor-portrayal.png"
import ActorKidMobile from "../../images/actor-portrayal-mobile.png"
import ActorPortrayal2 from "../../images/actor_protrayl.png"
import ActorPortrayal_mobile from "../../images/actor_protrayl_mobile.png"
import Callout86 from "../../images/86_CallOut.png"
import Callout100 from "../../images/100_CallOut.png"
import ChildBMI from "../../images/Child-BMI-graph.png"

import GrowthChartMobile from "../../images/growth-chart-mobileV3.png"
import GrowthChart from "../../images/growth-chartv3.png"


import percentage86 from "../../images/icons/percentage-86.svg"
import weightReductionwithBMI from "../../images/Weight_Reduction_with_BMI.png"
import weightReductionwithBMIMobile from "../../images/Weight_Reduction_with_BMI_mobile.png"
import percentage100 from "../../images/icons/percentage-100.svg"
import avgWeightReduction from "../../images/avg-weight-reduction.png"
import arrow15 from "../../images/arrow-15.png"
import maleSilo from "../../images/male-silo.png"
import hungerScale from "../../images/hunger-scale.png"
import hungerChart from "../../images/hunger-chart.png"
import hungerBBSProfile01 from "../../images/hunger-bbs-profile01.png"
import hungerBBSProfile02 from "../../images/hunger-bbs-profile02.png"
import hungerBBSProfile03 from "../../images/hunger-bbs-profile03.png"
import adultWeightReductionGraph from "../../images/Adult-weight-reduction-graph.png"


import QualityOfLifeVideo from "../../components/quality-of-life-video"
import VideoTranscript02 from "../../components/video-transcript"
import QualityOfLifeTranscript from "../../components/template-partials/patient/quality-of-life-transcript"



import BBSAdultsReductionGraph from  "../../images/Adult-weight-reduction-graph.png"
import BBSAdultsReductionGraphMobile from  "../../images/BBS-Adults-reduction_Grap_mobile.png"

//import WeightReductionwithBMI from "../../components/gatsby-images/Weight_Reduction_with_child_BMI"


import weeks14CallOut from "../../images/14-weeks-4_CallOut.png"
import year10Callout from "../../images/1-Year-10_CallOut.png"
import year2Callout from "../../images/2-Year-15_CallOut.png"
import BBSreduction from "../../images/BBS-Adults-reduction_Grap.png"
import BBSreductioMobile from "../../images/BBS-Adults-reduction_Grap_mobile_3.png"

import trackerChart from "../../images/adult-bmi-tracker.png"

const WeightHungerReduction = () => {
  return (
    <Layout>
      <Seo
        title="Weight & Hunger Reduction | IMCIVREE® (setmelanotide) injection"
        description="Learn how IMCIVREE® (setmelanotide) helped reduce measures of weight and hunger in a BBS clinical trial. Please see full Prescribing Information and Important Safety Information."
        ogTitle="Clinical Trial Results | IMCIVREE® (setmelanotide) injection"
        ogDesc="Learn about the clinical trial results of IMCIVREE® (setmelanotide) in children 6 years of age and older and adults. Please see full Prescribing Information and Important Safety Information."
      />
      <div className="content-block">
        <h1 className="h1">
          How did IMCIVREE help reduce measures of weight and hunger in the clinical
          trial?
        </h1>
        <div className="row">
        
        </div>

          <div className="columns">
            <Tabs>
              <Tabs.Pane name="Weight reduction in children" key="1">
                
                  <div className="column small-12 medium-12">
                  <h2 className="h2--no-margin-bottom h2-color-blue test">IMCIVREE helped children reach steady and long-lasting reduction in weight measures</h2>
                 
                  <img
                  src={weightReductionwithBMI}
                  alt="Average reduction in weight in adults over the course of clinical trial"
                  className="hide-for-small-only mt-2 lg_mt-2"
                />

                <img
                src={weightReductionwithBMIMobile}
                alt="Average reduction in weight in adults over the course of clinical trial"
                className="hide-for-medium hide-for-large ml_mt-2 mt-2"
              />
                  
                 
                 
              <div className="row">
              <div className="columns md_ml-2">
               <p style={{color:'#4B4F54', fontSize: '13px'}} className="mt-2  lg_mt-2">*A clinically meaningful reduction is generally defined as a reduction of greater than or equal to 0.2 in BMI Z-score.</p>
                <p class="mt-1 lg_mt-1">16 children between the ages of 6 to 17 were evaluated as part of the study.</p>
                <p>A Body Mass Index, or BMI, Z-score was used to measure the reduction in BMI in children. BMI Z-scores are reliable measures of weight in children who are still growing because they take into account height, age, and gender.</p>
                </div>
                </div>
                <div className="row">
                </div>

                
                <div
                  id="effect-of-imcivree-callout-2"
                  className="row mt-2 mb-2 md_mb-2 lg_mt-2"
                >
                  <div className="columns large-9 medium-10 small-12">
                    <Callout
                      fullWidth
                      className="lime-teal left text-left"
                      style={{ left: `0` }}
                    >
                      <p >
                      <strong>No change to diet or exercise:</strong> <span style={{fontWeight: 'normal'}}>In the clinical trial, people were not required to change their diet or exercise routine</span>
                      </p>
                    </Callout>
                  </div>
                </div>
                <div class="row">
                </div>
                
               
                <h2 className="h2--no-margin-bottom h2-color-blue mt-3 text-center  lg_mt-2" style={{maxWidth: '800px',marginRight : 'auto', marginLeft: 'auto'}}>IMCIVREE is the first and only treatment to significantly reduce BMI <span class="nowrap">Z-score</span> in children with obesity due to BBS</h2>
                <p className="text-center lg_mt-1 mb_mt-1 mt-1"  style={{maxWidth: '700px',marginRight : 'auto', marginLeft: 'auto'}}>Some people chose to continue taking IMCIVREE in a separate long-term clinical trial. After receiving 2 years of treatment, their results were analyzed.</p>
                <div className="row text-center">
                <div className="column small-12 medium-4 hide-for-small-only" style={{marginLeft: '0rem'}}>
                <img src={ActorPortrayal2} />
                  </div>
                  <div className="column small-12 medium-4 precentMargin mt-3">
                    <div class="precentMargin"><img src={Callout86}/></div>
                  
                  </div>
                  <div className="column small-12 medium-4 precentMargin mt-3">
                  <div class="precentMargin"><img src={Callout100}/></div>
                  
                  </div>

                  
                 
                <div className="column small-12 hide-for-medium hide-for-large mt-2 hide-for-small-only">
                <img src={ActorPortrayal2} />
                  </div>
                  <div className="column small-12 hide-for-medium hide-for-large mt-2 hide-for-large">
                <img src={ActorPortrayal_mobile} />
                  </div>
                </div>
                <div
                id="effect-of-imcivree-callout-2"
                className="row mb-2 md_mb-2"
              >
                <div className="columns large-9 medium-10 small-12">
                  <Callout
                    fullWidth
                    className="lime-teal left text-left"
                    style={{ left: `0` }}
                  >
                    <p>
                    After 2 years of treatment, IMCIVREE helped children maintain meaningful reductions in BMI Z-score
                    </p>
                  </Callout>
                </div>
              </div>
              <div className="lg_mt-2 ">
             
              <p className="md_mb-2"></p>
            </div>


                </div>
               

        

                <h2 className="h2--no-margin-bottom h2-color-blue lg_mt-3 mt-3">
                IMCIVREE reduced the severity of obesity in children living with BBS
                </h2>
                <p className="mt-1 md_mt-1 text-Align-Center" style={{ textAlign: 'center' , fontWeight: 'normal'}}>
                You may be more familiar with viewing a child's growth as a percentile on a chart from the doctor. These same charts can be used for BMI. This growth chart is a hypothetical representation of what a 12 year-old female with BBS who is taking IMCIVREE may experience in BMI reduction after 1 and 2 years, based on results from the clinical study.
                </p>

              
               
                
                <div className="row lg_mt-2 mt-2  text-Align-Center" style={{ textAlign: 'center',marginRight : 'auto', marginLeft: 'auto' }}>
                <img
                src={GrowthChart}
                alt="Average reduction in weight in adults over the course of clinical trial"
                className="hide-for-small-only"
               style={{marginRight : 'auto', marginLeft: 'auto'}}/>

              <img
              src={GrowthChartMobile}
              alt="Average reduction in weight in adults over the course of clinical trial"
              className="hide-for-medium hide-for-large"
            />
                  {/* <div className="column small-12 medium-5">
                <img src={pedCallout02} className="mt-2 lg_mt-2" />
              </div> */}

             



                  <div className="column small-12 ">
                    <p className="fw-normal hangingRef mt-2 md_mt-2">
                      Figure modeled after Gulati AK, Kaplan DW, Daniels SR.
                      Clinical tracking of severely obese children: a new growth
                      chart. <em>Pediatrics.</em> 2012;130(6):1136-1140.
                    </p>
                    <p className="fw-normal hangingRef ">Not an actual patient. Growth chart is based on females 2 to 20 years of age and is for illustrative purposes only.</p>
                   
                  </div>

            
                    <ul className="neon-green-bullets">
                    
                    <li className="mt-2 lg_mt-2" style={{textAlign : 'left'}}>
                    At the start of the clinical trial, BMI was 145% of the 95th percentile
                    </li>
                   
                    <li className="mt-2 lg_mt-2" style={{textAlign : 'left'}}>
                    At the end of the clinical trial, BMI was 128% of the 95th percentile
                      <ul>
                        <li className="is-dash" style={{textAlign : 'left'}}>17-percentage-point reduction</li>
                      </ul>
                    </li>
                    <li className="mt-2 lg_mt-2" style={{textAlign : 'left'}}>
                    After 2 years in a long-term extension of the clinical trial, BMI was 115% of the 95th percentile 
                    <ul>
                        <li className="is-dash" style={{textAlign : 'left'}}>30-percentage-point reduction</li>
                      </ul>
                    </li>
                  </ul>

                </div>
              </Tabs.Pane>

              <Tabs.Pane name="Weight reduction in adults" key="2">

                  <div className="column small-12 medium-12">
                  <h2 className="h2--no-margin-bottom h2-color-blue">IMCIVREE helped adults reach steady and long-lasting weight reduction</h2>
                 
                
                  <img
                  src={BBSAdultsReductionGraph}
                  alt="Average reduction in weight in adults over the course of clinical trial"
                  className="hide-for-small-only mt-2 lg_mt-2 ml_mt-2"
                />
  
                <img
                src={BBSAdultsReductionGraphMobile}
                alt="Average reduction in weight in adults over the course of clinical trial"
                className="hide-for-medium hide-for-large ml_mt-2 mt-2"
              />
              

                  <div className="text-center chart-overflow-x mb-1 md_mb-2 lg_mt-1">
                </div>
                </div>
                <div className="row" >
                <p style={{textAlign: 'left'}} className="mt-3 lg_mt-2">IMCIVREE reduced body weight over the course of the 1-year clinical trial. At the end of a clinical trial for IMCIVREE, 19 people continued in a long-term study. 6 of these people were adults. People are being assessed every 3 months until the end of the study (up to 5 years or study withdrawal).</p>
                <p style={{textAlign: 'left'}} className="mt-3">15 adults aged 18 years or older were evaluated as part of the study.</p>
                </div>
                <div className="columns large-9 medium-10 small-12 mt-2 lg_mt-2">
                <Callout
                  fullWidth
                  className="lime-teal left text-left "
                  style={{ left: `0` }}
                >
                  <p>
                  No change to diet or exercise: <span style={{fontWeight: 'normal'}}>In the clinical trial, people were not required to change their diet or exercise routine</span>
                  </p>
                </Callout>
              </div>
            


              <div className="row text-center lg_mt-4 mt-4">
              <div className="column small-12 medium-3">
              <img src={weeks14CallOut} />
                </div>
                <div className="column small-12 medium-1 ">
                </div>
                <div className="column small-12 medium-3 mt-3">
                  <div class=""><img src={year10Callout}/></div>
                </div>
                <div className="column medium-1 hide-for-small-only">
                <div class="vertical-dashed-line"></div>
                </div>
                <div className="column small-12 hide-for-large">
                <div class="hor-dashed-line"></div>
                </div>
                <div className="column small-12 medium-3 ">
                <div class=""><img src={year2Callout}/>
              
                </div>
              </div>
              </div>

              <div className="columns large-9 medium-10 small-12 mt-4 lg_mt-3" style={{ marginBottom: '2rem'}}>
              <Callout
                fullWidth
                className="lime-teal left text-left"
                style={{ left: `0` }}
              >
                <p className="">
                <strong>IMCIVREE reduced weight early and continuously over the course of 2 years of treatment</strong>
                </p>
              </Callout>
            </div>



            <h2 className="h2 h2-color-blue test mt-4 lg_mt-3"  style={{}}>
            IMCIVREE reduced the severity of obesity in adults living with BBS
                </h2>
              <p className="text-center" style={{marginTop: '1rem'}}>This BMI chart is a hypothetical representation of the BMI reduction an adult with BBS may experience after 1 year of treatment on IMCIVREE, based on data from the clinical trial.</p>
                <div className="text-center lg_mt-2 hide-for-small-only">
                  <img
                    src={BBSreduction}
                    alt="Average reduction in weight in adults over the course of clinical trial"
                    className=""
                  />
                </div>

                <div className="text-center lg_mt-2 hide-for-large">
                <img
                  src={BBSreductioMobile}
                  alt="Average reduction in weight in adults over the course of clinical trial"
                  className=""
                />
              </div>
              
               

                

                

             

                <div className="row mt-4 lg_mt-3">
                  <div className="small-12 medium-12 large-8 large-offset-3 column mb-4" style={{marginBottom:'4rem'}}>
                  
                  <Quote
                    Quote
                    copy={
                      <>
                      IMCIVREE has mainly helped me lose and control my weight. I know I couldn’t have done it without this medication.
                      </>
                    }
                    signoff={<>— Adult patient enrolled in the IMCIVREE clinical trial</>}
                  ></Quote>
              
                  </div>
                </div>
              </Tabs.Pane>

              <Tabs.Pane name="Hunger reduction" key="3">
                <h2 className="h2--no-margin-bottom">
                  IMCIVREE provided reduction in hunger scores early and
                  continuously throughout treatment
                </h2>
                <p className="mt-1 md_mt-1">
                  The effect of IMCIVREE on reducing hunger was studied in
                  people 12 years and older living with BBS who could
                  self-report their hunger.
                </p>
                <ul className="neon-green-bullets">
                  <li>
                    They completed a questionnaire every day for 1 year to
                    determine changes in their hunger
                  </li>
                  <li>
                    People scored their hunger on a daily basis using a scale
                    from 0 to 10
                  </li>
                </ul>
                <p className="mb-1"></p>
                <p className="text-center hide-for-medium">
                  &larr; Swipe left or right to view &rarr;
                </p>
                <div className="text-center swipe-container">
                  <img
                    src={hungerScale}
                    alt="Hunger Scale"
                    className="lg_mt-2 mb-2 md_mb-3"
                    style={{ minWidth: `800px`, maxWidth: `800px` }}
                  />
                </div>

                <h2 className="h2">
                  IMCIVREE reduced the most severe feelings of hunger
                </h2>
                <p className="text-center hide-for-medium">
                  &larr; Swipe left or right to view &rarr;
                </p>
                <div className="text-center swipe-container">
                  <img
                    src={hungerChart}
                    alt="IMCIVREE average hunger score"
                    className="mb-2 md_mb-2"
                    style={{ minWidth: `800px`, maxWidth: `880px` }}
                  />
                  <ul class="neon-green-bullets text-left video-transcript">
                    <li>14 people were studied.</li>
                  </ul>
                </div>
                <div className="row">
                  <div className="columns large-8 medium-10 small-12 mt-2 md_mt-2">
                    <Callout
                      fullWidth
                      className="lime-teal left text-left"
                      style={{ left: `0` }}
                    >
                      <p>
                        A majority of people experienced a reduction in hunger
                        score within 2 weeks of starting IMCIVREE
                      </p>
                    </Callout>
                  </div>
                </div>

                <div className="row mt-2 md_mt-4">
                  <div className="small-12 medium-12 large-2 column">
                    <figure className="hunger-bbs-profile text-center">
                      <img
                        src={hungerBBSProfile01}
                        alt="Person living with BBS"
                        className="mb-2 md_mb-3"
                        style={{ minWidth: `300px`, maxWidth: `300px` }}
                      />
                    </figure>
                  </div>
                  <div className="small-12 medium-12 large-8 large-offset-2 column mb-4">
                    <Quote
                      Quote
                      copy={
                        <>
                          Before IMCIVREE, I didn’t realize how much time I
                          spent focusing on food, and how much that was
                          affecting my day-to-day and the other things I could
                          be accomplishing.
                        </>
                      }
                      signoff={<>— Kathryn, a person living with BBS</>}
                    ></Quote>
                  </div>

                  <div className="small-12 medium-12 large-8 column mb-2 md_mb-4 small-order-2 medium-order-1">
                    <Quote
                      Quote
                      copy={
                        <>
                          The change in Reed's hunger has cascaded into many
                          positive life changes for all of us. There's less
                          agitation and anxiety over hunger or family meals.
                          This is simple normalcy for many families, but for us,
                          they're moments I'll never take for granted.
                        </>
                      }
                      signoff={<>— Kat, caregiver of a child living with BBS</>}
                    ></Quote>
                  </div>
                  <div className="small-12 medium-12 large-4 column mb-2 small-order-1 medium-order-2">
                    <figure className="hunger-bbs-profile text-center">
                      <img
                        src={hungerBBSProfile03}
                        alt="Caregiver of a child living with BBS"
                        className="mt-2 mb-1 md_mb-3"
                        style={{ minWidth: `300px`, maxWidth: `300px` }}
                      />
                    </figure>
                  </div>
                  
                  <div className="small-12 medium-12 large-2 column">
                    <figure className="hunger-bbs-profile text-center">
                      <img
                        src={hungerBBSProfile02}
                        alt="Caregiver of a child living with BBS"
                        className="mb-2 md_mb-3"
                        style={{ minWidth: `300px`, maxWidth: `300px` }}
                      />
                    </figure>
                  </div>
                  <div className="small-12 medium-12 large-8 large-offset-2 column">
                    <Quote
                      Quote
                      copy={
                        <>
                          He is no longer digging through the fridge or garbage,
                          so we do not lock them anymore. He isn’t asking for
                          food constantly between meals and snacks, and I
                          sometimes find myself realizing it’s been a few hours
                          and asking him if he’s ready for a snack.
                        </>
                      }
                      signoff={<>— Rachel, caregiver of a child living with BBS</>}
                    ></Quote>
                  </div>




                  
                </div>
              </Tabs.Pane>
             <Tabs.Pane name="Quality of life" key="4">
               
                      <div className="content-block mt-0 lg_mt-0 md_pb-0">
      <div className="row og" style={{textAlign: 'left'}}>
        <div className="column og small-12">
          <h1 className="h1 mb-0 md_mb-0">
            How did people with BBS and obesity report changes to their health-related quality of life?
          </h1>
        </div>
        <div className="columns og small-12 large-12 lg_mt-2 mt-2">
          <div className="color-teal jost-semibold fs-20 mb-1 md_mb-1">
           Quality-of-life is a measure of a person's day-to-day well-being at a point in time. 
          </div>
          <h3 className="fs-para fw color-gray">
          As part of this study, people with BBS were asked to evaluate aspects of their quality of life.</h3>
          <div className="row text-center">
              <div className="column small-12 medium-6" style={{textAlign: 'left'}}>
              <p>For children, questions in the survey measured:</p>
              <ul className="neon-green-bullets mt-1 md_mt-1 mb-1 md_mb-1 ml-2 md_ml-2 pr-1 md_pr-1">
            <li>Physical ability in daily activities</li>
            <li>Emotional state</li>
            <li>Social needs</li>
            <li>Ability to perform at school</li>
      
            </ul>
                </div>
                <div className="column small-12 medium-6" style={{textAlign: 'left'}}>
                <p>For adults, the questions in the survey measured:</p>
                <ul className="neon-green-bullets mt-1 md_mt-1 mb-1 md_mb-1 ml-2 md_ml-2 pr-1 md_pr-1">
              <li>Physical ability in daily activities</li>
              <li>Self-esteem</li>
              <li>Sexual life</li>
              <li>Feelings of distress in public</li>
              <li>Ability to perform at work</li>

        
              </ul>
                  </div>
            </div>





          <p className="fs-para fw mt-1 md_mt-1">Higher survey scores indicated improvement to daily life. Lower scores indicated a decrease.</p>

          <p className="fs-para fw mt-1 md_mt-1">On average, people reported higher scores after 1 year of taking IMCIVREE.</p>
          
          <p className="fs-para fw mt-1 md_mt-1"> Although general improvements were measured, there were a limited number of people to make clear conclusions. The trial was also not set up to determine whether these changes were because of IMCIVREE.</p>



        </div>
        {/* <div className="columns og small-12 large-6 lg_mt-2 mt-2">
          <div className="color-teal jost-semibold fs-20 mb-1 md_mb-1">
            In interviews of people who participated in IMCIVREE<br className="show-for-medium" /> clinical
            trials
          </div>

          <h3 className="fs-para fw-bold color-gray">All 19 participants reported improvements, which included:</h3>

          <ul className="neon-green-bullets mb-1 md_mb-1">
            <li>A large reduction in the amount of food they were eating</li>
            <li>Less food-seeking behaviors</li>
            <li>Better ability to focus</li>
            <li>
              Improvements in either their physical and/or emotional well-being
            </li>
          </ul>

          <div className="hide-for-medium">
            <HangingRef
              mb0
              ml2
              mt-1
              symbol="*"
              isAsterisk
              copy="n is the number of people in the study with impaired quality of life."
            />
          </div>
        </div> */}

      </div>
    </div>

    <div className="content-block pt-0 md_pt-2 md_pb-0">
    <div className="row og mt-2">
      <div className="columns og">
        <h2 className="h1 mb-1 lg_mb-1">The impact of IMCIVREE</h2>
        <h3 className="h2">
          Learn more about the impact of IMCIVREE from healthcare providers and people on treatment
        </h3>
      </div>
    </div>

    <QualityOfLifeVideo />
    <VideoTranscript02>
      <QualityOfLifeTranscript />
    </VideoTranscript02>

  </div>





               
              </Tabs.Pane>
            </Tabs>
          </div>
        

        <div className="text-center mt-3 md_mt-2">
          <Button
            copy={<>Learn about the possible side effects with IMCIVREE</>}
            url="/bbs/side-effects/"
          />
        </div>
      </div>
    </Layout>
  )
}

export default WeightHungerReduction
