import React from "react"
import Callout from "../components/callout"
import HangingRef from "../components/hanging-ref"

import ImageIconGreenPlus from "../images/icon-green-plus.svg"
import ImageIconGreenMinus from "../images/icon-green-minus.svg"
import ImageIconCloseX from "../images/icon-close-x.svg"
import ImageHyperpigmentaion from "../images/hyperpigmentation.png"
import ImageHypoGrowthAdolescent from "../images/hypothetical-growth-chart-adolescent.png"
import ImageFourteenWeekModalChart from "../images/14-week-modal-chart-no-p-value.png"
import ImageFourteenWeekModalChartAdult from "../images/14-week-modal-chart-adult.png"
import ImagePatientAccess from "../images/hcp/ppl/patient-access-modal-bg.svg"

import Quote from "../components/quote"
import overviewModal01 from "../images/overview-modal-profile01.png"
import overviewModal02 from "../images/overview-modal-profile02.png"

import OverviewRibbonIcon from "../images/bbs-overview-ribbon-icon.png"

import SymptomProgressionChart from "../components/gatsby-images/symptom-progression"

import PplHyperphagiaChart from "../components/ppl-hyperphagia-chart"

import HungerScaleGraph from "../images/hcp-ppl-hunger-scale-modal.png"
import MostCommonClinicalFeaturesTable from "./most-common-clinical-features-table"
import AdditionalClinicalFeaturesTable from "./additional-clinical-features-table"
import KeyIdentifiableFeaturesTable from "./key-identifiable-features-table"

import IconLightbulb from "../images/icon-lightbulb.png"
import IconClinialSupport from "../images/icon-clinical-support.png"
import IconLabDoor from "../images/icon-lab-door.png"
import IconSwabKit from "../images/icon-swab-kit-3x.png"

const Modal = ({ id }) => {
  const modalClickHandler = mID => {
    let mTarget = mID + "-modal-container"
    if (!document.getElementById(mTarget).classList.contains("modal-active")) {
      document.getElementById(mTarget).classList.add("modal-active")
      document.querySelector("body").classList.add("modal-body-active")
    } else {
      document.getElementById(mTarget).classList.remove("modal-active")
      document.querySelector("body").classList.remove("modal-body-active")
    }
  }

  const setWeightReductionContent = type => {
    return (
      <>
        {type === "bmi-14-year" && (
          <>
            <p className="mt-2 md_mb-0">
              In patients 6 to &lt;18 years of age with BBS
            </p>
            <h1 className="h1">
              IMCIVREE significantly reduced weight<sup>1</sup>
            </h1>
            <p className="h2 medium-text-center h2--no-margin-bottom">
              Weight changes in pivotal and supplemental patients 6 to &lt;18 years
              of age with BBS during the 14-week double&#8209;blind,
              placebo-controlled period*
            </p>
            <p className="text-center hide-for-medium mt-1">
              &larr; Swipe left or right to view &rarr;
            </p>
            <p className="text-center chart-overflow-x mb-2 md_mb-2">
              <img
                src={ImageFourteenWeekModalChart}
                alt="Change in body weight in 14-week-placebo-controlled period"
                style={{ width: "60%" }}
                className="pb-2"
              />
            </p>
            <HangingRef
              mb1
              symbol="*"
              isAsterisk
              copy={
                <>
                  Results in sub-population based on post-hoc analysis.
                  Supplemental patients were enrolled after the pivotal cohort
                  had begun treatment. None of the supplemental patients had
                  completed 52 weeks of IMCIVREE treatment at the time of data
                  analysis.<sup>1</sup>
                </>
              }
            />
            <HangingRef
              symbol=""
              isAsterisk
              copy="PCPB=placebo-controlled period baseline."
            />

            <HangingRef
            symbol=""
            isAsterisk
            copy={<><strong>Reference</strong>: 1. Haqq AM et al. Lancet Diabetes Endocrinol. 2022;10(12):859-868. doi:10.1016/S2213-8587(22)00277-7. Supplemental appendix available at: https://www.thelancet.com/journals/landia/article/PIIS2213-8587(22)00277-7/fulltext.
              </>
            }
            />
          </>
        )}
        {type === "bmi-24-month-1" && (
          <>
            <div className="h2--no-margin-bottom">
              Long-term extension study design<sup>1,2</sup>
            </div>
            <ul className="neon-green-bullets">
              <li>
                At completion of an index trial, 19 patients who enrolled in a
                separate open-label, long-term extension study had received at
                least 24 months of IMCIVREE
              </li>
              <li>
                Patients to be assessed every 3 months until the end of the
                study (up to 5 years or patient withdrawal)
              </li>
            </ul>
            <div>&nbsp;</div>
            <div className="h2--no-margin-bottom">
              Safety observations<sup>1</sup>
            </div>
            <ul className="neon-green-bullets">
              <li>
                One patient discontinued due to an AE unrelated to IMCIVREE
              </li>
            </ul>
            <div>&nbsp;</div>
            <div className="references mb-1 md_mb-1">AE=adverse event.</div>
            <div className="references">
              <strong>References: 1.</strong> Argente J et al. Endocrine Society
              Annual Meeting. Poster ODP606. June 11-14, 2022.{" "}
              <strong>2.</strong> Data on file. Rhythm Pharmaceuticals, Inc.
              Boston, MA.
            </div>
          </>
        )}
        {type === "bmi-24-month-2" && (
          <>
            <p className="mt-2 md_mb-0">
              Results from a long-term extension study in patients 6 to &lt;18 years
              of age with BBS (n=12):
            </p>
            <div className="h1">
              IMCIVREE delivered sustained weight reduction<sup>1,2</sup>*
            </div>
            <ul className="neon-green-bullets">
             
              <li>
                One person discontinued due to an AE unrelated to IMCIVREE
              </li>
            </ul>
            <div>&nbsp;</div>
            <div className="h2--no-margin-bottom">
              Long-term extension study design<sup>1,2</sup>
            </div>
            <ul className="neon-green-bullets">
               <li>
              At completion of an index trial, 19 patients who enrolled in a separate open-label,
long-term extension study had received at least 24 months of IMCIVREE
              </li>
              <li>
                Patients to be assessed every 3 months until the end of the
                study (up to 5 years or patient withdrawal)
              </li>
            </ul>
            <div>&nbsp;</div>
            <HangingRef
              mb0
              symbol="*"
              isAsterisk
              copy={
                <>
                  Compared with measures at index trial baseline.<sup>2</sup>
                </>
              }
            />
            <div className="references mt-1 mb-1 md_mt-1 md_mb-1">
              AE=adverse event.
            </div>
            <div className="references">
              <strong>References: 1.</strong> Argente J et al. Endocrine Society
              Annual Meeting. Poster ODP606. June 11-14, 2022.
              <strong>2.</strong> Data on file. Rhythm Pharmaceuticals, Inc.
              Boston, MA.
            </div>
          </>
        )}
        {type === "bmi-24-month-3" && (
          <>
            <p className="mt-2 mb-0 md_mb-0">
              Results from a long-term extension study in patients 6 to &lt;18 years
              of age with BBS (n=12):
            </p>
            <div className="h1">
              IMCIVREE delivered sustained weight reduction<sup className="position-relative">1</sup>*
            </div>
            <ul className="neon-green-bullets">
              
              <li>
                One patient discontinued due to an AE unrelated to IMCIVREE
              </li>
            </ul>
            <div>&nbsp;</div>

            <div className="h2--no-margin-bottom">
              Long-term extension study design<sup>1,2</sup>
            </div>
            <ul className="neon-green-bullets">
              <li>
                At completion of an index trial, 19 patients who enrolled in a
                separate open-label, long-term extension study had received at
                least 24 months of IMCIVREE<sup>2</sup>
              </li>
              <li>
                Patients to be assessed every 3 months until the end of the
                study (up to 5 years or patient withdrawal)<sup>1,2</sup>
              </li>
            </ul>
            <div>&nbsp;</div>
            <HangingRef
              mb0
              symbol="*"
              isAsterisk
              copy={
                <>
                  Compared with measures at index trial baseline.<sup>2</sup>
                </>
              }
            />
            <div className="references mt-1 mb-1 md_mt-1 md_mb-1">
              AE=adverse event.
            </div>
            <div className="references">
              <strong>References: 1.</strong> Argente J et al. Endocrine Society
              Annual Meeting. Poster ODP606. June 11-14, 2022.
              <strong> 2.</strong> Data on file. Rhythm Pharmaceuticals, Inc.
              Boston, MA.
            </div>
          </>
        )}
        {type === "adult-14-year" && (
          <>
            <p className="mt-2 md_mb-0">
              In patients &ge;18 years of age with BBS
            </p>
            <h1 className="h1">
              13x greater weight reduction with IMCIVREE
            </h1>
            <p className="h2 text-center">
              Weight changes in pivotal and supplemental patients &ge;18 years
              of age with BBS during the 14-week double&#8209;blind,
              placebo-controlled period*
            </p>
            <p className="text-center hide-for-medium">
              &larr; Swipe left or right to view &rarr;
            </p>
            <p className="text-center chart-overflow-x">
              <img
                src={ImageFourteenWeekModalChartAdult}
                alt="Change in body weight in 14-week-placebo-controlled period in adults"
                style={{ width: "60%" }}
              />
              <p>&nbsp;</p>
            </p>
          
            <HangingRef
              mb1
              symbol="*"
              isAsterisk
              copy="Results in sub-population based on ad-hoc analysis. Supplemental patients were enrolled after the pivotal cohort had begun treatment. None of the supplemental patients had completed 52 weeks of IMCIVREE treatment at the time of data analysis."
            />

            <HangingRef
              symbol=""
              isAsterisk
              copy="PCPB=placebo-controlled period baseline."
            />

            <div className="references">
              <strong>Reference: 1.</strong> Haqq AM et al.{" "}
              <em>Lancet Diabetes Endocrinol.</em> 2022;10(12):859-868.
              doi:10.1016/S2213-8587(22)00277-7. Supplemental appendix available
              at:
              https://www.thelancet.com/journals/landia/article/PIIS2213-8587(22)00277-7/fulltext.
            </div>
          </>
        )}
        {type === "adult-24-month-1" && (
          <>
            <p className="mt-2 md_mb-0">
              Results from a long-term extension study in patients with BBS
              &ge;18 years of age (n=6):
            </p>
            <h1 className="h1 w-100">
              IMCIVREE delivered sustained and improved weight reduction<sup className="position-relative">1</sup>*
            </h1>
            <div className="row">
              <div className="columns small-12 medium-5 text-center medium-text-right color-teal align-self-middle md_mr-2">
                <span style={{ fontSize: "400%", fontWeight: "600" }}>
                  ~15%
                </span>
              </div>
              <div className="columns small-12 medium-6 text-center medium-text-left align-self-middle mt--1">
                <strong className="h2">reduction in body weight</strong>
              </div>
            </div>
            <div>&nbsp;</div>
            <ul className="neon-green-bullets">
              <li>
                One patient discontinued due to an AE unrelated to IMCIVREE
                <sup>1</sup>
              </li>
            </ul>
            <div>&nbsp;</div>
            <div className="h2">
              Long-term extension study design<sup>1,2</sup>
            </div>
            <ul className="neon-green-bullets" style={{ marginTop: `-1.5rem` }}>
              <li>
                At completion of an index trial, 19 patients who enrolled in a
                separate open-label, long-term extension study had received at
                least 24 months of IMCIVREE<sup>2</sup>
              </li>
              <li>
                Patients to be assessed every 3 months until the end of the
                study (up to 5 years or patient withdrawal)<sup>1,2</sup>
              </li>
            </ul>
            <div>&nbsp;</div>
            <HangingRef
              symbol="*"
              isAsterisk
              copy={
                <>
                  Compared with measures at index trial baseline.<sup>2</sup>
                </>
              }
            />
            <div className="references mb-1 md_mb-1">AE=adverse event.</div>

            <div className="references">
              <strong>References: 1.</strong> Argente J et al. Endocrine Society
              Annual Meeting. Poster ODP606. June 11-14, 2022.&nbsp;
              <strong>2.</strong> Data on file. Rhythm Pharmaceuticals, Inc.
              Boston, MA.
            </div>
          </>
        )}
        {type === "adult-24-month-2" && (
          <>
            <h1 className="h1" style={{ width: `unset` }}>
              IMCIVREE delivered sustained and improved weight reduction at 24
              months<sup>1</sup>*
            </h1>
            <div className="row">
              <div className="columns small-12 medium-5 text-center medium-text-right color-teal align-self-middle md_mr-2">
                <span style={{ fontSize: "400%", fontWeight: "600" }}>
                  ~15%
                </span>
              </div>
              <div className="columns small-12 medium-6 text-center medium-text-left align-self-middle mt--1">
                <strong className="h2">reduction in body weight</strong>
              </div>
            </div>
            <div>&nbsp;</div>
            <ul className="neon-green-bullets">
             
              <li>
                One person discontinued due to an AE unrelated to IMCIVREE
                <sup>1</sup>
              </li>
            </ul>
            <div>&nbsp;</div>
            <div className="h2">
              Long-term extension study design<sup>1,2</sup>
            </div>
            <ul className="neon-green-bullets" style={{ marginTop: `-1.5rem` }}>
              <li>
                At completion of an index trial, 19 people who enrolled in a
                separate open-label, long-term extension study had received at
                least 24 months of IMCIVREE<sup>2</sup>
              </li>
              <li>
                People to be assessed every 3 months until the end of the study
                (up to 5 years or patient withdrawal)<sup>1,2</sup>
              </li>
            </ul>
            <div>&nbsp;</div>
            <HangingRef
              symbol="*"
              isAsterisk
              copy={
                <>
                  Compared with measures at index trial baseline.<sup>2</sup>
                </>
              }
            />
            <div className="references mb-1 md_mb-1">AE=Adverse Reactions</div>
            <div className="references">
              <strong>References: 1.</strong> Argente J et al. Endocrine Society
              Annual Meeting. Poster ODP606. June 11-14, 2022.&nbsp;
              <strong>2.</strong> Data on file. Rhythm Pharmaceuticals, Inc.
              Boston, MA.
            </div>
          </>
        )}
        {type === "adult-24-month-3" && (
          <>
            <h1 className="h1" style={{ width: `unset` }}>
              IMCIVREE delivered sustained and improved weight reduction at 24
              months<sup className="position-relative">1,2</sup>*
            </h1>
            <div className="row">
              <div className="columns small-12 medium-5 text-center medium-text-right color-teal align-self-middle md_mr-2">
                <span style={{ fontSize: "400%", fontWeight: "600" }}>
                  ~15%
                </span>
              </div>
              <div className="columns small-12 medium-6 text-center medium-text-left align-self-middle mt--1">
                <strong className="h2">reduction in body weight</strong>
              </div>
            </div>
            <div>&nbsp;</div>
            <ul className="neon-green-bullets">
             
              <li>
                One patient discontinued due to an AE unrelated to IMCIVREE
                <sup>1</sup>
              </li>
            </ul>
            <div>&nbsp;</div>
            <div className="h2">
              Long-term extension study design<sup>1,2</sup>
            </div>
            <ul className="neon-green-bullets" style={{ marginTop: `-1.5rem` }}>
              <li>
                At completion of an index trial, 19 patients who enrolled in a
                separate open-label, long-term extension study had received at
                least 24 months of IMCIVREE
              </li>
              <li>
                Patients to be assessed every 3 months until the end of the
                study (up to 5 years or patient withdrawal)
              </li>
            </ul>
            <div>&nbsp;</div>
            <HangingRef
              symbol="*"
              isAsterisk
              copy={
                <>
                  Compared with measures at index trial baseline.<sup>2</sup>
                </>
              }
            />
            <div className="references mb-1 md_mb-1">AE=adverse event.</div>
            <div className="references">
              <strong>References: 1.</strong> Argente J et al. Endocrine Society
              Annual Meeting. Poster ODP606. June 11-14, 2022.&nbsp;
              <strong>2.</strong> Data on file. Rhythm Pharmaceuticals, Inc.
              Boston, MA.
            </div>
          </>
        )}
      </>
    )
  }

  //Button and Modal content

  const PatientAccess = () => {
    return (
      <div>
        <div id="patient-access-hcp-ppl-trigger-container">
          <div style={{ display: "table" }} className="modal-content-block">
            <div style={{ display: "table-cell", verticalAlign: "middle" }}>
              <button onClick={() => modalClickHandler(id)}>
                <img
                  src={ImageIconGreenPlus}
                  alt="Green plus icon"
                  className="modal-trigger-button-plus"
                />
              </button>
            </div>
            <div style={{ display: "table-cell", verticalAlign: "middle" }}>
              &nbsp;&nbsp;
            </div>
            <div style={{ display: "table-cell", verticalAlign: "middle" }}>
              <span className="h2 color-teal">Patient access</span>
            </div>
          </div>
        </div>
        <div
          id="patient-access-hcp-ppl-modal-container"
          className="modal-container"
        >
          <div
            className="modal-content-container color-black mb-14 md_mb-10"
            style={{ padding: "2% 3% 0", overflow: "hidden" }}
          >
            <button
              className="modal-icon-close-x"
              onClick={() => modalClickHandler(id)}
            >
              <img
                src={ImageIconCloseX}
                alt="Close X icon"
                style={{ width: "80%" }}
              />
            </button>
            <div className="row">
              <div className="columns large-8 small-12 lg_mt-1 mt-1">
                <div className="row">
                  <div className="columns small-12">
                    <h1 className="h1">
                      Rhythm InTune is committed to assisting eligible patients,
                      regardless of insurance plan type
                    </h1>
                    <h2 className="h2" style={{ color: "#4B4F54" }}>
                      The majority of patients, regardless of coverage type,
                      have obtained approval for IMCIVREE<sup>1</sup>
                    </h2>
                    <ul
                      className="neon-green-bullets mb-1 md_mb-1"
                      style={{ color: "#4B4F54" }}
                    >
                      <li style={{ marginTop: "1rem" }}>
                        Many patients have obtained coverage approval at the
                        prior authorization level
                      </li>
                      <li>
                        The majority of commercial plans have coverage policies
                        in place for IMCIVREE
                      </li>
                      <li>
                        The majority of Medicaid covered lives have a coverage
                        policy in place or a positive decision on IMCIVREE
                        coverage
                      </li>
                    </ul>
                  </div>
                </div>
                <Callout left className="light-teal left text-left">
                  <p>
                    Financial assistance programs are available for eligible
                    patients
                  </p>
                </Callout>
                <div className="references md_mt-2 mt-1 gray-text">
                  <b>Reference:</b> <b>1.</b> Data on file. Rhythm
                  Pharmaceuticals, Inc. Boston, MA.
                </div>
              </div>
              <div className="columns large-4 small-12 text-right align-self-bottom">
                <img
                  src={ImagePatientAccess}
                  alt={"Actor Portrayal"}
                  style={{ bottom: "-3px", position: "relative" }}
                  className="hcp-ppl-actor-img"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const PatientAccessHcpBbs = () => {
    return (
      <div>
        <div id="patient-access-hcp-bbs-trigger-container">
          <div style={{ display: "table" }} className="modal-content-block">
            <div style={{ display: "table-cell", verticalAlign: "middle" }}>
              <button onClick={() => modalClickHandler(id)}>
                <img
                  src={ImageIconGreenPlus}
                  alt="Green plus icon"
                  className="modal-trigger-button-plus"
                />
              </button>
            </div>
            <div style={{ display: "table-cell", verticalAlign: "middle" }}>
              &nbsp;&nbsp;
            </div>
            <div style={{ display: "table-cell", verticalAlign: "middle" }}>
              <span className="h2 color-teal">Patient access</span>
            </div>
          </div>
        </div>
        <div
          id="patient-access-hcp-bbs-modal-container"
          className="modal-container"
        >
          <div
            className="modal-content-container color-black mb-14 md_mb-10"
            style={{ padding: "2% 3% 0", overflow: "hidden" }}
          >
            <button
              className="modal-icon-close-x"
              onClick={() => modalClickHandler(id)}
            >
              <img
                src={ImageIconCloseX}
                alt="Close X icon"
                style={{ width: "80%" }}
              />
            </button>
            <div className="row">
              <div className="columns large-8 small-12 lg_mt-1 mt-1">
                <div className="row">
                  <div className="columns small-12">
                    <h1 className="h1">
                      Rhythm InTune is committed to assisting eligible patients,
                      regardless of insurance plan type
                    </h1>
                    <h2 className="h2" style={{ color: "#4B4F54" }}>
                      The majority of patients, regardless of coverage type,
                      have obtained approval for IMCIVREE<sup>1</sup>
                    </h2>
                    <ul
                      className="neon-green-bullets mb-1 md_mb-1"
                      style={{ color: "#4B4F54" }}
                    >
                      <li style={{ marginTop: "1rem" }}>
                        Many patients have obtained coverage approval at the
                        prior authorization level
                      </li>
                      <li>
                        The majority of commercial plans have coverage policies
                        in place for IMCIVREE
                      </li>
                      <li>
                        The majority of Medicaid covered lives have a coverage
                        policy in place or a positive decision on IMCIVREE
                        coverage
                      </li>
                    </ul>
                  </div>
                </div>
                <Callout left className="light-teal left text-left">
                  <p>
                    Financial assistance programs are available for eligible
                    patients
                  </p>
                </Callout>
                <div>&nbsp;</div>
                <div className="references gray-text">
                  <b>Reference: 1.</b> Data on file. Rhythm Pharmaceuticals,
                  Inc. Boston, MA.
                </div>
              </div>
              <div className="columns large-4 small-12 text-right align-self-bottom">
                <img
                  src={ImagePatientAccess}
                  alt=""
                  style={{ bottom: "-3px", position: "relative" }}
                  className="hcp-bbs-actor-img"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const HyperpigmentationHcpPpl = () => {
    return (
      <div>
        <div id="hyperpigmentation-hcp-ppl-trigger-container">
          <div style={{ display: "table" }} className="modal-content-block">
            <div style={{ display: "table-cell", verticalAlign: "middle" }}>
              <button onClick={() => modalClickHandler(id)}>
                <img
                  src={ImageIconGreenPlus}
                  alt="Green plus icon"
                  className="modal-trigger-button-plus"
                />
              </button>
            </div>
            <div style={{ display: "table-cell", verticalAlign: "middle" }}>
              &nbsp;&nbsp;
            </div>
            <div style={{ display: "table-cell", verticalAlign: "middle" }}>
              <span className="h2 color-teal">Hyperpigmentation</span>
            </div>
          </div>
        </div>
        <div
          id="hyperpigmentation-hcp-ppl-modal-container"
          className="modal-container"
        >
          <div className="modal-content-container color-black mb-14 md_mb-10">
            <button
              className="modal-icon-green-minus"
              onClick={() => modalClickHandler(id)}
            >
              <img src={ImageIconGreenMinus} alt="Green minus icon" />
            </button>
            <h1 className="h1">
              Hyperpigmentation was common and rarely led to discontinuation
              <sup>1,2</sup>
            </h1>
            <p className="text-center">
              <strong>
                Example of hyperpigmentation<sup>3</sup>
              </strong>
            </p>
            <div>
              <img
                src={ImageHyperpigmentaion}
                alt="Example of hyperpigmentation before, during, and after stopping IMCIVREE treatment"
              />
            </div>
            <p>&nbsp;</p>
            <ul className="neon-green-bullets mb-1 md_mb-1">
              <li>
                Changes in skin pigmentation or hair color typically presented
                within the first few months of treatment with IMCIVREE
                <sup>4</sup>
                <ul className="neon-green-bullets">
                  <li className="is-dash">
                    Skin darkening plateaued during the course of treatment
                    <sup>4</sup>
                  </li>
                  <li className="is-dash">
                    Hyperpigmentation is variable<sup>3</sup>
                  </li>
                  <li className="is-dash">
                    This effect is reversible upon discontinuation of treatment
                    <sup>1</sup>
                  </li>
                  <li className="is-dash">
                    Perform a full body skin examination prior to initiation and
                    periodically during treatment with IMCIVREE to monitor
                    pre-existing and new skin pigmentary lesions<sup>1</sup>
                  </li>
                </ul>
              </li>
              <li>
                <strong>
                  There were no reports of melanoma related to the observed
                  hyperpigmentation in clinical trials of IMCIVREE<sup>5</sup>
                </strong>
                <ul>
                  <li className="is-dash">
                    Hyperpigmentation is not unexpected given that IMCIVREE
                    activates the melanocortin-1 receptor, which results in
                    melanin production<sup>1</sup>
                  </li>
                </ul>
              </li>
            </ul>
            <div className="references">
              <strong>References: 1.</strong> IMCIVREE [prescribing
              information]. Boston, MA. Rhythm Pharmaceuticals, Inc.
              <strong>2.</strong> Data on file. Rhythm Pharmaceuticals, Inc.
              Boston, MA. <strong>3.</strong> Cl&eacute;ment K et al.
              <em>Lancet Diabetes Endocrinol</em>. [Supplementary appendix]
              2020;8(12):960-970.
            </div>
          </div>
        </div>
      </div>
    )
  }

  const HyperphagiaPatPpl = () => {
    return (
      <div>
        <div id="hyperphagia-pat-ppl-trigger-container">
          <div style={{ display: "table" }} className="modal-content-block">
            <div style={{ display: "table-cell", verticalAlign: "middle" }}>
              <button onClick={() => modalClickHandler(id)}>
                <img
                  src={ImageIconGreenPlus}
                  alt="Green plus icon"
                  className="modal-trigger-button-plus"
                />
              </button>
            </div>
            <div style={{ display: "table-cell", verticalAlign: "middle" }}>
              &nbsp;&nbsp;
            </div>
            <div style={{ display: "table-cell", verticalAlign: "middle" }}>
              <span className="h2 color-teal">What is hyperphagia?</span>
            </div>
          </div>
        </div>
        <div
          id="hyperphagia-pat-ppl-modal-container"
          className="modal-container"
        >
          <div className="modal-content-container mb-14 md_mb-10">
            <button
              className="modal-icon-close-x"
              onClick={() => modalClickHandler(id)}
            >
              <img
                src={ImageIconCloseX}
                alt="Close X icon"
                style={{ width: "80%" }}
              />
            </button>
            <div className="title-box">
              <h1 className="h1">What is hyperphagia?</h1>
              <h2 className="h2 mb-0 lg_mb-0 md_mb-0">
                Appetite control can be a struggle for many people living with
                obesity, but with POMC, PCSK1, and LEPR deficiency, insatiable
                hunger (hyperphagia) is different
              </h2>
            </div>
            <div className="large mt-2 md_mt-3">
              <PplHyperphagiaChart />
            </div>
            <div className="mb-2 md_mb-3 mt-2 md_mt-3">
              <Callout fullWidth className="lime-teal left text-left">
                <p className="mb-0 md_mb-0">
                  In people living with POMC, PCSK1, or LEPR deficiency,
                  insatiable hunger and obesity are caused by a genetic change
                  that makes it difficult for the brain's hunger signaling
                  pathway to help you feel full. That's why medicine like
                  IMCIVREE can help
                </p>
              </Callout>
            </div>
            {/* <div className="column small-12">
              <div className="d-flex align-middle bbs-ribbon-box pl-1 pr-1">
                <img src={OverviewRibbonIcon} className="mr-1 md_mr-2" alt="" />
                <strong>                
                    In fact, the American Academy of Pediatrics and the Obesity Medicine
                    Association recommend taking FDA-approved medicine (like IMCIVREE)
                    to help when it is an option
                </strong>
              </div>
            </div> */}
            <div className="h1 mt-2 md_mt-2" style={{ width: "100%" }}>
              Hear from families who have experienced hyperphagia due to POMC,
              PCSK1, or LEPR deficiency
            </div>

            <div className="row mt-2 md_mt-2 lg_mt-2 md_ml-2 lg_ml-2">
              <div className="column medium-8">
                <Quote
                  copy={
                    <>
                      It felt as if I could only think about food, and when I
                      wanted to eat something, I searched until I found it.
                    </>
                  }
                  signoff={<>— Person who is living with POMC deficiency</>}
                ></Quote>
              </div>
            </div>

            <div className="row mt-2 md_mt-2 lg_mt-2 md_mr-2 lg_mr-2">
              <div className="column medium-4 desktop-only"></div>
              <div className="column medium-8">
                <Quote
                  copy={
                    <>
                      What I recall is this addiction-like desire for food and
                      this constant preoccupation with it. It’s more than the
                      physical feeling of hunger. It also plays on a mental
                      level.
                    </>
                  }
                  signoff={<>— Person who is living with POMC deficiency</>}
                ></Quote>
              </div>
            </div>

            <div className="row mt-2 md_mt-2 lg_mt-2 md_ml-2 lg_ml-2">
              <div className="column medium-8">
                <Quote
                  copy={
                    <>
                      When I would start eating something, I’d eat the whole
                      thing. No matter what sort of package it was I’d always
                      eat the whole thing. I also ate it at an enormous tempo.
                      It was really terrible.
                    </>
                  }
                  signoff={<>— Person who is living with LEPR deficiency</>}
                ></Quote>
              </div>
            </div>

            <div className="references lg_mt-3 md_mt-3 mt-1">
              MC4R=melanocortin-4 receptor.
            </div>
          </div>
        </div>
      </div>
    )
  }

  const SymptomProgressionOverTime = () => {
    return (
      <div>
        <div id="symptom-progression-over-time-trigger-container">
          <div style={{ display: "table" }} className="modal-content-block">
            <div style={{ display: "table-cell", verticalAlign: "middle" }}>
              <button onClick={() => modalClickHandler(id)}>
                <img
                  src={ImageIconGreenPlus}
                  alt="Green plus icon"
                  className="modal-trigger-button-plus"
                />
              </button>
            </div>
            <div style={{ display: "table-cell", verticalAlign: "middle" }}>
              &nbsp;&nbsp;
            </div>
            <div style={{ display: "table-cell", verticalAlign: "middle" }}>
              <span className="modal-button-text color-teal">
                Symptom progression over time
              </span>
            </div>
          </div>
        </div>
        <div
          id="symptom-progression-over-time-modal-container"
          className="modal-container color-black-bg"
        >
          <div className="modal-content-container">
            <button
              className="modal-icon-close-x"
              onClick={() => modalClickHandler(id)}
            >
              <img
                src={ImageIconCloseX}
                alt="Close X icon"
                style={{ width: "80%" }}
              />
            </button>
            <h1 className="h1">
              BBS has a highly variable phenotype with common features that
              evolve over time<sup>1-3</sup>
            </h1>
            {/* <div><img src={ImageSymptomProgression} /></div> */}
            <SymptomProgressionChart />
            <p>&nbsp;</p>
            <div className="references">
              <strong>References: 1.</strong> Forsythe E, Beales PL.
              <em>Eur J Hum Genet</em>. 2013;21(1):8-13. <strong>2.</strong>
              Castro-Sanchez S et al. <em>J Med Genet</em>. 2015;52(8):503-513.
              <strong>3.</strong> Katsanis N et al. <em>Hum Mol Genet</em>.
              2001;10(20):2293-2299. <strong>4.</strong> Forsyth R, Gunay-Aygun
              M. GeneReviews<sup>&reg;</sup>. July 14, 2003. Updated July 23,
              2020. Accessed May 3, 2022.
              https://www.ncbi.nlm.nih.gov/books/NBK1363. <strong>5.</strong>
              Khan OA et al. <em>Cureus</em>. 2019;11(2):e4114.
              <strong>6.</strong> Agrawal H et al. <em>Pediatr Rev</em>.
              2018;39(5):e21-e23. <strong>7.</strong> Vlahovic AM, Haxhija EQ.
              <em>
                Pediatric and Adolescent Plastic Surgery for the Clinician
              </em>
              . Springer; 2017:89-105. <strong>8.</strong> Putoux A et al.
              <em>Pediatr Nephrol</em>. 2012;27(1):7-15. <strong>9.</strong>
              Sherafat-Kazemzadeh R et al. <em>Pediatr Obes</em>.
              2013;8(5):e64-e67. <strong>10.</strong> Pomeroy J et al.
              <em>Pediatr Obes</em>. 2021;16(2):e12703. <strong>11.</strong>
              Beales PL et al. <em>J Med Genet</em>. 1999;36(6):437-446.
              <strong>12.</strong> Weihbrecht K et al. <em>Med Res Arch</em>.
              2017. doi:10.18103/mra.v5i9.1526.
            </div>
          </div>
        </div>
      </div>
    )
  }

  const TheRoleOfGeneticTesting = () => {
    return (
      <div>
        <div id="the-role-of-genetic-testing-trigger-container">
          <div style={{ display: "table" }} className="modal-content-block">
            <div style={{ display: "table-cell", verticalAlign: "middle" }}>
              <button onClick={() => modalClickHandler(id)}>
                <img
                  src={ImageIconGreenPlus}
                  alt="Green plus icon"
                  className="modal-trigger-button-plus"
                />
              </button>
            </div>
            <div style={{ display: "table-cell", verticalAlign: "middle" }}>
              &nbsp;&nbsp;
            </div>
            <div style={{ display: "table-cell", verticalAlign: "middle" }}>
              <span className="modal-button-text color-teal">
                The role of genetic testing
              </span>
            </div>
          </div>
        </div>
        <div
          id="the-role-of-genetic-testing-modal-container"
          className="modal-container color-black-bg"
        >
          <div className="modal-content-container">
            <button
              className="modal-icon-close-x"
              onClick={() => modalClickHandler(id)}
            >
              <img
                src={ImageIconCloseX}
                alt="Close X icon"
                style={{ width: "80%" }}
              />
            </button>
            <h1 className="h1">
              Genetic testing can help confirm a clinical diagnosis
              <sup>1-3</sup>
            </h1>
            <ul className="neon-green-bullets">
              <li>BBS is diagnosed clinically</li>
              <li>
                Genetic testing can help provide additional diagnostic
                information and can confirm a clinical diagnosis with nearly 80%
                of patients
              </li>
              <li>
                Consider the complete patient presentation and use your clinical
                judgment to diagnose BBS
              </li>
            </ul>
            <div>&nbsp;</div>
            <div className="references">
              <strong>References: 1.</strong> Forsythe E, Beales PL.
              <em>Eur J Hum Genet</em>. 2013;21(1):8-13. <strong>2.</strong>
              Beales PL et al. <em>J Med Genet</em>. 1999;36(6):437-446.
              <strong>3.</strong> Forsythe E et al. <em>Front Pediatr</em>.
              2018. doi:10.3389/fped.2018.00023.
            </div>
          </div>
        </div>
      </div>
    )
  }

  const WarningsAndPrecautions = () => {
    return (
      <div>
        <div id="warnings-and-precautions-trigger-container">
          <div style={{ display: "table" }} className="modal-content-block">
            <div style={{ display: "table-cell", verticalAlign: "middle" }}>
              <button onClick={() => modalClickHandler(id)}>
                <img
                  src={ImageIconGreenPlus}
                  alt="Green plus icon"
                  className="modal-trigger-button-plus"
                />
              </button>
            </div>
            <div style={{ display: "table-cell", verticalAlign: "middle" }}>
              &nbsp;&nbsp;
            </div>
            <div style={{ display: "table-cell", verticalAlign: "middle" }}>
              <span className="modal-button-text color-teal">
                <strong>Warnings and precautions</strong>
              </span>
            </div>
          </div>
        </div>
        <div
          id="warnings-and-precautions-modal-container"
          className="modal-container color-black-bg"
        >
          <div className="modal-content-container">
            <button
              className="modal-icon-close-x"
              onClick={() => modalClickHandler(id)}
            >
              <img
                src={ImageIconCloseX}
                alt="Close X icon"
                style={{ width: "80%" }}
              />
            </button>
            <h1 className="h1">
              Warnings and precautions<sup>1</sup>
            </h1>
            <ul className="neon-green-bullets">
              <li className="mb-1 md_mb-1">
                <strong>Disturbance in Sexual Arousal:</strong>&nbsp;Spontaneous
                penile erections in males and sexual adverse reactions in
                females have occurred. Inform patients that these events may
                occur and instruct patients who have an erection lasting longer
                than 4 hours to seek emergency medical attention
              </li>
              <li className="mb-1 md_mb-1">
                <strong>Depression and Suicidal Ideation:</strong>
                &nbsp;Depression and suicidal ideation have occurred. Monitor
                patients for new onset or worsening depression or suicidal
                thoughts or behaviors. Consider discontinuing IMCIVREE if
                patients experience suicidal thoughts or behaviors, or
                clinically significant or persistent depression symptoms occur
              </li>

              <li className="mb-1 md_mb-1">
                <strong>Hypersensitivity Reactions:</strong>
                &nbsp;Serious hypersensitivity reactions (e.g., anaphylaxis)
                have been reported. If suspected, advise patients to promptly
                seek medical attention and discontinue IMCIVREE.
              </li>

              <li className="mb-1 md_mb-1">
                <strong>
                  Skin Pigmentation and Darkening of Pre-existing Nevi:
                </strong>{" "}
                Generalized increased skin pigmentation and darkening of
                pre-existing nevi have occurred. Perform a full body skin
                examination prior to initiation and periodically during
                treatment to monitor pre-existing and new pigmentary lesions
              </li>
              <li className="mb-1 md_mb-1">
                <strong>
                  Risk of Serious Adverse Reactions Due to Benzyl Alcohol
                  Preservative in Neonates and Low Birth Weight Infants:
                </strong>{" "}
                IMCIVREE is not approved for use in neonates or infants. Serious
                and fatal adverse reactions including “gasping syndrome” can
                occur in neonates and low birth weight infants treated with
                benzyl alcohol-preserved drugs
              </li>
            </ul>
            <div className="references">
              <strong>Reference: 1.</strong> IMCIVREE [prescribing information].
              Boston, MA. Rhythm Pharmaceuticals, Inc.
            </div>
          </div>
        </div>
      </div>
    )
  }

  const HyperpigmentationHcpBbs = () => {
    return (
      <div>
        <div id="hyperpigmentation-hcp-bbs-trigger-container">
          <div style={{ display: "table" }} className="modal-content-block">
            <div style={{ display: "table-cell", verticalAlign: "middle" }}>
              <button onClick={() => modalClickHandler(id)}>
                <img
                  src={ImageIconGreenPlus}
                  alt="Green plus icon"
                  className="modal-trigger-button-plus"
                />
              </button>
            </div>
            <div style={{ display: "table-cell", verticalAlign: "middle" }}>
              &nbsp;&nbsp;
            </div>
            <div style={{ display: "table-cell", verticalAlign: "middle" }}>
              <span className="modal-button-text color-teal">
                <strong>Hyperpigmentation</strong>
              </span>
            </div>
          </div>
        </div>
        <div
          id="hyperpigmentation-hcp-bbs-modal-container"
          className="modal-container color-black-bg"
        >
          <div className="modal-content-container">
            <button
              className="modal-icon-close-x"
              onClick={() => modalClickHandler(id)}
            >
              <img
                src={ImageIconCloseX}
                alt="Close X icon"
                style={{ width: "80%" }}
              />
            </button>
            <h1 className="h1">
              Hyperpigmentation was common and rarely led to discontinuation
              <sup>1,2</sup>
            </h1>
            <p className="text-center">
              <strong>
                Example of hyperpigmentation<sup>3</sup>
              </strong>
            </p>
            <div className="md_mb-2 mb-2">
              <img
                src={ImageHyperpigmentaion}
                alt="Example of hyperpigmentation before, during, and after stopping IMCIVREE treatment"
              />
            </div>
            <ul className="neon-green-bullets md_mb-2 mb-2">
              <li>
                <strong>
                  Changes in skin pigmentation or hair color typically presented
                  2-3 weeks after initiation of IMCIVREE, with most events
                  occurring within the first month of treatment<sup>2</sup>
                </strong>
                <ul className="neon-green-bullets">
                  <li className="is-dash">
                    Skin darkening plateaued within the initial months of
                    treatment<sup>2</sup>
                  </li>
                  <li className="is-dash">
                    Hyperpigmentation is variable<sup>3</sup>
                  </li>
                  <li className="is-dash">
                    This effect is reversible upon discontinuation of treatment
                    <sup>1</sup>
                  </li>
                  <li className="is-dash">
                    Perform a full body skin examination prior to initiation and
                    periodically during treatment with IMCIVREE to monitor
                    pre-existing and new skin pigmentary lesions<sup>1</sup>
                  </li>
                </ul>
              </li>
              <li>
                <strong>
                  There were no reports of melanoma related to the observed
                  hyperpigmentation in clinical trials of IMCIVREE<sup>2</sup>
                </strong>
                <ul className="neon-green-bullets">
                  <li className="is-dash">
                    Hyperpigmentation is not unexpected given that IMCIVREE
                    activates the melanocortin-1 receptor, which results in
                    melanin production<sup>1</sup>
                  </li>
                </ul>
              </li>
            </ul>
            <p>&nbsp;</p>
            <p className="references">
              <strong>References: 1.</strong> IMCIVREE [prescribing
              information]. Boston, MA. Rhythm Pharmaceuticals, Inc.
              <strong>2.</strong> Data on file. Rhythm Pharmaceuticals, Inc.
              Boston, MA. <strong>3.</strong> Cl&eacute;ment K et al.
              <em>Lancet Diabetes Endocrinol.</em> [Supplementary appendix]
              2020;8(12):960-970.
            </p>
          </div>
        </div>
      </div>
    )
  }

  const RecommendedMonitoring = () => {
    return (
      <div>
        <div id="recommended-monitoring-trigger-container">
          <div style={{ display: "table" }} className="modal-content-block">
            <div style={{ display: "table-cell", verticalAlign: "middle" }}>
              <button onClick={() => modalClickHandler(id)}>
                <img
                  src={ImageIconGreenPlus}
                  alt="Green plus icon"
                  className="modal-trigger-button-plus"
                />
              </button>
            </div>
            <div style={{ display: "table-cell", verticalAlign: "middle" }}>
              &nbsp;&nbsp;
            </div>
            <div style={{ display: "table-cell", verticalAlign: "middle" }}>
              <span className="modal-button-text color-teal">
                <strong>Recommended monitoring</strong>
              </span>
            </div>
          </div>
        </div>
        <div
          id="recommended-monitoring-modal-container"
          className="modal-container color-black-bg"
        >
          <div className="modal-content-container">
            <button
              className="modal-icon-close-x"
              onClick={() => modalClickHandler(id)}
            >
              <img
                src={ImageIconCloseX}
                alt="Close X icon"
                style={{ width: "80%" }}
              />
            </button>
            <h1 className="h1">
              Recommended monitoring<sup>1</sup>
            </h1>
            <ul className="neon-green-bullets">
              <li className="mb-0">
                Periodically assess response to IMCIVREE therapy. In pediatric
                patients, evaluate the impact of weight loss on growth and
                maturation
              </li>
              <li className="mb-1">
                Evaluate weight loss after 1 year of treatment. If a person has
                not lost at least 5% of baseline body weight or 5% of baseline
                BMI for patients aged less than 18 years, discontinue IMCIVREE
                as it is unlikely that the patient will achieve and sustain
                clinically meaningful weight loss with continued treatment
              </li>
            </ul>
            <div className="references md_mt-1 mt-1">
              <strong>Reference: 1.</strong> IMCIVREE [prescribing information].
              Boston, MA. Rhythm Pharmaceuticals, Inc.
            </div>
          </div>
        </div>
      </div>
    )
  }

  const RecommendedMonitoringHcpBbs = () => {
    return (
      <div>
        <div id="recommended-monitoring-hcp-bbs-trigger-container">
          <div style={{ display: "table" }} className="modal-content-block">
            <div style={{ display: "table-cell", verticalAlign: "middle" }}>
              <button onClick={() => modalClickHandler(id)}>
                <img
                  src={ImageIconGreenPlus}
                  alt="Green plus icon"
                  className="modal-trigger-button-plus"
                />
              </button>
            </div>
            <div style={{ display: "table-cell", verticalAlign: "middle" }}>
              &nbsp;&nbsp;
            </div>
            <div style={{ display: "table-cell", verticalAlign: "middle" }}>
              <span className="modal-button-text color-teal">
                <strong>Recommended monitoring</strong>
              </span>
            </div>
          </div>
        </div>
        <div
          id="recommended-monitoring-hcp-bbs-modal-container"
          className="modal-container color-black-bg"
        >
          <div className="modal-content-container">
            <button
              className="modal-icon-close-x"
              onClick={() => modalClickHandler(id)}
            >
              <img
                src={ImageIconCloseX}
                alt="Close X icon"
                style={{ width: "80%" }}
              />
            </button>
            <h1 className="h1">
              Recommended monitoring<sup>1</sup>
            </h1>
            <ul className="neon-green-bullets">
              <li className="mb-0">
                Periodically assess response to IMCIVREE therapy. In pediatric
                patients, evaluate the impact of weight loss on growth and
                maturation
              </li>
              <li className="mb-1">
                Evaluate weight loss after 1 year of treatment. If a patient has
                not lost at least 5% of baseline body weight or 5% of baseline
                BMI for patients aged less than 18 years, discontinue IMCIVREE
                as it is unlikely that the patient will achieve and sustain
                clinically meaningful weight loss with continued treatment
              </li>
            </ul>
            <div className="references md_mt-1 mt-1">
              <strong>Reference: 1.</strong> IMCIVREE [prescribing information].
              Boston, MA. Rhythm Pharmaceuticals, Inc.
            </div>
          </div>
        </div>
      </div>
    )
  }

  const RecommendedMonitoringHcpPpl = () => {
    return (
      <div>
        <div id="recommended-monitoring-hcp-ppl-trigger-container">
          <div style={{ display: "table" }} className="modal-content-block">
            <div style={{ display: "table-cell", verticalAlign: "middle" }}>
              <button onClick={() => modalClickHandler(id)}>
                <img
                  src={ImageIconGreenPlus}
                  alt="Green plus icon"
                  className="modal-trigger-button-plus"
                />
              </button>
            </div>
            <div style={{ display: "table-cell", verticalAlign: "middle" }}>
              &nbsp;&nbsp;
            </div>
            <div style={{ display: "table-cell", verticalAlign: "middle" }}>
              <span className="modal-button-text color-teal">
                <strong style={{ fontSize: `1.375rem`, lineHeight: `1.8rem` }}>
                  Recommended monitoring
                </strong>
              </span>
            </div>
          </div>
        </div>
        <div
          id="recommended-monitoring-hcp-ppl-modal-container"
          className="modal-container color-black-bg"
        >
          <div className="modal-content-container">
            <button
              className="modal-icon-close-x"
              onClick={() => modalClickHandler(id)}
            >
              <img
                src={ImageIconCloseX}
                alt="Close X icon"
                style={{ width: "80%" }}
              />
            </button>
            <h1 className="h1">
              Recommended monitoring<sup>1</sup>
            </h1>
            <ul className="neon-green-bullets">
              <li className="mb-0">
                Periodically assess response to IMCIVREE therapy. In pediatric
                patients, evaluate the impact of weight loss on growth and
                maturation
              </li>
              <li className="mb-1">
                Evaluate weight loss after 12 to 16 weeks of treatment. If a
                patient has not lost at least 5% of baseline body weight or 5%
                of baseline BMI for patients with continued growth potential,
                discontinue IMCIVREE as it is unlikely that the patient will
                achieve and sustain clinically meaningful weight loss with
                continued treatment
              </li>
            </ul>
            <div className="references md_mt-1 mt-1">
              <strong>Reference: 1.</strong> IMCIVREE [prescribing information].
              Boston, MA. Rhythm Pharmaceuticals, Inc.
            </div>
          </div>
        </div>
      </div>
    )
  }

  const DosingForSevereRenalImpairment = () => {
    return (
      <>
        <div>
          <div id="dosing-for-severe-renal-impairment-trigger-container">
            <div style={{ display: "table" }} className="modal-content-block">
              <div style={{ display: "table-cell", verticalAlign: "middle" }}>
                <button onClick={() => modalClickHandler(id)}>
                  <img
                    src={ImageIconGreenPlus}
                    alt="Green plus icon"
                    className="modal-trigger-button-plus"
                  />
                </button>
              </div>
              <div style={{ display: "table-cell", verticalAlign: "middle" }}>
                &nbsp;&nbsp;
              </div>
              <div style={{ display: "table-cell", verticalAlign: "middle" }}>
                <span className="modal-button-text color-teal">
                  <strong>Dosing for severe renal impairment</strong>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          id="dosing-for-severe-renal-impairment-modal-container"
          className="modal-container color-black-bg"
        >
          <div className="modal-content-container">
            <button
              className="modal-icon-close-x"
              onClick={() => modalClickHandler(id)}
            >
              <img
                src={ImageIconCloseX}
                alt="Close X icon"
                style={{ width: "80%" }}
              />
            </button>
            <div style={{ paddingRight: "4%" }}>
              <h1 className="h1">
                Recommended dosage in people with severe renal impairment
                <sup>1</sup>
              </h1>
              <ul className="neon-green-bullets">
                <li className="mb-0">
                  For adult and pediatric patients 12 years of age and older
                  with severe renal impairment (eGFR of 15 to 29 mL/min/1.73 m
                  <sup>2</sup>), the recommended starting dosage is 0.5 mg (0.05
                  mL) injected subcutaneously once daily for 2 weeks, and the
                  recommended target dosage is 1.5 mg (0.15 mL) injected
                  subcutaneously once daily. Monitor patients for GI adverse
                  reactions
                </li>
                <li className="mb-1">
                  If the recommended starting dosage is:
                  <ul className="mt-0">
                    <li className="is-dash mb-0">
                      Tolerated for 2 weeks, increase the dosage to 1 mg (0.1
                      mL) once daily. If the 1-mg daily dosage is tolerated for
                      at least 1 week, increase the dosage to 1.5 mg (0.15 mL)
                      once daily
                    </li>
                    <li className="is-dash mb-0">
                      Not tolerated, discontinue IMCIVREE
                    </li>
                  </ul>
                </li>
              </ul>
              <p className="md_mb-1"></p>
              <p className="h1">
                Recommended dosage in people with mild or moderate renal
                impairment<sup>1</sup>
              </p>
              <ul className="md_mb-1 mb-1 neon-green-bullets">
                <li>
                  The recommended dosage in people with mild renal impairment
                  (eGFR of 60 to 89 mL/min/1.73 m<sup>2</sup>) or moderate renal
                  impairment (eGFR of 30 to 59 mL/min/1.73 m<sup>2</sup>) is the
                  same as in those with normal kidney function
                </li>
              </ul>
            </div>
            <div className="row">
              <div className="columns large-8 medium-10 small-12">
                <Callout fullWidth className="light-teal right text-right">
                  <p>
                    IMCIVREE is not recommended for use in patients with
                    end-stage renal disease (eGFR less than 15 mL/min/1.73 m
                    <sup>2</sup>) or in pediatric patients 6 to &lt;12 years of
                    age with severe renal impairment<sup>1</sup>
                  </p>
                </Callout>
              </div>
            </div>
            <div
              className="references md_mt-2 mt-2"
              style={{ marginBottom: ".5rem" }}
            >
              eGFR=estimated glomerular filtration rate; GI=gastrointestinal.
            </div>
            <div className="references">
              <strong>Reference: 1.</strong> IMCIVREE [prescribing information].
              Boston, MA. Rhythm Pharmaceuticals, Inc.
            </div>
          </div>
        </div>
      </>
    )
  }

  const DosingForSevereRenalImpairmentHcpBbs = () => {
    return (
      <>
        <div>
          <div id="dosing-for-severe-renal-impairment-hcp-bbs-trigger-container">
            <div style={{ display: "table" }} className="modal-content-block">
              <div style={{ display: "table-cell", verticalAlign: "middle" }}>
                <button onClick={() => modalClickHandler(id)}>
                  <img
                    src={ImageIconGreenPlus}
                    alt="Green plus icon"
                    className="modal-trigger-button-plus"
                  />
                </button>
              </div>
              <div style={{ display: "table-cell", verticalAlign: "middle" }}>
                &nbsp;&nbsp;
              </div>
              <div style={{ display: "table-cell", verticalAlign: "middle" }}>
                <span className="modal-button-text color-teal">
                  <strong>Dosing for severe renal impairment</strong>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          id="dosing-for-severe-renal-impairment-hcp-bbs-modal-container"
          className="modal-container color-black-bg"
        >
          <div className="modal-content-container">
            <button
              className="modal-icon-close-x"
              onClick={() => modalClickHandler(id)}
            >
              <img
                src={ImageIconCloseX}
                alt="Close X icon"
                style={{ width: "80%" }}
              />
            </button>
            <div style={{ paddingRight: "4%" }}>
              <h1 className="h1">
                Recommended dosage in patients with severe renal impairment
                <sup>1</sup>
              </h1>
              <ul className="neon-green-bullets">
                <li className="mb-0">
                  For adult and pediatric patients 12 years of age and older
                  with severe renal impairment (eGFR of 15 to 29 mL/min/1.73 m
                  <sup>2</sup>), the recommended starting dosage is 0.5 mg (0.05
                  mL) injected subcutaneously once daily for 2 weeks, and the
                  recommended target dosage is 1.5 mg (0.15 mL) injected
                  subcutaneously once daily. Monitor patients for GI adverse
                  reactions
                </li>
                <li className="mb-0">
                  If the recommended starting dosage is:
                  <ul className="mt-0">
                    <li className="is-dash mb-0">
                      Tolerated for 2 weeks, increase the dosage to 1 mg (0.1
                      mL) once daily. If the 1-mg daily dosage is tolerated for
                      at least 1 week, increase the dosage to 1.5 mg (0.15 mL)
                      once daily
                    </li>
                    <li className="is-dash mb-0">
                      Not tolerated, discontinue IMCIVREE
                    </li>
                  </ul>
                </li>
              </ul>
              <p className="md_mb-1"></p>
              <p className="h1">
                There is no recommended dosage change in patients with mild or
                moderate renal impairment<sup>1</sup>
              </p>
              <ul className="md_mb-1 mb-1 neon-green-bullets">
                <li>
                  The recommended dosage in patients with mild renal impairment
                  (eGFR of 60 to 89 mL/min/1.73 m<sup>2</sup>) or moderate renal
                  impairment (eGFR of 30 to 59 mL/min/1.73 m<sup>2</sup>) is the
                  same as in those with normal kidney function
                </li>
              </ul>
            </div>
            <Callout right className="light-teal right text-right">
              <p>
                IMCIVREE is not recommended for use in patients with end-stage
                renal disease (eGFR less than 15 mL/min/1.73 m<sup>2</sup>) or
                in pediatric patients 6 to &lt;12 years of age with severe renal
                impairment<sup>1</sup>
              </p>
            </Callout>
            <div
              className="references md_mt-2 mt-2"
              style={{ marginBottom: ".5rem" }}
            >
              eGFR=estimated glomerular filtration rate; GI=gastrointestinal.
            </div>
            <div className="references">
              <strong>Reference: 1.</strong> IMCIVREE [prescribing information].
              Boston, MA. Rhythm Pharmaceuticals, Inc.
            </div>
          </div>
        </div>
      </>
    )
  }

  const DosingForSevereRenalImpairmentHcpPpl = () => {
    return (
      <>
        <div>
          <div id="dosing-for-severe-renal-impairment-hcp-ppl-trigger-container">
            <div style={{ display: "table" }} className="modal-content-block">
              <div style={{ display: "table-cell", verticalAlign: "middle" }}>
                <button onClick={() => modalClickHandler(id)}>
                  <img
                    src={ImageIconGreenPlus}
                    alt="Green plus icon"
                    className="modal-trigger-button-plus"
                  />
                </button>
              </div>
              <div style={{ display: "table-cell", verticalAlign: "middle" }}>
                &nbsp;&nbsp;
              </div>
              <div style={{ display: "table-cell", verticalAlign: "middle" }}>
                <span className="modal-button-text color-teal">
                  <strong
                    style={{ fontSize: `1.375rem`, lineHeight: `1.8rem` }}
                  >
                    Dosing for severe renal impairment
                  </strong>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          id="dosing-for-severe-renal-impairment-hcp-ppl-modal-container"
          className="modal-container color-black-bg"
        >
          <div className="modal-content-container">
            <button
              className="modal-icon-close-x"
              onClick={() => modalClickHandler(id)}
            >
              <img
                src={ImageIconCloseX}
                alt="Close X icon"
                style={{ width: "80%" }}
              />
            </button>
            <div style={{ paddingRight: "4%" }}>
              <h1 className="h1">
                Recommended dosage in patients with severe renal impairment
                <sup>1</sup>
              </h1>
              <ul className="neon-green-bullets">
                <li className="mb-0">
                  For adult and pediatric patients 12 years of age and older
                  with severe renal impairment (eGFR of 15 to 29 mL/min/1.73 m
                  <sup>2</sup>), the recommended starting dosage is 0.5 mg (0.05
                  mL) injected subcutaneously once daily for 2 weeks, and the
                  recommended target dosage is 1.5 mg (0.15 mL) injected
                  subcutaneously once daily. Monitor patients for GI adverse
                  reactions
                </li>
                <li className="mb-0">
                  If the recommended starting dosage is:
                  <ul>
                    <li className="is-dash mb-0">
                      Tolerated for 2 weeks, increase the dosage to 1 mg (0.1
                      mL) once daily. If the 1-mg daily dosage is tolerated for
                      at least 1 week, increase the dosage to 1.5 mg (0.15 mL)
                      once daily
                    </li>
                    <li className="is-dash mb-0">
                      Not tolerated, discontinue IMCIVREE
                    </li>
                  </ul>
                </li>
              </ul>
              <p className="md_mb-1"></p>
              <p className="h1">
                Recommended dosage in patients with mild or moderate renal
                impairment<sup>1</sup>
              </p>
              <ul className="md_mb-1 mb-1 neon-green-bullets">
                <li>
                  The recommended dosage in people with mild renal impairment
                  (eGFR of 60 to 89 mL/min/1.73 m<sup>2</sup>) or moderate renal
                  impairment (eGFR of 30 to 59 mL/min/1.73 m<sup>2</sup>) is the
                  same as in those with normal kidney function
                </li>
              </ul>
            </div>
            <Callout right className="light-teal right text-right">
              <p>
                IMCIVREE is not recommended for use in patients with end-stage
                renal disease (eGFR less than 15 mL/min/1.73 m<sup>2</sup>) or
                in pediatric patients 6 to &lt;12 years of age with severe renal
                impairment<sup>1</sup>
              </p>
            </Callout>
            <div
              className="references md_mt-2 mt-2"
              style={{ marginBottom: ".5rem" }}
            >
              eGFR=estimated glomerular filtration rate; GI=gastrointestinal.
            </div>
            <div className="references">
              <strong>Reference: 1.</strong> IMCIVREE [prescribing information].
              Boston, MA. Rhythm Pharmaceuticals, Inc.
            </div>
          </div>
        </div>
      </>
    )
  }

  const HypotheticalGrowthChart = () => {
    return (
      <div>
        <div id="hypothetical-growth-chart-trigger-container">
          <div style={{ display: "table" }} className="modal-content-block">
            <div style={{ display: "table-cell", verticalAlign: "middle" }}>
              <button onClick={() => modalClickHandler(id)}>
                <img
                  src={ImageIconGreenPlus}
                  alt="Green plus icon"
                  className="modal-trigger-button-plus"
                />
              </button>
            </div>
            <div style={{ display: "table-cell", verticalAlign: "middle" }}>
              &nbsp;&nbsp;
            </div>
            <div style={{ display: "table-cell", verticalAlign: "middle" }}>
              <span className="modal-button-text color-teal">
                <strong>
                  Hypothetical growth chart for an adolescent with BBS
                </strong>
              </span>
            </div>
          </div>
        </div>
        <div
          id="hypothetical-growth-chart-modal-container"
          className="modal-container color-black-bg"
        >
          <div className="modal-content-container">
            <button
              className="modal-icon-close-x"
              onClick={() => modalClickHandler(id)}
            >
              <img
                src={ImageIconCloseX}
                alt="Close X icon"
                style={{ width: "80%" }}
              />
            </button>
            <h1 className="h1">
              Hypothetical growth chart for an adolescent with BBS
            </h1>
            <h2 className="h2">
              This chart is a visual representation of the projected BMI
              trajectory of a hypothetical female adolescent with BBS* over time
              <sup>1-3</sup>
            </h2>
            <p className="text-center">
              <img
                src={ImageHypoGrowthAdolescent}
                alt="Hypothetical growth chart for adolescent with BBS"
                style={{ width: "75%" }}
              />
            </p>
            <p>&nbsp;</p>
            <p className="footnote">
              Figure modeled after Gulati AK, Kaplan DW, Daniels SR. Clinical
              tracking of severely obese children: a new growth chart.
              <em>Pediatrics</em>. 2012 Dec;130(6):1136-1140.
            </p>
            {/* kary */}
            <HangingRef
              symbol="*"
              isAsterisk
              copy="Not an actual patient. Growth chart is based on females 2 to 20 years of age and is for illustrative purposes only."
            />
            <div className="references md_mt-1 mt-1">
              <strong>References: 1.</strong> Kelly AS et al.
              <em>Circulation</em>. 2013;128(15):1689-1712. <strong>2.</strong>
              Gulati AK et al. <em>Pediatrics</em>. 2012;130(6):1136-1140.
              <strong>3.</strong> Racette SB et al. <em>BMC Pediatr</em>.
              2017;17(1):130.
            </div>
          </div>
        </div>
      </div>
    )
  }

  const OtherParameters = () => {
    return (
      <div>
        <div id="other-parameters-trigger-container">
          <div style={{ display: "table" }} className="modal-content-block">
            <div style={{ display: "table-cell", verticalAlign: "middle" }}>
              <button onClick={() => modalClickHandler(id)}>
                <img
                  src={ImageIconGreenPlus}
                  alt="Green plus icon"
                  className="modal-trigger-button-plus"
                />
              </button>
            </div>
            <div style={{ display: "table-cell", verticalAlign: "middle" }}>
              &nbsp;&nbsp;
            </div>
            <div style={{ display: "table-cell", verticalAlign: "middle" }}>
              <span className="modal-button-text color-teal">
                <strong>Other parameters</strong>
              </span>
            </div>
          </div>
        </div>
        <div
          id="other-parameters-modal-container"
          className="modal-container color-black-bg"
        >
          <div className="modal-content-container">
            <button
              className="modal-icon-close-x"
              onClick={() => modalClickHandler(id)}
            >
              <img
                src={ImageIconCloseX}
                alt="Close X icon"
                style={{ width: "80%" }}
              />
            </button>
            <h1 className="h1">
              Other parameters<sup>1</sup>
            </h1>
            <ul className="neon-green-bullets">
              <li>
                Supportive of the effect of IMCIVREE on weight loss, there were
                general numeric improvements in blood pressure, lipids, and
                waist circumference. However, because of the limited number of
                patients studied and the lack of a control group, the treatment
                effects on these parameters could not be accurately quantified
              </li>
            </ul>
            <div>&nbsp;</div>
            <div className="references">
              <strong>Reference: 1.</strong> IMCIVREE [prescribing information].
              Boston, MA. Rhythm Rhythm Pharmaceuticals, Inc. Boston, MA.
            </div>
          </div>
        </div>
      </div>
    )
  }

  const Bmi14WeekReduction1 = () => {
    return (
      <div>
        <div id="bmi-14-week-reduction-1-trigger-container">
          <div style={{ display: "table" }} className="modal-content-block">
            <div style={{ display: "table-cell", verticalAlign: "middle" }}>
              <button onClick={() => modalClickHandler(id)}>
                <img
                  src={ImageIconGreenPlus}
                  alt="Green plus icon"
                  className="modal-trigger-button-plus"
                />
              </button>
            </div>
            <div style={{ display: "table-cell", verticalAlign: "middle" }}>
              &nbsp;&nbsp;
            </div>
            <div style={{ display: "table-cell", verticalAlign: "middle" }}>
              <span className="modal-button-text color-teal">
                <strong style={{ fontSize: `1rem`, lineHeight: `1.6` }}>
                  14-week weight reduction results vs placebo
                </strong>
              </span>
            </div>
          </div>
        </div>
        <div
          id="bmi-14-week-reduction-1-modal-container"
          className="modal-container color-black-bg"
        >
          <div className="modal-content-container">
            <button
              className="modal-icon-close-x"
              onClick={() => modalClickHandler(id)}
            >
              <img
                src={ImageIconCloseX}
                alt="Close X icon"
                style={{ width: "80%" }}
              />
            </button>
            {setWeightReductionContent("bmi-14-year")}
          </div>
        </div>
      </div>
    )
  }

  const Bmi14WeekReduction2 = () => {
    return (
      <div>
        <div id="bmi-14-week-reduction-2-trigger-container">
          <div style={{ display: "table" }} className="modal-content-block">
            <div style={{ display: "table-cell", verticalAlign: "middle" }}>
              <button onClick={() => modalClickHandler(id)}>
                <img
                  src={ImageIconGreenPlus}
                  alt="Green plus icon"
                  className="modal-trigger-button-plus"
                />
              </button>
            </div>
            <div style={{ display: "table-cell", verticalAlign: "middle" }}>
              &nbsp;&nbsp;
            </div>
            <div style={{ display: "table-cell", verticalAlign: "middle" }}>
              <span className="modal-button-text color-teal">
                <strong style={{ fontSize: `1rem`, lineHeight: `1.6` }}>
                  14-week weight reduction results vs placebo
                </strong>
              </span>
            </div>
          </div>
        </div>
        <div
          id="bmi-14-week-reduction-2-modal-container"
          className="modal-container color-black-bg"
        >
          <div className="modal-content-container">
            <button
              className="modal-icon-close-x"
              onClick={() => modalClickHandler(id)}
            >
              <img
                src={ImageIconCloseX}
                alt="Close X icon"
                style={{ width: "80%" }}
              />
            </button>
            {setWeightReductionContent("bmi-14-year")}
          </div>
        </div>
      </div>
    )
  }

  const Bmi14WeekReduction3 = () => {
    return (
      <div>
        <div id="bmi-14-week-reduction-3-trigger-container">
          <div style={{ display: "table" }} className="modal-content-block">
            <div style={{ display: "table-cell", verticalAlign: "middle" }}>
              <button onClick={() => modalClickHandler(id)}>
                <img
                  src={ImageIconGreenPlus}
                  alt="Green plus icon"
                  className="modal-trigger-button-plus"
                />
              </button>
            </div>
            <div style={{ display: "table-cell", verticalAlign: "middle" }}>
              &nbsp;&nbsp;
            </div>
            <div style={{ display: "table-cell", verticalAlign: "middle" }}>
              <span className="modal-button-text color-teal">
                <strong>14-week weight reduction results vs placebo</strong>
              </span>
            </div>
          </div>
        </div>
        <div
          id="bmi-14-week-reduction-3-modal-container"
          className="modal-container color-black-bg"
        >
          <div className="modal-content-container">
            <button
              className="modal-icon-close-x"
              onClick={() => modalClickHandler(id)}
            >
              <img
                src={ImageIconCloseX}
                alt="Close X icon"
                style={{ width: "80%" }}
              />
            </button>
            {setWeightReductionContent("bmi-14-year")}
          </div>
        </div>
      </div>
    )
  }

  const Bmi24MonthLongTerm1 = () => {
    return (
      <div>
        <div id="bmi-24-month-long-term-1-trigger-container">
          <div style={{ display: "table" }} className="modal-content-block">
            <div style={{ display: "table-cell", verticalAlign: "middle" }}>
              <button onClick={() => modalClickHandler(id)}>
                <img
                  src={ImageIconGreenPlus}
                  alt="Green plus icon"
                  className="modal-trigger-button-plus"
                />
              </button>
            </div>
            <div style={{ display: "table-cell", verticalAlign: "middle" }}>
              &nbsp;&nbsp;
            </div>
            <div style={{ display: "table-cell", verticalAlign: "middle" }}>
              <span className="modal-button-text color-teal">
                <strong style={{ fontSize: `1rem`, lineHeight: `1.6` }}>
                  24-month long-term extension trial
                </strong>
              </span>
            </div>
          </div>
        </div>
        <div
          id="bmi-24-month-long-term-1-modal-container"
          className="modal-container color-black-bg"
        >
          <div className="modal-content-container">
            <button
              className="modal-icon-close-x"
              onClick={() => modalClickHandler(id)}
            >
              <img
                src={ImageIconCloseX}
                alt="Close X icon"
                style={{ width: "80%" }}
              />
            </button>
            {setWeightReductionContent("bmi-24-month-1")}
          </div>
        </div>
      </div>
    )
  }

  const Bmi24MonthLongTerm2 = () => {
    return (
      <div>
        <div id="bmi-24-month-long-term-2-trigger-container">
          <div style={{ display: "table" }} className="modal-content-block">
            <div style={{ display: "table-cell", verticalAlign: "middle" }}>
              <button onClick={() => modalClickHandler(id)}>
                <img
                  src={ImageIconGreenPlus}
                  alt="Green plus icon"
                  className="modal-trigger-button-plus"
                />
              </button>
            </div>
            <div style={{ display: "table-cell", verticalAlign: "middle" }}>
              &nbsp;&nbsp;
            </div>
            <div style={{ display: "table-cell", verticalAlign: "middle" }}>
              <span className="modal-button-text color-teal">
                <strong style={{ fontSize: `1rem`, lineHeight: `1.6` }}>
                  24-month long-term extension trial
                </strong>
              </span>
            </div>
          </div>
        </div>
        <div
          id="bmi-24-month-long-term-2-modal-container"
          className="modal-container color-black-bg"
        >
          <div className="modal-content-container">
            <button
              className="modal-icon-close-x"
              onClick={() => modalClickHandler(id)}
            >
              <img
                src={ImageIconCloseX}
                alt="Close X icon"
                style={{ width: "80%" }}
              />
            </button>
            {setWeightReductionContent("bmi-24-month-2")}
          </div>
        </div>
      </div>
    )
  }

  const Bmi24MonthLongTerm3 = () => {
    return (
      <div>
        <div id="bmi-24-month-long-term-3-trigger-container">
          <div style={{ display: "table" }} className="modal-content-block">
            <div style={{ display: "table-cell", verticalAlign: "middle" }}>
              <button onClick={() => modalClickHandler(id)}>
                <img
                  src={ImageIconGreenPlus}
                  alt="Green plus icon"
                  className="modal-trigger-button-plus"
                />
              </button>
            </div>
            <div style={{ display: "table-cell", verticalAlign: "middle" }}>
              &nbsp;&nbsp;
            </div>
            <div style={{ display: "table-cell", verticalAlign: "middle" }}>
              <span className="modal-button-text color-teal">
                <strong>24-month long-term extension trial</strong>
              </span>
            </div>
          </div>
        </div>
        <div
          id="bmi-24-month-long-term-3-modal-container"
          className="modal-container color-black-bg"
        >
          <div className="modal-content-container">
            <button
              className="modal-icon-close-x"
              onClick={() => modalClickHandler(id)}
            >
              <img
                src={ImageIconCloseX}
                alt="Close X icon"
                style={{ width: "80%" }}
              />
            </button>
            {setWeightReductionContent("bmi-24-month-3")}
          </div>
        </div>
      </div>
    )
  }

  const Adult14WeekReduction1 = () => {
    return (
      <div>
        <div id="adult-14-week-reduction-1-trigger-container">
          <div style={{ display: "table" }} className="modal-content-block">
            <div style={{ display: "table-cell", verticalAlign: "middle" }}>
              <button onClick={() => modalClickHandler(id)}>
                <img
                  src={ImageIconGreenPlus}
                  alt="Green plus icon"
                  className="modal-trigger-button-plus"
                />
              </button>
            </div>
            <div style={{ display: "table-cell", verticalAlign: "middle" }}>
              &nbsp;&nbsp;
            </div>
            <div style={{ display: "table-cell", verticalAlign: "middle" }}>
              <span className="modal-button-text color-teal">
                <strong style={{ fontSize: `1rem`, lineHeight: `1.6` }}>
                  14-week weight reduction results vs placebo
                </strong>
              </span>
            </div>
          </div>
        </div>
        <div
          id="adult-14-week-reduction-1-modal-container"
          className="modal-container color-black-bg"
        >
          <div className="modal-content-container">
            <button
              className="modal-icon-close-x"
              onClick={() => modalClickHandler(id)}
            >
              <img
                src={ImageIconCloseX}
                alt="Close X icon"
                style={{ width: "80%" }}
              />
            </button>
            {setWeightReductionContent("adult-14-year")}
          </div>
        </div>
      </div>
    )
  }

  const Adult14WeekReduction2 = () => {
    return (
      <div>
        <div id="adult-14-week-reduction-2-trigger-container">
          <div style={{ display: "table" }} className="modal-content-block">
            <div style={{ display: "table-cell", verticalAlign: "middle" }}>
              <button onClick={() => modalClickHandler(id)}>
                <img
                  src={ImageIconGreenPlus}
                  alt="Green plus icon"
                  className="modal-trigger-button-plus"
                />
              </button>
            </div>
            <div style={{ display: "table-cell", verticalAlign: "middle" }}>
              &nbsp;&nbsp;
            </div>
            <div style={{ display: "table-cell", verticalAlign: "middle" }}>
              <span className="modal-button-text color-teal">
                <strong>14-week weight reduction results vs placebo</strong>
              </span>
            </div>
          </div>
        </div>
        <div
          id="adult-14-week-reduction-2-modal-container"
          className="modal-container color-black-bg"
        >
          <div className="modal-content-container">
            <button
              className="modal-icon-close-x"
              onClick={() => modalClickHandler(id)}
            >
              <img
                src={ImageIconCloseX}
                alt="Close X icon"
                style={{ width: "80%" }}
              />
            </button>
            {setWeightReductionContent("adult-14-year")}
          </div>
        </div>
      </div>
    )
  }

  const Adult24MonthLongTerm1 = () => {
    return (
      <div>
        <div id="adult-24-month-long-term-1-trigger-container">
          <div style={{ display: "table" }} className="modal-content-block">
            <div style={{ display: "table-cell", verticalAlign: "middle" }}>
              <button onClick={() => modalClickHandler(id)}>
                <img
                  src={ImageIconGreenPlus}
                  alt="Green plus icon"
                  className="modal-trigger-button-plus"
                />
              </button>
            </div>
            <div style={{ display: "table-cell", verticalAlign: "middle" }}>
              &nbsp;&nbsp;
            </div>
            <div style={{ display: "table-cell", verticalAlign: "middle" }}>
              <span className="modal-button-text color-teal">
                <strong style={{ fontSize: `1rem`, lineHeight: `1.6` }}>
                  24-month long-term extension trial
                </strong>
              </span>
            </div>
          </div>
        </div>
        <div
          id="adult-24-month-long-term-1-modal-container"
          className="modal-container color-black-bg"
        >
          <div className="modal-content-container">
            <button
              className="modal-icon-close-x"
              onClick={() => modalClickHandler(id)}
            >
              <img
                src={ImageIconCloseX}
                alt="Close X icon"
                style={{ width: "80%" }}
              />
            </button>
            {setWeightReductionContent("adult-24-month-1")}
          </div>
        </div>
      </div>
    )
  }

  const Adult24MonthLongTerm2 = () => {
    return (
      <div>
        <div id="adult-24-month-long-term-2-trigger-container">
          <div style={{ display: "table" }} className="modal-content-block">
            <div style={{ display: "table-cell", verticalAlign: "middle" }}>
              <button onClick={() => modalClickHandler(id)}>
                <img
                  src={ImageIconGreenPlus}
                  alt="Green plus icon"
                  className="modal-trigger-button-plus"
                />
              </button>
            </div>
            <div style={{ display: "table-cell", verticalAlign: "middle" }}>
              &nbsp;&nbsp;
            </div>
            <div style={{ display: "table-cell", verticalAlign: "middle" }}>
              <span className="modal-button-text color-teal">
                <strong>24-month long-term extension trial</strong>
              </span>
            </div>
          </div>
        </div>
        <div
          id="adult-24-month-long-term-2-modal-container"
          className="modal-container color-black-bg"
        >
          <div className="modal-content-container">
            <button
              className="modal-icon-close-x"
              onClick={() => modalClickHandler(id)}
            >
              <img
                src={ImageIconCloseX}
                alt="Close X icon"
                style={{ width: "80%" }}
              />
            </button>
            {setWeightReductionContent("adult-24-month-2")}
          </div>
        </div>
      </div>
    )
  }

  const Adult24MonthLongTerm3 = () => {
    return (
      <div>
        <div id="adult-24-month-long-term-3-trigger-container">
          <div style={{ display: "table" }} className="modal-content-block">
            <div style={{ display: "table-cell", verticalAlign: "middle" }}>
              <button onClick={() => modalClickHandler(id)}>
                <img
                  src={ImageIconGreenPlus}
                  alt="Green plus icon"
                  className="modal-trigger-button-plus"
                />
              </button>
            </div>
            <div style={{ display: "table-cell", verticalAlign: "middle" }}>
              &nbsp;&nbsp;
            </div>
            <div style={{ display: "table-cell", verticalAlign: "middle" }}>
              <span className="modal-button-text color-teal">
                <strong style={{ fontSize: `1rem`, lineHeight: `1.6` }}>
                  24-month long-term extension trial
                </strong>
              </span>
            </div>
          </div>
        </div>
        <div
          id="adult-24-month-long-term-3-modal-container"
          className="modal-container color-black-bg"
        >
          <div className="modal-content-container">
            <button
              className="modal-icon-close-x"
              onClick={() => modalClickHandler(id)}
            >
              <img
                src={ImageIconCloseX}
                alt="Close X icon"
                style={{ width: "80%" }}
              />
            </button>
            {setWeightReductionContent("adult-24-month-3")}
          </div>
        </div>
      </div>
    )
  }

  const WhatIsHyperphagia = () => {
    return (
      <div>
        <div id="what-is-hyperphagia-trigger-container">
          <div style={{ display: "table" }} className="modal-content-block">
            <div style={{ display: "table-cell", verticalAlign: "middle" }}>
              <button onClick={() => modalClickHandler(id)}>
                <img
                  src={ImageIconGreenPlus}
                  alt="Green plus icon"
                  className="modal-trigger-button-plus"
                />
              </button>
            </div>
            <div style={{ display: "table-cell", verticalAlign: "middle" }}>
              &nbsp;&nbsp;
            </div>
            <div style={{ display: "table-cell", verticalAlign: "middle" }}>
              <span className="modal-button-text color-teal">
                <strong>What is hyperphagia?</strong>
              </span>
            </div>
          </div>
        </div>
        <div
          id="what-is-hyperphagia-modal-container"
          className="modal-container color-black-bg"
        >
          <div className="modal-content-container">
            <button
              className="modal-icon-close-x"
              onClick={() => modalClickHandler(id)}
            >
              <img
                src={ImageIconCloseX}
                alt="Close X icon"
                style={{ width: "80%" }}
              />
            </button>
            <div className="row">
              <div className="column small-12">
                <h1 className="h1">What is hyperphagia?</h1>
                <h2 className="h2">
                  Appetite control can be a struggle for many people living with
                  obesity, but with BBS, insatiable hunger (hyperphagia) is
                  different
                </h2>
              </div> 
            </div>
            <div className="row og expanded">
              <div className="column small-12 medium-12 large-8">
                <div className="row fullHeight">
                  <div className="column og small-12 medium-12 large-4 ">
                    <div className="card">
                      <div className="card-divider text-center">
                        Occasional overeating
                      </div>
                      <div className="card-section">
                        <p>
                          Eating beyond a feeling of satiety at a special
                          occasion or celebratory meal (eg, Thanksgiving)
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="column og small-12 medium-12 large-4 ">
                    <div className="card">
                      <div className="card-divider text-center">
                        Hedonic overeating
                      </div>
                      <div className="card-section">
                        <p>
                          Eating beyond metabolic requirements based on the
                          expectation of pleasure from consuming foods
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="column og small-12 medium-12 large-4 ">
                    <div className="card">
                      <div className="card-divider text-center">
                        Binge eating
                      </div>
                      <div className="card-section">
                        <p>
                          Consumption of a large amount of food with loss of
                          control in a short period of time
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="divider column small-12 medium-12 large-1 text-center"></div>
              <div className="after-divider column small-12 medium-12 large-3">
                <div className="row">
                  <div className="column og small-12 pt-0 hyperphagia-card">
                    <div className="card">
                      <div className="card-divider text-center card-hyperphagia">
                        Hyperphagia
                        <span>
                          (Caused by an impaired MC4R pathway in people living
                          with BBS)
                        </span>
                      </div>
                      <div className="card-section">
                        <ul>
                          <li>Long time to satiation</li>
                          <li>Shorter duration of satiation</li>
                          <li>Prolonged feeling of hunger</li>
                          <li>
                            Severe preoccupation with food and distress if
                            denied food, often associated with abnormal
                            food-seeking behaviors
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-2 md_mt-2 mb-2 md_mb-3">
              <Callout fullWidth className="lime-teal left text-left">
                <p className="md_mb-0">
                  In BBS, insatiable hunger and obesity are caused by a genetic
                  change that makes it difficult for the brain's hunger
                  signaling pathway to help you feel full. That's why medicine
                  like IMCIVREE can help
                </p>
              </Callout>
            </div>
            {/* <div className="column small-12 mb-1 md_mb-2">
              <div className="d-flex align-middle bbs-ribbon-box pl-1 pr-1">
                <img src={OverviewRibbonIcon} className="mr-1 md_mr-2" alt="Blue Ribbon" />
                <strong>In fact, the American Academy of Pediatrics and the Obesity Medicine Association recommend taking FDA-approved medicine (like IMCIVREE) to help when it is an option</strong>
              </div>
            </div> */}
            <div className="ml-1 md_ml-1">
              <p className="h1 w-100">
                Hear from families who have experienced hyperphagia due to BBS
              </p>
            </div>
            <div className="row pt-2 pb-2 md_pt-2 md_pl-1  md_pr-1">
              <div className="small-12 medium-4 column">
                <figure className="hunger-bbs-profile text-center">
                  <img
                    src={overviewModal01}
                    alt="Ann, caregiver of a person living with BBS"
                    className="mb-2 md_mb-3"
                    style={{ minWidth: `300px`, maxWidth: `300px` }}
                  />
                </figure>
              </div>
              <div className="small-12 medium-8 column">
                <Quote
                  Quote
                  copy={
                    <>
                      After her first birthday, we noticed a rapid weight gain,
                      and she threw tantrums all the time and was very difficult
                      to manage. Looking back, I see this was most likely due to
                      how hungry she felt.
                    </>
                  }
                  signoff={<>— Ann, caregiver of a person living with BBS</>}
                ></Quote>
              </div>
              <div className="small-12 medium-8 column small-order-2 medium-order-1">
                <Quote
                  Quote
                  copy={
                    <>
                      While Wyatt and Carson have the same variants of the{" "}
                      <i>BBS10</i> gene, only Wyatt has hyperphagia and obesity.
                      I couldn’t take the kids out by myself because Wyatt
                      needed constant supervision to make sure he wasn’t
                      stealing food. I have to lock our fridge, trash, and
                      pantry.
                    </>
                  }
                  signoff={<>— Rachel, caregiver of a child living with BBS</>}
                ></Quote>
              </div>
              <div className="small-12 medium-4 column small-order-1 medium-order-2">
                <figure className="hunger-bbs-profile text-center mt-3">
                  <img
                    src={overviewModal02}
                    alt="Rachel, caregiver of a child living with BBS"
                    className="mb-2 md_mb-3"
                    style={{ minWidth: `300px`, maxWidth: `300px` }}
                  />
                </figure>
              </div>
            </div>
            <p className="footnote ml-1 md_ml-1">
              MC4R=melanocortin-4 receptor.
            </p>
          </div>
        </div>
      </div>
    )
  }

  const GeneticTestingHcpPpl = () => {
    return (
      <div id="genetic-testing-hcp-ppl-trigger-container">
        <div style={{ display: "table" }} className="modal-content-block">
          <div style={{ display: "table-cell", verticalAlign: "middle" }}>
            <button onClick={() => modalClickHandler(id)}>
              <img
                src={ImageIconGreenPlus}
                alt="Green plus icon"
                className="modal-trigger-button-plus"
              />
            </button>
          </div>
          <div style={{ display: "table-cell", verticalAlign: "middle" }}>
            &nbsp;&nbsp;
          </div>
          <div style={{ display: "table-cell", verticalAlign: "middle" }}>
            <span className="modal-button-text color-teal">
              <strong>Genetic testing</strong>
            </span>
          </div>
        </div>
        <div
          id="genetic-testing-hcp-ppl-modal-container"
          className="modal-container"
        >
          <div className="modal-content-container mb-14 md_mb-10">
            <button
              className="modal-icon-close-x"
              onClick={() => modalClickHandler(id)}
            >
              <img
                src={ImageIconCloseX}
                alt="Close X icon"
                style={{ width: "80%" }}
              />
            </button>
            <div className="row">
              {/* Modal content wrapper */}
              <div className="column small-12 text-left">
                <h1 className="h1 mb-4 lg_mb-1">
                  A no-charge,* extensive genetic testing program for MC4R
                  pathway diseases, including POMC, PCSK1, and LEPR deficiency
                </h1>
              </div>
              <div
                className="column small-12 text-left"
                style={{ margin: `3rem auto` }}
              >
                <div className="row">
                  {/* Left side column */}
                  <div className="columns small-12 large-8">
                    <div className="row" style={{ gap: `30px` }}>
                      <div className="columns small-12">
                        <div
                          className="row"
                          style={{
                            gap: `20px`,
                            minHeight: `210px`,
                            alignItems: `center`,
                          }}
                        >
                          <div className="columns small-12 large-3">
                            <img
                              src={IconLightbulb}
                              alt="Lightbulb icon"
                              style={{ display: `block`, margin: `0 auto` }}
                            />
                          </div>
                          <div className="columns small-12 large-8">
                            <h2
                              className="jost-semibold"
                              style={{
                                color: `#4B4F54`,
                                fontSize: `1.5rem`,
                                lineHeight: `2rem`,
                              }}
                            >
                              Extensive panel offers broad insights
                            </h2>
                            <p>
                              The gene panel includes 79 genes and 1 chromosome
                              region, reflective of nearly all of the most
                              frequently tested genes associated with obesity.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="columns small-12 mt-2 lg_mt-0">
                        <div
                          className="row"
                          style={{
                            gap: `20px`,
                            minHeight: `210px`,
                            alignItems: `center`,
                          }}
                        >
                          <div className="columns small-12 large-3">
                            <img
                              src={IconClinialSupport}
                              alt="HCP icon"
                              style={{ display: `block`, margin: `0 auto` }}
                            />
                          </div>
                          <div className="columns small-12 large-8">
                            <h2
                              className="jost-semibold"
                              style={{
                                color: `#4B4F54`,
                                fontSize: `1.5rem`,
                                lineHeight: `2rem`,
                              }}
                            >
                              Tailored support for results interpretation
                            </h2>
                            <p>
                              Access to a geneticist to help interpret results
                              and licensed genetic counselors for your patients
                              are available. Services are provided through
                              third-party partners.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="columns small-12 mt-2 lg_mt-0">
                        <div
                          className="row"
                          style={{
                            gap: `20px`,
                            minHeight: `210px`,
                            alignItems: `center`,
                          }}
                        >
                          <div className="columns small-12 large-3">
                            <img
                              src={IconLabDoor}
                              alt="Door icon"
                              style={{ display: `block`, margin: `0 auto` }}
                            />
                          </div>
                          <div className="columns small-12 large-8">
                            <h2
                              className="jost-semibold"
                              style={{
                                color: `#4B4F54`,
                                fontSize: `1.5rem`,
                                lineHeight: `2rem`,
                              }}
                            >
                              Testing conducted by a laboratory partner
                            </h2>
                            <p>
                              DNA testing is conducted by PreventionGenetics, a
                              CLIA-accredited clinical laboratory.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Right side content */}
                  <div className="columns small-12 large-4">
                    <div className="row h-100">
                      <div className="columns small-12 mt-4 lg_mt-0">
                        <img
                          src={IconSwabKit}
                          alt="Swab kit icon"
                          style={{
                            display: `block`,
                            margin: `0 auto`,
                            minWidth: `274px`,
                            maxWidth: `274px`,
                          }}
                        />
                        <p
                          className="footnote text-center"
                          style={{ marginTop: `1rem` }}
                        >
                          Blood and OCD-100 buccal swab
                          <br />
                          sample colletion kits are available.
                        </p>
                      </div>
                      <div class="columns small-2">&nbsp;</div>
                      <div className="columns small-10 mt-4 lg_mt-6">
                        <Callout
                          fullWidth
                          className="light-teal right text-left"
                        >
                          <p>
                            For more information about the genetic testing
                            program, visit{" "}
                            <a
                              href="https://www.uncoveringrareobesity.com"
                              target="_blank"
                              className="color-white underline"
                            >
                              UncoveringRareObesity.com
                            </a>
                          </p>
                        </Callout>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-left md_mt-1">
                <p className="footnote hanging-ref ">
                  *Rhythm Pharmaceuticals covers the cost of the test and
                  provides sample collection kits. Patients are responsible for
                  office visit, sample collection, or other costs.
                </p>

                <p className="footnote mt-1 md_mt-1">
                  CLIA=Clinical Laboratory Improvement Amendments;
                  MC4R=melanocortin-4 receptor.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const HungerScaleHcpPpl = () => {
    return (
      <div>
        <div id="hunger-scale-hcp-ppl-trigger-container">
          <div style={{ display: "table" }} className="modal-content-block">
            <div style={{ display: "table-cell", verticalAlign: "middle" }}>
              <button onClick={() => modalClickHandler(id)}>
                <img
                  src={ImageIconGreenPlus}
                  alt="Green plus icon"
                  className="modal-trigger-button-plus"
                />
              </button>
            </div>
            <div style={{ display: "table-cell", verticalAlign: "middle" }}>
              &nbsp;&nbsp;
            </div>
            <div style={{ display: "table-cell", verticalAlign: "middle" }}>
              <span className="h2 color-teal">Hunger scale</span>
            </div>
          </div>
        </div>
        <div
          id="hunger-scale-hcp-ppl-modal-container"
          className="modal-container"
        >
          <div className="modal-content-container color-black mb-14 md_mb-10">
            <button
              className="modal-icon-close-x"
              onClick={() => modalClickHandler(id)}
            >
              <img
                src={ImageIconCloseX}
                alt="Close X icon"
                style={{ width: "80%" }}
              />
            </button>
            <div className="row">
              <div className="column small-12 text-left">
                <h1 className="h1 mb-2 lg_mb-2">
                  Hunger scale in the clinical trials<sup>1</sup>
                </h1>
              </div>
            </div>
            <div className="row">
              <div className="column small-12 text-left">
                <figure className="text-center pr-0 lg_pr-3">
                  <img
                    src={HungerScaleGraph}
                    alt="IMCIVREE hunger scale in the clinical trials"
                    className="mb-2"
                  />
                </figure>
              </div>
            </div>
            <div className="row">
              <div className="column small-12 text-left">
                <ul className="neon-green-bullets" style={{ color: "#4B4F54" }}>
                  <li>
                    Patients ≥12 years of age who were able to self-report their
                    hunger (n=16) recorded their daily maximal hunger in a
                    diary, which was then assessed by the Daily Hunger
                    Questionnaire Item 2
                  </li>
                  <li>
                    Hunger was scored on an 11-point scale from 0 (“not hungry
                    at all”) to 10 (“hungriest possible”)
                  </li>
                </ul>
                <p className="references footnote mt-1 md_mt-1 fw-normal gray-text">
                  <b>Reference: 1.</b> IMCIVREE [prescribing information].
                  Boston, MA. Rhythm Pharmaceuticals, Inc.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const HungerScaleHcpBbs = () => {
    return (
      <div>
        <div id="hunger-scale-hcp-bbs-trigger-container">
          <div style={{ display: "table" }} className="modal-content-block">
            <div style={{ display: "table-cell", verticalAlign: "middle" }}>
              <button onClick={() => modalClickHandler(id)}>
                <img
                  src={ImageIconGreenPlus}
                  alt="Green plus icon"
                  className="modal-trigger-button-plus"
                />
              </button>
            </div>
            <div style={{ display: "table-cell", verticalAlign: "middle" }}>
              &nbsp;&nbsp;
            </div>
            <div style={{ display: "table-cell", verticalAlign: "middle" }}>
              <span className="h2 color-teal">Hunger scale</span>
            </div>
          </div>
        </div>
        <div
          id="hunger-scale-hcp-bbs-modal-container"
          className="modal-container"
        >
          <div className="modal-content-container mb-14 md_mb-10">
            <button
              className="modal-icon-close-x"
              onClick={() => modalClickHandler(id)}
            >
              <img
                src={ImageIconCloseX}
                alt="Close X icon"
                style={{ width: "80%" }}
              />
            </button>
            <div className="row">
              <div className="column small-12 text-left">
                <h1 className="h1 mb-2 lg_mb-2">
                  Hunger scale in the BBS clinical trial<sup>1</sup>
                </h1>
              </div>
            </div>
            <div className="row">
              <div className="column small-12 text-left">
                <figure className="text-center pr-0 lg_pr-3">
                  <img
                    src={HungerScaleGraph}
                    alt="Hunger scale in BBS clinical trial"
                    className="mb-2"
                  />
                </figure>
              </div>
            </div>
            <div className="row">
              <div className="column small-12 text-left">
                <ul className="neon-green-bullets">
                  <li>
                    Patients ≥12 years of age who were able to self-report their
                    hunger (n=14) recorded their daily maximal hunger in a
                    diary, which was then assessed by the Daily Hunger
                    Questionnaire Item 2
                  </li>
                  <li>
                    Hunger was scored on an 11-point scale from 0 (“not hungry
                    at all”) to 10 (“hungriest possible”)
                  </li>
                </ul>
                <p className="references mt-1 md_mt-1">
                  <b>Reference: 1.</b> IMCIVREE [prescribing information].
                  Boston, MA. Rhythm Pharmaceuticals, Inc.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const WarningPrecautionsHcpPpl = () => {
    return (
      <div>
        <div id="warning-precautions-hcp-ppl-trigger-container">
          <div style={{ display: "table" }} className="modal-content-block">
            <div style={{ display: "table-cell", verticalAlign: "middle" }}>
              <button onClick={() => modalClickHandler(id)}>
                <img
                  src={ImageIconGreenPlus}
                  alt="Green plus icon"
                  className="modal-trigger-button-plus"
                />
              </button>
            </div>
            <div style={{ display: "table-cell", verticalAlign: "middle" }}>
              &nbsp;&nbsp;
            </div>
            <div style={{ display: "table-cell", verticalAlign: "middle" }}>
              <span className="h2 modal-button-text color-teal">
                <span class="nowrap">Warnings and</span> precautions
              </span>
            </div>
          </div>
        </div>
        <div
          id="warning-precautions-hcp-ppl-modal-container"
          className="modal-container"
        >
          <div className="modal-content-container color-black mb-14 md_mb-10">
            <button
              className="modal-icon-close-x"
              onClick={() => modalClickHandler(id)}
            >
              <img
                src={ImageIconCloseX}
                alt="Close X icon"
                style={{ width: "80%" }}
              />
            </button>
            <div className="row">
              <div className="column small-12 text-left">
                <h1 className="h1 mb-4 lg_mb-1">
                  Warnings and precautions<sup>1</sup>
                </h1>
              </div>
              <div className="column small-12 text-left">
                <ul className="neon-green-bullets">
                  <li className="color-gray mb-1 md_mb-1">
                    <b>Disturbance in Sexual Arousal:</b>&nbsp;Spontaneous
                    penile erections in males and sexual adverse reactions in
                    females have occurred. Inform patients that these events may
                    occur and instruct patients who have an erection lasting
                    longer than 4 hours to seek emergency medical attention
                  </li>
                  <li className="color-gray mb-1 md_mb-1">
                    <b>Depression and Suicidal Ideation:</b>&nbsp;Depression and
                    suicidal ideation have occurred. Monitor patients for new
                    onset or worsening depression or suicidal thoughts or
                    behaviors. Consider discontinuing IMCIVREE if patients
                    experience suicidal thoughts or behaviors, or clinically
                    significant or persistent depression symptoms occur
                  </li>
                   <li className="color-gray mb-1 md_mb-1">
                   <b>Hypersensitivity Reactions:</b>&nbsp;Serious hypersensitivity reactions (e.g., anaphylaxis) have been reported. If suspected, advise patients to promptly seek medical attention and discontinue IMCIVREE
                   </li>
                  <li className="color-gray mb-1 md_mb-1">
                    <b>Skin Pigmentation and Darkening of Pre-existing Nevi:</b>
                    &nbsp;Generalized increased skin pigmentation and darkening
                    of pre-existing nevi have occurred. Perform a full body skin
                    examination prior to initiation and periodically during
                    treatment to monitor pre-existing and new pigmentary lesions
                  </li>
                  <li className="color-gray mb-1 md_mb-1">
                    <b>
                      Risk of Serious Adverse Reactions Due to Benzyl Alcohol
                      Preservative in Neonates and Low Birth Weight Infants:
                    </b>
                    &nbsp;IMCIVREE is not approved for use in neonates or
                    infants. Serious and fatal adverse reactions including
                    “gasping syndrome” can occur in neonates and low birth
                    weight infants treated with benzyl alcohol-preserved drugs
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="references text-left md_mt-1"
              style={{ color: "#4B4F54" }}
            >
              <strong>Reference: 1.</strong> IMCIVREE [prescribing information].
              Boston, MA. Rhythm Pharmaceuticals, Inc.
            </div>
          </div>
        </div>
      </div>
    )
  }

  const ClinicallyDiagnosingBBS = () => {
    return (
      <div>
        <div id="clinically-diagnosing-bbs-trigger-container">
          <div style={{ display: "table" }} className="modal-content-block">
            <div style={{ display: "table-cell", verticalAlign: "middle" }}>
              <button onClick={() => modalClickHandler(id)}>
                <img
                  src={ImageIconGreenPlus}
                  alt="Green plus icon"
                  className="modal-trigger-button-plus"
                />
              </button>
            </div>
            <div style={{ display: "table-cell", verticalAlign: "middle" }}>
              &nbsp;&nbsp;
            </div>
            <div style={{ display: "table-cell", verticalAlign: "middle" }}>
              <span className="h4 fw-semibold color-teal">
                Clinically diagnosing BBS
              </span>
            </div>
          </div>
        </div>
        <div
          id="clinically-diagnosing-bbs-modal-container"
          className="modal-container"
        >
          <div className="modal-content-container color-black mb-14 md_mb-10">
            <button
              className="modal-icon-close-x"
              onClick={() => modalClickHandler(id)}
            >
              <img
                src={ImageIconCloseX}
                alt="Close X icon"
                style={{ width: "80%" }}
              />
            </button>
            <div className="row">
              <div className="column small-12 text-left">
                <h1 className="h1 mb-4 lg_mb-1">
                  How BBS can present in your practice
                </h1>
              </div>
            </div>
            <div id="clinically-diagnosing-bbs-tables-wrapper">
              <MostCommonClinicalFeaturesTable />
              <hr />
              <AdditionalClinicalFeaturesTable />
            </div>
          </div>
        </div>
      </div>
    )
  }

  const GeneticTestingHcpBBS = () => {
    return (
      <div>
        <div id="genetic-testing-hcp-bbs-trigger-container">
          <div style={{ display: "table" }} className="modal-content-block">
            <div style={{ display: "table-cell", verticalAlign: "middle" }}>
              <button onClick={() => modalClickHandler(id)}>
                <img
                  src={ImageIconGreenPlus}
                  alt="Green plus icon"
                  className="modal-trigger-button-plus"
                />
              </button>
            </div>
            <div style={{ display: "table-cell", verticalAlign: "middle" }}>
              &nbsp;&nbsp;
            </div>
            <div style={{ display: "table-cell", verticalAlign: "middle" }}>
              <span className="h4 fw-semibold color-teal">Genetic testing</span>
            </div>
          </div>
        </div>
        <div
          id="genetic-testing-hcp-bbs-modal-container"
          className="modal-container"
        >
          <div className="modal-content-container color-black mb-14 md_mb-10">
            <button
              className="modal-icon-close-x"
              onClick={() => modalClickHandler(id)}
            >
              <img
                src={ImageIconCloseX}
                alt="Close X icon"
                style={{ width: "80%" }}
              />
            </button>
            <div className="row">
              {/* Modal content wrapper */}
              <div className="column small-12 text-left">
                <h1 className="h1 mb-4 lg_mb-1">
                  The only no-charge,* extensive genetic testing program for
                  MC4R pathway diseases, including BBS
                </h1>
              </div>
              <div
                className="column small-12 text-left"
                style={{ margin: `3rem auto` }}
              >
                <div className="row">
                  {/* Left side column */}
                  <div className="columns small-12 large-8">
                    <div className="row" style={{ gap: `30px` }}>
                      <div className="columns small-12">
                        <div
                          className="row"
                          style={{
                            gap: `20px`,
                            minHeight: `210px`,
                            alignItems: `center`,
                          }}
                        >
                          <div className="columns small-12 large-3">
                            <img
                              src={IconLightbulb}
                              alt="Lightbulb icon"
                              style={{ display: `block`, margin: `0 auto` }}
                            />
                          </div>
                          <div className="columns small-12 large-8 d-flex flex-dir-column align-center">
                            <h2
                              className="jost-semibold color-gray"
                              style={{
                                fontSize: `1.5rem`,
                                lineHeight: `2rem`,
                              }}
                            >
                              Extensive panel offers broad insights
                            </h2>
                            <p className="color-gray">
                              More than 20 genes have been found to be
                              associated with BBS. The gene panel includes 79
                              genes and 1 chromosome region, reflective of
                              nearly all of the most frequently tested genes
                              associated with obesity.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="columns small-12 mt-2 lg_mt-0">
                        <div
                          className="row"
                          style={{
                            gap: `20px`,
                            minHeight: `210px`,
                            alignItems: `center`,
                          }}
                        >
                          <div className="columns small-12 large-3">
                            <img
                              src={IconClinialSupport}
                              alt="HCP icon"
                              style={{ display: `block`, margin: `0 auto` }}
                            />
                          </div>
                          <div className="columns small-12 large-8 d-flex flex-dir-column align-center">
                            <h2
                              className="jost-semibold color-gray"
                              style={{
                                fontSize: `1.5rem`,
                                lineHeight: `2rem`,
                              }}
                            >
                              Tailored support for results interpretation
                            </h2>
                            <p className="color-gray">
                              Access to a geneticist to help interpret results
                              and licensed genetic counselors for your patients
                              are available. Services are provided through
                              third-party partners.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="columns small-12 mt-2 lg_mt-0">
                        <div
                          className="row"
                          style={{
                            gap: `20px`,
                            minHeight: `210px`,
                            alignItems: `center`,
                          }}
                        >
                          <div className="columns small-12 large-3">
                            <img
                              src={IconLabDoor}
                              alt="Door icon"
                              style={{ display: `block`, margin: `0 auto` }}
                            />
                          </div>
                          <div className="columns small-12 large-8 d-flex flex-dir-column align-center">
                            <h2
                              className="jost-semibold color-gray"
                              style={{
                                fontSize: `1.5rem`,
                                lineHeight: `2rem`,
                              }}
                            >
                              Testing conducted by a laboratory partner
                            </h2>
                            <p className="color-gray">
                              DNA testing is conducted by PreventionGenetics, a
                              CLIA-accredited clinical laboratory.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Right side content */}
                  <div className="columns small-12 large-4">
                    <div className="row h-100">
                      <div className="columns small-12 mt-4 lg_mt-0">
                        <img
                          src={IconSwabKit}
                          alt="Swab kit icon"
                          style={{
                            display: `block`,
                            margin: `0 auto`,
                            minWidth: `274px`,
                            maxWidth: `274px`,
                          }}
                        />
                        <p
                          className="footnote text-center"
                          style={{ marginTop: `1rem` }}
                        >
                          Blood and OCD-100 buccal swab
                          <br />
                          sample colletion kits are available.
                        </p>
                      </div>
                      <div class="columns small-2">&nbsp;</div>
                      <div className="columns small-10 mt-4 lg_mt-6">
                        <Callout
                          fullWidth
                          className="light-teal right text-left"
                        >
                          <p>
                            For more information about the genetic testing
                            program, visit{" "}
                            <a
                              href="https://www.uncoveringrareobesity.com"
                              target="_blank"
                              className="color-white underline"
                            >
                              UncoveringRareObesity.com
                            </a>
                          </p>
                        </Callout>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-left md_mt-1">
                <p className="footnote hanging-ref mb-1 md_mb-1">
                  *Rhythm Pharmaceuticals covers the cost of the test and
                  provides sample collection kits. Patients are responsible for
                  office visit, sample collection, or other costs.
                </p>
                <p className="footnote ">
                  CLIA=Clinical Laboratory Improvement Amendments;
                  MC4R=melanocortin-4 receptor.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const KeyIdentifiableFeatures = () => {
    return (
      <div>
        <div id="key-identifiable-features-trigger-container">
          <div style={{ display: "table" }} className="modal-content-block">
            <div style={{ display: "table-cell", verticalAlign: "middle" }}>
              <button onClick={() => modalClickHandler(id)}>
                <img
                  src={ImageIconGreenPlus}
                  alt="Green plus icon"
                  className="modal-trigger-button-plus"
                />
              </button>
            </div>
            <div style={{ display: "table-cell", verticalAlign: "middle" }}>
              &nbsp;&nbsp;
            </div>
            <div style={{ display: "table-cell", verticalAlign: "middle" }}>
              <span className="h4 fw-semibold color-teal">
                Key identifiable features
              </span>
            </div>
          </div>
        </div>
        <div
          id="key-identifiable-features-modal-container"
          className="modal-container"
        >
          <div className="modal-content-container color-black mb-14 md_mb-10">
            <button
              className="modal-icon-close-x"
              onClick={() => modalClickHandler(id)}
            >
              <img
                src={ImageIconCloseX}
                alt="Close X icon"
                style={{ width: "80%" }}
              />
            </button>
            <div className="row">
              <div className="column small-12 text-left">
                <h1 className="h1 mb-4 lg_mb-1">
                  BBS has a highly variable phenotype with key identifiable
                  features
                </h1>
                <h2 className="h2">
                  BBS is clinically and genetically diverse, so not all patients
                  with BBS will present the same way or with all of these
                  features
                </h2>
              </div>
              <div className="column small-12 text-left">
                <KeyIdentifiableFeaturesTable />
              </div>
            </div>
            <div className="text-left md_mt-4">
              <p className="footnote">CKD=chronic kidney disease.</p>
              <div class="references fw-normal md_mt-1 mt-1">
                <strong>References: 1.</strong> Lorem ipsum dolor sit amet.{" "}
                <strong>2.</strong> Condimentum lacinia quis vel eros donec ac
                odio tempor orci. <strong>3.</strong> Dolor sit amet consectetur
                adipiscing elit ut. Nisi lacus sed viverra tellus in hac.
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      {id === "patient-access-hcp-ppl" && <PatientAccess />}
      {id === "patient-access-hcp-bbs" && <PatientAccessHcpBbs />}
      {id === "hyperpigmentation-hcp-ppl" && <HyperpigmentationHcpPpl />}
      {id === "hyperphagia-pat-ppl" && <HyperphagiaPatPpl />}
      {id === "symptom-progression-over-time" && <SymptomProgressionOverTime />}
      {id === "the-role-of-genetic-testing" && <TheRoleOfGeneticTesting />}
      {id === "warnings-and-precautions" && <WarningsAndPrecautions />}
      {id === "hyperpigmentation-hcp-bbs" && <HyperpigmentationHcpBbs />}
      {id === "recommended-monitoring" && <RecommendedMonitoring />}
      {id === "recommended-monitoring-hcp-ppl" && (
        <RecommendedMonitoringHcpPpl />
      )}
      {id === "recommended-monitoring-hcp-bbs" && (
        <RecommendedMonitoringHcpBbs />
      )}
      {id === "dosing-for-severe-renal-impairment" && (
        <DosingForSevereRenalImpairment />
      )}
      {id === "dosing-for-severe-renal-impairment-hcp-ppl" && (
        <DosingForSevereRenalImpairmentHcpPpl />
      )}
      {id === "dosing-for-severe-renal-impairment-hcp-bbs" && (
        <DosingForSevereRenalImpairmentHcpBbs />
      )}
      {id === "hypothetical-growth-chart" && <HypotheticalGrowthChart />}
      {id === "other-parameters" && <OtherParameters />}
      {id === "bmi-14-week-reduction-1" && <Bmi14WeekReduction1 />}
      {id === "bmi-14-week-reduction-2" && <Bmi14WeekReduction2 />}
      {id === "bmi-14-week-reduction-3" && <Bmi14WeekReduction3 />}
      {id === "bmi-24-month-long-term-1" && <Bmi24MonthLongTerm1 />}
      {id === "bmi-24-month-long-term-2" && <Bmi24MonthLongTerm2 />}
      {id === "bmi-24-month-long-term-3" && <Bmi24MonthLongTerm3 />}
      {id === "adult-14-week-reduction-1" && <Adult14WeekReduction1 />}
      {id === "adult-14-week-reduction-2" && <Adult14WeekReduction2 />}
      {id === "adult-24-month-long-term-1" && <Adult24MonthLongTerm1 />}
      {id === "adult-24-month-long-term-2" && <Adult24MonthLongTerm2 />}
      {id === "adult-24-month-long-term-3" && <Adult24MonthLongTerm3 />}
      {id === "what-is-hyperphagia" && <WhatIsHyperphagia />}
      {id === "genetic-testing-hcp-ppl" && <GeneticTestingHcpPpl />}
      {id === "hunger-scale-hcp-ppl" && <HungerScaleHcpPpl />}
      {id === "hunger-scale-hcp-bbs" && <HungerScaleHcpBbs />}
      {id === "warning-precautions-hcp-ppl" && <WarningPrecautionsHcpPpl />}
      {id === "clinically-diagnosing-bbs" && <ClinicallyDiagnosingBBS />}
      {id === "genetic-testing-hcp-bbs" && <GeneticTestingHcpBBS />}
      {id === "key-identifiable-features" && <KeyIdentifiableFeatures />}
    </>
  )
}

export default Modal
