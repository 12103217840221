import * as React from "react"
import { Link } from "gatsby"

import Layout from "../../../components/hcp-ppl-layout"
import Seo from "../../../components/seo"
import VideoTranscript from "../../../components/video-transcript"
import TakingImcivreeVideo from "../../../components/taking-imcivree-video"
import HowToInjectTranscriptPpl from "../../../components/template-partials/patient/how-to-inject-transcript-ppl"

const Sitemap = () => (
  <Layout noNav>
    <Seo title="Taking Imcivree | IMCIVREE® (setmelanotide) injection | For HCPs" description="View patient resources for IMCIVREE® (setmelanotide), including guides, training video and start form. Please see full Prescribing Information for Important Safety Information." />
    <div className="injection-video content-block">
      <div className="row og">
        <div className="columns og">
          <h1 className="h1">How to Inject IMCIVREE</h1>
        </div>
      </div>
      <TakingImcivreeVideo/>
      <VideoTranscript className="lg_mt-2 mt-2">
        <HowToInjectTranscriptPpl/>
      </VideoTranscript>
    </div>
  </Layout>
)

export default Sitemap
