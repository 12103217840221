import React from 'react'


const AmbersJourneyVideoEsTranscript = (props) => {

  return (
    <>

      <p>TEXT ON SCREEN:<br/>La Historia de Ambar y Mayra</p>

      <p>TEXT ON SCREEN:<br/>¿Qué es IMCIVREE?<br/>
      IMCIVREE es un medicamento recetado que se utiliza en adultos y niños de 6 años de edad o más con obesidad debido a:</p>

      <ul>
        <li>Las afecciones genéticas de deficiencia de proopiomelanocortina (POMC), proproteína convertasa subtilisina/kexina tipo 1 (PCSK1) o receptores de leptina (LEPR), para ayudarlos a perder peso y mantenerse así.</li>
        <li>Síndrome de Bardet-Biedl (SBB), para ayudarlos a perder peso y mantenerse así.</li>
      </ul>
      <br/>

      <p>Su proveedor de atención médica debe solicitar una prueba aprobada por la FDA para confirmar la deficiencia de POMC, PCSK1 o LEPR antes de que comience a usar IMCIVREE.</p>

      <p>IMCIVREE no se debe usar en personas con las siguientes afecciones porque es posible que no funcione:</p>

      <ul>
        <li>Obesidad debido a sospecha de deficiencia de POMC, PCSK1 o LEPR no confirmada por pruebas genéticas o con resultados benignos o probablemente benignos de las pruebas genéticas.</li>
        <li>Otros tipos de obesidad no relacionados con la deficiencia de POMC, PCSK1 o LEPR, ni con el SBB, incluida la obesidad asociada con otras afecciones genéticas y la obesidad general.</li>
      </ul>
      <br/>

      <p>Se desconoce si IMCIVREE es seguro y eficaz en niños menores de 6 años.</p>

      <p>No use IMCIVREE si ha tenido una reacción alérgica grave a este medicamento o a alguno de sus ingredientes. Pueden producirse reacciones alérgicas graves, incluida anafilaxia.</p> 

      <p>(Ambar) Yo soy Ambar, tengo 32 años, vivo en Carolina, Puerto Rico, vivo con mis padres. Tengo un título universitario, un bachillerato en turismo.</p> 

      <p>(Mayra) El diagnóstico de Ambar comenzó cuando ella tenía tres años de edad, eh que nos dimos cuenta de que habían unas situaciones con ella que no eran normales. Mi embarazo fue de ocho meses solamente porque ella pues decidió venir antes a este mundo.… en el pie izquierdo, tenía un dedito extra el cual verificaron que tenía hueso y hubo que removerlo, que amputarlo. Y luego pues eh el Dr comenzó a encontrar ciertas cosas que yo le iba diciendo como que no miraba directamente a los ojos eh, y entonces que no hablaba. Tenia 3 años y no hablaba. Cuando el pediatra noto que en efecto pues habían ciertas situaciones con ella, me refirió a diferentes especialistas, un neurólogo, un geneticista, endocrinólogo, eh patólogo de habla y ocupacional.  Y entonces cada uno de esos especialistas la evaluó en su campo y se y decidieron que ella necesitaba terapias. La visita que tuvimos con la geneticista, eh ella me preguntó que si en la familia habían personas con dedos extras, o con problemas de visión, yo le informé que no. Y entonces dada la forma de el cuerpo de Ambar, su obesidad, su facciones, ella determinó casi de inmediato que ella podía tener el síndrome de Bardet-Biedl. No se sabía, eh, con ciencia cierta. Porque para ese momento no existían, eh, evaluaciones o pruebas de sangre genéticas para poder determinarlo.</p>

      <p>TEXT ON SCREEN:<br/>El SBB se diagnostica clínicamente y las pruebas genéticas pueden ayudar a proporcionar un contexto adicional para informar un diagnóstico</p>

      <p>(Mayra) Al conocer el diagnóstico, también supimos que ella, eh, siempre iba a tener problema de obesidad. En ese momento no nos explicaron que el problema de obesidad era porque ella iba a tener siempre mucha hambre. O sea, al tener hambre y comer tanto en exceso, le causó problemas mayores de obesidad. Varios síntomas de su condición fueron bien frustrantes desde el principio. Pues esa hambre constante porque yo no sabía cuándo parar, porque yo decía ella me sigue pidiendo comida…Cuando Ambar llegó a la etapa en que alcanzaba los gabinetes, el médico me decía pues, ponle un candado o has algún tipo de trampa para que ella no pueda abrir los gabinetes y la nevera. Cuando me dieron el diagnóstico del síndrome de Bardet-Biedl, el médico me explicó que. Ambar iba a tener diferentes sintomatología durante verdad su vida, eh una de ellas pues era la obesidad,  dedos extras en en sus eh, manos o pies que ella tuvo en su pie izquierdo, eh problema perceptomotor de habla, eh, problema neurológico, eh y sobre todo la retinitis pigmentosa que podía, eh, comenzar a desarrollarse a medida que ella creciera. Nunca me mencionaron que su obesidad era a causa del exceso de hambre del hambre constante que podía estar teniendo.</p>

      <p>(Ambar) Era una obsesión a la comida.</p>

      <p>(Mayra) El problema de la hiperfagia de Ambar afectó toda la familia en el sentido de que pues hubo que controlar. Pero cuando iba a la escuela, pues ahí no había control de que ella comía. En actividades, habían que hacían en la escuela, habían sandwiches, habian eh, cositas dulces. Había pizza, eh, comida que se suponía que ella no comiera y no solamente se comía, eh la  cantidad que le tocaba, o sea, comía mucho más.</p>

      <p>(Ambar) La diferencia entre okay, cuando ya hacía dietas o ejercicios, sí, bajaba de peso pero bajaba unas libras pero a la otra semana, subía más. con IMCIVREE, bajaba las libras, pero esas libras se quedaban fuera. No, no volvía a subir. Me ayudó a detener esa obsesión de comer más de lo que tenía en el plato.</p>

      <p>(Mayra) Luego de que comenzó el tratamiento de IMCIVREE yo le servía sus porciones pequeñas y muchas veces deja, eh, parte de la comida. No se la come toda, a pesar de que es poco lo que le sirvo. La mayor parte del cambio ha sido en el área abdominal que Ambar tenía un abdomen bastante protuberante y ancho. Y eso es el cambio más drástico que ella ha tenido. Entonces, todo quien la ve cuando pasa tiempo se sorprende se maravilla, notan que su actitud cambió, y ahora se siente mucho más cómoda, más segura de sí misma en su forma de caminar en su forma, incluso de hablar. Antes de IMCIVREE, yo pensé que jamás iba a poder lograr ver a Ambar perder peso. Al ella comenzar con el medicamento y ver que ha ido bajando de peso poco a poco adecuadamente y no lo ha ganado. Yo estoy maravillada porque jamás pensé que Ambar iba a poder bajar de peso. Y ahora veo que si es posible bajar de peso para una persona con el síndrome de Bardet-Biedl. Mis esperanzas para el futuro es lograr que Ambar se realice como persona individual, que continúe teniendo buena salud y sea feliz.</p>
      
      <p>(Ambar) Mis esperanzas para el futuro es ser una persona normal realmente, seguir viviendo y enseñando a otras personas que aun teniendo un impedimento no te hace menos persona, si no que el peor impedimento es tu limitarte a ti mismo.  Me siento más confiada en mi misma, siento que no tengo que esconder quien soy.</p>

      {/* ISI espanol */}

      <p>TEXT ON SCREEN:<br/><b>Información importante de seguridad</b></p>

      <p><b>Antes de usar IMCIVREE, informe a su proveedor de atención médica sobre todas sus afecciones médicas, incluso lo siguiente:</b></p>

      <ul>
        <li>Tiene o ha tenido áreas de piel oscurecida, incluida decoloración de la piel (hiperpigmentación).</li>
        <li>Tiene o ha tenido depresión o pensamientos o conductas suicidas.</li>
        <li>Tiene problemas renales.</li>
        <li>Está embarazada o planea quedar embarazada. Perder peso durante el embarazo puede dañar a su bebé en gestación. Su proveedor de atención médica puede detener su tratamiento con IMCIVREE si queda embarazada. Informe a su proveedor de atención médica si queda embarazada o cree que podría estar embarazada durante el tratamiento con IMCIVREE.</li>
        <li>Está amamantando o planea amamantar. Se desconoce si IMCIVREE pasa a la leche materna. No debe amamantar durante el tratamiento con IMCIVREE.</li>
      </ul>
      <br/>

      <p>IMCIVREE<sup>&reg;</sup><br/>(setmelanotida) inyección</p>

      <p><b>Información importante de seguridad (continuación)</b></p>

      <p><b>Informe a su proveedor de atención médica sobre todos los medicamentos que toma,</b> incluidos los medicamentos recetados y de venta libre, vitaminas y suplementos a base de hierbas.</p>

      <p><b>Consulte las Instrucciones de uso detalladas</b> que vienen con IMCIVREE para aprender cómo preparar e inyectar IMCIVREE y cómo desechar (eliminar) correctamente las jeringas y agujas usadas.</p>

      <p><b>¿Cuáles son los posibles efectos secundarios de IMCIVREE?</b><br/>
      <b>IMCIVREE puede causar efectos secundarios graves, entre ellos:</b></p>

      <ul>
        <li><b>Problemas de la función sexual masculina y femenina.</b> IMCIVREE puede causar una erección que ocurre sin ninguna actividad sexual en hombres (erección espontánea del pene) y reacciones sexuales no deseadas (cambios en la excitación sexual que se producen sin ninguna actividad sexual) en mujeres. Si tiene una erección que dura más de 4 horas, busque ayuda médica de emergencia inmediatamente.</li>
        <li><b>Depresión y pensamientos o acciones suicidas.</b> Usted o un cuidador deben llamar a su proveedor de atención médica de inmediato si tiene algún síntoma de depresión, pensamientos o conductas suicidas, o algún cambio inusual en el estado de ánimo o la conducta que sea nuevo o que empeore.</li>
        <li><b>Reacciones alérgicas graves.</b> Deje de usar IMCIVREE y obtenga ayuda médica de inmediato si tiene algún síntoma de reacción alérgica grave, como hinchazón en la cara, los labios, la lengua o la garganta; problemas para respirar o tragar; erupción cutánea grave o picazón intensa; desmayos o sensación de mareo; latidos cardíacos acelerados.</li>
        <li><b>Aumento de la pigmentación de la piel y oscurecimiento de las lesiones cutáneas (lunares o nevos) que ya tiene.</b> Estos cambios se producen debido a la forma en que actúa IMCIVREE en el cuerpo y desaparecerán cuando deje de usar IMCIVREE. Debe realizarse un examen de piel de cuerpo entero antes de comenzar el tratamiento con IMCIVREE y durante este, para detectar cambios en la piel</li>
        <li><b>Toxicidad por alcohol bencílico.</b> El alcohol bencílico es un conservante en IMCIVREE. El alcohol bencílico puede causar efectos secundarios graves, incluida la muerte, en bebés prematuros y con bajo peso al nacer que han recibido medicamentos que contienen alcohol bencílico. IMCIVREE no se debe usar en bebés prematuros y con bajo peso al nacer.</li>
      </ul>
      <br/>

      <p><b>Los efectos secundarios más frecuentes de IMCIVREE incluyen</b> oscurecimiento de la piel, reacciones en el lugar de inyección, náuseas, dolor de cabeza, diarrea, dolor de estómago, vómitos, depresión y una erección que ocurre sin actividad sexual en hombres.</p>

      <p>Estos no son todos los posibles efectos secundarios de IMCIVREE. Llame a su médico para obtener asesoramiento médico sobre los efectos secundarios. También puede informar los efectos secundarios a la FDA al 1-800-FDA-1088 o a Rhythm Pharmaceuticals al 1-833-789-6337.</p>

      <p>IMCIVREE<sup>&reg;</sup><br/>
      (setmelanotida) inyección</p>

      <p>Text on screen:<br/>&copy; 2024, Rhythm Pharmaceuticals, Inc. Todos los derechos reservados.<br/>
      Rhythm, IMCIVREE y sus logotipos son marcas comerciales de Rhythm Pharmaceuticals, Inc.<br/>
      US-SET-2300130 02/2024</p>


    </>
  )
}

export default AmbersJourneyVideoEsTranscript
