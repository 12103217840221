import * as React from "react"

import Layout from "../../../components/hcp-ppl-layout"
import Seo from "../../../components/seo"
import Button from "../../../components/button"
import Modal from "../../../components/modal"

import ImageDoseChart from "../../../images/dosing-chart-hcp-ppl.png"
import ImageDoseBox from "../../../images/dosing-box.png"

const Dosing = () => (
  <Layout>
    <Seo
      title="Dosing & Administration | IMCIVREE® (setmelanotide) injection | For HCPs"
      description="View recommended dosing information for adult and pediatric patients taking IMCIVREE® (setmelanotide). Please see full Prescribing Information for Important Safety Information."
      ogTitle="Dosing & Administration | IMCIVREE® (setmelanotide) injection"
      ogDesc="View recommended dosing information for adult and pediatric patients taking IMCIVREE® (setmelanotide). Please see full Prescribing Information for Important Safety Information."
    />
    <div className="hcp-ppl-dosing">
      <div className="content-block md_pb-2">
        <div className="row">
          <div className="column small-12">
            <h1 className="h1">
              Once-daily, subcutaneous injection that can be administered at
              home<sup>1</sup>
            </h1>
            <h2 className="h2">
              Titrate adult and pediatric patients to the target dose to
              optimize tolerability and response
            </h2>
          </div>
        </div>

        <div className="row">
        <div className="columns small-12 large-8">
          <img
            src={ImageDoseChart}
            alt="IMCIVREE recommended dosing chart for adult and pediatric patients"
            className="w-100"
          />
        </div>
        <div className="columns small-12 large-4 text-center">
          <img
            src={ImageDoseBox}
            alt="IMCIVREE multiple-dose vial"
            className="w-sm-50 mt-2 mb-1 md_mb-1"
          />
          <br/>
          <h5 className="h5">
            10-mg/1-mL
            <br />
            multiple-dose vial
          </h5>
        </div>
      </div>

        <ul className="neon-green-bullets mb-1">
          <li>
            IMCIVREE should be administered once daily, at the beginning of the
            day, without regard to meals
            <ul>
              <li className="is-dash">
                There is no food requirement for administration
              </li>
            </ul>
          </li>
          <li className="mb-1">
            No dose adjustments are needed for patients with mild to moderate
            renal impairment
          </li>
        </ul>

        <div className="row">
          <div className="columns small-12 medium-4 text-left mb-1">
            <Modal
              id="recommended-monitoring-hcp-ppl"
              className="color-black-bg"
            />
          </div>
          <div className="columns small-12 medium-5 small-offset-0  medium-offset-1 large-offset-1 text-left text-sm-left">
            <Modal
              id="dosing-for-severe-renal-impairment-hcp-ppl"
              className="color-black-bg"
            />
          </div>
        </div>
      </div>

      {/* <div className="row og">
        <div className="columns small-12 medium-8 large-7 xlarge-6">
          <Callout fullWidth className="light-teal left text-left">
            <p>The recommended target dosage of IMCIVREE is 3&nbsp;mg</p>
          </Callout>
        </div>
      </div> */}

      <div className="content-block pt-1" style={{ paddingBottom: "unset" }}>

        <div className="text-center">
          <Button
            textCenter
            copy="Start your patient on IMCIVREE"
            url="/hcp/ppl/prescribing-imcivree/"
          />
        </div>
        <p className="small md_mt-2 mt-2 fw-normal">
          <strong>Reference: 1.</strong> IMCIVREE [prescribing information].
          Boston, MA. Rhythm Pharmaceuticals, Inc.
        </p>
      </div>
    </div>
  </Layout>
)

export default Dosing
