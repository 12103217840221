import React, { useEffect, useState } from "react"
import Helmet from "react-helmet"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { StaticQuery, graphql } from "gatsby"
import MainLogo from "../../global-components/main-logo"
import hline from "../../../../images/header-after.png"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faAngleRight,
  faEnvelope,
  faFileAlt,
} from "@fortawesome/free-solid-svg-icons"
import * as Utils from "../../../../helpers/utils"

const Header = ({ data }) => <></>
export default function MyHeader(props) {
  const [navOpen, setNavOpen] = useState(false)
  const url = typeof window !== "undefined" ? window.location.pathname : ""
  const toggleNav = e => {
    let width = document.body.clientWidth
    if (width <= 1024) {
      e.currentTarget.classList.toggle("isOpen")
      document
        .querySelector(`.main-header__mobile-menu-wrapper`)
        .classList.toggle("isOpen")
      if (navOpen) {
        setNavOpen(false)
      } else {
        setNavOpen(true)
      }
      if (e.currentTarget.classList.contains(".hasClass")) {
        e.preventDefault()
      }
    } else {
      return true
    }
  }

  const setCookie = (cname, cvalue, exdays) => {
    const d = new Date()
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
    let expires = "expires=" + d.toUTCString()
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/"
  }

  const getCookie = cname => {
    let name = cname + "="
    let decodedCookie = decodeURIComponent(document.cookie)
    let ca = decodedCookie.split(";")
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i]
      while (c.charAt(0) == " ") {
        c = c.substring(1)
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length)
      }
    }
    return ""
  }

  const initFontSize = () => {
    // initialize font-size cookie
    if (getCookie("font-size")) {
      document.querySelector(`html`).className = getCookie("font-size")
    }
  }

  const fontResize = e => {
    if (e) {
      setCookie("font-size", e, 1) //expires in 1 day
      initFontSize()
    }
  }

  const toggleActive = e => {
    e.currentTarget.classList.toggle("active")

    //remove active class from dom that's not current target
    let elems = document.querySelectorAll(".active")
    ;[].forEach.call(elems, function (el) {
      if (e.currentTarget !== el) {
        el.classList.remove("active")
      }
    })
    e.stopPropagation()
  }

  const toggleMobileDrawer = e => {
    let width = document.body.clientWidth
    e.currentTarget.parentNode.classList.add("isSelected")
    //remove active class from dom that's not current target
    let elems = document.querySelectorAll(".isSelected")
    ;[].forEach.call(elems, function (el) {
      if (e.currentTarget.parentNode !== el) {
        el.classList.remove("isSelected")
      }
    })
    e.stopPropagation()
    //console.log(e.currentTarget.parentNode)
    if (width <= 1024) {
      let parent = e.currentTarget.parentNode
      let target = e.currentTarget

      //console.log(target)
      if (target.classList.contains("hasChild")) {
        parent.classList.toggle("isOpen")
        e.preventDefault()
      } else {
        document
          .querySelector(`.main-header__mobile-menu-wrapper`)
          .classList.toggle("isOpen")
      }
    } else {
      return true
    }
  }

  ;<StaticQuery
    query={graphql`
      query SiteTitleQuery1 {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => <Header data={data} {...props} />}
  />

 const setActive = () => {
  let current = window.location.pathname;
  let elems = document.querySelectorAll("header nav ul li > a[href]");
  
  // Remove isActive class from all elements within .main-nav
  document.querySelectorAll("header nav.main-nav .isActive").forEach(el => {
    el.classList.remove("isActive");
  });

  [].forEach.call(elems, function (el) {
    // if the current path is like this link, make it active
    if (el.getAttribute("href") === current) {
      el.parentNode.classList.add("isActive");
      el.classList.add("isActive");
      let parentClass = el.getAttribute("parentclass");
      if (parentClass) {
        let parentActive = document.querySelector("." + parentClass);
        parentActive.classList.add("isActive");
      }
    }
  });
}

  const isiHandler = e => {
    e.preventDefault()
    let scrollByOffset = 0,
      buttonTarget = document.querySelector(".main-nav-toggle")
    if (buttonTarget.classList.contains("isOpen") === true) {
      toggleNav(e)
      buttonTarget.classList.remove("isOpen")
    }
    scrollByOffset =
      scrollByOffset - document.querySelector("header").offsetHeight - 32
    document.getElementById("isiContainer").scrollIntoView()
    window.scrollBy(0, scrollByOffset)
  }

  // interstitial
  const exitModal = e => {
    let modalExit = document.querySelector("#modal-exit")
    let destinationURL = e.currentTarget.href
    e.preventDefault()
    console.log(destinationURL)
    modalExit.classList.add("open")
    document.querySelector(".enter").href = destinationURL
  }

  useEffect(() => {
    //check for utms
    Utils.utmCheck()
    setActive()
    // initialize font-size cookie
    initFontSize()

    const onClick = e => {
      let elems = document.querySelectorAll(".active")
      ;[].forEach.call(elems, function (el) {
        el.classList.remove("active")
      })
    }
    document.body.addEventListener("click", onClick)
    return () => {
      document.body.removeEventListener("click", onClick)
    }
  }, [])

  return (
    <>
      {/* Use helmet to dynamically add a body class*/}
      {navOpen && <Helmet bodyAttributes={{ class: "patient-bbs nav-lock" }} />}
      {!navOpen && <Helmet bodyAttributes={{ class: "patient-bbs" }} />}
      {/* Use helmet to dynamically add a body class*/}
      <header
        className={
          props.noNav ? "main-header main-header--no-nav" : "main-header"
        }
      >
        <div className="main-header__decoration"></div>
        <div className="row mobile-only">
          <div className="columns">
            <ul className="mobile-only-tertiary">
              <li className="text-center">
                <a
                  href="https://rhythm-vault-digital-publishing-production.s3.amazonaws.com/IMCIVREEPrescribingInformation.pdf"
                  target="_blank"
                >
                  Prescribing
                  <br />
                  Information
                </a>
              </li>
              <li className="text-center">
                <a onClick={isiHandler}>
                  Important Safety
                  <br />
                  Information
                </a>
              </li>
              <li className="text-center">
                <Link to="/hcp/bbs/" onClick={e => exitModal(e)}>
                  Visit
                  <br />
                  HCP Site
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="row row--inner main-header__mobile-tray">
          <div className="columns large-2">
            <MainLogo to="/bbs/" location="header" />
            <button
              className="main-nav-toggle hide-for-large"
              onClick={e => toggleNav(e)}
            >
              <span></span>
              <span></span>
              <span></span>
              <span className="visually-hidden">toggle mobile menu</span>
            </button>
          </div>
          <div className="columns large-10">
            <div className="utility-nav-wrapper">
              <div className="tertiary-nav desktop-only">
                <ul className="nav-items">
                  <li className="">
                    <div className="site-label">
                      Site intended for US residents only.
                    </div>
                  </li>
                  <li className="slanted">
                    <a
                      href="https://rhythm-vault-digital-publishing-production.s3.amazonaws.com/IMCIVREEPatientPrescribingInformation.pdf"
                      target="_blank"
                    >
                      Patient Information
                    </a>
                  </li>
                  <li className="slanted">
                    <a
                      href="https://rhythm-vault-digital-publishing-production.s3.amazonaws.com/IMCIVREEPrescribingInformation.pdf"
                      target="_blank"
                    >
                      Prescribing Information
                    </a>
                  </li>
                  <li className="slanted">
                    <a onClick={isiHandler}>Important Safety Information</a>
                  </li>
                  <li className="slanted">
                    <Link
                      to={props.ppl ? "/hcp/ppl/" : "/hcp/bbs/"}
                      onClick={e => exitModal(e)}
                    >
                      Visit HCP Site
                    </Link>
                  </li>
                  <li
                    className="other-indications patient"
                    onClick={e => toggleActive(e)}
                  >
                    Other Indications
                    <ul className="nav-sub-items patient">
                      <li>
                        <Link to="/ppl/">POMC, PCSK1, and LEPR deficiency</Link>
                      </li>
                      <li>
                        <Link to="/bbs/">Bardet-Biedl syndrome</Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
              <div className="secondary-nav desktop-only">
                <ul className="nav-items">
                  <li>
                    <a href="/Patient_Consent_Form.pdf" target="_blank">
                      <FontAwesomeIcon icon={faFileAlt} /> Fill out Consent Form{" "}
                      <FontAwesomeIcon className="caret" icon={faAngleRight} />
                    </a>
                  </li>
                  <li>
                    <Link to="/bbs/sign-up/">
                      <FontAwesomeIcon icon={faEnvelope} /> Connect with a
                      Patient Education Manager{" "}
                      <FontAwesomeIcon className="caret" icon={faAngleRight} />
                    </Link>
                  </li>
                  <li className="font-size">
                    <span className="small" onClick={e => fontResize("gsmall")}>
                      A
                    </span>{" "}
                    <span className="pipe">|</span>{" "}
                    <span
                      className="normal"
                      onClick={e => fontResize("gmedium")}
                    >
                      A
                    </span>{" "}
                    <span className="pipe">|</span>{" "}
                    <span className="large" onClick={e => fontResize("glarge")}>
                      A
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="row row--inner main-header__mobile-menu-wrapper">
          <div className="columns main-column">
            <nav className="main-nav bbs_pat_nav">
              <ul className="main-nav__menu">
                <li>
                  <Link
                    className="no-child nav_patient"
                    to={props.ppl ? "/ppl/disease-overview/" : "/bbs/overview/"}
                    onClick={e => toggleMobileDrawer(e)}
                  >
                    <span>
                      {props.ppl ? "Diseases Overview" : "BBS Overview"}
                    </span>
                  </Link>
                </li>
                <li className="about">
                  {/*fix for url problem */}
                  <Link
                    to="/bbs/how-imcivree-works/"
                    className={"hasChild nav_patient"}
                    onClick={e => toggleMobileDrawer(e)}
                  >
                    <span>About IMCIVREE</span>
                  </Link>
                  <div>
                    <div className="main-nav__mega-inner">
                      <div className="row align-middle align-justity">
                        <div className="columns">
                          <ul className="main-nav__child-menu">
                            <li>
                              <Link
                                parentclass="about"
                                to="/bbs/how-imcivree-works/"
                              >
                                How does IMCIVREE work?
                              </Link>
                            </li>
                            <li>
                              <Link parentclass="about" to="/bbs/studied/">
                                IMCIVREE study results
                              </Link>
                            </li>
                            <li>
                              <Link
                                parentclass="about"
                                to="/bbs/effect-of-imcivree/"
                              >
                                How did IMCIVREE help people?
                              </Link>
                            </li>

                            <li>
                              <Link parentclass="about" to="/bbs/side-effects/">
                                Side effects
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="starting">
                  <Link
                    to="/bbs/how-imcivree-given/"
                    className={"hasChild nav_patient"}
                    onClick={e => toggleMobileDrawer(e)}
                  >
                    <span>Starting IMCIVREE</span>
                  </Link>
                  <div>
                    <div className="main-nav__mega-inner">
                      <div className="row align-middle align-justity">
                        <div className="columns">
                          <div className="row">
                            <div className="columns small-12 large-expand">
                              <ul className="main-nav__child-menu">
                                <li>
                                  <Link
                                    to="/bbs/how-imcivree-given/"
                                    parentclass="starting"
                                  >
                                    How is IMCIVREE given?
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/bbs/getting-started/"
                                    parentclass="starting"
                                  >
                                    Getting started on IMCIVREE
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/bbs/understanding-your-dose/"
                                    parentclass="starting"
                                  >
                                    Understanding your dose
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="support">
                  {/*fix for url problem */}
                  <Link
                    to="/bbs/support-resources/"
                    className={"hasChild nav_patient"}
                    onClick={e => toggleMobileDrawer(e)}
                  >
                    <span>Support & Resources</span>
                  </Link>
                  <div>
                    <div className="main-nav__mega-inner">
                      <div className="row align-middle align-justity">
                        <div className="columns">
                          <div className="row">
                            <div className="columns small-12 large-expand">
                              <ul className="main-nav__child-menu">
                                <li>
                                  <Link
                                    parentclass="support"
                                    to="/bbs/support-resources/"
                                  >
                                    Rhythm InTune
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    parentclass="support"
                                    to="/bbs/patient-access/"
                                  >
                                    Patient access
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    parentclass="support"
                                    to="/bbs/programs/"
                                  >
                                    IMCIVREE programs
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    parentclass="support"
                                    to="/bbs/resources/"
                                  >
                                    Helpful resources
                                  </Link>
                                </li>
                                <li>
                                  <Link parentclass="support" to="/bbs/faq/">
                                    Frequently asked questions
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
              <div className="mobile-only-secondary-utility-nav">
                <ul className="finess lead-finess">
                  <li>
                    Other Indications
                    <ul className="nav-sub-items">
                      <li>
                        <Link to="/ppl/">POMC, PCSK1, and LEPR deficiency</Link>
                      </li>
                      <li>
                        <Link to="/bbs/">Bardet-Biedl syndrome</Link>
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul className="finess">
                  <li>
                    <a
                      href="/Patient_Consent_Form.pdf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FontAwesomeIcon icon={faFileAlt} /> Fill out Consent Form{" "}
                      <FontAwesomeIcon className="caret" icon={faAngleRight} />
                    </a>
                  </li>
                  <li>
                    <Link to="/bbs/sign-up/">
                      <FontAwesomeIcon icon={faEnvelope} /> Connect with a
                      Patient Education Manager{" "}
                      <FontAwesomeIcon className="caret" icon={faAngleRight} />
                    </Link>
                  </li>
                </ul>
                <ul>
                  <li>
                    <a
                      href="https://rhythm-vault-digital-publishing-production.s3.amazonaws.com/IMCIVREEPatientPrescribingInformation.pdf"
                      target="_blank"
                    >
                      Patient Information
                    </a>
                  </li>
                  {/*
                    <li><a href="https://rhythm-vault-digital-publishing-production.s3.amazonaws.com/IMCIVREEPrescribingInformation.pdf" target="_blank">Prescribing Information</a></li>
                    <li><a onClick={isiHandler}>Important Safety Information</a></li>
                    <li><Link to="/hcp/bbs/">Visit HCP</Link></li>
                    */}
                </ul>
              </div>
            </nav>
          </div>
        </div>
        <div
          className="header-after"
          style={{ backgroundImage: `url(${hline})` }}
        ></div>
      </header>
    </>
  )
}
Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}
