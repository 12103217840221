import React from "react"

import Layout from "../../../components/hcp-bbs-layout"
import Seo from "../../../components/seo"
import Button from "../../../components/button"
import Callout from "../../../components/callout"
import CalloutCircular from "../../../components/callout-circular"
import Tabs from "../../../components/template-partials/global-components/tabs/tabs"

import "../../../components/scss-pages/hcp/bbs/_study_design.scss"

import OverallBmiReductionImage from "../../../images/image-overall-bmi-reduction-family.png"
import ImageStudyDesignChart from "../../../images/study_design_screening_with_n.png"

const StudyDesign = () => {
  return (
    <Layout>
      <Seo
        title="Study Design | IMCIVREE® (setmelanotide) injection | For HCPs"
        description="IMCIVREE® (setmelanotide) was studied in a phase 3 clinical trial of patients with BBS. Please see full Prescribing Information for Important Safety Information."
        ogTitle="Study Design | IMCIVREE® (setmelanotide) injection"
        ogDesc="See how IMCIVREE® (setmelanotide) was studied in patients. Please see full Prescribing Information for Important Safety Information."
      />
      <div className="content-block" id="study-design">
        <h1 className="h1 lg_mt-1">
          IMCIVREE was studied in the first-ever phase 3 clinical trial
          dedicated to obesity and hunger reduction in patients with BBS
          <sup>1,2</sup>
        </h1>

        <div className="row">
          <div className="columns">
            <Tabs className="--hcp">
              <Tabs.Pane name="STUDY DESIGN" key="1">
                <h2 className="h2">
                  The efficacy and safety of IMCIVREE for the reduction of
                  weight and hunger in patients with BBS were studied in a phase
                  3 trial with a randomized, double-blind, placebo-controlled
                  period<sup>3,4</sup>
                </h2>
                <p className="text-center hide-for-medium">
                  &larr; Swipe left or right to view &rarr;
                </p>
                <div className="text-center chart-overflow-x">
                  <img
                    src={ImageStudyDesignChart}
                    alt="IMCIVREE study design"
                    className="w-100"
                  />
                </div>
                <div>&nbsp;</div>
                <div className="row" style={{ gap: `20px` }}>
                  <div
                    className="columns small-12 medium-3 color-teal align-self-middle"
                    style={{ maxWidth: `135px` }}
                  >
                    <strong style={{ fontSize: "350%", lineHeight: `1` }}>
                      50%
                    </strong>
                  </div>
                  <div className="columns small-12 medium-8 large-9 align-self-middle fw-bold">
                    of enrolled patients were children or adolescents who would
                    normally be expected to gain weight as they grow
                    <sup>3,5</sup>
                  </div>
                </div>
                <div>&nbsp;</div>
                <div className="row">
                  <div className="column small-12 mb-1 md_mb-1">
                    <h1 className="h1">
                      Other parameters<sup>2</sup>
                    </h1>
                    <p>
                      Supportive of the effect of IMCIVREE on weight loss, there
                      were general numeric improvements in blood pressure,
                      lipids, and waist circumference. However, because of the
                      limited number of patients studied and the lack of control
                      group, the treatment effects on these parameters could not
                      be accurately quantified.<sup>3</sup>
                    </p>
                  </div>
                </div>
                <div>
                  <p className="footnote">
                    The study enrolled patients &ge;6 years of age with obesity
                    and a clinical diagnosis of BBS. Adult patients had a BMI of
                    &ge;30 kg/m<sup>2</sup> and pediatric patients had weight in
                    the &ge;97th percentile using growth chart assessments. To
                    maintain the blind during period 1 (14-week
                    placebo-controlled period), dose titration to a fixed dose
                    of 3 mg given subcutaneously once daily was performed during
                    the first 2 weeks of both period 1 and period 2 (52-week
                    open-label period). Efficacy analyses were conducted in 44
                    patients at the end of period 1 (week 14, placebo-controlled
                    data) and in 31 patients during the active-treatment period,
                    defined as the period from randomization to week 52 in
                    patients initially randomized to IMCIVREE, and from week 14
                    to week 66 in patients initially randomized to placebo.
                    Analyses of the active-treatment period include patients who
                    had either completed 52 weeks from the start of IMCIVREE
                    treatment or discontinued the study early at the time of the
                    prespecified data cutoff.<sup>3</sup>
                  </p>
                </div>
              </Tabs.Pane>
              <Tabs.Pane name="OVERALL BMI REDUCTION" key="2">
                <div style={{ position: "relative" }}>
                  <div className="mb-1">
                    <div className="row">
                      <div className="columns small-12">
                        <h2 className="h2 h2--no-margin-bottom">
                          In patients &ge;6 years of age with&nbsp;BBS
                        </h2>
                        <h2 className="h1 mt-0 md_mt-0">
                          IMCIVREE delivered BMI reductions<sup>3</sup>
                        </h2>
                      </div>
                      <div className="column small-12">
                        <div id="overall-bmi-reduction-content-column-wrapper">
                          <div className="columns small-12 large-6 tab-column position-relative lg_pt-2">
                            <Callout
                              fullWidth
                              className="light-teal left text-left"
                            >
                              <div className="row">
                                <div className="column small-6">
                                  <CalloutCircular className="light-teal">
                                    <p>
                                      <span>~8%</span>
                                    </p>
                                  </CalloutCircular>
                                </div>
                                <div className="column small-6 align-self-middle">
                                  <p className="ml-2 md_ml-0 lg_ml-1">
                                    mean reduction&nbsp;in BMI&nbsp;after
                                    1&nbsp;year<sup>3</sup>
                                  </p>
                                </div>
                              </div>
                            </Callout>

                            <div>&nbsp;</div>
                            <div>&nbsp;</div>

                            <Callout
                              fullWidth
                              className="light-teal left"
                              style={{ paddingLeft: `15%` }}
                            >
                              <p>
                                Patients were not required to change their diet
                                or exercise routine<sup>2</sup>
                              </p>
                            </Callout>
                          </div>
                          <div
                            className="columns small-12 large-6 tab-column"
                            style={{ position: `relative` }}
                          >
                            <div className="img-wrapper">
                              <img
                                src={OverallBmiReductionImage}
                                alt="Actor portrayals"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Tabs.Pane>
            </Tabs>
          </div>
        </div>
        <div>&nbsp;</div>
        <div>&nbsp;</div>

        <div className="row">
          <div className="column small-12 large-5 large-offset-1 text-center mb-2">
            <Button
              copy="Discover the efficacy of IMCIVREE in children"
              url="/hcp/bbs/pediatric-bmi-z-score-reduction/"
            />
          </div>
          <div className="column small-12 large-5 text-center">
            <Button
              copy="Discover the efficacy of IMCIVREE in adults"
              url="/hcp/bbs/adult-weight-reduction/"
            />
          </div>
        </div>

        <div>&nbsp;</div>
        <div className="references footnote md_mt-1 mt-1">
          <strong>References: 1.</strong> Haqq AM et al.{" "}
          <em>Lancet Diabetes Endocrinol.</em> 2022;10(12):859-868.
          doi:10.1016/S2213-8587(22)00277-7. Supplemental appendix available at:
          https://www.thelancet.com/journals/landia/article/PIIS2213-8587(22)00277-7/fulltext.{" "}
          <strong>2.</strong> Data on file. Rhythm Pharmaceuticals, Inc. Boston,
          MA. <strong>3.</strong> IMCIVREE [prescribing information]. Boston,
          MA. Rhythm Pharmaceuticals, Inc. <strong>4.</strong> Haws RM et al.{" "}
          <em>Contemp Clin Trials Commun.</em> 2021;22:100780.{" "}
          <strong>5.</strong> Centers for Disease Control and Prevention. 2000
          CDC Growth Charts for the United States: Methods and Development.
          Accessed August 11, 2023.
          https://www.cdc.gov/nchs/data/series/sr_11/sr11_246.pdf.
        </div>
      </div>
    </Layout>
  )
}

export default StudyDesign
