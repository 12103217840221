import React, { useState, useEffect } from "react"
import Cookies from 'js-cookie';

const CookieBanner = () => {

  const [cookieConsent, setCookieConsent] = useState(false);
  const cookieName = "acceptCookiesImcivree";

  const acceptCookies = (e) => {
    Cookies.set(cookieName, true, { expires: 1 });
    setCookieConsent(true);
    e.preventDefault();
  }

  useEffect(()=>{
    let cb = document.getElementById('cookie-banner');
    setTimeout(function(){
      if(Cookies.get(cookieName)){
        setCookieConsent(true);
        if(cb){
          cb.classList.remove('isVisible')
        }
      }else{
        setCookieConsent(false);
        if(cb){
          cb.classList.add('isVisible')
        }
      }
    },100);
  });

  return (
    <>
      {!cookieConsent &&
        <div id="cookie-banner">
          <div className="row og align-middle align-justify">
            <div className="column og small-12 medium-9">
              <p className="text-center medium-text-left mb-0"><strong>We use cookies to give you the best online experience and content. By using our website, you agree to our use of cookies in accordance with our cookie policy.</strong></p>
            </div>
            <div className="column small-12 medium-expand og text-center mt-1 large-text-right">
              <button onClick={(e)=>acceptCookies(e)}>Accept Cookies</button>
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default CookieBanner
