import * as React from "react"

import Layout from "../../components/bbs-layout"
import Seo from "../../components/seo"
import Button from "../../components/button"

import silos from "../../images/silos.png"
import eightpercent from "../../images/eightpercent.png"

const Studied = () => (
  <Layout>
    <Seo
      title="IMCIVREE study results' | IMCIVREE® (setmelanotide) injection"
      description="IMCIVREE® (setmelanotide) was studied in both children 6 years of age and older and adults in a year-long clinical trial. Please see full Prescribing Information and Important Safety Information."
      ogTitle="IMCIVREE study results' | IMCIVREE® (setmelanotide) injection"
      ogDesc="IMCIVREE® (setmelanotide) was studied in both children 6 years of age and older and adults in a year-long clinical trial. Please see full Prescribing Information and Important Safety Information."
    />
    <div className="content-block">
      <h1 className="h1">IMCIVREE study results</h1>
      <h2 className="h2--no-margin-bottom">IMCIVREE was studied in both children and adults in a year-long clinical trial</h2>

      <p className="mt-1 md_mt-1">The clinical trial was the largest ever to assess weight and hunger reduction in people living with BBS.</p>

      <div className="row">
        <div className="column small-12 medium-10">
          <ul className="neon-green-bullets mb-2 md_mb-2">
            <li className="mb-1 md_mb-1">31 people were evaluated in the clinical trial
              <ul>
                <li className="is-dash">All people were 6 years of age and older with obesity and a clinical diagnosis of BBS</li>
              </ul>
            </li>
            <li>
              Children and adults included in the clinical trial had to have a certain weight or body mass index (BMI). BMI is a measure of body fat based on height and weight
              <ul>
                <li className="is-dash">Children had to have a weight in the 97th percentile or higher</li>
                <li className="is-dash">Adults had to have a BMI of 30 or more</li>
              </ul>
            </li>
          </ul>
          <div className="column text-center small-12 show hide-for-medium">
            <img src={silos} alt="IMCIVREE clinical trial - children and adults" />
          </div>
          <p className="md_mb-2"></p>
          <h2 className="h2--no-margin-bottom">IMCIVREE reduced BMI over the course of the 1-year clinical trial</h2>
          <p><img src={eightpercent} alt="" /></p>

         
          <h2 className="h2--no-margin-bottom">People taking IMCIVREE had general improvements in other areas</h2>
          <p className="mt-1 md_mt-1">Blood pressure, lipids, and waist size improved with IMCIVREE. Due to a limited number of people studied and the lack of a control group in the study, these treatment effects could not be accurately measured.</p>
        </div>
      
      </div>
      <div className="text-center mt-3 md_mt-2">
        <Button textCenter={false} large copy={<>See how IMCIVREE helped reduce <br className="show-for-large"/> measures of weight and hunger</>} url="/bbs/effect-of-imcivree/" />
      </div>
    </div>
  </Layout>
)

export default Studied
