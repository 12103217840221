import React from 'react'
import vpl from "../images/video-placeholder.jpg";

const TakingImcivreeVideo = (props) => {
  return (
    <>
    <div className="row og">
      <div className={`columns og responsive-video-container ` + props.className}>
        <iframe src="https://player.vimeo.com/video/882346831?h=0f822ff24b" width="100%" height="auto" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen title="IMCIVREE Injection Training Video"></iframe>
        <img src={vpl} width="100%" height="auto" alt="How to Inject IMCIVREE video placeholder"/>
      </div>
    </div>
    </>
  )
}

export default TakingImcivreeVideo
