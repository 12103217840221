import React from 'react'


const HowToInjectTranscript = (props) => {

  return (
    <>

<p>Hi, I'm Brittany, and today I'll be providing you with step-by-step instructions for injecting IMCIVREE (setmelanotide). You can also find the steps in the "How to Give IMCIVREE" brochure that came with your first IMCIVREE shipment and online at IMCIVREE.com.</p>

<p>What is IMCIVREE? IMCIVREE is a prescription medicine used in adults and children six years of age and older with obesity due to Bardet-Biedl syndrome (BBS) to help them lose weight and keep the weight off. IMCIVREE is not for use in people with the following conditions because it may not work:</p>

<ul>
  <li>Other types of obesity not related to BBS or other FDA-approved uses of IMCIVREE, including obesity associated with other genetic conditions and general obesity.</li>
</ul>

<p>It is not known if IMCIVREE is safe and effective in children under six years of age.</p>

<p><strong>Important Safety Information:</strong> Do not use IMCIVREE if you have had a serious allergic reaction to it or any of its ingredients. Serious allergic reactions, including anaphylaxis, can happen. Please see Important Safety Information continued and full Prescribing Information.</p>

<p>Please also refer to the training you receive by your doctor or pharmacy and discuss any questions you may have with them.</p>

<h2>Step One: Gather Your Supplies</h2>

<p>When you are ready to inject, take IMCIVREE out of the refrigerator and let the vial warm up to room temperature. This usually takes about 15 minutes, but you can also warm the vial by rolling it gently between your hands for about one minute. Don't use the microwave or hot water, and be careful not to shake the vial.</p>

<p>Set all your supplies out on a clean, flat surface. You will need your IMCIVREE vial, a syringe and needle provided by your doctor or pharmacist, two alcohol wipes, a gauze pad, and a sharps disposal container.</p>

<p>The liquid should be clear or nearly clear, but it may have a slight yellow color. Don't use it if: the expiration date has passed, the liquid is cloudy, there are particles floating in the vial, or the plastic cap on a new vial is broken or missing.</p>


<p>Only use the syringes with needles that your doctor or pharmacist provided and never reuse needles or share them with other people.</p>

<h2>Step Two: Fill Your Syringe with Medicine</h2>

<p>After thoroughly washing your hands with soap and warm water, you are ready to begin. Remove the plastic cap from the vial and throw it away. Do not put the plastic cap back on the vial. Clean the top of the gray rubber stopper with one of the alcohol wipes. Then throw the wipe away.</p>

<p>Hold the syringe in one hand, keeping the protective needle cap on. Pull the plunger back to fill the syringe with air equal to the amount of medicine you need. Then pull the protective cap straight off the needle away from your body. Throw the cap away.</p>

<p>Position the syringe directly over the vial and insert the needle straight down into the center of the stopper. Push the plunger down to transfer all the air from the syringe into the vial. Carefully turn the vial upside down, keeping the needle inside. Make sure the tip of the needle is always fully covered by the medicine. Slowly pull the plunger back to fill the syringe with the amount of IMCIVREE prescribed by your doctor.</p>

<p>If your vial doesn't have enough medicine for your dose, discard it and open a new one. Don't use medicine from two different vials to make one dose.</p>

<p>Keeping the needle in the vial, check the syringe for air bubbles. If you see any larger air bubbles, gently tap the side of the syringe with your fingers until the bubbles rise to the top. Then move the tip of the needle above the medicine and slowly push the plunger up to push the air back into the vial. After the large air bubbles are gone, bring the needle back down so that the medicine covers it, and slowly pull the plunger back again to fill the syringe with your prescribed dose.</p>

<p>Now, pull the needle straight out of the vial. Gently set the syringe down on its side, being careful not to let the needle touch the work surface.</p>

<h2>Step Three: Choose Your Injection Site</h2>

<p>Once you have filled the syringe with your prescribed dose, it's time to prepare your injection site. This can be the front of a thigh or a spot on your belly at least two inches away from your belly button. You can also use the back of an arm, although you may need someone to help you inject there. Avoid any bones, scars or moles, and never inject into an area that's red, swollen, or irritated.</p>

<p>Use a different spot each day, at least one inch away from the last one you use. It's a good idea to rotate injection sites so that you inject into your belly one day, a thigh the next day, and so on.</p>

<h2>Step Four: Inject the Medicine</h2>

<p>Now it's time to clean the injection site. For demonstration purposes, we will be using an injection dome. To clean the injection site, gently rub the area with your second alcohol wipe. Wait about 10 seconds for the skin to dry. Don't touch, fan, or blow on it.</p>

<p>Now, pinch about two inches of skin at the injection site between your thumb and your forefinger. With your other hand, hold the syringe like a pencil and push the needle straight into your pinched skin with a quick darting motion. Make sure the needle goes all the way into the skin. Then slowly push the plunger down to inject the medicine. To make sure you're not injecting too quickly, you can count to five while pushing the plunger.</p>

<p>Now, let go of your pinched skin and pull the needle out at the same time. Throw the needle away in your sharps container. Finally, you can use your gauze pad to apply gentle pressure to the injection site. That's it. The injection is complete.</p>

<h2>Step Five: Dispose of Your Supplies and Store Your Medicine</h2>

<p>The last step is to store your medicine for the next day. Once you've opened a vial, do not put the plastic cap back on. Place the vial back in its carton and store it in the refrigerator. For full instructions on storing your medicine see the injection training guide that came with your first shipment.</p>

<p>Always discard used needles in your sharps container. Never throw them in the trash. For full information about safe disposal of used needles, consult safeneedledisposal.org.</p>

<p>A new routine can take a little while to get used to, but you can refer to this video or the injection training guide anytime. As always, discuss any questions with your physician. Rhythm Pharmaceuticals, and your Rhythm InTune Patient Education Manager are also here to support you.</p>


      <p><b>Text on screen:</b><br/>Please see full Prescribing Information and Patient Information, including how to inject IMCIVREE, at:</p>

      <p>IMCIVREE.com<br/> 1-855-206-0815</p>

      {/* ISI */}

      <p><b>What is IMCIVREE?</b><br/>
      IMCIVREE is a prescription medicine used in adults and children 6 years of age and older with obesity due to Bardet-Biedl syndrome (BBS) to help them lose weight and keep the weight off.</p>

      <p>IMCIVREE is not for use in people with the following conditions because it may not work:</p>

      <ul>
        <li>Other types of obesity not related to BBS or other FDA-approved uses of IMCIVREE, including obesity associated with other genetic conditions and general obesity</li>
      </ul>
      <br/>

      <p>It is not known if IMCIVREE is safe and effective in children under 6 years of age.</p>

      <p><b>Do not use IMCIVREE if you have had a serious allergic reaction to it or any of its ingredients. Serious allergic reactions, including anaphylaxis, can happen.</b></p>

      <p><b>Important Safety Information</b></p>

      <p><b>Before you use IMCIVREE, tell your healthcare provider about all your medical conditions, including if you:</b></p>

      <ul>
        <li>Have or have had areas of darkened skin, including skin discoloration (hyperpigmentation)</li>
        <li>Have or have had depression, or suicidal thoughts or behavior</li>
        <li>Have kidney problems</li>
        <li>Are pregnant or planning to become pregnant. Losing weight while pregnant may harm your unborn baby. Your healthcare provider may stop your treatment with IMCIVREE if you become pregnant. Tell your healthcare provider if you become pregnant or think you might be pregnant during treatment with IMCIVREE</li>
        <li>Are breastfeeding or plan to breastfeed. It is not known if IMCIVREE passes into your breast milk. You should not breastfeed during treatment with IMCIVREE</li>
      </ul>
      <br/>

      <p><b>Tell your healthcare provider about all the medicines you take,</b> including prescription and over-the-counter medicines, vitamins, and herbal supplements.</p>

      <p><b>See the detailed Instructions for Use</b> that come with your IMCIVREE to learn how to prepare and inject IMCIVREE, and how to properly throw away (dispose of) used syringes and needles.</p>

      <p><b>What are the possible side effects of IMCIVREE?</b><br/>
      <b>IMCIVREE may cause serious side effects, including:</b></p>

      <ul>
        <li><b>Male and female sexual function problems.</b> IMCIVREE can cause an erection that happens without any sexual activity in males (spontaneous penile erection) and unwanted sexual reactions (changes in sexual arousal that happen without any sexual activity) in females. If you have an erection lasting longer than 4 hours, get emergency medical help right away</li>
        <li><b>Depression and suicidal thoughts or actions.</b> You or a caregiver should call your healthcare provider right away if you have any new or worsening symptoms of depression, suicidal thoughts or behaviors, or any unusual changes in mood or behavior</li>
        <li><b>Serious allergic reactions.</b> Stop taking IMCIVREE and get medical help right away if you have any symptoms of a serious allergic reaction including: swelling of your face, lips, tongue, or throat; problems breathing or swallowing; severe rash or itching; fainting or feeling dizzy; rapid heartbeat</li>
        <li><b>Increased skin pigmentation and darkening of skin lesions (moles or nevi) you already have.</b> These changes happen because of how IMCIVREE works in the body and will go away when you stop using IMCIVREE. You should have a full body skin exam before starting and during treatment with IMCIVREE to check for skin changes</li>
        <li><b>Benzyl alcohol toxicity.</b> Benzyl alcohol is a preservative in IMCIVREE. Benzyl alcohol can cause serious side effects, including death, in premature and low-birth weight infants who have received medicines that contain benzyl alcohol. IMCIVREE should not be used in premature and low-birth weight infants</li>
      </ul>
      <br/>

      <p><b>The most common side effects of IMCIVREE include</b> darkening of the skin, injection site reactions, nausea, headache, diarrhea, stomach pain, vomiting, depression, and an erection that happens without any sexual activity in males.</p>

      <p>These are not all the possible side effects of IMCIVREE. Call your doctor for medical advice about side effects. You may report side effects to FDA at 1-800-FDA-1088 or to Rhythm Pharmaceuticals at 1-833-789-6337.</p>

      <p><b>Please see the full Prescribing Information at IMCIVREE.com.</b></p>

      <p><b>Text on screen:</b><br/>&copy; 2024, Rhythm Pharmaceuticals, Inc. All rights reserved.<br/>
      Rhythm, IMCIVREE, and their logos are trademarks of Rhythm Pharmaceuticals, Inc.<br/>US-SET-2000028 03/2024</p>

    </>
  )
}

export default HowToInjectTranscript
