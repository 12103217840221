import React from 'react'


const LearnMoreAboutPemTranscript = (props) => {

  return (
    <>
      <p>Rhythm InTune support has been really instrumental in lots of different areas. They have definitely helped us navigate insurance approvals. </p>

      <p>They've also helped with lots of education. There's been some online in-services that they've provided that have been very helpful. And then just having a resource available for questions any time that something should come up.</p>

      <p>The partnership with a patient education manager and Rhythm in the care of Bardet-Biedl syndrome patients is crucial. I'm incredibly thankful, as are the patients.</p>

      <p>The Rhythm team has been very helpful to me and my patients and their caregivers in education, in checking on patients, educating them about how to give the injections, and helping them to understand how the medication works.</p>

      <p>And there's a lot of encouragement, and a lot of support. All of my patients have been very happy and very complimentary of how much support that they're getting.</p>

      <p>Rhythm offered their connection to their patient support program. And the patient care specialist has been instrumental to us navigating the process of getting Reed the medication. She took time to meet with our pediatrician and educate her and her staff and answer any questions.</p>

      <p>She was helpful with holding our hand through the entire process of getting approved for the medication, getting started with the injections and following along, since then. She has been wonderful.</p>

      <p>My experience with the patient education manager and Rhythm has been exceptional. I think it is great to have a partnership that can help support patients and families as they traverse using a new agent, understanding how to get coverage, and also having materials and education, both on a really practical level for how to use the medication, store it, and then also how to monitor and evaluate for side effects in collaboration with the prescribing physician.</p>

      <p>It feels great to have the support. They're so caring and willing to be by your side. It's just been great.</p>

      <p>My patient education manager has really made a big difference for me, by helping me navigate that prior authorization process. She'll send me updates on where we are in the process of the prior authorization, whether they need an appeal, what we're waiting on, if insurance company needs something else, if the pharmacy is missing something.</p>

      <p>Having a resource to help as our clinic was learning how to obtain this medication, what was required for the insurance approval, was very helpful.</p>

      <p>Then having that same resource, once the patient and family receive the medication and are working on starting it and looking at what it looks like to have that agent in their home and continue it successfully, was great to have an additional resource.</p> 


    </>
  )
}

export default LearnMoreAboutPemTranscript
