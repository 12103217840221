import * as React from "react"

import Layout from "../../components/bbs-layout"
import Seo from "../../components/seo"

import TakingImcivreeVideoEs from "../../components/taking-imcivree-video-es"
import VideoTranscript from "../../components/video-transcript"
import HowToInjectTranscriptEs from "../../components/template-partials/patient/how-to-inject-transcript-es"


const HowToTakeImcivreeEs = () => (
  <Layout noNav>

    <Seo title="Como inyectar IMCIVREE | IMCIVREE® (setmelanotide) injection" />

    <div className="content-block">

      <div className="row og">
        <div className="columns og">
          <h1 className="h1">Como inyectar IMCIVREE</h1>
          <h2 className="h2">See a demonstration on how to inject IMCIVREE at home</h2>
        </div>
      </div>

      <TakingImcivreeVideoEs />
        <VideoTranscript>
          <HowToInjectTranscriptEs />
        </VideoTranscript>

    </div>

  </Layout>
)

export default HowToTakeImcivreeEs