import React from "react"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"

const HallmarkClinicalFeatures = ({ alt }) => {
  const { mobileImage, desktopImage } = useStaticQuery(
    graphql`
      query {
        mobileImage: file(
          relativePath: { eq: "hallmark-clinical-features-mobile.png" }
        ) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        desktopImage: file(
          relativePath: { eq: "hallmark-clinical-features-desktop-new.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 800, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  // Set ImageData.
  const sources = [
    mobileImage.childImageSharp.fluid,
    {
      ...desktopImage.childImageSharp.fluid,
      media: `(min-width: 641px)`,
    },
  ]

  return (
    <>
      <Img height="auto" fluid={sources} alt={alt} />
    </>
  )
}

export default HallmarkClinicalFeatures

HallmarkClinicalFeatures.defaultProps = {
  alt: "This is where you have alt text @todo add me",
}

HallmarkClinicalFeatures.propTypes = {
  alt: PropTypes.string.isRequired,
}
