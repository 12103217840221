import * as React from "react"

import Layout from "../../components/bbs-layout"
import Seo from "../../components/seo"

import VideoTranscript from "../../components/video-transcript"
import TakingImcivreeVideo from "../../components/taking-imcivree-video"
import HowToInjectTranscript from "../../components/template-partials/patient/how-to-inject-transcript"


const HowToTakeImcivree = () => (
  <Layout noNav>

    <Seo title="How to take IMCIVREE | IMCIVREE® (setmelanotide) injection" />

    <div className="content-block">

      <div className="row og">
        <div className="columns og">
          <h1 className="h1">How to take IMCIVREE</h1>
          <h2 className="h2">See a demonstration on how to inject IMCIVREE at home</h2>
        </div>
      </div>

      <TakingImcivreeVideo />
        <VideoTranscript>
          <HowToInjectTranscript />
        </VideoTranscript>

    </div>

  </Layout>
)

export default HowToTakeImcivree