import * as React from "react"

import Layout from "../../components/bbs-layout"
import Seo from "../../components/seo"

import HangingRef from "../../components/hanging-ref"
import Button from "../../components/button"

import QualityOfLifeVideo from "../../components/quality-of-life-video"
import VideoTranscript02 from "../../components/video-transcript"
import QualityOfLifeTranscript from "../../components/template-partials/patient/quality-of-life-transcript"


const QualityOfLife = () => (
  <Layout>
    <Seo
      title="Quality of life results | IMCIVREE® (setmelanotide) injection"
      description="Learn how taking IMCIVREE® (setmelanotide) impacted people's quality of life. Please see full Prescribing Information and Important Safety Information."
      ogTitle="Quality of life results | IMCIVREE® (setmelanotide) injection"
      ogDesc="Learn how taking IMCIVREE® (setmelanotide) impacted people's quality of life. Please see full Prescribing Information and Important Safety Information."
    />

    <div className="content-block mt-0 lg_mt-0 md_pb-0">
      <div className="row og">
        <div className="column og small-12">
          <h1 className="h1 mb-0 md_mb-0">
            How did IMCIVREE impact people's day-to-day activities?
          </h1>
        </div>
        <div className="columns og small-12 large-12 lg_mt-2 mt-2">
          <div className="color-teal jost-semibold fs-20 mb-1 md_mb-1">
            In a study of 20 patients where day-to-day activities were measured
          </div>
          <h3 className="fs-para fw-bold color-gray">All patients maintained or improved their health-related quality of life status, regardless of baseline impairment.</h3>
          <ul className="neon-green-bullets mt-1 md_mt-1 mb-1 md_mb-1 ml-2 md_ml-2 pr-1 md_pr-1">
            <li>
              100% of children who expressed impaired quality of life at the
              start of the study (n=4)* experienced meaningful improvements by
              the end
            </li>
            <li>
              62.5% of adults (5 out of 8 people) who expressed impaired quality
              of life at the start of the study experienced meaningful
              improvements by the end
            </li>
            <li>
              100% of adults who did not express quality of life impairments at the start
              of the study (n=3) maintained or experienced meaningful
              improvements by the end
            </li>
          </ul>

          <p className="fs-para fw-bold mt-1 md_mt-1">Limitations include the small number of patients in groups across some of the assessments, which may in part be due to how rare the disease is, and the subjective nature of hunger scoring.</p>

          <div>
            <HangingRef
              mb0
              ml2
              mt-1
              
              symbol="*"
              isAsterisk
              copy="n is the number of people in the study with impaired quality of life."
            />
          </div>

        </div>
        {/* <div className="columns og small-12 large-6 lg_mt-2 mt-2">
          <div className="color-teal jost-semibold fs-20 mb-1 md_mb-1">
            In interviews of people who participated in IMCIVREE<br className="show-for-medium" /> clinical
            trials
          </div>

          <h3 className="fs-para fw-bold color-gray">All 19 participants reported improvements, which included:</h3>

          <ul className="neon-green-bullets mb-1 md_mb-1">
            <li>A large reduction in the amount of food they were eating</li>
            <li>Less food-seeking behaviors</li>
            <li>Better ability to focus</li>
            <li>
              Improvements in either their physical and/or emotional well-being
            </li>
          </ul>

          <div className="hide-for-medium">
            <HangingRef
              mb0
              ml2
              mt-1
              symbol="*"
              isAsterisk
              copy="n is the number of people in the study with impaired quality of life."
            />
          </div>
        </div> */}

      </div>
    </div>

    <div className="content-block pt-0 md_pt-0 md_pb-0">
      <div className="row og lg_mt-6 mt-2">
        <div className="columns og">
          <h2 className="h1 mb-1 lg_mb-1">The impact of IMCIVREE</h2>
          <h3 className="h2">
            Learn more about the impact of IMCIVREE from healthcare providers and people on treatment
          </h3>
        </div>
      </div>

      <QualityOfLifeVideo />
      <VideoTranscript02>
        <QualityOfLifeTranscript />
      </VideoTranscript02>

    </div>



    <div className="content-block pt-0 md_pt-0 md_pb-0">
      <div className="text-center mt-3 md_mt-2">
        <Button
          copy={<>Learn about the possible side effects with IMCIVREE</>}
          url="/bbs/side-effects/"
        />
      </div>
    </div>

  </Layout>
)

export default QualityOfLife
