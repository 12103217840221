import React from 'react'


const MoaVideoTranscript = (props) => {

  return (
    <>
      <p>Bardet-Biedl syndrome, also known as BBS, is a rare genetic disease.</p>

      <p>People living with BBS experience a wide range of symptoms, such as visual impairment; learning disabilities; extra toes and fingers; genital and hormonal problems; kidney problems; intense, hard-to control hunger, also referred to as hyperphagia; and early-onset obesity. In this video, we’re going to explain what causes the intense, hard-to-control hunger experienced by people living with BBS.</p>

      <p>When someone with BBS experiences that intense, hard-to-control hunger it may feel like it’s taking a longer time to feel full while eating. They may feel hungry again right after a meal, think about food constantly, or sneak or steal food. Let’s take a look at why people living with BBS experience hunger differently…</p>

      <p>The brain is made up of different parts, each with their own functions. One part—the hypothalamus—has a key signaling pathway called the melanocortin-4 receptor, or MC4R, pathway that controls the feelings of hunger.</p>

      <p>It does this by following the instructions in the body’s genes. That intense, hard-to-control hunger occurs when a person inherits variants, or changes, in a BBS gene that prevent the MC4R pathway from working properly.</p>

      <p>Let’s think of it this way. Think of the hypothalamus as a neighborhood within the brain. In this neighborhood, there are roads that allow trucks to travel to and from the brain.</p>

      <p>As mentioned, one road is called the melanocortin-4 receptor, or MC4R, pathway. On it, trucks deliver messages about hunger.</p>

      <p>The BBS gene acts as a traffic light, helping to guide trucks to deliver messages that the body is satisfied and doesn’t need more food. This is how things work in someone who does not have BBS.</p>

      <p>In BBS, the traffic light doesn’t work properly due to the change in the BBS gene. Trucks can’t get through the intersection, meaning the not hungry. Messages can’t be delivered.</p>

      <p>When this happens, the brain doesn’t know that the body is satisfied and doesn’t need more food. Instead, the brain thinks the body is still hungry, which can contribute to obesity.</p>

      <p>A broken traffic light, or impaired MC4R pathway, is a root cause of hunger and obesity in BBS.</p>

      <p>However, there is a medicine that may be able to help.</p>

      <p>IMCIVREE (setmelanotide) injection is a prescription medicine used in adults and children 6 years of age and older with obesity due to Bardet-Biedl syndrome (BBS) to help them lose weight and keep the weight off.</p>

      <p>IMCIVREE is not for use in people with the following conditions because it may not work: other types of obesity not related to BBS or other FDA-approved uses of IMCIVREE, including obesity associated with other genetic conditions and general obesity.</p>

      <p>IMCIVREE acts as a traffic officer by guiding trucks on the MC4R pathway in the brain and restoring the flow of traffic. Now, the trucks can deliver messages to the brain that the body is satisfied and doesn’t need more food.</p>

      <p>In clinical trials, IMCIVREE delivered early, significant, and sustained reduction in weight measures and hunger.</p>

      <p>It is a once daily injection given at home at the beginning of each day, with or without food.</p>

      <p>Most common side effects of IMCIVREE include darkening of the skin, injection site reactions, and nausea. Sexual function problems, depression, and suicidal thoughts or actions may also occur during treatment with IMCIVREE. No serious side effects were reported related to IMCIVREE in the BBS trial. These are not all of the possible side effects of IMCIVREE. Please see the Important Safety Information at the end of this video.</p>

      <p>Support and resources are available for families living with BBS. To learn more, please explore our website for additional information.</p>

      {/* ISI */}

      <p><b>What is IMCIVREE?</b><br/>
      IMCIVREE is a prescription medicine used in adults and children 6 years of age and older with obesity due to Bardet-Biedl syndrome (BBS) to help them lose weight and keep the weight off.</p>

      <p>IMCIVREE is not for use in people with the following conditions because it may not work:</p>

      <ul>
        <li>Other types of obesity not related to BBS or other FDA-approved uses of IMCIVREE, including obesity associated with other genetic conditions and general obesity</li>
      </ul>
      <br/>

      <p>It is not known if IMCIVREE is safe and effective in children under 6 years of age.</p>

      <p><b>Do not use IMCIVREE if you have had a serious allergic reaction to it or any of its ingredients. Serious allergic reactions, including anaphylaxis, can happen.</b></p>

      <p><b>Important Safety Information</b></p>

      <p><b>Before you use IMCIVREE, tell your healthcare provider about all your medical conditions, including if you:</b></p>

      <ul>
        <li>Have or have had areas of darkened skin, including skin discoloration (hyperpigmentation)</li>
        <li>Have or have had depression, or suicidal thoughts or behavior</li>
        <li>Have kidney problems</li>
        <li>Are pregnant or planning to become pregnant. Losing weight while pregnant may harm your unborn baby. Your healthcare provider may stop your treatment with IMCIVREE if you become pregnant. Tell your healthcare provider if you become pregnant or think you might be pregnant during treatment with IMCIVREE</li>
        <li>Are breastfeeding or plan to breastfeed. It is not known if IMCIVREE passes into your breast milk. You should not breastfeed during treatment with IMCIVREE</li>
      </ul>
      <br/>

      <p><b>Tell your healthcare provider about all the medicines you take,</b> including prescription and over-the-counter medicines, vitamins, and herbal supplements.</p>

      <p><b>See the detailed Instructions for Use</b> that come with your IMCIVREE to learn how to prepare and inject IMCIVREE, and how to properly throw away (dispose of) used syringes and needles.</p>

      <p><b>What are the possible side effects of IMCIVREE?</b><br/>
      <b>IMCIVREE may cause serious side effects, including:</b></p>

      <ul>
        <li><b>Male and female sexual function problems.</b> IMCIVREE can cause an erection that happens without any sexual activity in males (spontaneous penile erection) and unwanted sexual reactions (changes in sexual arousal that happen without any sexual activity) in females. If you have an erection lasting longer than 4 hours, get emergency medical help right away</li>
        <li><b>Depression and suicidal thoughts or actions.</b> You or a caregiver should call your healthcare provider right away if you have any new or worsening symptoms of depression, suicidal thoughts or behaviors, or any unusual changes in mood or behavior</li>
        <li><b>Serious allergic reactions.</b> Stop taking IMCIVREE and get medical help right away if you have any symptoms of a serious allergic reaction including: swelling of your face, lips, tongue, or throat; problems breathing or swallowing; severe rash or itching; fainting or feeling dizzy; rapid heartbeat</li>
        <li><b>Increased skin pigmentation and darkening of skin lesions (moles or nevi) you already have.</b> These changes happen because of how IMCIVREE works in the body and will go away when you stop using IMCIVREE. You should have a full body skin exam before starting and during treatment with IMCIVREE to check for skin changes</li>
        <li><b>Benzyl alcohol toxicity.</b> Benzyl alcohol is a preservative in IMCIVREE. Benzyl alcohol can cause serious side effects, including death, in premature and low-birth weight infants who have received medicines that contain benzyl alcohol. IMCIVREE should not be used in premature and low-birth weight infants</li>
      </ul>
      <br/>

      <p><b>The most common side effects of IMCIVREE include</b> darkening of the skin, injection site reactions, nausea, headache, diarrhea, stomach pain, vomiting, depression, and an erection that happens without any sexual activity in males.</p>

      <p>These are not all the possible side effects of IMCIVREE. Call your doctor for medical advice about side effects. You may report side effects to FDA at 1-800-FDA-1088 or to Rhythm Pharmaceuticals at 1-833-789-6337.</p>

      <p><b>Please see the full Prescribing Information at IMCIVREE.com.</b></p>


    </>
  )
}

export default MoaVideoTranscript
