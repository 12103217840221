import * as React from "react"
import Helmet from "react-helmet"

import Layout from "../../components/ppl-layout"
import Seo from "../../components/seo"
import Callout from "../../components/callout"
import Button from "../../components/button"

import actor from "../../images/actor-kid.png"


const PatientAccess = () => (
  <Layout>
    <Helmet bodyAttributes={{ class: "patient-ppl ppl-access" }} />
    <Seo
      title="Patient Access | IMCIVREE® (setmelanotide) injection"
      description="Learn how Rhythm InTune is committed to assisting eligible patients regardless of insurance plan type."
      ogTitle="Patient Access | IMCIVREE® (setmelanotide) injection"
      ogDesc="Learn how Rhythm InTune is committed to assisting eligible patients regardless of insurance plan type."
    />
    <div id="patient-access" className="content-block md_pb-2 lg_pb-2 pb-2">
      <div className="row">
        <div
          className="columns small-12 medium-9"
          style={{ position: "relative" }}
        >
          <h1 className="h1">
            Rhythm InTune is committed to assisting eligible patients regardless
            of insurance plan type
          </h1>
          <h2 className="h2 lg_mb-1 md_mb-1 mb-1">
            The majority of patients, regardless of coverage type, have obtained
            approval for IMCIVREE
          </h2>
          <div className="row og lg_mb-2 md_mb-2 mb-2">
            <div className="columns og">
              <ul className="neon-green-bullets">
                <li>
                  Many patients get coverage as soon as prior authorization is approved
                  <ul>
                    <li className="is-dash">Prior authorization is a step your doctor's office takes to ensure your medication is covered by your insurance plan</li>
                  </ul>
                </li>
                <li>
                  The majority of commercial plans have coverage policies in
                  place for IMCIVREE
                </li>
                <li>
                  IMCIVREE is usually covered for those with Medicaid
                </li>
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="columns large-8 medium-10 small-12">
              <Callout
                fullWidth
                className="lime-teal left text-left"
              >
                <p>
                  Financial assistance programs are available for eligible
                  patients
                </p>
              </Callout>
            </div>
          </div>
        </div>
        <div className="columns small-12 medium-3 position-relative ppl-actor-box">
          <img src={actor} className="pa-actor" alt="Actor portrayal" />
          <p className="footnote patient-access-footnote patient-access-model">
            Actor portrayal.
          </p>
        </div>
      </div>
    </div>
    <div className="text-center">
      <Button
        large
        copy={<>See available helpful resources</>}
        url="/ppl/helpful-resources/"
      />
    </div>
    <div>&nbsp;</div>
  </Layout>
)

export default PatientAccess
