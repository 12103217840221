import React from "react"

import Layout from "../../../components/hcp-bbs-layout"
import Seo from "../../../components/seo"
import Button from "../../../components/button"
import Callout from "../../../components/callout"
import Modal from "../../../components/modal"
import Tabs from "../../../components/template-partials/global-components/tabs/tabs"
import HangingRef from "../../../components/hanging-ref"

import ImageAdultWeightReduction from "../../../images/adult-weight-reduction-overtime-08172023.png"
import ImageAdultWeightIndividualResults from "../../../images/adult-weight-individual.svg"
import ImageChangeInBmi from "../../../images/change-in-bmi.png"

const AdultWeight = () => {
  return (
    <Layout>
      <Seo
        title="Adult Weight Reduction | IMCIVREE® (setmelanotide) injection | For HCPs"
        description="Learn about weight reduction in patients 18 years of age and older with BBS. Please see full Prescribing Information for Important Safety Information."
        ogTitle="Adult Clinical Study Weight Results | IMCIVREE® (setmelanotide) injection"
        ogDesc="Review the clinical study weight results of IMCIVREE® (setmelanotide) in patients 18 years of age and older. Please see full Prescribing Information for Important Safety Information."
      />
      <div className="content-block" id="hcp-bbs-adult-weight">
        <div
          className="h2--no-margin-bottom lg_mt-1"
          style={{ lineHeight: "2.5rem" }}
        >
          In patients &ge;18 years of age with BBS
        </div>
        <h1 className="h1">
          IMCIVREE delivered early, significant, and sustained weight reduction
          <sup>1-3</sup>
        </h1>
        <div>&nbsp;</div>
        <div className="row">
          <div className="columns">
            <Tabs className="--hcp">
              <Tabs.Pane name="WEIGHT REDUCTION OVER TIME" key="1">
                <div className="row">
                  <div className="column small-12 large-9">
                    <h2 className="h2 text-center">
                      Weight reduction in patients &ge;18 years of age<sup>1-4</sup>
                    </h2>
                    <p className="text-center hide-for-medium">
                      &larr; Swipe left or right to view &rarr;
                    </p>
                    <div className="text-center chart-overflow-x mb-1 md_mb-2">
                      <img
                        src={ImageAdultWeightReduction}
                        alt="Weight reduction in patients 18 and over"
                        style={{ width: "90%" }}
                        className="pb-1"
                      />
                    </div>
                  </div>
                  <div className="column small-12 large-3">
                    <div className="show-for-large">&nbsp;</div>
                    <div className="show-for-large">&nbsp;</div>
                    <Modal id="adult-14-week-reduction-1" />
                    <div className="md_mb-1"></div>
                    <Modal id="adult-24-month-long-term-1" />
                  </div>
                </div>
                <div>&nbsp;</div>
                <div id="adult-weight-reduction">
                    <Callout
                      left
                      className="light-teal left text-left"
                      style={{ paddingLeft: `10%` }}
                    >
                      <p className="mb-1 md_mb-1">
                        Clinically significant ~10% mean weight reduction in
                        patients ≥18 years of age at week 52<sup>2,3</sup>
                      </p>
                      <ul
                        className="ul--standard neon-green-bullets"
                        style={{ paddingLeft: `25px` }}
                      >
                        <li className="jost-semibold no-marker">
                          At 24 months, patients in a long-term
                          extension trial experienced a mean ~15%
                          reduction&nbsp;in body weight<sup>4</sup>
                        </li>
                      </ul>
                    </Callout>
                  </div>
                <div className="row mt-2 md_mt-3">
                  <div className="columns small-12">
                  <p class="hangingRef mb-0">Mean change in body weight in patients 18 years and older is an exploratory endpoint.</p> 
                  <HangingRef
                      symbol="*"
                      isAsterisk
                      mb0
                      copy={
                        <>
                          ATB=active treatment baseline, defined as the last
                          measurement before the first dose of IMCIVREE, ie,
                          week 0 for IMCIVREE group and week 14 for placebo
                          group.<sup>2</sup>
                        </>
                      }
                    />
                    <HangingRef
                      symbol={
                      <>
                        <sup>
                          &dagger;
                        </sup>
                      </>}
                      mb0
                      copy={
                        <>
                          Data shown only include patients who received 52 weeks
                          of IMCIVREE at the time of the analysis.
                          <sup>2</sup>
                        </>
                      }
                    />
                    <HangingRef
                          symbol={
                            <>
                              <sup>
                                &Dagger;
                              </sup>
                            </>}
                      copy={
                        <>
                          For patients aged 18 years or older, population sizes
                          ranged from 7 to 15, with n=12 at 52 weeks on active
                          treatment. Error bars are the standard deviation.<sup>2</sup>
                        </>
                      }
                    />
                  </div>
                </div>
                <div>&nbsp;</div>
              </Tabs.Pane>
              <Tabs.Pane name="INDIVIDUAL RESULTS" key="2">
                <div className="row">
                  <div className="column small-12 large-9">
                    <h2 className="h2 text-center">
                      Percentage change in weight in patients &ge;18 years of
                      age after 52 weeks (n=12)<sup>1</sup>
                    </h2>
                    <p className="text-center hide-for-medium">&larr; Swipe left or right to view &rarr;</p> 
                    <div className="text-center chart-overflow-x">
                      <img
                        src={ImageAdultWeightIndividualResults}
                        alt="Individual percentage change in weight chart"
                        style={{ width: "90%" }}
                        className="pb-1"
                      />
                    </div>
                  </div>
                  <div className="column small-12 large-3">
                    <div>&nbsp;</div>
                    <div>&nbsp;</div>
                    <Modal id="adult-14-week-reduction-2" />
                    <div className="md_mb-1"></div>
                    <Modal id="adult-24-month-long-term-2" />
                  </div>
                </div>
                <div className="md_mb-3"></div>
                <div className="row mt-2">
                  <Callout
                    left
                    className="light-teal left text-left"
                    style={{ paddingLeft: `10%` }}
                  >
                    <p>
                      Patients were not required to change their diet or
                      exercise routine<sup>1</sup>
                    </p>
                  </Callout>
                  <div className="columns small-12 align-self-bottom">
                    <div>&nbsp;</div>
                    <div>&nbsp;</div>
                    <HangingRef
                      symbol="*"
                      isAsterisk
                      copy={
                        <>
                          Patients with data after 52 weeks of treatment.
                          <sup>1</sup>
                        </>
                      }
                    />
                  </div>
                </div>
              </Tabs.Pane>
              <Tabs.Pane name="CHANGE IN BMI" key="3">
                <>
                  <div className="row md_mt-1">
                    <div className="column small-12 large-7">
                      <img src={ImageChangeInBmi} alt="Percentile change in BMI in patients 18 and over" />
                    </div>
                    <div className="column small-12 large-5">
                      <div className="hide-for-large">&nbsp;</div>
                      <div className="interior-menu--grey"> 
                        <h2 className="h1">
                          IMCIVREE significantly reduced the severity of obesity due to BBS<sup>1-4</sup>
                        </h2>
                        <p className="text-left mr-1">
                          This chart is a visual representation of what a
                          hypothetical adult with BBS* who started IMCIVREE at
                          20 years of age may experience in BMI reduction after
                          1 year and 2 years, based on data from the phase 3
                          trial and a separate long-term extension trial
                          <sup>†</sup>
                        </p>
                      </div>
                      <div>&nbsp;</div>
                      <Modal id="adult-24-month-long-term-3" />
                      <div>&nbsp;</div>
                    </div>
                  </div>
                  <HangingRef
                    mb0
                    symbol=""
                    copy="Chart is for illustrative purposes only."
                  />
                  <HangingRef
                    mb0
                    symbol="*"
                    isAsterisk
                    copy="Not an actual patient."
                  />
                  <HangingRef
                    mb0
                    symbol="&dagger;"
                    isDagger
                    copy={
                      <>
                        Patients with data after 1 year of treatment.<sup>7</sup>
                      </>
                    }
                  />
                </>
              </Tabs.Pane>
            </Tabs>
          </div>
        </div>
        <div className="row mt-1 md_mt-2">
          <div className="column small-12">
            <div className="text-center mt-1 md_mt-2">
              <Button copy="See hunger reduction" url="/hcp/bbs/hunger-reduction" />
            </div>

            <div className="references footnote mt-2 md_mt-3">
              <strong>References: 1.</strong> Data on file. Rhythm Pharmaceuticals, Inc. Boston, MA. <strong>2.</strong> Haqq AM et al. <em>Lancet Diabetes Endocrinol.</em> 2022;10(12):859-868. doi:10.1016/S2213-8587(22)00277-7. Supplemental appendix available at: https://www.thelancet.com/journals/landia/article/PIIS2213-8587(22)00277-7/fulltext. <strong>3.</strong> Grossman DC et al; US Preventive Services Task Force. <em>JAMA.</em> 2017;317(23):2417-2426. <strong>4.</strong> Argente J et al. Endocrine Society Annual Meeting. Poster ODP606. June 11-14, 2022.
            </div>

          </div>
        </div>

      </div>
    </Layout>
  )
}

export default AdultWeight
