import * as React from "react"

import Layout from "../../../components/hcp-bbs-layout"
import Seo from "../../../components/seo"
import Callout from "../../../components/callout"
import Button from "../../../components/button"
import ContactInfoHcpPpl from "../../../components/contact-info-hcp-ppl"
import Modal from "../../../components/modal"

import LearnMoreAboutPemVideo from "../../../components/learn-more-about-patient-education-managers-video"
import PatientCaregiverQuote from "../../../components/patient-caregiver-quote"
import VideoTranscript from "../../../components/video-transcript"
import LearnMoreAboutPemVideoTranscript from "../../../components/template-partials//hcp/bbs/learn-more-about-patient-education-managers-transcript"

import RhythmInTuneImg from "../../../images/hcp/ppl/rhythm-intune-hcp.png"
import Book from "../../../images/icons/icon-book.svg"
import Community from "../../../images/icons/icon-community.svg"
import Insurance from "../../../images/icons/icon-insurance.svg"
import Calendar from "../../../images/icons/icon-calendar.svg"
import KathrynQuote from "../../../images/kathryn-quote.png"
import KatQuote from "../../../images/kat-quote.png"
import DrOkorie from "../../../images/dr-okorie.png"
import TargetIcon from "../../../images/target_icon.png"


const PatientSupport = () => (
  <Layout>
    <Seo
      title="Rhythm InTune Patient Support | IMCIVREE® (setmelanotide) injection | For HCPs"
      description="Rhythm InTune is a support program designed for people living with BBS and their caregivers. Please see full Prescribing Information for Important Safety Information."
      ogTitle="Rhythm InTune Patient Support | IMCIVREE® (setmelanotide) injection"
      ogDesc="Rhythm InTune is a support program designed for people living with BBS and their caregivers. Please see full Prescribing Information for Important Safety Information."
    />
    <div className="content-block">
      <div className="row og align-left align-middle mb-1 md_mb-3">
        <div className="columns og small-12 medium-3 text-center">
          <img
            src={RhythmInTuneImg}
            alt="Rhythm InTune Logo"
            width="250"
            className="mt-1 mb-1"
          />
        </div>
        <div className="columns og small-12 medium-9">
          <h1 className="h1 mb-0 md_mb-0" style={{ lineHeight: `1` }}>Personalized one-on-one support for people living with BBS</h1>
        </div>
      </div>
      <div className="row og align-left">
        <div className="columns og small-12">
          <h1 className="h1">
            Rhythm InTune is here to help every person with BBS&mdash;even if they're not on IMCIVREE treatment
          </h1>
        </div>
      </div>
      <div className="row og align-left">
        <div className="columns og large-6 small-12">
          <h2 className="h2">
            A Rhythm InTune Patient Education Manager* is a single point of
            contact who can help people living with BBS and their caregivers:
          </h2>

          <div className="row og align-middle align-center">
            <div className="columns og large-3 small-12 text-center mb-2 large-text-left">
              <img src={Book} alt="Rhythm InTune resources" />
            </div>
            <div className="columns og large-9 mb-2">
              <p className="mb-0 text-left">
                <strong>Access educational resources,</strong> such as virtual
                education programs about BBS or treatment with IMCIVREE.
              </p>
            </div>
          </div>

          <div className="row og lg_mt-2 mt-2 align-middle align-center">
            <div className="columns og large-3 small-12 text-center mb-2 large-text-left">
              <img src={Community} alt="Rhythm InTune community" />
            </div>
            <div className="columns og large-9 mb-2">
              <p className="mb-0 text-left">
                <strong>Connect to a community</strong> where they can learn
                from the experiences of others.
              </p>
            </div>
          </div>

          <div className="row og lg_mt-2 mt-2 align-middle align-center">
            <div className="columns og large-3 small-12 text-center mb-2 large-text-left">
              <img src={Insurance} alt="Rhythm InTune coverage" />
            </div>
            <div className="columns og large-9 mb-2">
              <p className="mb-0 text-left">
                <strong>Access treatment</strong> by helping to understand
                drug coverage, prior authorizations, appeals support, and, for
                eligible patients, copay support and financial assistance.
              </p>
            </div>
          </div>

          <div className="row og lg_mt-2 mt-2 align-middle align-center">
            <div className="columns og large-3 small-12 text-center mb-2 large-text-left">
              <img src={Calendar} alt="Rhythm InTune calendar" />
            </div>
            <div className="columns og large-9 mb-2">
              <p className="mb-0 text-left">
                <strong>Get started on treatment</strong> by coordinating
                IMCIVREE deliveries and injection support and training with the specialty
                pharmacy.
              </p>
            </div>
          </div>


          <div className="row og lg_mt-2 mt-2 align-middle align-center">
          <div className="columns og large-3 small-12 text-center mb-2 large-text-left">
            <img src={TargetIcon} alt="Rhythm InTune calendar" />
          </div>
          <div className="columns og large-9 mb-2">
            <p className="mb-0 text-left">
              <strong> 
              With wellness tips and framework for tracking success</strong><sup>&dagger;</sup>
            </p>
          </div>
        </div>



        </div>

        <div className="columns og large-6 small-12">
          <h2 className="h2">
            For more information about the services Rhythm InTune provides,
            contact us at:
          </h2>
          <div className="lg_mt-3 mt-2">
            <ContactInfoHcpPpl />
          </div>
        </div>
      </div>
      <div className="columns mb-2 mt-2 md_mt-2">
        <Modal id="patient-access-hcp-bbs" className="color-black-bg" />
      </div>
    </div>

    <Callout left className="light-teal left text-left">
      <p style={{ paddingLeft: '13px' }}>
      Rhythm InTune is committed to providing people living with BBS and their caregivers with support and tools throughout their treatment journey
      </p>
    </Callout>

    <div className="content-block md_pt-1">
    <p class="hangingRef mb-0 "><span class="isAsterisk">*</span>
            Patient Education Managers are employees of Rhythm
            Pharmaceuticals and do not provide medical care or advice. We
            encourage your patients to always speak to their healthcare
            providers regarding their medical care.
            </p>
            <p class="hangingRef mb-0 "><span class="isAsterisk"><sup>&dagger;</sup></span>Designed to support patients and caregivers as they work with their doctor on wellness goals.
          </p>
          
      <div className="row og lg_mt-3">
        <div className="columns og">
          <h3 className="h1">Learn more about Patient Education Managers</h3>
          <p className="h2">See how they can provide optimal support for patients in your practice and help them start and stay on therapy</p>

          <LearnMoreAboutPemVideo />
          <VideoTranscript>
            <LearnMoreAboutPemVideoTranscript />
          </VideoTranscript>

        </div>
      </div>
    </div>

    <div className="content-block pb-0 md_pb-0">
        <div id="patient-caregiver-quotes">
          <div className="flex-wrapper">
            <PatientCaregiverQuote
              imgSrc={DrOkorie}
              copy="The partnership with a Patient Education Manager and Rhythm InTune in the care of BBS patients is crucial and a natural extension of this multidisciplinary team approach. It's a great support model."

              signoff={
                <>
                  — Uzoma C. Okorie, MD, FAAP<br/>
                  Bardet-Biedl Syndrome Multispecialty Clinic<br/>
                  at Marshfield Medical Center
              </>
              }
            />

            <PatientCaregiverQuote
              order="reverse"
              imgSrc={KatQuote}
              copy="Our Patient Education Manager has been instrumental to us. She took time not only to meet with us, but also our pediatrician's office. She has held our hands through the entire process."
              signoff="— Kat, caregiver of a child living with BBS"
            />

            <PatientCaregiverQuote
              imgSrc={KathrynQuote}
              copy="It feels great to have the support. [The Patient Education Managers] are so caring and willing to be by your side [for] the entire process of getting you started on IMCIVREE and sticking by your side the entire time you're on it."
              signoff="— Kathryn, who is living with with BBS"
            />
          </div>
        </div>
      </div>

    <div className="content-block pt-0 md_pt-0" style={{marginTop: '-24px'}}>
      <div className="text-center mt-6 md_mt-6">
        <Button
          copy="See webinars and resources"
          url="/hcp/bbs/webinars-downloadable-resources/"
        />
      </div>
    </div>
    
  </Layout>
)

export default PatientSupport
