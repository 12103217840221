import React from 'react'


const PemAmyTranscript = (props) => {

  return (
    <>
      <p><strong>Graphics:</strong></p>

          <p><strong>Amy</strong></p>

          <p><strong>Rhythm InTune logo</strong></p>

          <p><strong>AMY on-camera:</strong></p>

          <p>Hi, I'm Amy and I live in the Chicagoland area and I work throughout the Midwest.</p>

          <p>I've been providing patient education support for Rhythm since 2021.</p>

          <p>I’m a mom of three and most of my free time is spent with my family.</p>

          <p><strong>Graphic:</strong></p>

          <p><strong>My Role</strong></p>

          <p>As a patient education manager, I know that it can be overwhelming and sometimes you just need someone to listen to what's going on in your life. I can then connect you with the resources that may relieve you and your family of some of that struggle.</p>

          <p><strong>Graphic:</strong></p>

          <p><strong>The Best Part of My Job</strong></p>

          <p>I became a Patient Education Manager because I love helping people who are in need, so that they can spend more time caring for their loved one. That’s the very best part of my job.</p>

          <p><strong>Graphic:</strong></p>

          <p className='text-center'><strong>Patient Education Managers (PEMs) are employees of Rhythm Pharmaceuticals, Inc. The information they provide is for educational purposes only and should not be construed as medical advice or healthcare treatment recommendations. The content on this website is not intended to be a substitute for professional medical advice, diagnosis, or treatment. Always seek the advice of your treating physician with any questions regarding your medical care or treatment plan.</strong></p>

          <p className='text-center'><strong>US-SET-2300064 06/2023</strong></p>


    </>
  )
}

export default PemAmyTranscript
