import React from 'react'

const IconCopyBlock = ({ centered, heading, icon, copyBlack, content, iconAlt, marginLeft }) => {
  return (
    <div className="mb-2 md_mb-3 icon-copy-block row">
      <div className="columns og large-12">
      {
        heading &&
        <h3 className="h3 color-gray">{heading}</h3>
      }
      </div>
      <div className={`row ${centered ? "align-middle" : ""}`}>
        <div className={`columns og small-12 medium-3 large-2 text-center ${centered ? "" : "medium-text-left"}`}>
          <img src={icon} className="mb-1" alt={iconAlt} />
        </div>
        <div className="columns small-12 medium-9 large-10">
          <p className={`${centered ? "text-center medium-text-left" : ""} ${copyBlack ? "color-black" : ""}`} style={{ marginLeft: marginLeft ? "1.1rem" : null }}>{content}</p>
        </div>
      </div>
    </div>
  )
}

export default IconCopyBlock
