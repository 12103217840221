import * as React from "react"

import Layout from "../../../components/hcp-bbs-layout"
import Seo from "../../../components/seo"

import HyperphagiaVideo from "../../../components/hyperphagia-video"
import VideoTranscript from "../../../components/video-transcript"
import HyperphagiaVideoTranscript from "../../../components/template-partials/hcp/bbs/hyperphagia-video-transcript"

const RealExperiencesWithHyperphagiaHcpBbsVideoPage = () => (
  <Layout noNav>

  <Seo
  title="Real Experiences With Hyperphagia Video | IMCIVREE® (setmelanotide)"
  description="Learn about the daily impact of hyperphagia & how physicians & families are managing it together. Please see full Prescribing Information & Important Safety Information."
  />

    <div className="content-block">

      <div className="row og">
        <div className="columns og">
          <h1 className="h1">Real experiences with hyperphagia</h1>
          <h2 className="h2">Learn about its daily impact and how physicians and families are managing it together.</h2>
        </div>
      </div>

      <HyperphagiaVideo/>
        <VideoTranscript>
          <HyperphagiaVideoTranscript />
        </VideoTranscript>

    </div>

  </Layout>
)

export default RealExperiencesWithHyperphagiaHcpBbsVideoPage