import * as React from "react"
import { Link } from "gatsby"

import Layout from "../../components/bbs-layout"
import Seo from "../../components/seo"
import Button from "../../components/button"
import IconList from "../../components/icon-list"

import ImageSyringe from "../../images/syringe.svg"
import ImageSunrise from "../../images/sunrise.svg"
import ImagePlusMinus from "../../images/plus-minus.svg"
import RulerKidImage from "../../components/gatsby-images/kid-image"

import VideoTranscript from "../../components/video-transcript"
import TakingImcivreeVideo from "../../components/taking-imcivree-video-es"
import HowToInjectTranscriptEs from "../../components/template-partials/patient/how-to-inject-transcript-es"

const GiveImcivree = () => (
  <Layout>
    <Seo
      title="How IMCIVREE is Given | IMCIVREE® (setmelanotide) injection"
      description="Learn how IMCIVREE® (setmelanotide) is given. Please see full Prescribing Information and Important Safety Information."
      ogTitle="How IMCIVREE is Given | IMCIVREE® (setmelanotide) injection"
      ogDesc="Learn how IMCIVREE® (setmelanotide) is given. Please see full Prescribing Information and Important Safety Information."
    />
    <div className="content-block">
      <h1 className="h1">How is IMCIVREE given?</h1>
      <h2 className="h2">IMCIVREE is a once-daily injection used to help reduce weight in people living with BBS</h2>
      <h3 className="h3 color-gray">IMCIVREE is given:</h3>

      <div className="row og lg_mt-2 mt-1">
        <div className="column og small-12 medium-4 text-center pb-2">
          <IconList icon={ImageSyringe} iconAlt="IMCIVREE injection under skin" copy={<>As an injection under the skin</>} />
        </div>
        <div className="column og small-12 medium-4 text-center pb-2">
          <IconList icon={ImageSunrise} iconAlt="Once daily at the beginning of the day" copy={<>Once daily at the beginning of the day</>} />
        </div>
        <div className="column small-12 medium-4 text-center pb-2">
          <IconList icon={ImagePlusMinus} iconAlt="With or without food" copy={<>With or without food</>} />
        </div>
      </div>

      <div className="row og">
        <div className="columns og">
          <p>Because IMCIVREE is injected under the skin, the needle is short and thin</p>
          <ul className="neon-green-bullets">
            <li>The IMCIVREE needle is half an inch long</li>
          </ul>
        </div>
      </div>

      <div className="row og">
        <div className="columns og">
          <div className="kid-ruler-img-container lg_mt-2 mt-2">
            <RulerKidImage alt="Child with obesity" />
          </div>
        </div>
      </div>

      <div className="row og">
        <div className="columns og">
          <h3 className="h1">Watch the video below to see how an IMCIVREE injection is given</h3>
        </div>
      </div>

      <TakingImcivreeVideo/>
      <VideoTranscript>
        <HowToInjectTranscriptEs/>
      </VideoTranscript>

      <div className="row og lg_mt-2 mt-2">
        <div className="columns og">
          <p className="mb-2 md_mb-2">Please see the <a href="/How_To_Give_IMCIVREE_Guide.pdf" target="_blank" className="underline">How to Give IMCIVREE Guide</a> for illustrated, step-by-step instructions for giving an IMCIVREE injection.</p>
        </div>
      </div>

      <div className="text-center lg_mt-2 mt-2">
        <Button large copy="Learn more about getting started on IMCIVREE" url="/bbs/getting-started/" />
      </div>
    </div>
  </Layout>
)

export default GiveImcivree
