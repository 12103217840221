import React from 'react'


const DiagnosisVideoTranscript = (props) => {

  return (
    <>
            <p>Historically, the diagnosis of Bardet-Biedl syndrome has been a clinical diagnosis.</p>

            <p>When I think about evaluating a patient for Bardet-Biedl syndrome, I often think about a child living with early-onset obesity; so, obesity that presents before the age of six. And then, I often think about one additional factor that makes me suspect this condition.</p>

            <p>Usually, I'm looking for things such as markers of intellectual disability, maybe not meeting developmental milestones...</p>

            <p>Or I look for other sort of organ involvement; specifically, potential visual impairment or renal involvement.</p>

            <p>And I think about the eating phenotype and whether there are signs and symptoms of hyperphagia or insatiable hunger that the parents are describing.</p>

            <p>And we're trying to rule in or out other mono- or polygenic forms of obesity, one of which is Bardet-Biedl syndrome.</p>

            <p>Secondary clinical features include speech delay, congenital heart disease, developmental delay, diabetes myelitis, and dental anomalies, ataxia with poor coordination, or smell abnormalities.</p>

            <p>It's important to note that the clinical presentation of Bardet-Biedl syndrome varies quite widely, even in affected individuals within families.</p>

            <p>I had a very normal pregnancy. There was nothing that was brought to my attention. And when Bri was born, other than the 12 toes, she was a very happy baby.</p>

            <p>Close to nine to 13 months is when things really started to occur. She was lagging behind. She was starting to have other medical issues that weren't necessarily explained.</p>

            <p>Over time, there was a rapid weight gain. She was having issues with communication, continued falling behind her peers. She was having tantrums frequently, mainly because of communication skills or just now being aware that she was hungry all the time. Her vision impairments were becoming more of an issue, but I was just dismissed by those physicians.</p>

            <p>They made a clinical diagnosis of Bardet-Biedl syndrome when she was six, and then had a DNA confirmation when she was nine, and they confirmed her as BBS-10.</p>

            <p>BBS is a ciliopathy. And so, we have a wide variety of symptoms that may come from that. The ciliopathy, or the cilia in the cells, are responsible for so many different things in the body, that it really does affect the whole body.</p>

            <p>When we think about the diagnosis of Bardet-Biedl syndrome, I think we have to acknowledge that there's still so much that we don't understand. And while we know that it is a fairly rare condition, and while we often think about it being diagnosed in childhood, I think a lot of these cases are being missed because there is a heterogeneity in its phenotype and a lot of the presenting symptoms are occurring over time.</p>

            <p>When we think about a young child living with Bardet-Biedl syndrome, we might only see early-onset obesity and hyperphagia. We know that usually the eye involvement does occur early on, but it could be that visual impairment does not incur[sic] until into their 20s and 30s. And for some, it might not be that that organ involvement is really presenting with the renal anomalies and cardiac findings until into their 40s and 50s.</p>

            <p>But again, often those things are occurring; they're just not being screened for.</p>

            <p>We want to stay mindful of, are there elements of the history that we might have overlooked? For instance, polydactyly, extra digits that are taken off within the first year—surgically corrected, might not be obvious to the clinician who sees the patient after the procedure. But knowing this piece of information could really help make the diagnosis.</p>

            <p>Early diagnosis is so important, because I have patients that I'm seeing at 50 and maybe 60 years old, that have been affected by significant obesity. Their BMI may be 60, 70, 80. And by that time, they've got so many health problems, and they have multiple issues.</p>

            <p>If we can get these patients on the front end, and we can get them when they're children especially, and not set them up for a lifelong battle with their weight, and a lifelong struggle with all of these co-morbidities, that would be lifesaving for many of them, but life-changing for all of them.</p>

            <p>Early diagnosis is incredibly important, because there's a whole lot more that we can do now than previously.</p>

            <p>We can change the clinical outcomes and avoid the acceleration of potentially co-existing health concerns, such as diabetes, the renal impairment, hypertension as well, which go together with obesity, and are known co-morbidities of obesity.</p>

            <p>Reed is 11 years old now, and he was diagnosed with Bardet-Biedl Syndrome, BBS, when he was eight months old. When Reid was born, he had six fully developed fingers and toes in both hands and feet, which we weren't prepared for because nothing showed otherwise anything out of ordinary, including the ultrasounds. And then when he came out, he was hungry all the time. He started showing developmental delays quite early. He had delays with speech, with cognitive, with physical. We had his extra fingers and toes removed when he was one year old. So that impacted his motor skills.</p>

            <p>He also started showing some vision issues quite early. I believe when he was about 18 months, we noticed that when he would get out of the crib and the room was dimly lit or was still dark, he would stop before he began to move. So, we started noticing that maybe he didn't see very well. He did not sleep through the night until he was about five years old. He would constantly wake up, and I believe he woke up because he was hungry because hunger was there from day one and it was insatiable and it was hard to control, and it was ever-present day and night.</p>

            <p>When we took Reed for his six-month checkup with pediatrician, his weight was at 31 pounds, and our pediatrician took Reed's weight and birth record into consideration and said, "You know what, guys? I'm going to be right back," and when she left the office, she returned back after a few minutes carrying a giant medical encyclopedia and said, "I think Reed has something that's called Bardet-Biedl syndrome or BBS."</p>

            <p>I think the most compelling message is really ability to make a difference in the life of a patient, in a multisystemic way. So, identifying a pattern, and even if it doesn't involve your organ at this time, being able to identify other patterns, other systems that are involved, could certainly make a difference in the lives of the families.</p>

            <p>When we think about treating BBS, we really have two goals of treatment. The primary goal is weight reduction in an effort to improve health outcomes over time by reducing the life-limiting complications that can occur from living with excess weight long term. The way that that is done is through reduction of hyperphagia.</p>

            <p>We know that in children and adults living with Bardet-Biedl syndrome, they're hyperphagia is incredibly impactful on their quality of life. It impacts their school performance and their work performance and their relationships.</p>

            <p>And then, once those appropriate diagnoses are made, we need to think about what are the best treatment options that we have and how do we get those treatment options into the hands of our patients so they can use them well, so that they can have the best health trajectory over time.</p>


    </>
  )
}

export default DiagnosisVideoTranscript
