import * as React from "react"

import Layout from "../../components/bbs-layout"
import Seo from "../../components/seo"

import LearnMoreAboutOneOnOneSupportFromAPemVideo from "../../components/learn-more-about-one-on-one-personalized-support-from-a-patient-education-manager-video"
import VideoTranscript from "../../components/video-transcript"
import LearnMoreAboutOneOnOneSupportFromAPemTranscript from "../../components/template-partials/patient/learn-more-about-one-on-one-personalized-support-from-a-patient-education-manager-transcript"


const LearnMoreAboutOneOnOneSupportFromAPem = () => (
  <Layout noNav>

    <Seo title="Learn more about one-on-one personalized support from a Patient Education Manager | IMCIVREE® (setmelanotide) injection" />

    <div className="content-block">

      <div className="row og">
        <div className="columns og">
          <h1 className="h1">Learn more about one-on-one personalized support from a Patient Education Manager</h1>
          <h2 className="h2">Hear from people who have experienced the benefits of working with a Patient Education Manager every step of the way.</h2>
        </div>
      </div>

      <LearnMoreAboutOneOnOneSupportFromAPemVideo />
        <VideoTranscript>
          <LearnMoreAboutOneOnOneSupportFromAPemTranscript />
        </VideoTranscript>

    </div>

  </Layout>
)

export default LearnMoreAboutOneOnOneSupportFromAPem