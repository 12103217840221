import React from "react"
import CalloutBox from "./callout-box"
import LineItem from "./template-partials/global-components/list-item"

import NotEqualIcon from "../images/obesity-not-equal-icon.svg"


const PplHyperphagiaChart = props => {
  return (
    <div id="overeating-disorders">
      <div className="row">
        <div className="columns small-12 large-expand mb-1">
          <CalloutBox
            headerStyles={{ backgroundColor: "#939599" }}
            contentStyles={{ backgroundColor: "#FFF" }}
            headerCopy="Occasional overeating"
          >
            <p>
              Eating beyond a feeling of satiety at a special occasion or
              celebratory meal (eg, Thanksgiving)
            </p>
          </CalloutBox>
        </div>

        <div className="show-for-large" style={{ width: `10px` }}></div>

        <div className="columns small-12 large-expand mb-1">
          <CalloutBox
            headerStyles={{ backgroundColor: "#939599" }}
            contentStyles={{ backgroundColor: "#FFF" }}
            headerCopy="Hedonic overeating"
          >
            <p>
              Eating beyond a feeling of fullness for pleasure or comfort
            </p>
          </CalloutBox>
        </div>

        <div className="show-for-large" style={{ width: `10px` }}></div>

        <div className="columns small-12 large-expand mb-1">
          <CalloutBox
            headerStyles={{ backgroundColor: "#939599" }}
            contentStyles={{ backgroundColor: "#FFF" }}
            headerCopy="Binge eating"
          >
            <p>
              Eating a large amount of food with loss of control in a short period of time
            </p>
          </CalloutBox>
        </div>

        <div className="show-for-large position-relative" style={{ width: `60px` }}>
          <div className="color-teal-bg h-100" style={{ width: `6px`, margin: '0 auto' }}></div>
          <img src={NotEqualIcon} className="not-equal-icon position-absolute" alt="" />
        </div>

        <div style={{height: '0px'}}>&nbsp;</div>

        <div className="hide-for-large position-relative flex align-items-center align-middle" style={{ width: `100%`, height: `85px`, marginTop: `-1rem` }}>
          <div className="color-teal-bg" style={{ width: `100%`, height: `6px`, margin: '0 auto' }}></div>
          <img src={NotEqualIcon} className="not-equal-icon position-absolute" alt="" />
        </div>

        <div className="columns small-12 large-expand">
          <CalloutBox
            headerStyles={{ backgroundColor: "#007A8A" }}
            contentStyles={{ backgroundColor: "#FFF" }}
            headerCopy="<span style='display: block; margin-top: 4px;'>Hyperphagia</span>
            <span style='font-size: 1rem; font-weight: 400; display: block; margin-top: 8px; padding-bottom: 4px;'>(Caused by an impaired MC4R pathway in people with genetic obesity)</span>"
          >
            <ul className="ul--standard">
              {[
                "Taking a longer time to feel full while eating",
                "Feeling hungry again right after a meal",
                "Intense hunger that may never go away",
                "Thinking about food constantly",
                "Becoming upset when food is unavailable",
                "Food-seeking behavior (eg, sneaking or stealing food)",
              ].map(item => (
                <LineItem copy={item} />
              ))}
            </ul>
          </CalloutBox>
        </div>
      </div>
    </div>
  )
}

export default PplHyperphagiaChart
