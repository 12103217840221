import React from "react"

import Layout from "../../../components/hcp-bbs-layout"
import Seo from "../../../components/seo"

import "../../../components/scss-pages/hcp/bbs/_index.scss"
import Helmet from "react-helmet"

import IconData from "../../../images/hcp/ppl/icon-data.png"
import IconDiamond from "../../../images/hcp/ppl/icon-diamond.svg"
import IconRaR from "../../../images/hcp/ppl/icon-email.svg"
import HCPHero from "../../../components/HCPBBSHero"

const icons = [
  {
    svg: IconData,
    url: "/hcp/bbs/study-design/",
    alt: "See the efficacy of IMCIVREE",
    copy: "See the efficacy of IMCIVREE",
  },
  {
    svg: IconDiamond,
    url: "/Start_Form.pdf",
    alt: "Download a Prescription Start Form to prescribe IMCIVREE",
    copy: "Download a Prescription Start Form to prescribe IMCIVREE",
    type: "download",
  },
  {
    svg: IconRaR,
    url: "/hcp/bbs/sign-up/",
    alt: "Sign up for email updates about IMCIVREE or contact a Rhythm representative",
    copy: "Sign up for email updates about IMCIVREE or contact a Rhythm representative",
  },
]

const IndexPage = () => (
  <Layout>
    <Seo
      title="Bardet-Biedl Syndrome (BBS) | IMCIVREE® (setmelanotide) injection | For HCPs"
      description="IMCIVREE® (setmelanotide) is the first and only PRECISION MEDICINE to target impairment in the hypothalamic MC4R pathway, a root cause of hyperphagia and obesity in BBS. Please see full Prescribing Information for Important Safety Information."
      ogTitle="IMCIVREE® (setmelanotide) injection"
      ogDesc="Learn about IMCIVREE® (setmelanotide) injection and the disease it treats. Sign up for updates to learn more. Please see full Prescribing Information for Important Safety Information."
    />
    <Helmet
      bodyAttributes={{ id: "hcp-bbs-gateway", class: "hcp-bbs-home hcp-bbs" }}
    />

    <HCPHero icons={icons} />
  </Layout>
)

export default IndexPage
