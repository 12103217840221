import * as React from "react"
import { Link } from "gatsby"

import Layout from "../../components/ppl-layout"
import Seo from "../../components/seo"
import AccessibilityText from "../../components/accessibility-text"

const Accessibility = () => (
  <Layout noNav>
    <Seo title="Accessibility Statement | IMCIVREE® (setmelanotide) injection" />
    <AccessibilityText/>
  </Layout>
)

export default Accessibility
