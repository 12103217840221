import React from 'react'


const PemEmilyTranscript = (props) => {

  return (
    <>
      <p><strong>Graphics:</strong></p>

      <p><strong>Emily</strong></p>

      <p><strong>Rhythm InTune logo</strong></p>

      <p><strong>EMILY on-camera:</strong></p>

      <p>Hi I’m Emily and I live in the Dallas/Ft Worth area. I’m a Patient Education Manager here at Rhythm. I’ve been educating and supporting patients in my career for over 20 years.</p>

      <p>I love nature and weather! I love the sunset, I love being outdoors, I love hiking, looking at the stars. All of that puts me in my happy place.</p>

      <p><strong>Graphic:</strong></p>

<p><strong>My Role</strong></p>

      <p>Some of the things I do as a Patient Education Manager is provide support. I can give you information on your disease, your medication.</p>

          <p><strong>Graphic:</strong></p>

          <p><strong>The Best Part of My Job</strong></p>

      <p>I love what I do. I’ve learned so much from my patients and families just like yours. Having a rare disease can be so isolating and having support can make a huge difference. Understanding there’s so much you go through from finding the right doctor to the lengthy time to diagnosis to getting on the right therapy. It can be overwhelming navigating all of this while still trying to live your life. Sometimes you just need someone to lean on, someone outside of your circle that can listen, to educate and support you, and that’s where I come in.</p>

      <p><strong>Graphic:</strong></p>

      <p className='text-center'><strong>Patient Education Managers (PEMs) are employees of Rhythm Pharmaceuticals, Inc. The information they provide is for educational purposes only and should not be construed as medical advice or healthcare treatment recommendations. The content on this website is not intended to be a substitute for professional medical advice, diagnosis, or treatment. Always seek the advice of your treating physician with any questions regarding your medical care or treatment plan.</strong></p>

      <p className='text-center'><strong>US-SET-2300067 06/2023</strong></p>


    </>
  )
}

export default PemEmilyTranscript
