import React, { useEffect } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const Sitemap = () => {
  let lastKnownScrollPosition = 0
  let ticking = false

  // interstitial
  const exitModal = e => {
    let modalExit = document.querySelector("#modal-exit")
    let destinationURL = e.currentTarget.href;
      e.preventDefault();
      console.log(destinationURL);
      modalExit.classList.add("open");
      document.querySelector(".enter").href = destinationURL;
  }

  function animate(scrollPos) {
    if (scrollPos > 25) {
      document.querySelector(`.main-logo--header`).classList.add("scrolled");
    } else {
      document.querySelector(`.main-logo--header`).classList.remove("scrolled");
    }
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      document.addEventListener("scroll", e => {
        lastKnownScrollPosition = window.scrollY

        if (!ticking) {
          window.requestAnimationFrame(() => {
            animate(lastKnownScrollPosition)
            ticking = false
          })
          ticking = true
        }
      })
    }
  }, []);
  return (
  <Layout noNav>
    <Seo title="Sitemap | IMCIVREE® (setmelanotide) injection" />
    <div className="sitemap content-block">

    <div className="row og">
      <div className="columns">
        <h1 className="h1 lg_mt-2">Sitemap</h1>
      </div>
    </div>

      <div className="row og">
        <div className="columns">
          <h2 className="h3 lg_mt-2">For Patients</h2>
          <div className="hr"></div>
        </div>
      </div>

      <div className="row og">
        <div className="columns og">
          <h4>Bardet-Biedl Syndrome</h4>
          <ul style={{ marginLeft: '0.3rem' }}>
            <li><Link to="/bbs/">BBS Home</Link></li>
            <li><Link to="/bbs/overview/">BBS Overview</Link></li>
            <li className="lg_mt-1 mt-1"><span>About IMCIVREE</span>
              <ul>
                <li><Link to="/bbs/how-imcivree-works/">How Does IMCIVREE Work?</Link></li>
                <li><Link to="/bbs/studied/">How Was IMCIVREE Studied?</Link></li>
                <li><Link to="/bbs/effect-of-imcivree/">Weight & Hunger Reduction</Link></li>
                <li><Link to="/bbs/side-effects/">Side Effects</Link></li>
              </ul>
            </li>
            <li className="lg_mt-1 mt-1"><span>Starting IMCIVREE</span>
              <ul>
                <li><Link to="/bbs/how-imcivree-given/">How is IMCIVREE Given?</Link></li>
                <li><Link to="/bbs/getting-started/">Getting Started On IMCIVREE</Link></li>
                <li><Link to="/bbs/understanding-your-dose/">Understanding Your Dose</Link></li>
              </ul>
            </li>
            <li className="lg_mt-1 mt-1"><span>Support &amp; Resources</span>
              <ul>
                <li><Link to="/bbs/support-resources/">Rhythm InTune</Link></li>
                <li><Link to="/bbs/programs/">IMCIVREE Programs</Link></li>
                <li><Link to="/bbs/resources/">Helpful Resources</Link></li>
                <li><Link to="/bbs/faq/">Frequently Asked Questions</Link></li>
              </ul>
            </li>
          </ul>
        </div>
        <div className="columns og">
          <h4>POMC, PCSK1, or LEPR Deficiency</h4>
          <ul className="lg_mt-2 mt-2" style={{ marginLeft: '0.3rem' }}>
            <li><Link to="/ppl/">PPL Home</Link></li>
            <li className="lg_mt-1 mt-1"><span>About IMCIVREE</span>
              <ul>
                {/* <li><Link to="/ppl/about/">About IMCIVREE</Link></li> */}
                <li><Link to="/ppl/how-it-works/">How IMCIVREE Works?</Link></li>
                <li><Link to="/ppl/clinical-study-results/">Clinical Study Design</Link></li>
                <li><Link to="/ppl/effect-of-imcivree/">Weight & Hunger Reduction</Link></li>
                <li><Link to="/ppl/side-effects/">Side Effects</Link></li>
              </ul>
            </li>
            <li className="lg_mt-1"><span>Starting IMCIVREE</span>
              <ul>
                <li><Link to="/ppl/how-imcivree-is-given/">How Is IMCIVREE Given?</Link></li>
                <li><Link to="/ppl/getting-started/">Getting Started on IMCIVREE</Link></li>
                <li><Link to="/ppl/understanding-your-dose/">Understanding Your Dose</Link></li>
              </ul>
            </li>
            <li className="lg_mt-1"><span>Support &amp; Resources</span>
              <ul>
                <li><Link to="/ppl/rhythm-intune/">Rhythm InTune</Link></li>
                <li><Link to="/ppl/helpful-resources/">Resources</Link></li>
                <li><Link to="/ppl/faq/">Frequently Asked Questions</Link></li>
              </ul>
            </li>
          </ul>
        </div>
      </div>

      <div className="row og lg_mt-2 mt-2">
        <div className="columns">
          <h2 className="h3 lg_mt-2">For Healthcare Providers</h2>
          <div className="hr"></div>
        </div>
      </div>

      <div className="row og">
        <div className="columns og">
          <h4>Bardet-Biedl Syndrome</h4>
          <ul style={{ marginLeft: '0.3rem' }}>
            <li><Link onClick={(e) => exitModal(e)} to="/hcp/bbs/overview/">BBS Overview</Link></li>
            <li><Link onClick={(e) => exitModal(e)} to="/hcp/bbs/moa/">Mechanism of Action</Link></li>
            <li className="lg_mt-1 mt-1">Efficacy
              <ul>
                <li><Link onClick={(e) => exitModal(e)} to="/hcp/bbs/study-design/">Study Design</Link></li>
                <li><Link onClick={(e) => exitModal(e)} to="/hcp/bbs/pediatric-bmi-z-score-reduction/">Pediatric BMI Z-score Reduction</Link></li>
                <li><Link onClick={(e) => exitModal(e)} to="/hcp/bbs/adult-weight-reduction/">Adult Weight Reduction</Link></li>
                <li><Link onClick={(e) => exitModal(e)} to="/hcp/bbs/hunger-reduction/">Hunger Reduction</Link></li>
              </ul>
            </li>
            <li className="lg_mt-1 mt-1">Safety Profile
              <ul>
                <li><Link onClick={(e) => exitModal(e)} to="/hcp/bbs/safety-profile/">Most Common Adverse Events</Link></li>
              </ul>
            </li>
            <li className="lg_mt-1 mt-1"><Link onClick={(e) => exitModal(e)} to="/hcp/bbs/dosing-administration/">Dosing &amp; Administration</Link></li>
            <li><Link onClick={(e) => exitModal(e)} to="/hcp/bbs/prescribing-imcivree/">Prescribing IMCIVREE</Link></li>
            <li className="lg_mt-1 mt-1"><span>Patient Support &amp; Resources</span>
              <ul>
                <li><Link onClick={(e) => exitModal(e)} to="/hcp/bbs/patient-support/">Patient Support</Link></li>
                <li><Link onClick={(e) => exitModal(e)} to="/hcp/bbs/webinars-downloadable-resources/">Webinars &amp; Downloadable Resources</Link></li>
                <li><Link onClick={(e) => exitModal(e)} to="/hcp/bbs/faq/">Frequently Asked Questions</Link></li>
              </ul>
            </li>
          </ul>
        </div>
        <div className="columns og">
          <h4>POMC, PCSK1, or LEPR Deficiency</h4>
          <ul style={{ marginLeft: '0.3rem' }}>
            <li><Link onClick={(e) => exitModal(e)} to="/hcp/ppl/moa/">Mechanism of Action</Link></li>
            <li className="lg_mt-1 mt-1">Efficacy
              <ul>
                <li><Link onClick={(e) => exitModal(e)} to="/hcp/ppl/study-design/">Study Design</Link></li>
                <li><Link onClick={(e) => exitModal(e)} to="/hcp/ppl/weight-reduction/">Weight Reduction</Link></li>
                <li><Link onClick={(e) => exitModal(e)} to="/hcp/ppl/hunger-reduction/">Hunger Reduction</Link></li>
              </ul>
            </li>
            <li className="lg_mt-1 mt-1"><Link to="/hcp/ppl/safety-profile/">Safety Profile</Link></li>
            <li><Link onClick={(e) => exitModal(e)} to="/hcp/ppl/dosing-administration/">Dosing &amp; Administration</Link></li>
            <li><Link onClick={(e) => exitModal(e)} to="/hcp/ppl/prescribing-imcivree/">Prescribing IMCIVREE</Link></li>
            <li className="lg_mt-1 mt-1"><span>Patient Support &amp; Resources</span>
              <ul>
                <li><Link onClick={(e) => exitModal(e)} to="/hcp/ppl/patient-support/">Patient Support</Link></li>
                <li><Link onClick={(e) => exitModal(e)} to="/hcp/ppl/patient-resources/">Patient Resources</Link></li>
                <li><Link onClick={(e) => exitModal(e)} to="/hcp/ppl/faq/">Frequently Asked Questions</Link></li>
              </ul>
            </li>
          </ul>
        </div>
      </div>

    </div>
  </Layout>
  )
}

export default Sitemap
