import * as React from "react"
import Helmet from "react-helmet"
import { Link } from "gatsby"

import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Layout from "../../components/bbs-layout"
import Seo from "../../components/seo"
import IconArticle from "../../components/icon-article"
import Button from "../../components/button"
import ChapterMenu from "../../components/template-partials/global-components/chapter-menu/chapter-menu";

import ImcivreeBrochure from "../../images/icons/article/imcivree-brochure.png"
import ImcivreeStartGuide from "../../images/icons/article/imcivree-start-guide.png"
import ImcivreeGuide from "../../images/icons/article/imcivree-guide.png"
import TreatmentJournal from "../../images/icons/article/treatment-journal.png"
import RhythmBrochure from "../../images/icons/article/rhythm-intune-brochure.png"
import WellnessBrochure from "../../images/icons/article/wellness-brochure.png"
import ConsentForm from "../../images/icons/article/pt-consent-form.png"
import StepByStepThumb from "../../images/icons/article/step-by-step-thumbnail.png"
import TrainingVideo from "../../images/icons/article/training-video.png"
import ManagingPossibleSideEffectsWithImcivreeImg from "../../images/icons/article/managing-possible-side-effects-with-imcivree-thumbnail.png"
import LearnMoreAboutOneOnOneSupportFromAPemBBS from "../../images/icons/article/intune–ann-and-sabrina.png"
import HearAboutRealExperiencesVideoImg from "../../images/icons/article/hear-about-real-experiences-with-insatiable-hunger-bbs-thumbnail.png"
import ACloserLook from "../../images/icons/article/a-closer-look.jpg"
import EscucheAAmbar from "../../images/icons/article/ambar-spanish-video-thumbnail.png"
import ImcivreeImpactKathrynBoxingThumbnail from "../../images/icons/article/imcivree-impact-kathryn-boxing.png"



import PlayBtn from "../../images/icons/icon-play.svg"



const Resources = () => (
  <Layout>
    <Helmet bodyAttributes={{ class: "patient-bbs bbs-resources" }} />
    <Seo
      title="Helpful Resources | IMCIVREE® (setmelanotide) injection"
      description="Find resources to support you through your treatment with IMCIVREE® (setmelanotide), including the patient consent form, brochures, guides, and more. Please see full Prescribing Information and Important Safety Information."
      ogTitle="Resources | IMCIVREE® (setmelanotide) injection"
      ogDesc="Find resources to support you through your treatment with IMCIVREE® (setmelanotide), including the patient consent form, brochures, guides, and more. Please see full Prescribing Information and Important Safety Information."
    />

    <div className="content-block lg_pb-0 pb-0">
      <div className="row og col-reverse">
        <div className="column og small-12 medium-12 large-9 lg_pl-0">
          <h1 className="h1">Helpful resources</h1>
          <h2 className="h2">Here are some available resources to support you throughout your treatment with IMCIVREE</h2>

          <h3 className="h3" id="imcivree-resources">IMCIVREE resources</h3>
          <IconArticle title="IMCIVREE Brochure" id="imcivree-brochure" icon={ImcivreeBrochure} iconAlt="IMCIVREE brochure" btnCopy={<>Download IMCIVREE <br/>Brochure (English)</>} file="/24145-06_IMCIVREE_DIGITAL_Patient_Core_Brochure_v17_PPc.pdf" btnCopy2={<>Download IMCIVREE<br/> Brochure (Spanish)</>} file2="/IMCIVREE_Brochure_(Spanish).pdf">
            <p>Includes information about IMCIVREE, like clinical trial results and side effects, for those who are interested in learning more or are considering starting treatment.</p>
          </IconArticle>
        </div>

        <div className="column og small-12 medium-12 large-3 mt-1 mb-2">
            <ChapterMenu title={`Jump to:`}>
              <li><span className="interior-menu__icon"><FontAwesomeIcon icon={faAngleDown} /></span><a href="#imcivree-resources">IMCIVREE resources</a></li>
              <li><span className="interior-menu__icon"><FontAwesomeIcon icon={faAngleDown} /></span><a href="#intune-resources">Rhythm InTune resources</a></li>
              <li><span className="interior-menu__icon"><FontAwesomeIcon icon={faAngleDown} /></span><a href="#informational-videos">Informational videos</a></li>
            </ChapterMenu>
          </div>
      </div>
    </div>

    <div className="content-block lg_pt-0 pt-0">
      <div className="row og">
        <div className="column og small-12 lg_pl-0">
          <IconArticle title="IMCIVREE Getting Started Guide" icon={ImcivreeStartGuide} iconAlt="IMCIVREE Getting Started Guide" btnCopy={<>Download Getting <br/>Started Guide (English)</>} file="/Getting_Started_Guide.pdf" btnCopy2={<>Download Getting <br/>Started Guide (Spanish)</>} file2="/Getting_Started_Guide_(Spanish).pdf">
            <p className="mb-0">Includes helpful information about starting on IMCIVREE, including an overview of the clinical trial, what to expect, and helpful tips for giving injections.</p>
            <p className="small">NOTE: A printed version of the Getting Started Guide is also in the Getting Started Kit, which is included with your first shipment of IMCIVREE.</p>
          </IconArticle>


          <IconArticle title="How to Give IMCIVREE Guide" icon={ImcivreeGuide} iconAlt="How to Give IMCIVREE Guide" btnCopy={<>Download How to Give <br/>IMCIVREE Guide (English)</>} file="/How_To_Give_IMCIVREE_Guide.pdf" btnCopy2={<>Download How to Give <br/>IMCIVREE Guide (Spanish)</>} file2="/How_To_Give_IMCIVREE_Guide_(Spanish).pdf">
            <p className="mb-0">A step-by-step guide that includes everything you need to know about giving an IMCIVREE injection.</p>
            <p className="small">NOTE: A printed version of the How to Give IMCIVREE Guide is also in the Getting Started Kit, which is included with your first shipment of IMCIVREE.</p>
          </IconArticle>

          <IconArticle title="IMCIVREE Treatment Journal" icon={TreatmentJournal} iconAlt="IMCIVREE Treatment Journal" btnCopy={<>Download IMCIVREE <br/>Treatment Journal (English)</>} file="/IMCIVREE_Treatment_Journal.pdf" btnCopy2={<>Download IMCIVREE <br/>Treatment Journal (Spanish)</>} file2="/IMCIVREE_Treatment_Journal_(Spanish).pdf">
            <p className="mb-0">A journal that helps you track treatment by recording when and where IMCIVREE was injected each day, as well as any important notes you may want to remember to tell your doctor.</p>
            <p className="small">NOTE: A printed version of the IMCIVREE Treatment Journal is also in the Getting Started Kit, which is included with your first shipment of IMCIVREE.</p>
          </IconArticle>

          <h3 className="h3" id="intune-resources">Rhythm InTune resources</h3>

          <IconArticle title="Patient Consent Form" icon={ConsentForm} iconAlt="Patient Consent Form" btnCopy={<>Download Patient <br/>Consent Form (English)</>} file="/Patient_Consent_Form.pdf">
            <p>To be contacted by a Patient Education Manager at Rhythm InTune for personalized support, please fill out a Patient Consent Form.</p>
          </IconArticle>

          <IconArticle title="Rhythm InTune Brochure" icon={RhythmBrochure} iconAlt="Rhythm InTune Brochure" btnCopy={<>Download Rhythm <br/>InTune Brochure (English)</>} file="/Rhythm_InTune_Brochure.pdf"  btnCopy2={<>Download Rhythm <br/>InTune Brochure (Spanish)</>} file2="/Rhythm_InTune_Brochure-Sp.pdf">
            <p>A brochure detailing the personalized support, education, resources, and information that Rhythm InTune offers to people living with rare genetic diseases of obesity. You can also learn more about Rhythm InTune by clicking the link <Link to="/bbs/support-resources/" className="underline">here</Link>.</p>
          </IconArticle>

          <IconArticle title="Starting on Treatment: Step-By-Step Guide" icon={StepByStepThumb} iconAlt="Starting on Treatment: Step-By-Step Guide" btnCopy={<>Download Starting on Treatment Guide (English)</>} file="/Step-By-Step-Guide.pdf" btnCopy2={<>Download Starting on Treatment Guide (Spanish)</>} file2="/Step-By-Step-Guide-Sp.pdf">
            <p className="mb-0">A step-by-step guide for patients and caregivers outlining what you need to know when starting treatment and how Rhythm InTune can help you through the process.</p>
            <p className="small">NOTE: A printed version of the Starting on Treatment: Step-By-Step Guide is also in the Getting Started Kit, which is included with your first shipment of IMCIVREE.</p>
          </IconArticle>

          <IconArticle title="Wellness Brochure" icon={WellnessBrochure} iconAlt="Wellness Brochure" btnCopy={<>Download Wellness <br/> Brochure (English)</>} file="/PSP_Wellness_Brochure.pdf" btnCopy2={<>Download Wellness <br/> Brochure (Spanish)</>} file2="/PSP_Wellness_Brochure-Spanish_Version.pdf">
            <p>A brochure that provides wellness tips for people living with a rare genetic disease of obesity.</p>
          </IconArticle>

          <h3 className="h3" id="informational-videos">Informational videos</h3>
          <IconArticle title="Injection Training Video" icon={TrainingVideo} iconAlt="Informational Videos">
            <p>This video demonstrates how to inject and store your medicine.</p>
            <div className="download-btns-container row large-unstack">
              <div className="column small-12 large-4">
                <Link to="/bbs/how-to-take-imcivree/" className="global-button teal row align-middle">
                  <div className="column">Watch the video (English)</div>
                  <div className="column shrink"><img src={PlayBtn}/></div>
                </Link>
              </div>
              <div className="column small-12 large-4 ml-0 md_ml-2 lg_ml-2">
                <Link to="/bbs/how-to-take-imcivree-es/" className="global-button teal row align-middle">
                  <div className="column">Watch the video (Spanish)</div>
                  <div className="column shrink"><img src={PlayBtn}/></div>
                </Link>
              </div>
            </div>
          </IconArticle>


          <IconArticle title="Hear about real experiences with insatiable hunger" icon={HearAboutRealExperiencesVideoImg} iconAlt="IMCIVREE Injection Video">
            <p>Learn more about its impact on everyday life and how families and their healthcare teams are managing it together.</p>
            <div className="download-btns-container row large-unstack">
              <div className="column small-12 large-4">
                <Link to="/bbs/hear-about-real-experiences-with-insatiable-hunger/" className="global-button teal row align-middle">
                  <div className="column">Watch the video (English)</div>
                  <div className="column shrink"><img src={PlayBtn}/></div>
                </Link>
              </div>
              {/* <div className="column small-12 large-4 ml-0 md_ml-2 lg_ml-2">
                <Link to="/bbs/how-imcivree-given-es/" className="global-button teal row align-middle">
                  <div className="column">Watch the video (Spanish)</div>
                  <div className="column shrink"><img src={PlayBtn}/></div>
                </Link>
              </div> */}
            </div>
          </IconArticle>
          <IconArticle title="A closer look at how IMCIVREE works in the brain" icon={ACloserLook} iconAlt="IMCIVREE Injection Video">
            <p>See how IMCIVREE targets an impaired melanocortin-4 receptor pathway, a root cause of hyperphagia and obesity in BBS.</p>
            <div className="download-btns-container row large-unstack">
              <div className="column small-12 large-4">
                <Link to="/bbs/a-closer-look-at-how-imcivree-works-in-the-brain/" className="global-button teal row align-middle">
                  <div className="column">Watch the video (English)</div>
                  <div className="column shrink"><img src={PlayBtn}/></div>
                </Link>
              </div>
              {/* <div className="column small-12 large-4 ml-0 md_ml-2 lg_ml-2">
                <Link to="/bbs/how-imcivree-given-es/" className="global-button teal row align-middle">
                  <div className="column">Watch the video (Spanish)</div>
                  <div className="column shrink"><img src={PlayBtn}/></div>
                </Link>
              </div> */}
            </div>
          </IconArticle>
          <IconArticle title="What to expect with IMCIVREE" icon={ImcivreeImpactKathrynBoxingThumbnail} iconAlt="What to expect with IMCIVREE">
            <p>Hear from families and clinicians about how IMCIVREE is bringing hope for people living with BBS.</p>
            <div className="download-btns-container row large-unstack">
              <div className="column small-12 large-4">
                <Link to="/bbs/the-impact-of-imcivree/" className="global-button teal row align-middle">
                  <div className="column">Watch the video (English)</div>
                  <div className="column shrink"><img src={PlayBtn}/></div>
                </Link>
              </div>
              {/* <div className="column small-12 large-4 ml-0 md_ml-2 lg_ml-2">
                <Link to="/bbs/how-imcivree-given-es/" className="global-button teal row align-middle">
                  <div className="column">Watch the video (Spanish)</div>
                  <div className="column shrink"><img src={PlayBtn}/></div>
                </Link>
              </div> */}
            </div>
          </IconArticle>
          <IconArticle title="Managing possible side effects with IMCIVREE" icon={ManagingPossibleSideEffectsWithImcivreeImg} iconAlt="IMCIVREE Injection Video">
            <p>Hear from healthcare providers who have experience managing their patient’s side effects.</p>
            <div className="download-btns-container row large-unstack">
              <div className="column small-12 large-4">
                <Link to="/bbs/managing-possible-side-effects-with-imcivree/" className="global-button teal row align-middle">
                  <div className="column">Watch the video (English)</div>
                  <div className="column shrink"><img src={PlayBtn}/></div>
                </Link>
              </div>
              {/* <div className="column small-12 large-4 ml-0 md_ml-2 lg_ml-2">
                <Link to="/bbs/how-imcivree-given-es/" className="global-button teal row align-middle">
                  <div className="column">Watch the video (Spanish)</div>
                  <div className="column shrink"><img src={PlayBtn}/></div>
                </Link>
              </div> */}
            </div>
          </IconArticle>
          {/* <IconArticle title="How to take IMCIVREE" icon={TrainingVideo} iconAlt="IMCIVREE Injection Video">
            <p>See a demonstration on how to inject IMCIVREE at home.</p>
            <div className="download-btns-container row large-unstack">
              <div className="column small-12 large-4">
                <Link to="/bbs/how-to-take-imcivree/" className="global-button teal row align-middle">
                  <div className="column">Watch the video (English)</div>
                  <div className="column shrink"><img src={PlayBtn}/></div>
                </Link>
              </div>
            </div>
          </IconArticle> */}

          <IconArticle title="Learn more about one-on-one personalized support from a Patient Education Manager" icon={LearnMoreAboutOneOnOneSupportFromAPemBBS} iconAlt="How IMCIVREE Works">
            <p>Hear from people who have experienced the benefits of working with a Patient Education Manager every step of the way.</p>
            <div className="download-btns-container row large-unstack">
              <div className="column small-12 large-4">
                <Link to="/bbs/learn-more-about-one-on-one-personalized-support-from-a-patient-education-manager/" className="global-button teal row align-middle">
                  <div className="column">Watch the video (English)</div>
                  <div className="column shrink"><img src={PlayBtn}/></div>
                </Link>
              </div>
              {/* <div className="column small-12 large-4 ml-0 md_ml-2 lg_ml-2">
                <Link to="/bbs/how-imcivree-given-es/" className="global-button teal row align-middle">
                  <div className="column">Watch the video (Spanish)</div>
                  <div className="column shrink"><img src={PlayBtn}/></div>
                </Link>
              </div> */}
            </div>
          </IconArticle>

          <IconArticle title="El viaje de Ambar a IMCIVREE" icon={EscucheAAmbar} iconAlt="El viaje de Ambar a IMCIVREE">
            <p>Escuche a Ambar, que vive con BBS, y a su madre Mayra.</p>
            <div className="download-btns-container row large-unstack">
              <div className="column small-12 large-4">
                <Link to="/bbs/ambars-journey-to-imcivree/" className="global-button teal row align-middle">
                  <div className="column">Vea el vídeo (Español) </div>
                  <div className="column shrink"><img src={PlayBtn}/></div>
                </Link>
              </div>
              {/* <div className="column small-12 large-4 ml-0 md_ml-2 lg_ml-2">
                <Link to="/bbs/video-ambars-journey-to-imcivree/" className="global-button teal row align-middle">
                  <div className="column">Watch the video (Spanish)</div>
                  <div className="column shrink"><img src={PlayBtn}/></div>
                </Link>
              </div> */}
            </div>
          </IconArticle>

          {/* <IconArticle title="BBS Educational Flashcard" icon={DownloadBbsEducationalFlashcard} iconAlt="BBS Educational Flashcard" btnCopy={<>Download BBS Educational Flashcard (English)</>} file="/BBS-Educational-Flashcard.pdf">
            <p>Includes information to help educate school staff on the basics of BBS and ways to support students with the disease.</p>
          </IconArticle> */}

          <div className="text-center mt-2 md_mt-2" style={{ maxWidth: '450px', margin: '0 auto' }}>
            <Button copy="Get answers to frequently asked questions about IMCIVREE" url="/bbs/faq/" />
          </div>

        </div>
      </div>
    </div>
  </Layout>
)

export default Resources
