import React from "react"
import Helmet from "react-helmet"

import Layout from "../../../components/hcp-bbs-layout"
import Seo from "../../../components/seo"
import HangingRef from "../../../components/hanging-ref"
import Callout from "../../../components/callout"
import Button from "../../../components/button"
import Modal from "../../../components/modal"

import PatientCaregiverQuote from "../../../components/patient-caregiver-quote"
import KathrynQuote from "../../../images/kathryn-quote.png"
import KatQuote from "../../../images/kat-quote.png"
import AlainaQuote from "../../../images/alaina_vidmar.png"
import RachelQuote from "../../../images/rachel-and-family-2.png"
import ChristyDavisQuote from  "../../../images/christy_davis.png"
import PointReduction from "../../../images/point-reduction.png"
import HungerScoresChart from "../../../images/hunger-scores-chart.png"

const HungerReduction = () => {
  return (
    <Layout>
      <Helmet bodyAttributes={{ class: "hcp-bbs efficacy-child-page" }} />
      <Seo
        title="Hunger Reduction | IMCIVREE® (setmelanotide) injection | For HCPs"
        description="Learn about hunger reduction in patients 12 years of age and over with BBS. Please see full Prescribing Information for Important Safety Information."
        ogTitle="Clinical Study Hunger Results | IMCIVREE® (setmelanotide) injection"
        ogDesc="See the clinical study hunger results of IMCIVREE® (setmelanotide) in patients 12 years of age and over. Please see full Prescribing Information for Important Safety Information."
      />
      <div className="content-block" style={{ paddingBottom: '0'}}>
        <h2 className="h2--no-margin-bottom lg_mt-1">
          In patients &ge;12 years of age with BBS,
        </h2>
        <h1 className="h1">
          IMCIVREE delivered early, significant, and sustained hunger reduction
          <sup>1</sup>
        </h1>

        <div className="row">
        <div className="columns">
            <h2 className="h2 text-center small-12 medium-12 large-12">
              Hunger scores in the 14-week placebo-controlled and 52-week
              open-label periods<sup>1-3*</sup>
            </h2>

              <p className="text-center hide-for-medium hide-for-large">&larr; Swipe left or right to view &rarr;</p>
               
              <div className="col-reverse hunger-scores-chart">
                <div className="small-12 medium-12 large-12 point-reduction text-center mb-2">
                  <img src={PointReduction} alt="" style={{width: '184px'}} />
                </div>
                <div className="small-12 medium-12 large-12 chart-overflow-x mb-2 md_mb-2">
                  <img src={HungerScoresChart} alt="Hunger change score in patients 12 years and older with BBS" />
                </div>
              </div>
            </div>
            </div>
            
            <div className="row" >
              <div className="columns small-12 medium-8">
                <Callout fullWidth className="light-teal left text-left">
                  <p>
                  Patients who switched from placebo to IMCIVREE experienced a rapid reduction in hunger, matching that of patients initially assigned to IMCIVREE<sup>3</sup>
                  </p>
                </Callout>
              </div>
              <div className="columns small-12 medium-1"></div>
              <div className="columns small-12 medium-3 align-self-middle align-center mt-2 md_mt-2">
                <Modal id="hunger-scale-hcp-bbs" />
              </div>
            </div>
            <div className="mt-2 mb-2 md_mt-3 md_mb-2">
              <HangingRef
                mb0
                symbol="*"
                isAsterisk
                copy={
                  <>
                    Patients &ge;12 years of age who were able to self-report their
                    hunger (n=14) recorded their daily maximal hunger in a
                    diary, which was then assessed by the Daily Hunger
                    Questionnaire Item 2. Hunger was scored on an 11-point scale
                    from 0 (“not hungry at all”) to 10 (“hungriest possible”).<sup>2</sup>
                  </>
                }
              />
              <HangingRef
                mb0
                symbol={
                  <><sup>&dagger;</sup></>
                }
                isAsterisk
                copy={
                  <>
                    During the placebo-controlled period, dose titration to a
                    fixed dose of IMCIVREE 3 mg given subcutaneously once daily
                    was performed during the first 2 weeks of both the
                    placebo-controlled and open-label periods to maintain
                    blinding.<sup>2</sup>
                  </>
                }
              />
            </div>
        </div>
        <div className="columns">
      </div>

      <div className="content-block pt-0 md_pt-0">
        <div id="patient-caregiver-quotes">
          <div className="flex-wrapper">
            <PatientCaregiverQuote
              xlarge
              imgSrc={KathrynQuote}
              alt="Person living with BBS"
              copy="Before IMCIVREE, I didn’t realize how much time I spent focusing on food, and how much that was affecting my day-to-day and the other things I could be accomplishing."
              signoff="— Kathryn, who is living with BBS"
            />

            <PatientCaregiverQuote
              xlarge
              order="reverse"
              imgSrc={KatQuote}
              alt="Caregiver of a child with BBS"
              copy="The change in Reed’s hunger has cascaded into many positive life changes for all of us. There's less agitation and anxiety over hunger or family meals. This is simple normalcy for many families, but for us, they’re moments I’ll never take for granted."
              signoff="— Kat, caregiver of a child living with BBS"
            />

            <PatientCaregiverQuote
              xlarge
              imgSrc={RachelQuote}
              alt="Caregiver of a child with BBS"
              copy="He is no longer digging through the fridge or garbage, so we do not lock them anymore. He isn’t asking for food constantly between meals and snacks, and I sometimes find myself realizing it’s been a few hours and asking him if he’s ready for a snack."
              signoff="— Rachel, caregiver of a child living with BBS"
            />

            <PatientCaregiverQuote
            xlarge
            order="reverse"
            imgSrc={AlainaQuote}
            alt="Caregiver of a child with BBS"
            copy="Patients and families living with BBS often express such gratitude and relief when started on IMCIVREE and see the weight trajectories and hyperphagia start to improve. To have a medication that can support correcting the biology that is dysregulated and to see positive outcomes provides hope."
            signoff="— Alaina Vidmar, Pediatric Endocrinologist"
          />

          <PatientCaregiverQuote
              xlarge
              imgSrc={ChristyDavisQuote}
              alt="Caregiver of a child with BBS"
              copy="These are patients that nothing else has ever worked. So to see them so happy about having something that's finally helping is amazing. Now all they talk about is, &rsquo;I'm not hungry all the time. I'm not seeking out food all the time. I'm able to do other things.&lsquo;
That shift from the number on the scale to the quality of life, I really love that."
              signoff="— Christy Davis, Obesity and Weight Management Specialist"
            />
            
          </div>
        </div>
      </div>
      <div className="content-block">
        <div className="text-center my-3 md_my-2">
          <Button
            copy="See change in quality of life"
            url="/hcp/bbs/quality-of-life/"
          />
        </div>
        <div className="content-block md_pt-2 md_pb-1">
          <p className="references footnote">
            <strong>References:</strong> 
            <strong>1.</strong> Haqq AM et al. <em>Lancet Diabetes Endocrinol.</em> 2022;10(12):859-868. doi:10.1016/S2213-8587(22)00277-7. Supplemental appendix available at: <span className="wordbreak">https://www.thelancet.com/journals/landia/article/PIIS2213-8587(22)00277-7/fulltext.</span> 
            <strong>2.</strong> IMCIVREE [prescribing information]. Boston, MA. Rhythm Pharmaceuticals, Inc. 
            <strong>3.</strong> Data on file. Rhythm Pharmaceuticals, Inc. Boston, MA.
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default HungerReduction
