import React from 'react'

const IsiComboBbs = () => {

  return (
    <div id="isi-wrapper">
    <div id="isi-hcp-ppl" className="isi row og mb-2">
      <div className="columns og small-12">
      <p className="mb-0"><strong className="color-teal">What is IMCIVREE?</strong></p>
      <p className="mb-0">IMCIVREE is a prescription medicine used in adults and children 6 years of age and older with obesity due to:</p>
      <ul className="neon-green-bullets mb-1">
        <li>The genetic conditions pro-opiomelanocortin (POMC), proprotein convertase subtilisin/kexin type 1 (PCSK1), or leptin receptor (LEPR) deficiency, to help them lose weight and keep the weight off</li>
        <li>Bardet-Biedl syndrome (BBS) to help them lose weight and keep the weight off</li>
      </ul>
      <p>Your healthcare provider should order an FDA-approved test to confirm POMC, PCSK1, or LEPR deficiency before you start using IMCIVREE.</p>
      <p className="mb-1"></p>
      <p className="mb-0">IMCIVREE is not for use in people with the following conditions because it may not work:</p>
      <ul className="neon-green-bullets">
        <li>Obesity due to suspected POMC, PCSK1, or LEPR deficiency not confirmed by genetic testing or with benign or likely benign genetic testing results</li>
        <li>Other types of obesity not related to POMC, PCSK1, or LEPR deficiency, or BBS, including obesity associated with other genetic conditions and general obesity</li>
      </ul>
      <p className="mb-1"></p>
      <p>It is not known if IMCIVREE is safe and effective in children under 6 years of age.</p>
      <p className="mb-1"></p>
      <p className="mb-0"><strong>Before you use IMCIVREE, tell your healthcare provider about all your medical conditions, including if you:</strong></p>
      <ul className="neon-green-bullets">
        <li>Have or have had areas of darkened skin, including skin discoloration (hyperpigmentation)</li>
        <li>Have or have had depression, or suicidal thoughts or behavior</li>
        <li>Have kidney problems</li>
        <li>Are pregnant or planning to become pregnant. Losing weight while pregnant may harm your unborn baby. Your healthcare provider may stop your treatment with IMCIVREE if you become pregnant. Tell your healthcare provider if you become pregnant or think you might be pregnant during treatment with IMCIVREE</li>
        <li>Are breastfeeding or plan to breastfeed. It is not known if IMCIVREE passes into your breast milk. You should not breastfeed during treatment with IMCIVREE</li>
      </ul>
      <p className="mb-1"></p>
      <p><strong>Tell your healthcare provider about all the medicines you take,</strong> including prescription and over-the-counter medicines, vitamins, and herbal supplements.</p>
      <p className="mb-1"></p>
      <p><strong>See the detailed Instructions for Use</strong> that come with your IMCIVREE to learn how to prepare and inject IMCIVREE, and how to properly throw away (dispose of) used syringes and needles.</p>
      <p className="mb-1"></p>
      <p className="mb-0"><strong className="color-teal">What are the possible side effects of IMCIVREE?</strong></p>
      <p className="mb-0"><strong>IMCIVREE may cause serious side effects, including:</strong></p>
      <ul className="neon-green-bullets">
        <li><strong>Male and female sexual function problems.</strong> IMCIVREE can cause an erection that happens without any sexual activity in males (spontaneous penile erection) and unwanted sexual reactions (changes in sexual arousal that happen without any sexual activity) in females. If you have an erection lasting longer than 4 hours, get emergency medical help right away</li>
        <li><strong>Depression and suicidal thoughts or actions.</strong> You or a caregiver should call your healthcare provider right away if you have any new or worsening symptoms of depression, suicidal thoughts or behaviors, or any unusual changes in mood or behavior</li>
        <li><strong>Increased skin pigmentation and darkening of skin lesions (moles or nevi) you already have.</strong> These changes happen because of how IMCIVREE works in the body and will go away when you stop using IMCIVREE. You should have a full body skin exam before starting and during treatment with IMCIVREE to check for skin changes</li>
        <li><strong>Benzyl alcohol toxicity.</strong> Benzyl alcohol is a preservative in IMCIVREE. Benzyl alcohol can cause serious side effects, including death, in premature and low-birth weight infants who have received medicines that contain benzyl alcohol. IMCIVREE should not be used in premature and low-birth weight infants</li>
      </ul>
      <p className="mb-1"></p>
      <p><strong>The most common side effects of IMCIVREE include</strong> darkening of the skin, injection site reactions, nausea, headache, diarrhea, stomach pain, vomiting, depression, and an erection that happens without any sexual activity in males.</p>
      <p className="mb-1"></p>
      <p>These are not all the possible side effects of IMCIVREE. Call your doctor for medical advice about side effects. You may report side effects to FDA at 1-800-FDA-1088 or to Rhythm Pharmaceuticals at 1-833-789-6337.</p>
      <p><strong>Please see the full <a className="underline" rel="noopener noreferrer" href="https://rhythm-vault-digital-publishing-production.s3.amazonaws.com/IMCIVREEPatientPrescribingInformation.pdf" target="_blank">Patient Information</a> for additional Safety Information.</strong></p>
      </div>
    </div>
    </div>
  )
}

export default IsiComboBbs
