import React from 'react'
import vpl from "../images/video-placeholder.jpg";

const TakingImcivreeVideo = (props) => {
  return (
    <>
    <div className="row og" style={{ marginBottom: `21px` }}>
      <div className={`columns responsive-video-container ` + props.className}>
        <iframe src="https://player.vimeo.com/video/1013216794?badge=0&autopause=0&player_id=0&app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" title="How to Give IMCIVREE"></iframe>
        <img src={vpl} width="100%" height="auto" alt="How to Inject IMCIVREE video placeholder"/>
      </div>
    </div>
    </>
  )
}

export default TakingImcivreeVideo
