import React from 'react'
import Button from "../components/button"

import MainLogo from '../components/template-partials/global-components/main-logo'

const FourOhFourText = () => {
  return (
    <>
      <div className="content-block">
        <MainLogo to="/" location="four-oh-four"/>
        <h1 className="h1 lg_mt-1 mt-1">404 Page Not Found</h1>
        <p>The page or content you are looking for cannot be found or is temporarily unavailable.</p>
        <div className="hr show-for-large"></div>
        <div className="row">
        <div className="row og text-center medium-text-left lg_mt-2 mt-2">
          {/* <div className="column og small-12 medium-shrink">
            <Button className="full-width color-teal arrow-reverse" teal="true" copy={<>Back To Previous Page</>} url="javascript:history.back()" />
          </div> */}
          <div className="column og small-12 medium-shrink mt-2">
            <Button className="full-width color-teal" teal="true" copy={<>Home</>} url="/" />
          </div>
        </div>
        </div>
      </div>
    </>
  )
}

export default FourOhFourText
