import React, { useEffect } from "react"

import { Link } from "gatsby"

import { faAngleDown } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import Layout from "../../../components/hcp-bbs-layout"
import Seo from "../../../components/seo"
import Button from "../../../components/button"
import IconArticle from "../../../components/icon-article"

import startForm from "../../../images/icons/article/start-form.png"

import patientDiagnosis from "../../../images/icons/article/patient-diagnosis-tool.png"
import hyperphagiaQuestionaire from "../../../images/icons/article/hyperphagia-questionaire.png"

import ImcivreeBrochure from "../../../images/icons/article/imcivree-brochure.png"
import ImcivreeStartGuide from "../../../images/icons/article/imcivree-start-guide.png"
import ImcivreeGuide from "../../../images/icons/article/imcivree-guide.png"
import TreatmentJournal from "../../../images/icons/article/treatment-journal.png"
import RhythmBrochure2 from "../../../images/icons/article/rhythm-intune-brochure-2.png"

import RealExperiencesWithHyperphagiaThumbnail from "../../../images/icons/article/real-experiences-with-hyperphagia-thumbnail.png"
import DiagnosingBbsInYourPracticeThumbnail from "../../../images/icons/article/diagnosing-bbs-in-your-practice-thumbnail.png"
import ManagingAdverseEventsAndInjectionTrainingThumbnail from "../../../images/icons/article/managing-adverse-events-and-injection-training-thumbnail.png"
import ImpactImcivreeVideoThumbnail from "../../../images/icons/article/impact-imcivree-video-thumbnail.png"
import LearnMoreAboutPemThumbnail from "../../../images/icons/article/learn-more-about-pem-thumbnail.png"

//Chapter Menu
import ChapterMenu from "../../../components/template-partials/global-components/chapter-menu/chapter-menu"

import PlayBtn from "../../../images/icons/icon-play.svg"

const DownloadableResources = () => {
  const scrollIntoViewWithOffset = (selector, offset) => {
    window.scrollTo({
      behavior: "smooth",
      top:
        document.querySelector(selector).getBoundingClientRect().top -
        document.body.getBoundingClientRect().top -
        offset,
    })
  }

  const scrollToSection = (e, sectionId) => {
    e.preventDefault();
    const section = document.getElementById(sectionId);
    if (section) {
      const offset = -60; // Adjust this offset as needed
      const bodyRect = document.body.getBoundingClientRect().top;
      const elementRect = section.getBoundingClientRect().top;
      const offsetPosition = elementRect - bodyRect + offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    } else {
      console.error(`Element with ID "${sectionId}" not found.`);
    }
  };

  useEffect(() => {
    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
      console.log("this")
      anchor.addEventListener("click", function (e) {
        e.preventDefault()
        //console.log(document.querySelector(this.getAttribute('href')).getAttribute('id'));
        scrollIntoViewWithOffset(
          "#" +
            document
              .querySelector(this.getAttribute("href"))
              .getAttribute("id"),
          40
        )
      })
    })
  }, [])

  return (
    <Layout>
      <Seo
        title="Webinars & Downloadable Resources | IMCIVREE® (setmelanotide) injection | For HCPs"
        description="Sign up for upcoming educational programs about IMCIVREE® (setmelanotide) and download resources. Please see full Prescribing Information for Important Safety Information."
        ogTitle="Webinars & Downloadable Resources | IMCIVREE® (setmelanotide) injection"
        ogDesc="Sign up for upcoming educational programs about IMCIVREE® (setmelanotide) and download resources. Please see full Prescribing Information for Important Safety Information."
      />

      <div className="content-block">
        <div className="row col-reverse">
          <div className="small-12 medium-12 large-9">
           

            <p className="h1 mt-2 md_mt-2 pr-1 md_pr-1">Contact a representative to speak about IMCIVREE for obesity due to BBS</p>

            <Button
              copy="Contact a representative"
              url="/hcp/bbs/sign-up/"
            />

            <h2 id="downloaded-resources" className="h1 mt-2 md_mt-2"  style={{ paddingTop: '160px!important' }}>
              Downloadable resources
            </h2>
          </div>

          <div className="small-12 medium-12 large-3 mb-2 lg_mt-1">


            {/* NOTE: Keep in row / columns order like this */}
            <div className="row">
              <div className="columns">
                <ChapterMenu title={`Jump to:`} style={{ maxWidth: "unset" }}>
                  <li>
                    <span className="interior-menu__icon">
                      <FontAwesomeIcon icon={faAngleDown} />
                    </span>
                    <a href="#downloaded-resources"  onClick={(e) => scrollToSection(e, 'downloaded-resources')}>Downloadable resources</a>
                  </li>
                  <li>
                    <span className="interior-menu__icon">
                      <FontAwesomeIcon icon={faAngleDown} />
                    </span>
                    <a href="#informational-videos"   onClick={(e) => scrollToSection(e, 'informational-videos')}>Informational videos</a>
                  </li>
                  <li>
                    <span className="interior-menu__icon">
                      <FontAwesomeIcon icon={faAngleDown} />
                    </span>
                    <a href="#patient-resources"  onClick={(e) => scrollToSection(e, 'patient-resources')}>Patient resources</a>
                  </li>
                
                </ChapterMenu>
              </div>
            </div>

          </div>
        </div>

        <IconArticle
          title="IMCIVREE Prescription Start Form"
          icon={startForm}
          iconAlt="IMCIVREE Prescription Start Form"
          btnCopy="Download (English)"
          file="/Start_Form.pdf"
          btnCopy2="Download (Spanish)"
          file2="/Spanish_Version_IMCIVREE_Start_Form.pdf"
        >
          <p>
            Serves as your patient’s prescription and provides an opportunity
            for your patient to enroll in Rhythm InTune, a support program from
            Rhythm Pharmaceuticals.<br/> Note: The preferred method of starting IMCIVREE is via our Prescription Start Form. 
            If you would prefer to e-prescribe, <strong>please be sure to select PANTHERx Rare Pharmacy</strong>.</p>
        </IconArticle>

        <IconArticle
          title="Patient Diagnosis Tool"
          icon={patientDiagnosis}
          iconAlt="Patient Diagnosis Tool"
          btnCopy="Download (English)"
          file="/Patient_Diagnosis_Tool.pdf"
        >
          <p>
            This tool dives deeper into the clinical manifestations of BBS and
            provides helpful information that can aid in your diagnosis.
          </p>
        </IconArticle>

        <IconArticle
          title="Hyperphagia Questionnaire"
          icon={hyperphagiaQuestionaire}
          iconAlt="Hyperphagia Questionnaire"
          btnCopy="Download (English)"
          file="/Hyperphagia_Questionnaire.pdf"
        >
          <p>
            This questionnaire helps to identify hyperphagia in your patients
            and determine if/how it is impacting their daily lives.
          </p>
        </IconArticle>


        <h2 id="informational-videos" className="h1 mt-2 md_mt-2"  style={{ paddingTop: '160px!important' }}>
          Informational videos
        </h2>

        <div className="row md_mt-2">
          <div className="columns og small-12 large-4 informational-video-single px-0 md_pl-0 mb-0 md_mb-2">

            <div className="card">
              <figure>
                <img src={DiagnosingBbsInYourPracticeThumbnail} alt="Diagnosing BBS in your practice" />
              </figure>
              <div className="card-body">
                <h4 className="h4 h4-webinars jost-semibold mt-1 md_mt-2">Diagnosing BBS in your practice</h4>
                <p className="md_pb-0">
                  Recognize the various clinical manifestations of BBS to accelerate a diagnosis.
                </p>
              </div>
                <Link to="/hcp/bbs/diagnosing-bbs-in-your-practice" className="global-button row align-middle mb-2">
                  <div className="column">Watch the video</div>
                  <div className="column shrink">
                    <img src={PlayBtn} />
                  </div>
                </Link>
            </div>

          </div>

          <div className="columns og small-12 large-4 informational-video-single px-0 mb-0 md_mb-2">

            <div className="card">
              <figure>
                <img src={RealExperiencesWithHyperphagiaThumbnail} alt="Real experiences with hyperphagia" />
              </figure>
              <div className="card-body">
                <h4 className="h4 h4-webinars jost-semibold mt-1 md_mt-2">Real experiences with hyperphagia</h4>
                <p className="md_pb-0">
                  Learn about its true impact and how physicians and families are managing it together.
                </p>
              </div>

                <Link to="/hcp/bbs/real-experiences-with-hyperphagia/" className="global-button row align-middle mb-2">
                  <div className="column">Watch the video</div>
                  <div className="column shrink">
                    <img src={PlayBtn} />
                  </div>
                </Link>
            </div>

          </div>

          <div className="columns og small-12 large-4 informational-video-single px-0  mb-0 md_mb-2">

            <div className="card">
              <figure>
                <img src={ImpactImcivreeVideoThumbnail} alt="" />
              </figure>
              <div className="card-body">
                <h4 className="h4 h4-webinars jost-semibold mt-1 md_mt-2">The impact of IMCIVREE</h4>
                <p className="md_pb-0">
                Hear from families and clinicians about how IMCIVREE is bringing hope for people living with BBS.
                </p>
              </div>

                <Link to="/hcp/bbs/the-impact-of-imcivree/" className="global-button row align-middle mb-2">
                  <div className="column">Watch the video</div>
                  <div className="column shrink">
                    <img src={PlayBtn} />
                  </div>
                </Link>
            </div>

          </div>
      </div>

      <div className="row md_mt-2">

        <div className="columns og small-12 large-4 informational-video-single px-0 mb-0 md_mb-2">

          <div className="card">
            <figure>
              <img src={ManagingAdverseEventsAndInjectionTrainingThumbnail} alt="Managing adverse events and injection training" />
            </figure>
            <div className="card-body">
              <h4 className="h4 h4-webinars jost-semibold mt-1 md_mt-2">Managing adverse events<br className="show-for-large"/> and injection training</h4>
              <p className="md_pb-0">
                Hear from physicians who have prescribed IMCIVREE.
              </p>
            </div>

              <Link to="/hcp/bbs/managing-adverse-events-and-injection-training/" className="global-button row align-middle mb-2">
                <div className="column">Watch the video</div>
                <div className="column shrink">
                  <img src={PlayBtn} />
                </div>
              </Link>
          </div>

        </div>

        <div className="columns og small-12 large-4 informational-video-single px-0 mb-0 md_mb-2">

          <div className="card">
            <figure>
              <img src={LearnMoreAboutPemThumbnail} alt="Learn more about Patient Education Managers" />
            </figure>
            <div className="card-body">
              <h4 className="h4 h4-webinars jost-semibold mt-1 md_mt-2">Learn more about Patient Education Managers</h4>
              <p className="md_pb-0">
                See how they can provide optimal support for patients in your practice and help them start and stay on therapy.
              </p>
            </div>

              <Link to="/hcp/bbs/learn-more-about-patient-education-managers" className="global-button row align-middle mb-2">
                <div className="column">Watch the video</div>
                <div className="column shrink">
                  <img src={PlayBtn} />
                </div>
              </Link>
          </div>

        </div>
      </div>     

        <h2 id="patient-resources" className="h1 mt-2 md_mt-2" style={{ paddingTop: '160px!important' }}>
          Patient resources
        </h2>
        <h3 className="h2">
          Here are some available resources to support your patients throughout
          their treatment with IMCIVREE
        </h3>
        <IconArticle
          title="IMCIVREE Brochure"
          icon={ImcivreeBrochure}
          iconAlt="IMCIVREE Brochure"
          btnCopy="Download (English)"
          file="/24145-06_IMCIVREE_DIGITAL_Patient_Core_Brochure_v17_PPc.pdf"
          btnCopy2="Download (Spanish)"
          file2="/IMCIVREE_Brochure_(Spanish).pdf"
        >
          <p>
            Includes information about IMCIVREE for those who are interested in
            learning more or are considering starting treatment.
          </p>
        </IconArticle>
        <IconArticle
          title="IMCIVREE Getting Started Guide"
          icon={ImcivreeStartGuide}
          iconAlt="IMCIVREE Getting Started Guide"
          btnCopy="Download (English)"
          file="/Getting_Started_Guide.pdf"
          btnCopy2="Download (Spanish)"
          file2="/Getting_Started_Guide_(Spanish).pdf"
        >
          <p className="mb-0">
            Includes helpful information about starting on IMCIVREE, including
            an overview of the clinical trial, what to expect, and helpful tips
            for giving injections.
          </p>
          <p>
            Note: A printed version of the Getting Started Guide is also in the
            Getting Started Kit, which is included with your first shipment of
            IMCIVREE and supplies.
          </p>
        </IconArticle>
        <IconArticle
          title="How to Give IMCIVREE Guide"
          icon={ImcivreeGuide}
          iconAlt="How to Give IMCIVREE Guide"
          btnCopy="Download (English)"
          file="/How_To_Give_IMCIVREE_Guide.pdf"
          btnCopy2="Download (Spanish)"
          file2="/How_To_Give_IMCIVREE_Guide_(Spanish).pdf"
        >
          <p className="mb-0">
            A step-by-step guide that includes everything you need to know about
            giving an IMCIVREE injection.
          </p>
          <p>
          Note: A printed version of the How to Give IMCIVREE Guide is also in
            the Getting Started Kit, which is included with your first shipment
            of IMCIVREE and supplies.
          </p>
        </IconArticle>
        <IconArticle
          title="IMCIVREE Treatment Journal"
          icon={TreatmentJournal}
          iconAlt="IMCIVREE Treatment Journal"
          btnCopy="Download (English)"
          file="/IMCIVREE_Treatment_Journal.pdf"
          btnCopy2="Download (Spanish)"
          file2="/IMCIVREE_Treatment_Journal_(Spanish).pdf"
        >
          <p className="mb-0">
            A useful journal that helps you track treatment by recording when
            and where IMCIVREE was injected each day, as well as any important
            notes you may want to remember to tell your doctor.
          </p>
          <p>
            Note: A printed version of the IMCIVREE Treatment Journal is also in
            the Getting Started Kit, which is included with your first shipment
            of IMCIVREE and supplies.
          </p>
        </IconArticle>
        <IconArticle
          title="Rhythm InTune Brochure"
          icon={RhythmBrochure2}
          iconAlt="Rhythm InTune Brochure"
          btnCopy="Download (English)"
          file="/Rhythm_InTune_Brochure.pdf"
          btnCopy2="Download (Spanish)"
          file2="/Rhythm_InTune_Brochure-Sp.pdf"
        >
          <p className="mb-0">
            A brochure detailing the personalized support, education, resources,
            and information that Rhythm InTune offers to people living with rare
            diseases of obesity.
          </p>
          <p>
            Note: You can also learn more about Rhythm InTune by clicking the
            link <Link to="/hcp/bbs/patient-support/">here</Link>.
          </p>
        </IconArticle>


        <div className="text-center lg_mt-3 mt-2">
          <Button
            textCenter
            copy="Frequently asked questions"
            url="/hcp/bbs/faq/"
          />
        </div>
        
      </div>
    </Layout>
  )
}

export default DownloadableResources
