import { Link } from "gatsby"
import React from "react"

const TopHeader = ({ isiHandler, toggleActive, ppl }) => {
  return (
    <div className="top_header desktop-only">
      <p style={{ marginBottom: 0 }}>
        Site intended for US healthcare professionals only.
      </p>

      <div className="top_nav_items">
        <div className="slanted_top_nav_item">
          <div className="nav_text">
            <Link className="color-gray" to={ppl ? "/ppl/" : "/bbs/"}>
              Visit Patient Site
            </Link>
          </div>
        </div>
        <div className="slanted_top_nav_item">
          <div className="nav_text">
            <a className="color-gray" onClick={isiHandler}>
              Important Safety Information
            </a>
          </div>
        </div>
        <div className="slanted_top_nav_item">
          <div className="nav_text">
            <a
              className="color-gray"
              href="https://rhythm-vault-digital-publishing-production.s3.amazonaws.com/IMCIVREEPrescribingInformation.pdf"
              target="_blank"
            >
              Prescribing Information
            </a>
          </div>
        </div>

        <div className="utility-nav-wrapper tertiary-nav desktop-only">
          <ul className="nav-items">
            <li className="other-indications" onClick={e => toggleActive(e)}>
              Other Indications
              <ul className="nav-sub-items">
                <li>
                  <Link to="/hcp/ppl/">POMC, PCSK1, and LEPR deficiency</Link>
                </li>
                <li>
                  <Link to="/hcp/bbs/">Bardet-Biedl syndrome</Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default TopHeader
