import React from "react"
import CalloutBox from "./callout-box"
import LineItem from "./template-partials/global-components/list-item"

import NotEqualIcon from "../images/obesity-not-equal-icon.svg"

const HcpPplOvereatingDisordersChart = props => {
  return (
    <div id="overeating-disorders">
      <div className="row">
        <div className="columns small-12 large-expand mb-1">
          <CalloutBox
            headerStyles={{ backgroundColor: "#939599" }}
            contentStyles={{ backgroundColor: "#FFF" }}
            headerCopy="Occasional overeating<sup>4</sup>"
          > 
            <p>
              Eating beyond a feeling of satiety at a special occasion or
              celebratory meal (eg, Thanksgiving)
            </p>
          </CalloutBox>
        </div>

        <div className="show-for-large" style={{ width: `10px` }}></div>

        <div className="columns small-12 large-expand mb-1">
          <CalloutBox
            headerStyles={{ backgroundColor: "#939599" }}
            contentStyles={{ backgroundColor: "#FFF" }}
            headerCopy="Hedonic overeating<sup>5</sup>"
          >
            <p>
              Eating beyond metabolic requirements based on the expectation of
              pleasure from consuming foods
            </p>
          </CalloutBox>
        </div>

        <div className="show-for-large" style={{ width: `10px` }}></div>

        <div className="columns small-12 large-expand mb-1">
          <CalloutBox
            headerStyles={{ backgroundColor: "#939599" }}
            contentStyles={{ backgroundColor: "#FFF" }}
            headerCopy="Binge eating<sup>4-6</sup>"
          >
            <p>
              Consumption of a large amount of food with loss of control in a
              short period of time
            </p>
          </CalloutBox>
        </div>

        <div className="show-for-large position-relative" style={{ width: `60px` }}>
          <div className="color-teal-bg h-100" style={{ width: `6px`, margin: '0 auto' }}></div>
          <img src={NotEqualIcon} className="not-equal-icon position-absolute" alt="" />
        </div>

        <div style={{height: '0px'}}>&nbsp;</div>

        <div className="hide-for-large position-relative flex align-items-center align-middle" style={{ width: `100%`, height: `85px`, marginTop: `-1rem` }}>
          <div className="color-teal-bg" style={{ width: `100%`, height: `6px`, margin: '0 auto' }}></div>
          <img src={NotEqualIcon} className="not-equal-icon position-absolute" alt="" />
        </div>

        <div className="columns small-12 large-expand">
          <CalloutBox
            headerStyles={{ backgroundColor: "#007A8A" }}
            contentStyles={{ backgroundColor: "#FFF" }}
            headerCopy="Hyperphagia<sup>3,7</sup><br/><span style='font-size: .8em; font-weight: 400'>(Pathological, insatiable hunger)</span>"
          >
            <ul className="ul--standard">
              {[
                "Long time to satiation",
                "Shorter duration of satiation",
                "Prolonged feeling of hunger",
                "Severe preoccupation with food and distress if denied food, often associated with abnormal food-seeking behaviors",
              ].map(item => (
                <LineItem copy={item} />
              ))}
            </ul>
          </CalloutBox>
        </div>
      </div>
    </div>
  )
}

export default HcpPplOvereatingDisordersChart
