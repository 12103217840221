import * as React from "react"

import Layout from "../../components/ppl-layout"
import Seo from "../../components/seo"
import Button from "../../components/button"

import HowWorksSvgDesktop from "../../images/patient-ppl-how-imcivree-works-desktop.svg"
import HowWorksSvgMobile from "../../images/patient-ppl-how-imcivree-works-mobile.svg"


const HowItWorksPage = () => (
  <Layout>
    <Seo
      title="How It Works | IMCIVREE® (setmelanotide) injection"
      description="Learn how IMCIVREE® (setmelanotide) targets the MC4R pathway in people with POMC, PCSK1, and LEPR deficiencies. Please see full Prescribing Information for Important Safety Information."
      ogTitle="How It Works | IMCIVREE® (setmelanotide) injection"
      ogDesc="Learn how IMCIVREE® (setmelanotide) works to treat patients. Please see full Prescribing Information for Important Safety Information."
    />
    <div id="about" className="content-block pb-0 md_pb-0">
        <div className="row og mb-0 md_mb-0">
            <div className="columns large-12 og">
                <h1 className="h1">How does IMCIVREE work?</h1>
                <h2 className="h2--no-margin-bottom">IMCIVREE is designed to re-establish function to the "road" (MC4R pathway) so trucks can deliver messages to the brain that the body is satisfied</h2>
            </div>
        </div>
        <div className="lg_mt-0 mt-0" id="the-hypothalamus">
        <div className="row og lg_mt-0 mt-0">
          <div className="columns og">
            <div>
              <img src={HowWorksSvgDesktop} alt="MC4R pathway on IMCIVREE" className="show-for-large" />
              <img src={HowWorksSvgMobile} alt="MC4R pathway on IMCIVREE" className="hide-for-large" />
            </div>
            <div className="row og">
              <div className="large-12 og">
                <p className="mt-0 md_mt-0">Think of IMCIVREE as a traffic officer. When the traffic light is broken, the traffic officer directs trucks on the road (MC4R pathway),
allowing them to deliver messages to the brain that the body is satisfied and doesn't need more food.</p>
                <p>This is how IMCIVREE works to help the pathway work properly.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="text-center lg_mt-2 mt-0">
      <Button large copy={<>See how IMCIVREE was studied</>} url="/ppl/clinical-study-results/" />
    </div>
    <div>&nbsp;</div>
  </Layout>
)

export default HowItWorksPage
