import React from "react"
import HeroCTAbutton from "./HeroCTAbutton"

const HeroBottomButtons = ({ patient, icons }) => {
  return (
    <div className="hero_bottom_buttons">
      {icons.map((icon, index) => (
        <HeroCTAbutton
          icon={icon.svg}
          alt={icon.alt}
          copy={icon.copy}
          url={icon.url}
          gray={patient}
          type={icon.type}
        />
      ))}
    </div>
  )
}

export default HeroBottomButtons
