import React, {useEffect} from "react"
import PropTypes from "prop-types"

import Layout from "../../../components/hcp-ppl-layout"
import Seo from "../../../components/seo"
import Button from "../../../components/button"

export default function ThankYouPPL(props) {

return (
    <>
    <Layout>
        <Seo
          title="Sign Up For Updates - Thank you! | IMCIVREE® (setmelanotide) injection | For HCPs"
          description="Learn about Rhythm InTune, a support program designed for caregivers and people living with rare genetic diseases of obesity."
          ogTitle="Sign Up For Updates - Thank you! | IMCIVREE® (setmelanotide) injection"
          ogDesc="Learn about Rhythm InTune, a support program designed for caregivers and people living with rare genetic diseases of obesity."
        />
        <div className="inner-container">
            <section className="register-section">
                <div className="register-wrapper md_mt-5 mt-1">
                    <div id="ppl-section" className="text-center">
                        <h1>Thank You</h1>
                        <p className="mb-3 md_mb-3">Thank you for signing up for updates about IMCIVREE!</p>
                        <Button copy="Go back to IMCIVREE homepage" url="/hcp/ppl/" />
                    </div>
                </div>
            </section>
        </div>
    </Layout>
    </>
  )
}

ThankYouPPL.prototype ={
    siteTitle: PropTypes.string,
}

ThankYouPPL.defaultProps = {
  siteTitle: ``,
}
