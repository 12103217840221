import React, { useEffect } from "react"
import Seo from "../../components/seo"
import Helmet from 'react-helmet'

export default function IndexPage(props) {


  useEffect(() => {
    
  }, [])

  return (
    <>
    <Helmet>
        <meta http-equiv="refresh" content="0;url=https://imcivree.com/hcp/bbs/" />
    </Helmet>
    </>
  )
}
