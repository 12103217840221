import * as React from "react"
import { Link } from "gatsby"
import IconCopyBlock from "../../components/icon-copy-block"

import Layout from "../../components/ppl-layout"
import Seo from "../../components/seo"
import Button from "../../components/button"
import Callout from "../../components/callout"

import ImageDeciding from "../../images/deciding.svg"
import ImageLearning from "../../images/learning.svg"
import ImageStarting from "../../images/starting.svg"
import ImageStaying from "../../images/staying.svg"

const GettingStarted = () => (
  <Layout>
    <Seo
      title="Learning to Inject | IMCIVREE® (setmelanotide) injection"
      description="Your healthcare provider will provide training to you on how to inject IMCIVREE® (setmelanotide). Please see full Prescribing Information for Important Safety Information."
      ogTitle="Learning to Inject | IMCIVREE® (setmelanotide) injection"
      ogDesc="Your healthcare provider will provide training to you on how to inject IMCIVREE® (setmelanotide). Please see full Prescribing Information for Important Safety Information."
    />
    <div className="content-block md_pb-0">
      <h1 className="h1">Getting started on IMCIVREE</h1>
      <div className="icon-container md_mt-2">
        <IconCopyBlock
          heading={
            <>
              <span class='color-gray'>Deciding on IMCIVREE</span>
            </>
          }
          iconAlt="Deciding on IMCIVREE"
          icon={ImageDeciding}
          content={
            <>
              <div className="ml-1 md_ml-0">
                You and your doctor decide whether IMCIVREE is an appropriate
                treatment. If you agree on IMCIVREE, your doctor will submit a
                Start Form along with your consent to enroll in <Link to="/ppl/rhythm-intune/"><u>Rhythm InTune</u></Link>
                , if you choose to. Rhythm InTune is your dedicated source for
                personalized support, which includes helping you understand
                insurance coverage and exploring financial assistance options
                for eligible patients.
              </div>
            </>
          }
        />

        <IconCopyBlock
          heading={
            <>
              <span class='color-gray'>Learning how to inject IMCIVREE</span>
            </>
          }
          iconAlt="Learning to inject IMCIVREE"
          icon={ImageLearning}
          content={
            <>
              <div className="ml-1 md_ml-1">
                Injection training is offered before you start IMCIVREE.
                Training can be given by your doctor or coordinated through
                Rhythm InTune.
              </div>
            </>
          }
        />

        <IconCopyBlock
          heading={
            <>
              <span class='color-gray'>Starting on IMCIVREE</span>
            </>
          }
          iconAlt="Starting on IMCIVREE"
          icon={ImageStarting}
          content={
            <>
              <div className="ml-1 md_ml-0">
                IMCIVREE is only available through PANTHERx Rare Pharmacy.
                Rhythm InTune will coordinate delivery of IMCIVREE. You should
                start IMCIVREE at your starting dose, as directed by your
                doctor.
              </div>
            </>
          }
        />

        <IconCopyBlock
          heading="Staying on IMCIVREE"
          iconAlt="Staying on IMCIVREE"
          icon={ImageStaying}
          content={
            <>
              <div className="ml-1 md_ml-0">
                Your doctor will monitor you for the first few weeks while you
                are on IMCIVREE. During this time, they may increase or decrease
                your dose until you reach your target dose.
              </div>
            </>
          }
        />
      </div>
    </div>

    <div className="row">
      <div className="columns large-8 medium-10 small-12">
        <Callout fullWidth className="lime-teal left text-left">
          <p>
            Rhythm InTune provides personalized support and educational
            resources and programs throughout treatment to help you start and
            stay on IMCIVREE
          </p>
        </Callout>
      </div>
    </div>
    <div className="content-block">
      <div
        className="text-center mt-3 md_mt-2"
        style={{ maxWidth: 420 + "px", margin: "auto" }}
      >
        <Button
          large
          copy={<>See dosing information</>}
          url="/ppl/understanding-your-dose/"
        />
      </div>
    </div>
  </Layout>
)

export default GettingStarted
