import * as React from "react"
import { Link } from "gatsby"

import FourOhFourLayout from "../components/404-layout"
import Seo from "../components/seo"
import FourOhFourText from "../components/404-text"

const Accessibility = () => (
  <FourOhFourLayout noNav>
    <Seo title="Page Not Found | IMCIVREE® (setmelanotide) injection" />
    <FourOhFourText/>
  </FourOhFourLayout>
)

export default Accessibility
