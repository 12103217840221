import * as React from "react"

import Layout from "../../components/bbs-layout"
import Seo from "../../components/seo"
import Callout from "../../components/callout"
import Helmet from "react-helmet"

import actor from "../../images/actor-kid.png"

import Button from "../../components/button"

const PatientAccess = () => (
  <Layout>
    <Helmet bodyAttributes={{ class: "patient-bbs bbs-access" }} />
    <Seo
      title="Patient Access | IMCIVREE® (setmelanotide) injection"
      description="Learn how Rhythm InTune is committed to assisting eligible patients regardless of insurance plan type."
      ogTitle="Patient Access | IMCIVREE® (setmelanotide) injection"
      ogDesc="Learn how Rhythm InTune is committed to assisting eligible patients regardless of insurance plan type."
    />
    <div id="patient-access-bbs" className="content-block md_pb-2 lg_pb-2 pb-2">
      <div className="row">
        <div className="columns small-12 medium-9">
          <h1 className="h1">
            Rhythm InTune is committed to assisting eligible patients regardless
            of insurance plan type
          </h1>
          <h2 className="h2 mb-0 lg_mb-1 md_mb-1">
            The majority of patients, regardless of coverage type, have been approved for IMCIVREE
          </h2>
          <div className="row og lg_mb-2 md_mb-2 mb-1">
            <div className="columns og">
              <ul className="neon-green-bullets mt--1">
                <li>
                Many patients get coverage as soon as prior authorization is approved
                  <ul>
                    <li className="is-dash">Prior authorization is a step your doctor's office takes to ensure your medication is covered by your insurance plan</li>
                  </ul>
                </li>
                <li>
                  The majority of commercial plans have coverage policies in
                  place for IMCIVREE
  
                </li>
                <li>
                IMCIVREE is usually covered for those with Medicaid
                </li>
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="columns large-8 medium-10 small-12">
              <Callout fullWidth className="lime-teal left text-left">
                <p>
                  Financial assistance programs are available for eligible
                  patients
                </p>
              </Callout>
            </div>
          </div>
        </div>
        <div className="columns small-12 medium-3 mt-2 actor-box">
          <p className="text-right small mb-0 lg_mb-0 actor-text">
            Actor portrayal.
          </p>
          <img src={actor} className="md_mt-2 bbs-access-actor" alt="Actor portrayal" />
        </div>
      </div>

      <div className="text-center mt-1 md_mt-2">
        <Button copy="Sign up for one of our IMCIVREE programs" url="/bbs/programs/" />
      </div>
    </div>
  </Layout>
)

export default PatientAccess
