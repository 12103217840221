import * as React from "react"

import Layout from "../../../components/hcp-ppl-layout"
import Seo from "../../../components/seo"

import ManagingAdverseEventsVideo from "../../../components/managing-adverse-events-video"
import VideoTranscript from "../../../components/video-transcript"
import ManagingAdverseEventsVideoTranscript from "../../../components/template-partials/hcp/bbs/managing-adverse-events-video-transcript"


const ManagingAdverseEventsAndInjectionTrainingHcpPpl = () => (
  <Layout noNav>

  <Seo
  title="Managing Adverse Events With IMCIVREE| IMCIVREE® (setmelanotide)"
  description="Hear from physicians who have prescribed IMCIVREE® (setmelanotide) in this video. Please see full Prescribing Information and Important Safety Information."
  />

    <div className="content-block">

      <div className="row og">
        <div className="columns og">
          <h1 className="h1">Managing adverse events and injection training</h1>
          <h2 className="h2">Hear from physicians who have prescribed IMCIVREE.</h2>
        </div>
      </div>

      <ManagingAdverseEventsVideo />
        <VideoTranscript>
          <ManagingAdverseEventsVideoTranscript />
        </VideoTranscript>

    </div>

  </Layout>
)

export default ManagingAdverseEventsAndInjectionTrainingHcpPpl