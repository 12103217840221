import * as React from "react"

import Layout from "../../components/ppl-layout"
import Seo from "../../components/seo"
import Button from "../../components/button"

import MagnifyingGlassImg from "../../images/magnifying-glass.svg"

const ClinicalStudyResultsPage = () => (
  <Layout>
    <Seo
      title="Clinical Study Results | IMCIVREE® (setmelanotide) injection"
      description="IMCIVREE® (setmelanotide)was evaluated in 2 studies of people 6 years and older. Please see full Prescribing Information for Important Safety Information."
      ogTitle="Clinical Study Results | IMCIVREE® (setmelanotide) injection"
      ogDesc="IMCIVREE® (setmelanotide) was evaluated in 2 studies of people 6 years and older. Please see full Prescribing Information for Important Safety Information."
    />
    <div id="clinical-study-results" className="content-block md_pb-0">
      <h1 className="h1">How was IMCIVREE studied?</h1>
      <h2 className="h2">IMCIVREE was evaluated in 2 studies of people 6 years of age and older</h2>
      <div className="row text-center lg_mt-2 mt-2">
        <div className="column og small-6">
          <p className="h3">STUDY 1</p>
          <p>People with obesity due to<br/><strong className="h2 text-gray">POMC or PCSK1<br/>deficiency</strong></p>
          <p><b className="h3 text-gray">10</b><br/>participants</p>
          <p>Results after<br/><b className="h3 text-gray">1 year</b></p>
        </div>
        <div className="column og small-6 yellow-left yellow-left--ignore-stack">
          <p className="h3">STUDY 2</p>
          <p>People with obesity due to<br/><strong className="h3 text-gray">LEPR<br/>deficiency</strong></p>
          <p><b className="h3 text-gray">11</b><br/>participants</p>
          <p>Results after<br/><b className="h3 text-gray">1 year</b></p>
        </div>
      </div>
      <h2 className="h2--no-margin-bottom lg_mb-0 mb-0 lg_mt-3 mt-3">In both studies</h2>
      <p>Adults had a body mass index (BMI) of 30 kg/m<sup>2</sup> or more. Children had weight in the 95th percentile or higher using growth chart assessments.</p>
      <h2 className="h2--no-margin-bottom lg_mb-0 mb-0 lg_mt-2 mt-2">Study design</h2>
      <p>IMCIVREE was evaluated in 21 individuals (10 from Study 1 and 11 from Study 2) who completed at least 1 year of treatment.</p>
      <div className="row magnifying-glass lg_mt-2 lg_mb-2">
        <div className="column small-4 medium-2 mb-1 text-center medium-text-left">
          <img src={MagnifyingGlassImg} alt="IMCIVREE clinical study" />
        </div>
        <p className="column og small-8 medium-10 d-flex align-self-middle pr-0">
        There were 6 additional participants who had not yet completed 1 year of treatment when this information was collected. Their results
were not included in efficacy information, but are included in safety information.
        </p>
      </div>
      <p className="mb-1"></p>
      <p>Both studies included a period of time taking IMCIVREE, followed by a withdrawal period of 8 weeks, which included 4 weeks of IMCIVREE followed by 4 weeks of no
treatment. Neither the investigators nor the participants were aware of when the 4-week nontreatment time period was occurring.</p>
      <p>After the withdrawal period, participants went on to receive up to 32 additional weeks of treatment with IMCIVREE.</p>
      <p className="md_pb-1"></p>
      <div className="text-center">
        <Button large copy={<>See how IMCIVREE helped reduce <br className="hide-for-small show-for-medium"/> measures of weight and hunger</>} url="/ppl/effect-of-imcivree/" />
      </div>
    </div>
  </Layout>
)

export default ClinicalStudyResultsPage
