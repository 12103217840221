import React from 'react'


const ImciRhythmUbHyperphagiaVideoTranscript = (props) => {

  return (
    <>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Tempus quam pellentesque nec nam. Placerat in egestas erat imperdiet. Bibendum est ultricies integer quis auctor elit sed vulputate. Dis parturient montes nascetur ridiculus mus mauris vitae ultricies. Fermentum posuere urna nec tincidunt.</p>

      <p> Metus vulputate eu scelerisque felis. Quis imperdiet massa tincidunt nunc pulvinar sapien et. Est ultricies integer quis auctor elit. Pulvinar neque laoreet suspendisse interdum consectetur libero. Purus sit amet luctus venenatis lectus magna fringilla urna porttitor. Porttitor eget dolor morbi non arcu. Scelerisque purus semper eget duis at tellus at urna condimentum. Nec ultrices dui sapien eget mi proin sed libero. Quam id leo in vitae turpis. Risus commodo viverra maecenas accumsan lacus vel facilisis volutpat. Vulputate dignissim suspendisse in est ante in nibh mauris cursus. Id interdum velit laoreet id. Nullam non nisi est sit amet. Sed risus pretium quam vulputate dignissim suspendisse in. In hac habitasse platea dictumst quisque sagittis purus sit.</p>

      <p>Cras sed felis eget velit aliquet sagittis. Suspendisse faucibus interdum posuere lorem ipsum dolor sit amet. Blandit aliquam etiam erat velit scelerisque. Accumsan in nisl nisi scelerisque eu ultrices vitae auctor. Placerat duis ultricies lacus sed turpis tincidunt. In hendrerit gravida rutrum quisque non tellus orci ac. Eu nisl nunc mi ipsum faucibus vitae aliquet nec ullamcorper. Tristique senectus et netus et malesuada. Cursus vitae congue mauris rhoncus. Non diam phasellus vestibulum lorem sed risus. Turpis nunc eget lorem dolor sed viverra ipsum. Sed risus pretium quam vulputate. Malesuada fames ac turpis egestas. Vel orci porta non pulvinar.</p>

      <p>Sed felis eget velit aliquet sagittis id consectetur purus ut. Mi proin sed libero enim sed faucibus. Neque egestas congue quisque egestas diam. Ultricies tristique nulla aliquet enim tortor at. Cursus eget nunc scelerisque viverra mauris in aliquam sem fringilla. Blandit turpis cursus in hac habitasse platea dictumst. Pulvinar elementum integer enim neque volutpat ac tincidunt vitae semper. Scelerisque viverra mauris in aliquam sem fringilla. Vitae nunc sed velit dignissim sodales ut. Amet consectetur adipiscing elit ut. Lectus arcu bibendum at varius vel pharetra vel turpis. Nunc lobortis mattis aliquam faucibus. Blandit volutpat maecenas volutpat blandit aliquam etiam erat. Elit sed vulputate mi sit amet mauris commodo quis imperdiet. Aliquam vestibulum morbi blandit cursus. Commodo elit at imperdiet dui accumsan sit amet.</p>

      <p>In fermentum et sollicitudin ac. Adipiscing tristique risus nec feugiat in fermentum posuere urna nec. Faucibus turpis in eu mi bibendum neque egestas congue quisque. Commodo odio aenean sed adipiscing. Dictum fusce ut placerat orci. Vel quam elementum pulvinar etiam non quam. Cursus in hac habitasse platea dictumst quisque. Ut tortor pretium viverra suspendisse potenti nullam. Et ultrices neque ornare aenean. Sit amet porttitor eget dolor morbi non. Enim blandit volutpat maecenas volutpat blandit aliquam etiam erat velit. Egestas fringilla phasellus faucibus scelerisque eleifend donec. Luctus accumsan tortor posuere ac ut consequat semper. Euismod in pellentesque massa placerat duis. Dictum varius duis at consectetur lorem donec massa sapien. Laoreet sit amet cursus sit. Pellentesque sit amet porttitor eget dolor. Aenean pharetra magna ac placerat vestibulum lectus mauris. Nibh tortor id aliquet lectus.</p>

    </>
  )
}

export default ImciRhythmUbHyperphagiaVideoTranscript
