import React from 'react'
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const PlainDrawer = ({ children, handleOpen, open }) => (
  <div className="plain">
    <span className="accordion__icon right">
      {open &&
        <FontAwesomeIcon icon={faMinus} />
      }
      {!open &&
        <FontAwesomeIcon icon={faPlus} />
      }
    </span>
    <button className="accordion__button right" onClick={handleOpen}>
      {children}
    </button>
  </div>
)


export default PlainDrawer
