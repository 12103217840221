import React from "react"
// import vpl from "../images/video-placeholder.jpg"

const DiagnosisVideo = props => {
  return (
    <>
    <div className="row og">
      <div className={`columns og responsive-video-container ` + props.className}>
        <div class="responsive-embed widescreen">
          <iframe src="https://player.vimeo.com/video/882518079?h=e10e276d4a" width="100%" height="auto" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen title="Diagnosing BBS in your practice"></iframe>
        </div>
      </div>
    </div>
    </>
  )
}

export default DiagnosisVideo