import React, {useState, useEffect} from 'react'

const Wrapper = (props) => {

  const [open, setOpen] = useState(false)
  const [init, setInit] = useState(false);

  const handleOpen = (e) => {
    if(!open){
      setOpen(true);
      props.addToOpen();
    }else{
      setOpen(false);
      props.removeFromOpen();
    }
  }

  useEffect(() => {
    if(props.children.props.isOpen){
      setOpen(true);
      setTimeout(function(){
        props.addToOpen();
      },100);
    }
  },[props.children.props.isOpen]);

  useEffect(() => {
      if(props.allOpen === true){
        setOpen(true);
      }else{
        if(props.initial === true){
          setOpen(false);
        }
      }
  },[props.allOpen]);

  return (
    <li>
      {React.Children.map(props.children, child => {
        const drawer = React.cloneElement(child.props.children[0], {
          handleOpen, open
        });
        const expanded = React.cloneElement(child.props.children[1]);
        return(
          <>
            {drawer}
            {open && expanded}
          </>
        )
      })}
    </li>
  )
}

export default Wrapper
