import * as React from "react"

import Layout from "../../components/ppl-layout"
import Seo from "../../components/seo"
import Button from "../../components/button"
import Callout from "../../components/callout"
import HangingRef from "../../components/hanging-ref"

import PatientPplDosingChartWithVial from "../../images/patient-bbs-dosing-chart.png"
import MdVial from "../../images/md-vial.png"

const UnderstandingDose = () => (
  <Layout>
    <Seo
      title="Understanding Your Dose | IMCIVREE® (setmelanotide) injection"
      description="View dosing information for IMCIVREE® (setmelanotide). Please see full Prescribing Information for Important Safety Information."
      ogTitle="Understanding Your Dose | IMCIVREE® (setmelanotide) injection"
      ogDesc="View dosing information for IMCIVREE® (setmelanotide). Please see full Prescribing Information for Important Safety Information."
    />
    <div className="content-block md_pb-1">
      <h1 className="h1">Understanding your dose</h1>
      <p>
        Each vial contains multiple doses. The number of doses in each vial will
        depend on your individual dose. Please note that the vial will not be
        full of medicine. Only fill the syringe with the amount of IMCIVREE
        needed for your prescribed dose. The table below shows the number of
        potential doses included in each vial based on different prescribed
        doses.
      </p>
      <div className="row og align-middle">
        <div className="columns og large-offset-2 small-12 medium-expand">
          <div className="text-left mb-1 md_mb-2">
            <img
              width="100%"
              src={PatientPplDosingChartWithVial}
              alt="Patient dosing chart"
            />
          </div>
          <HangingRef
            mb0
            ml3
            mt-1
            symbol="*"
            isAsterisk
            copy="If you have kidney problems, talk to your healthcare provider to discuss your recommended target dose."
          />
        </div>
        <div className="columns og small-12 medium-expand text-center large-text-left mt-2 lg_ml-2">
          <div className="mb-1 md_mb-2">
            <img
              src={MdVial}
              alt="IMCIVREE multiple-dose vial"
              style={{ maxWidth: `236px` }}
            />
          </div>
        </div>
      </div>
      <div className="columns small-12 og mt-1 md_mt-3">
        <p>
          Only use the number of doses per vial as listed on this table. Dispose
          of any leftover medicine.
        </p>
      </div>
    </div>
    <div className="row">
      <div className="columns large-8 medium-10 small-12">
        <Callout fullWidth className="lime-teal left text-left">
          <p>
            Do not combine vials of IMCIVREE for your injection. Use one vial
            that contains enough medicine for your prescribed dose
          </p>
        </Callout>
      </div>
    </div>
    <div className="mb-1"></div>
    <div className="content-block">
      <div
        className="text-center"
        style={{ maxWidth: 490 + "px", margin: "auto" }}
      >
        <Button
          large
          copy={
            <>Learn about personalized support provided by Rhythm InTune </>
          }
          url="/ppl/rhythm-intune/"
        />
      </div>
    </div>
  </Layout>
)

export default UnderstandingDose
