import React from "react"

import Layout from "../../../components/hcp-bbs-layout"
import Seo from "../../../components/seo"
import Button from "../../../components/button"
import Callout from "../../../components/callout"
import TypesOfObesityChart from "../../../components/types-of-obesity-chart-bbs"

const TypesOfObesity = () => {
  return (
    <Layout>
      <Seo
        title="Types of Obesity | IMCIVREE® (setmelanotide) injection | For HCPs"
        description="HCPs: Learn about genetic obesity caused by rare MC4R pathway diseases. Please see full Prescribing Information for Important Safety Information."
        ogTitle="Types of Obesity | IMCIVREE® (setmelanotide) injection"
        ogDesc="Learn more about the disease that IMCIVREE® (setmelanotide) treats. Please see full Prescribing Information for Important Safety Information."
      />
      <div className="content-block pt-4 md_pt-4 mb-2">
        <div className="row" style={{ marginBottom: 16 }}>
          <div className="column">
            <h1 className="h1">
            Not all obesity is the same: Understanding the role of the hypothalamic MC4R pathway
            </h1>
            <h2 className="h2--no-margin-bottom" style={{ paddingBottom: 4 }}>
              Identifying the type of obesity your patient has can:
            </h2>

            <ul className="ul--standard">
              <li>
                Help you determine the optimal management of their specific
                disease<sup>1,2</sup>
              </li>
              <li>
                Reduce the cumulative impact of their obesity, especially if diagnosed early<sup>3,4</sup>
              </li>
            </ul>
          </div>
        </div>

        <div className="types-of-obesity-chart-img-container large">
          <TypesOfObesityChart />
        </div>
      </div>

      <Callout left className="light-teal left text-left">
        <p>
          Knowing a root cause of your patient's obesity and hunger can be an
          important first step in diagnosis and optimal management of their
          specific disease<sup>2,3,4</sup>
        </p>
      </Callout>

      <div className="content-block">
        <div className="text-center">
          <Button
            copy="Learn more about hyperphagia in BBS"
            url="/hcp/bbs/hyperphagia/"
          />
        </div>

        <p className="footnote mt-2 md_mt-4 md_mb-1 mb-1">
          AAP=American Academy of Pediatrics; LEPR=leptin receptor;
          MC4R=melanocortin-4 receptor; OMA=Obesity Medicine Association;
          PCSK1=proprotein convertase subtilisin/kexin type 1;
          POMC=proopiomelanocortin.
        </p>
        <p className="footnote references">


          <b>References:</b> <b>1.</b>{" "}IMCIVREE [prescribing information]. Boston, MA. Rhythm Pharmaceuticals, Inc.{" "}
          <b>2.</b>{" "}Eneli I et al.{" "}<i>Appl Clin Genet</i>. 2019;12:87-93.{" "}
          <b>3.</b>{" "}Hampl SE et al.{" "}<i>Pediatrics</i>. 2023;151(2):e202206064.{" "}
          <b>4.</b>{" "}Manara E et al.{" "}<i>Ital J Pediatr</i>. 2019;45(1):72.{" "}
          <b>5.</b>{" "}Huvenne H et al.{" "}<i>Obes Facts.</i>. 2016;9(3):158-173.{" "}
          <b>6.</b>{" "}Tondt J et al.{" "}<i>Obesity Algorithm</i><sup>&reg;</sup> <i>2023</i>. Obesity Medicine Association; 2023. Accessed August 11, 2023.
          https://obesitymedicine.org/obesity-algorithm.{" "}
          <b>7.</b>{" "}Kalinderi, K et al. Syndromic and Monogenic Obesity: New Opportunities Due to Genetic-Based Pharmacological Treatment. Children 2024, 11, 153. https://doi.
          org/10.3390/ children11020153.
        </p>
      </div>
    </Layout>
  )
}

export default TypesOfObesity
