import React from "react"
import Modal from "./modal"
import GreenPlus from "../images/icon-green-plus.svg"
import BrainIcon from "./icons/BrainIcon"

const BoxCTA = ({ previewText, onPlusClick }) => {
  return (
    <div className="box-cta-container">
      <div className="cta-left-icons">
        <BrainIcon size={100} style={{ marginRight: -24, zIndex: 0 }} />
        <div className="left-triangle"></div>
      </div>

      <div className="box-cta">
        <p>{previewText}</p>
        <img
          src={GreenPlus}
          alt="Green Plus Icon"
          style={{ cursor: "pointer" }}
          onClick={onPlusClick}
        />
      </div>
    </div>
  )
}

export default BoxCTA
