import React from 'react'

const AccessibilityText = () => {
  return (
    <>
      <div className="content-block">
        <h1 className="h1">Accessibility statement for IMCIVREE.com</h1>
        <p>Rhythm Pharmaceuticals is committed to ensuring digital accessibility for people with disabilities. We are continually improving the user experience for everyone and
applying the relevant accessibility standards.</p>
        <div className="hr"></div>
        <h4 className="h2"><strong>Conformance status</strong></h4>
        <p>The Web Content Accessibility Guidelines (WCAG) define requirements for designers and developers to improve accessibility for people with disabilities. It defines
three levels of conformance: Level A, Level AA, and Level AAA. IMCIVREE.com is partially conformant with WCAG 2.0 Level AA. Partially conformant means that
some parts of the content do not fully conform to the accessibility standard.</p>
      </div>
    </>
  )
}

export default AccessibilityText
