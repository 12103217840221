import React from "react"
import Helmet from "react-helmet"

// import Layout from "../../components/ppl-layout"
import Layout from "../../components/ppl-layout"
import Seo from "../../components/seo"
import PatientHero from "../../components/PatientHero"

import IconBookDark from "../../images/icons/icon-book-dark.svg"
import IconPhoneDark from "../../images/icons/icon-phone-dark.svg"
import IconDiamondDark from "../../images/icons/icon-diamond-dark.svg"

const icons = [
  {
    svg: IconBookDark,
    url: "/ppl/how-it-works/",
    alt: "See IMCIVREE results",
    copy: "See how IMCIVREE can help",
  },
  {
    svg: IconPhoneDark,
    url: "/ppl/sign-up/",
    alt: "Rhythm InTune Phone",
    copy: "Connect with a Patient Education Manager for one-on-one personalized support",
  },
  {
    svg: IconDiamondDark,
    url: "/ppl/how-imcivree-is-given/",
    alt: "How IMCIVREE is given",
    copy: "Learn more about how IMCIVREE is given",
  },
]

const IndexPage = () => (
  <Layout pageId="ppl-home" ppl>
    <Helmet bodyAttributes={{ class: "patient-ppl ppl-home" }} />
    <Seo
      title="POMC, PCSK1, LEPR | IMCIVREE® (setmelanotide) injection"
      description="Discover IMCIVREE® (setmelanotide) injection, the first and only FDA-approved treatment designed to target an impaired MC4R pathway in the brain, a root cause of hunger and obesity in people living with POMC, PCSK1, or LEPR deficiency. Please see full Prescribing Information for Important Safety Information."
      ogTitle="IMCIVREE® (setmelanotide) injection"
      ogDesc="Discover IMCIVREE® (setmelanotide), an FDA-approved treatment, and learn about the diseases it treats. Please see full Prescribing Information and Important Safety Information."
    />

    <div id="patient-bbs-home">
      <div id="patient-hero">
        <div className="hero position-relative">
          <PatientHero ppl icons={icons} />
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
