import React from "react"
import DiscoverWorld from "../images/discover_world.png"
import HeroBottomButtons from "./HeroBottomButtons"

const bbsContent = {
  title1: "For people living with",
  title2: "Bardet Biedl syndrome (BBS)",
  whiteText:
    "treatment to target an impaired MC4R pathway, a root cause of hunger and obesity in people living with BBS",
}

const pplContent = {
  title1: "For people living with",
  title2: "POMC, PCSK1, or LEPR",
  title2_white: "deficiency",
  whiteText:
    "treatment to target an impaired MC4R pathway in the brain, a root cause of hunger and obesity in people living with POMC, PCSK1, or LEPR deficiency",
}

// bbs by default
const PatientHero = ({ ppl, icons }) => {
  const content = ppl ? pplContent : bbsContent

  return (
    <div className={"general_hero patient " + (ppl ? "ppl" : "bbs")}>
      <div className="hero_content">
        <div className="top_title">
          <h2 className="title">{content.title1}</h2>
          <h2 className="title color-neon-green">
            <strong>{content.title2} </strong>
            <span className="color-white">{content.title2_white}</span>
          </h2>
        </div>

        <img
          src={DiscoverWorld}
          className="discover_image"
          alt="Discover a World Beyond Obesity"
        />
        <p className="small hero_actor_portrayals">Actor portrayals.</p>

        <div className="slanted_rectangle">
          <p className="slanted_rectangle_text">
            <span className="color-neon-green" style={{ fontWeight: "bold" }}>
              The first and only FDA-approved
            </span>{" "}
            {content.whiteText}
          </p>
        </div>

        <HeroBottomButtons patient icons={icons} />

        <p className="small" style={{ marginBottom: 0 }}>
          MC4R=melanocortin-4 receptor.
        </p>
      </div>
    </div>
  )
}

export default PatientHero
