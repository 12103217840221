import * as React from "react"

import Layout from "../../components/ppl-layout"
import Seo from "../../components/seo"
import Button from "../../components/button"
import ContactInfoPpl from "../../components/contact-info-ppl"
import PemVideoProfile from "../../components/pem-video-profile"
import IconArticle from "../../components/icon-article"
import IconList from "../../components/icon-list"
import Callout from "../../components/callout"
import VideoTranscript from "../../components/video-transcript"

import PatientEducationManagerTranscriptElisa from "../../components/template-partials/patient/pem-transcript-elisa"
import PatientEducationManagerTranscriptCaryn from "../../components/template-partials/patient/pem-transcript-caryn"
import PatientEducationManagerTranscriptAmy from "../../components/template-partials/patient/pem-transcript-amyn"
import PatientEducationManagerTranscriptEmily from "../../components/template-partials/patient/pem-transcript-emily"
import PatientEducationManagerTranscriptJuliet from "../../components/template-partials/patient/pem-transcript-julieto"
import PatientEducationManagerTranscriptKristina from "../../components/template-partials/patient/pem-transcript-kristina"

import Book from "../../images/icons/icon-book.svg"
import Community from "../../images/icons/icon-community.svg"
import Insurance from "../../images/icons/icon-insurance.svg"
import Calendar from "../../images/icons/icon-calendar.svg"
import ConsentForm from "../../images/icons/article/pt-consent-form.png"
import RhythmInTuneImg from "../../images/rhythm-intune.png"

import pemProfile01 from "../../images/pem-profile01.png"
import pemProfile02 from "../../images/pem-profile02.png"
import pemProfile03 from "../../images/pem-profile03.png"
import pemProfile04 from "../../images/pem-profile04.png"
import pemProfile05 from "../../images/pem-profile05.png"
import pemProfile07 from "../../images/pem-profile07.png"

import PemManagerCarolyn from "../../images/pem-manager-carolyn.png"
import PemManagerLauren from "../../images/pem-manager-lauren.png"
import PemManagerAmy from "../../images/pem-manager-amy.png"
import PemManagerDeborah from "../../images/pem-manager-deborah.png"
import PemManagerAli from "../../images/pem-manager-ali.png"
import PemManagerBrigid from "../../images/pem-manager-brigid.png"

const RhythmInTune = () => (
  <Layout>
    <Seo
      title="Rhythm InTune | IMCIVREE® (setmelanotide) injection"
      description="Learn about Rhythm InTune, a support program designed for people living with POMC, PCSK1, or LEPR deficiency and their caregivers."
      ogTitle="Rhythm InTune | IMCIVREE® (setmelanotide) injection"
      ogDesc="Learn about Rhythm InTune, a support program designed for people living with rare genetic diseases of obesity and their caregivers."
    />
    <div id="rhythm-intune" className="content-block pb-1 md_pb-1">
      <div className="headline align-bottom">
        <img src={RhythmInTuneImg} alt="Rhythm InTune Logo" width="250" />
        <h1 className="h1 mt-1" style={{ padding: "0", margin: "0" }}>
          Your source for one-on-one personalized support
        </h1>
      </div>
      <h2 className="h2">
        A support program designed for people living with POMC, PCSK1, or LEPR deficiency<br className="show-for-large"/> and their caregivers
      </h2>
      <p>
        Rhythm InTune is here for every step of your journey and
        provides resources, education, and information tailored to
        fit your unique needs as someone living with a rare genetic disease of obesity.
      </p>
      <p>
        When you enroll in Rhythm InTune, you can communicate directly with a
        Patient Education Manager.* Patient Education Managers are your single
        point of contact at Rhythm InTune and can help you:
      </p>
      <div className="two-col-yellow-divider row medium-unstack">
        <div className="column">
          <IconList
            icon={Book}
            iconAlt="Rhythm InTune resources"
            copy="Access educational resources"
          />
          <IconList
            icon={Community}
            iconAlt="Rhythm InTune community"
            copy="Connect to a community"
          />
        </div>
        <div className="column">
          <IconList
            icon={Insurance}
            iconAlt="insurance coverage"
            copy="Understand your insurance coverage"
          />
          <IconList
            icon={Calendar}
            iconAlt="Rhythm InTune calendar"
            copy="Get started on a Rhythm treatment"
          />
        </div>
      </div>
      <div className="row mt-1 md_mt-3">
        <div className="columns large-10 medium-10 small-12">
          <Callout className="lime-teal left text-left md_pl-0">
            <p>Rhythm InTune is committed to providing people with POMC, PCSK1, or LEPR deficiency and their caregivers with support and helpful tools throughout their treatment journey</p>
          </Callout>
        </div>
      </div>
      <p className="hanging-ref small mt-3 md_mt-3">
        *Patient Education Managers are employees of Rhythm Pharmaceuticals and
        do not provide medical care or advice. We encourage you to always speak
        to your healthcare providers regarding your medical care.
      </p>
    </div>

    <div className="content-block connect-pem pt-0 md_pt-0">
      <h3 className="h3 mt-2 md_mt-1 lg_mt-1">
        <strong>
          Want to connect with a Patient Education Manager?
        </strong>
      </h3>
      <IconArticle
        title="Patient Consent Form"
        icon={ConsentForm}
        iconAlt="Patient Consent Form"
        btnCopy="Complete the Patient Consent Form"
        file="../../Patient_Consent_Form.pdf"
      >
        <p>
        Please fill out this Patient Consent Form to be contacted by a Rhythm InTune Patient Education Manager.
        </p>
      </IconArticle>

      <p>
        If you haven't filled out a Patient Consent Form but are interested in
        speaking with a Patient Education Manager, give us a call or send us an
        email at:
      </p>
      <p className="md_pb-1"></p>

      <ContactInfoPpl />

      <h3 className="h3">Get to know our Patient Education Managers</h3>
      <p>Our Patient Education Managers are located all over the country and are eager to support you on your treatment journey!</p>
      <p>When you enroll in Rhythm InTune, a dedicated Patient Education Manager will be assigned to you based on your area.<sup>&dagger;</sup></p>
      <p className="hanging-ref footnote"><sup>&dagger;</sup>Location is subject to change.</p>

      <div className="row align-justify lg_mt-2 mt-2 mb-2 md_mb-0 pem-profile-row">

        <div className="pem-video-profile pem-video-profile-has-video column og small-12 large-6">
          <div className="pem-video-box">
            <iframe src="https://player.vimeo.com/video/877669825?h=ae82cbb909&amp;badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen class="position-absolute w-100 h-100" style={{top:'0', left:'0'}} title="PEM Introduction Video featuring Elisa"></iframe>
          </div>
          <PemVideoProfile
            profilePic={pemProfile05} 
            title="Elisa Herrera, RDN, LD, CDCES"
            region="Puerto Rico/East (bilingual)"
            alt="Elisa Herrera, RDN, LD, CDCES"
          />
          <VideoTranscript>
            <PatientEducationManagerTranscriptElisa />
          </VideoTranscript>
        </div>

        <div className="pem-video-profile column og small-12 large-6">
          
          <PemVideoProfile
            profilePic={PemManagerAli}
            title="Ali Davis, RN, BSN"
            region="Southwest/West (bilingual)"
            alt="Ali Davis, RN, BSN"
          />
        </div>

        <div className="pem-video-profile pem-video-profile-has-video column og small-12 large-6">
          <div className="pem-video-box">
            <iframe src="https://player.vimeo.com/video/877669792?h=40d1a91e24&amp;badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen class="position-absolute w-100 h-100" style={{top:'0', left:'0'}} title="PEM Introduction Video featuring Caryn"></iframe>
          </div>
          <PemVideoProfile
            profilePic={pemProfile01}
            title="Caryn Dressel, RD, LD, CDCES, CHC"
            region="Southeast"
            alt="Caryn Dressel, RD, LD, CDCES, CHC"
          />
          <VideoTranscript>
            <PatientEducationManagerTranscriptCaryn />
          </VideoTranscript>
        </div>

        <div className="pem-video-profile pem-video-profile-has-video column og small-12 large-6">
          <div className="pem-video-box">
            <iframe src="https://player.vimeo.com/video/877669775?h=dd39c6d079&amp;badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen class="position-absolute w-100 h-100" style={{top:'0', left:'0'}} title="PEM Introduction Video featuring Amy"></iframe>
          </div>
          <PemVideoProfile
            profilePic={pemProfile02}
            title="Amy Neach, MSW"
            region="Midwest"
            alt="Amy Neach, MSW"
          />
          <VideoTranscript>
            <PatientEducationManagerTranscriptAmy />
          </VideoTranscript>
        </div>

        <div className="pem-video-profile pem-video-profile-has-video column og small-12 large-6">
          <div className="pem-video-box">
            <iframe src="https://player.vimeo.com/video/877669863?h=5fa8db3b14&amp;badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen class="position-absolute w-100 h-100" style={{top:'0', left:'0'}} title="PEM Introduction Video featuring Emily"></iframe>
          </div>
          <PemVideoProfile
            profilePic={pemProfile03}
            title="Emily Malorzo, RD, CDCES"
            region="Texas"
            alt="Emily Malorzo, RD, CDCES"
          />
          <VideoTranscript>
            <PatientEducationManagerTranscriptEmily />
          </VideoTranscript>
        </div>

        <div className="pem-manager-profile pem-video-profile-has-video column og small-12 large-6">
          <div className="pem-video-box">
            <iframe src="https://player.vimeo.com/video/877669892?h=5799c5f0d6&amp;badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen class="position-absolute w-100 h-100" style={{top:'0', left:'0'}} title="PEM Introduction Video featuring Juliet"></iframe>
          </div>
          <PemVideoProfile
            profilePic={pemProfile04}
            title="Juliet O&rsquo;Connor, MS, RD, LDN, CDCES"
            region="Northeast"
            alt="Juliet O&rsquo;Connor, MS, RD, LDN, CDCES"
          />          
          <VideoTranscript>
            <PatientEducationManagerTranscriptJuliet />
          </VideoTranscript>
        </div>

        <div className="pem-video-profile pem-video-profile-has-video column og small-12 large-6">
          <div className="pem-video-box">
            <iframe src="https://player.vimeo.com/video/877669917?h=97ffde60b7&amp;badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen class="position-absolute w-100 h-100" style={{top:'0', left:'0'}} title="PEM Introduction Video featuring Kristina"></iframe>
          </div>
          <PemVideoProfile
            profilePic={pemProfile07}
            title="Kristina Shields, BA, MSW, CELCC"
            region="West"
            alt="Kristina Shields, BA, MSW, CELCC"
          />
          <VideoTranscript>
            <PatientEducationManagerTranscriptKristina />
          </VideoTranscript>
        </div>

        <div className="pem-manager-profile column og small-12 large-6">
          <PemVideoProfile
            profilePic={PemManagerCarolyn}
            title="Carolyn Finocchiaro, APRN"
            region="Oklahoma"
            alt="Carolyn Finocchiaro, APRN"
          />
        </div>
        <div className="pem-manager-profile column og small-12 large-6">
          <PemVideoProfile
            profilePic={PemManagerLauren}
            title="Lauren McLaughlin, BA"
            region="Mid Atlantic"
            alt="Lauren McLaughlin, BA"
          />
        </div>
        <div className="pem-manager-profile column og small-12 large-6">
          <PemVideoProfile
            profilePic={PemManagerAmy}
            title="Amy DeStefanis, LICSW"
            region="Oklahoma"
            alt="Amy DeStefanis, LICSW"
          />  
        </div>
        <div className="pem-manager-profile column og small-12 large-6">
          <PemVideoProfile
            profilePic={PemManagerDeborah}
            title="Deborah Morgan, RD"
            region="Carolinas"
            alt="Deborah Morgan, RD"
          />
        </div>
        <div className="pem-manager-profile column og small-12 large-6">
          <PemVideoProfile
            profilePic={PemManagerBrigid}
            title="Brigid Burzanko, BSN"
            region="Central"
            alt="Brigid Burzanko, BSN"
          />
        </div>
      </div>
      <div className="text-center">
        <Button
          copy="Learn about patient access for IMCIVREE"
          url="/ppl/patient-access/"
        />
      </div>
    </div>
  </Layout>
)

export default RhythmInTune
