import React from 'react'

import Button from './button'

const IconArticle = ({ title, id, icon, iconAlt, children, btnCopy, gray, file, btnCopy2, file2, textBold, buttonSize }) => {
  return (
    <div className="icon-article" id={id}>
      <h4 className='h4 show-for-small-only jost-semibold'>{title}</h4>
      <div className="icon-article-container row align-middle">
        <div className="column small-12 medium-shrink text-center medium-text-left" style={{marginRight:`2rem`}}><img src={icon} alt={iconAlt} width={140} /></div>
        <div className="column small-12 medium-9">
          <h4 id="resource-h4" className={textBold ? 'h4 hide-for-small-only jost-semibold' : 'h4 hide-for-small-only jost-semibold'}>{title}</h4>
          {children}
          <div className="download-btns-container row large-unstack">
            {
              btnCopy &&
              <div className={buttonSize ? buttonSize + " column small-12 " : 'column large-4'}>
                <Button type="download" gray={gray} copy={btnCopy} file={file} />
              </div>
            }
            {
              btnCopy2 &&
              <div className="column small-12 large-4 ml-0 md_ml-0 lg_ml-3">
                <Button type="download" gray={gray} copy={btnCopy2} file={file2} />
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default IconArticle
