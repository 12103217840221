import React from "react"
import CalloutBox from "./callout-box"
import Divider from "./Divider"

const OvereatingDisordersChart = props => {
  return (
    <div id="overeating-disorders">
      <div className="row">
      <div className="small-12 large-expand bottom-margin-20">
          <CalloutBox
            headerStyles={{ backgroundColor: "#4D4D4D" }}
            contentStyles={{ backgroundColor: "#939599", padding: 0 }}
            headerCopy="Episodic/Periodic"
            style={{ border: 0 }}
          >
            <div>
              <CalloutBox
                headerStyles={{
                  backgroundColor: "#939599",
                  minHeight: 0,
                  padding: 10,
                  alignSelf: "flex-start",
                }}
                contentStyles={{ backgroundColor: "#EFF5F7" }}
                headerCopy="Occasional overeating"
                style={{ border: 0 }}
              >
                <p>
                  Eating beyond a feeling of satiety at a special occasion or
                  celebratory meal (eg, Thanksgiving)<sup>1</sup>
                </p>
              </CalloutBox>
              <CalloutBox
                headerStyles={{
                  backgroundColor: "#939599",
                  minHeight: 0,
                  padding: 10,
                  alignSelf: "flex-start",
                }}
                contentStyles={{ backgroundColor: "#EFF5F7" }}
                headerCopy="Hedonic overeating"
                style={{ border: 0 }}
              >
                <p>
                  Eating beyond satiety and metabolic needs. Influenced by
                  appetite and cravings<sup>2,3</sup>
                </p>

                <strong className="color-teal">Cause:</strong>

                <ul className="ul--standard">
                  <li>
                    The pleasure centers in the brain, often driven by emotion
                    or environmental circumstances<sup>2,3</sup>
                  </li>
                </ul>
              </CalloutBox>
              <CalloutBox
                headerStyles={{
                  backgroundColor: "#939599",
                  minHeight: 0,
                  padding: 10,
                  alignSelf: "flex-start",
                }}
                contentStyles={{ backgroundColor: "#EFF5F7",minHeight:"410px" }}
                headerCopy="Binge eating"
                style={{ border: 0 }}
              >
                <p>
                  Episodic consumption of large amounts of food beyond hunger
                  and/or satiety within a short period with loss of control. If recurring, defined as
                  Binge Eating Disorder (BED).<sup>1,2</sup>
                </p>

               

                <p
                  className="color-teal"
                  style={{ margin: "12px 0 0 0", fontWeight: "bold" }}
                >
                  Behaviors may include:
                </p>

                <ul className="ul--standard">
                  <li>
                   Rapid eating<sup>5</sup>
                  </li>
                  <li>
                  Eating in isolation<sup>5</sup>
                </li>
                <li>
                    Distress due to eating behavior<sup>1</sup>
                  </li>

                </ul>
                <strong className="color-teal">Cause:</strong>

                <ul className="ul--standard">
                  <li>
                    Psychological factors, family history, dieting, gender
                    <sup>2,4,5</sup>
                  </li>
                </ul>
              </CalloutBox>
            </div>
          </CalloutBox>
        </div>

        <Divider />

        <div className="small-12 large-expand top-margin-20">
          <CalloutBox
            headerStyles={{ backgroundColor: "#DF5F48" }}
            contentStyles={{ backgroundColor: "#E3725F", padding: 0 }}
            headerCopy="Persistent"
          >
            <CalloutBox
              headerStyles={{
                backgroundColor: "#E3725F",
                minHeight: 0,
                padding: 10,
                alignSelf: "flex-start",
              }}
              contentStyles={{ backgroundColor: "#FDF7F6" }}
              headerCopy="Hyperphagia caused by MC4R pathway impairment"
              style={{ border: 0 }}
            >
             
              <p style={{fontWeight: "500", marginBottom: ".5em"}}>Pathological, insatiable hunger and impaired satiety differentiated from other types of overeating by its severity.
              <br/>Hyperphagia is also marked by:</p>
             

              <ul className="ul--standard">
                <li>
                Persistent preoccupation with food<sup>4</sup>
                </li>

                <li>
                Prolonged time to satiation and shortened duration of satiety
                  <sup>4</sup>
                </li>
                <li>
                  Prolonged feeling of hunger<sup>4</sup>
                </li>
                <li>Specific abnormal behaviors</li>
               
              </ul>

             

              <p style={{ margin: "12px 0 0 0" }}>
                <strong className="color-teal">Behaviors may include:</strong>
              </p>

              <ul className="ul--standard">
                <li>
                  Distress if food is unavailable
                  <ul className="ul--standard">
                    <li>
                     Children: may exhibit as tantrums or
                      persistent negotiation/demand for food<sup>7,8</sup>
                    </li>
                    <li>
                      Adults: may manifest in emotional effects including sadness, frustration, irritability, anxiety and/or guilt
                      <sup>12</sup>
                    </li>
                  </ul>
                </li>
                <li>
                  Abnormal food-seeking behaviors such as night eating or hiding
                  food (children may also steal/sneak food)<sup>10</sup>
                </li>
                <li>Eating excessively – not to be confused with binge eating<sup>7</sup></li>
               
               
              </ul>
              <p  class="mt-1 lg_mt-1">Symptoms and behaviors may range in severity<sup>1</sup></p>

              <p style={{ margin: "12px 0 0 0" }}>
              <strong className="color-teal">Cause:</strong>
            </p>

            <ul className="ul--standard">
            <li>
            Rare genetic variants in the MC4R pathway, a signaling pathway in the hypothalamus<sup>6</sup>
            </li>
            </ul>

              <p
                style={{
                  padding: 12,
                  borderRadius: 6,
                  border: "1px solid #ccc",
                  backgroundColor: "#fff",
                  marginTop: 12,
                  fontWeight: "bold",
                }}
                className="color-teal"
              >
                According to 2023 AAP and OMA guidelines, managing hyperphagia
                can be challenging and may require the use of pharmacotherapy
                <sup>4,11</sup>
              </p>
            </CalloutBox>
          </CalloutBox>
        </div>
      </div>
    </div>
  )
}

export default OvereatingDisordersChart
