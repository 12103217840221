import React from "react"

const MostCommonClinicalFeaturesTable = props => {
  return (
    <table
      id="key-identifiable-features-table"
      cellPadding={"0"}
      cellSpacing={"0"}
    >
      <thead>
        <tr>
          <th></th>
          <th>Birth</th>
          <th>
            First years of life
            <span className="fw-medium d-block">(0 to 5 years)</span>
          </th>
          <th>
            Eary childhood
            <span className="fw-medium d-block">(up to 10 years)</span>
          </th>
          <th>
            Adolescence to adulthood
            <span className="fw-medium d-block">(&gt;10 years)</span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr className="bg-dark">
          <td>Postaxial polydactyly<sup>2,4-7</sup></td>
          <td>Extra digits (postaxial)</td>
          <td>Typically surgically removed</td>
          <td></td>
          <td></td>
        </tr>

        <tr className="bg-light">
          <td>Renal anomalies<sup>2,4,8</sup></td>
          <td>Anatomical malformations</td>
          <td>Progressive kidney disease</td>
          <td>Polyuria/Polydipsia</td>
          <td>Chronic kidney disease</td>
        </tr>

        <tr className="bg-dark">
          <td>Hyperphagia and obesity<sup>2,9-12</sup></td>
          <td>Normal birth weight</td>
          <td>
            Rapid weight gain leading to early-onset, severe obesity, unusual
            food seeking
          </td>
          <td>
          Hyperphagia and severe obesity persist
          </td>
          <td>
            Continued hyperphagia and severe obesity persist, presenting as
            truncal obesity for adults
          </td>
        </tr>

        <tr className="bg-light">
          <td>Cognitive impairment<sup>2,13</sup></td>
          <td></td>
          <td>
            Developmental delay,
            <br />
            speech delay
          </td>
          <td>Specialized schooling needs, behavioral difficulties </td>
          <td>
            Learning difficulties
          </td>
        </tr>

        <tr className="bg-dark">
          <td>Visual impairment<sup>2,14</sup></td>
          <td></td>
          <td></td>
          <td>
            Progressive vision loss,
            <br />
            night blindness
          </td>
          <td>Legal blindness</td>
        </tr>

        <tr className="bg-light">
          <td>Hypogonadism<sup>2,13</sup></td>
          <td></td>
          <td></td>
          <td></td>
          <td>
            Delayed puberty,
            <br />
            genital anomalies
          </td>
        </tr>
      </tbody>
    </table>
  )
}

export default MostCommonClinicalFeaturesTable
