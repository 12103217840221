/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import "./layout.scss"

const FourOhFourLayout = ({ children, pageId, noNav }) => {


  return (
    <>
      <div className={noNav ? "container container--no-nav" : "container"}>
          <main id={pageId}>{children}</main>
      </div>
    </>
  )
}

FourOhFourLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default FourOhFourLayout
