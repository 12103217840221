import React, {} from "react"

import Layout from "../../../components/hcp-ppl-layout"
import Seo from "../../../components/seo"
import Button from "../../../components/button"
import Tabs from "../../../components/template-partials/global-components/tabs/tabs"

import ImageFunctional from "../../../images/hcp/ppl/hcp-ppl-functional-mc4r.png"
import ImageFunctionalMobile from "../../../images/hcp/ppl/hcp-ppl-functional-mc4r-mobile.png"
import ImageMc4rNeuron from "../../../images/hcp/ppl/hcp-ppl-moa-neuron.png"
import ImageMc4rNeuronMobile from "../../../images/hcp/ppl/hcp-ppl-moa-neuron-mobile.png"
import ImageMoaImpaired from "../../../images/hcp/ppl/hcp-ppl-mod-impaired.png"
import ImageMoaImpairedMobile from "../../../images/hcp/ppl/hcp-ppl-mod-impaired-mobile.png"
import ImageGreenArrow from "../../../images/hcp/ppl/moa-green-arrow.png"
import ImageredArrow from "../../../images/hcp/ppl/moa-red-arrow.png"


const Moa = () => {

  return (
    <Layout>
      <Seo
        title="Mechanism of Action | IMCIVREE® (setmelanotide) injection | For HCPs"
        description="Learn how an impairment in the MC4R pathway is a root cause of hyperphagia and obesity in POMC, PCSK1, and LEPR deficiency. Please see full Prescribing Information for Important Safety Information."
        ogTitle="Mechanism of Action | IMCIVREE® (setmelanotide) injection"
        ogDesc="Learn about the mechanism of action for IMCIVREE® (setmelanotide). Please see full Prescribing Information for Important Safety Information."
      />

      <div className="content-block" id="hcp-ppl-mechanism">

        <h1 className="h1 h1--sm-mobile lg_mt-1 md_mb-0">The MC4R pathway in the hypothalamus is a key neuronal pathway in regulating hunger, caloric intake, and energy expenditure<sup>1</sup></h1>
        <h2 className="h2 ignore-resize mt-1 md_mt-1" style={{marginBottom: '2rem !important'}}>Impairment in the MC4R pathway is a root cause of hyperphagia and obesity in POMC, PCSK1, and LEPR deficiency<sup>1</sup></h2>

        <div className="row og">
          <div className="columns og">
            <Tabs className=" tabs--hcp">
              <Tabs.Pane
                name={
                  <>
                  Functional MC4R pathway activity<sup>1,2</sup>
                  </>
                }
                key="1"
              >
                <div className="row og md_mb-1 lg_mb-1">
                  <div className="column og small-12">
                    <p>Leptin binding to LEPR triggers a signaling cascade, including secretion of alpha-melanocyte-stimulating hormone (α-MSH) from the POMC neuron, which binds to the MC4 receptor, activating the  control of energy expenditure and satiety.</p>
                    <img src={ImageFunctional} className="show-for-medium" alt="Functional MC4R pathway activity" />
                    <img src={ImageFunctionalMobile} className="show-for-small hide-for-medium" alt="Functional MC4R pathway activity"/>
                  </div>
                </div>
              </Tabs.Pane>
              <Tabs.Pane
                name={
                  <>
                  Impaired MC4R pathway activity<sup>1</sup>
                  </>
                }
                key="2"
              >
                <div className="row og">
                  <div className="column small-12 og">
                    <ul className="mc4r-pathway-list">
                      <li>In people with POMC, PCSK1, or LEPR deficiency, genetic variants can disrupt signaling.</li>
                      <li className="arrow-item">
                        <img src={ImageredArrow} />
                      </li>
                      <li>Alpha-melanocyte-stimulating hormone (α-MSH) production is impaired or deficient, preventing activation of the MC4 receptor, therefore impairing regulation of energy expenditure and satiety signaling.</li>
                    </ul>
                  </div>
                </div>
                <div className="row og">
                  <div className="column small-12 og">
                    <img src={ImageMoaImpaired} className="show-for-medium" alt="Impaired MC4R pathway activity" />
                    <img src={ImageMoaImpairedMobile} className="show-for-small hide-for-medium" alt="Impaired MC4R pathway activity" />

                  </div>
                </div>
              </Tabs.Pane>
            </Tabs>
          </div>
        </div>

        <h2 className="h1 h1--sm-mobile mt-3 mb-2   md_mt-3 md_mb-2">IMCIVREE is the first and only precision medicine to target impairment in the hypothalamic MC4R pathway<sup>1,3</sup></h2>

        <div className="row og">
          <div className="columns og">
            <ul className="mc4r-pathway-list">
              <li>IMCIVREE, an MC4R agonist, acts in place of alpha-melanocyte-stimulating hormone (&alpha;-MSH) to activate the MC4 receptor, to reestablish MC4R pathway activity.<sup>3-5</sup></li>
              <li className="arrow-item"> <img src={ImageGreenArrow} /></li>
              <li>Activation of the MC4R pathway can help to increase satiety signals and energy expenditure, therefore reducing hunger, and consequently, food intake and weight.<sup>3</sup></li>
            </ul>
          </div>
        </div>

        <div className="row og">
          <div className="columns og">
            <img src={ImageMc4rNeuron} className="md_mt-2 show-for-medium" alt="MC4R Pathway with IMCIVREE" />
            <img src={ImageMc4rNeuronMobile} className="mt-2 show-for-small hide-for-medium" alt="MC4R Pathway with IMCIVREE" />
          </div>
        </div>

        <div className="text-center mt-2 md_mt-2 mb-2 md_mb-3">
          <Button copy="Learn more about the efficacy of IMCIVREE" url="/hcp/ppl/study-design/" />
        </div>

        <p className="footnote mb-1 md_mb-1">LEPR=leptin receptor; MC4R=melanocortin-4 receptor; PCSK1=proprotein convertase subtilisin/kexin type 1; POMC=proopiomelanocortin.</p>
        <p className="references" style={{fontWeight: '400'}}><strong>References: 1.</strong> Eneli I et al. <em>Appl Clin Genet</em>. 2019;12:87-93. <strong>2.</strong> Huvenne H et al. <em>Obes Facts</em>. 2016;9(3):158-173. <strong>3.</strong> IMCIVREE [prescribing information]. Boston, MA. Rhythm Pharmaceuticals, Inc. <strong>4.</strong> Trapp CM et al. <em>Curr Opin Endocrinol Diabetes Obes</em>. 2023;30(2):136-140. <strong>5.</strong> Haws R et al. <em>Diabetes Obes Metab</em>. 2020;22(11):2133-2140. doi:10.1111/dom.14133. </p>

      </div>

    </Layout>
  )

}

export default Moa
