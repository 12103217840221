import React from 'react'
import parse from "html-react-parser"

import DownloadArrow from "../images/icons/icon-download-neongreen.svg"
import GreenArrowRight from "../images/green-arrow-right.svg";
import TealArrowRight from "../images/teal-arrow-right.svg";

const Quote = ({ className, copy, signoff, textCenter, xlarge=false }) => {
  return (
    <>
        <div className="row quote">
            <div className='column'>
                <div className={`${xlarge ? "large-quote" : "nomal-quote"} row quote-inner-wrapper align-middle ${className}`}>
                    <div className={`${textCenter ? "text-center" : "text-left"} column`}><p className='fw-semibold color-teal'>{copy}</p>
                    <p className='attribution fw-normal color-gray text-right sm_mr-3 md_mr-2 lg_mr-2'>{signoff}</p></div>
                </div>
            </div>
        </div>
      
    </>
  )
}

export default Quote
