import React from 'react'


const PemVideoProfile = ({ profilePic, title, region, alt, lang }) => {
  return (
    <div>
      <img src={profilePic} alt={alt} className="pem-profile-pic" />
      <div className="pem-profile text-left">
        <p className="color-teal mb-0">
          {title}
          <span className="pem-region d-block">{region}</span>
          <span className="pem-lang d-block mb-1 md_mb-1">{lang}</span>
        </p>
      </div>
    </div>
  )
}

export default PemVideoProfile