import * as React from "react"
import Helmet from "react-helmet";
import { Link } from "gatsby"

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDown} from "@fortawesome/free-solid-svg-icons";

import Layout from "../../components/ppl-layout"
import Seo from "../../components/seo"
import Button from "../../components/button"
import IconArticle from "../../components/icon-article"

import ChapterMenu from "../../components/template-partials/global-components/chapter-menu/chapter-menu";

import StepByStepThumb from "../../images/icons/article/step-by-step-thumbnail.png"
import TreatmentBrochure from "../../images/icons/article/treatment-brochure.png"
import ImcivreeGuide from "../../images/icons/article/imcivree-guide.png"
import TreatmentJournal from "../../images/icons/article/treatment-journal.png"
import RhythmBrochure from "../../images/icons/article/rhythm-intune-brochure.png"
import TrainingVideo from "../../images/icons/article/training-video.png"
import PlayBtn from "../../images/icons/icon-play.svg"
import ConsentForm from "../../images/icons/article/pt-consent-form.png"
import WellnessForm from "../../images/icons/article/pt-wellness.png"


const HelpfulResources = () => (


  <Layout>
    <Helmet bodyAttributes={{ class: 'patient-ppl ppl-resources'}}/>
    <Seo
      title="Helpful Resources | IMCIVREE® (setmelanotide) injection"
      description="View available resources to support you throughout your treatment with IMCIVREE® (setmelanotide). Please see full Prescribing Information for Important Safety Information."
      ogTitle="Helpful Resources | IMCIVREE® (setmelanotide) injection"
      ogDesc="View available resources to support you throughout your treatment with IMCIVREE® (setmelanotide). Please see full Prescribing Information for Important Safety Information."
    />

    <div className="content-block" id="patient-ppl-helpful-resources">
      <div className="row og col-reverse neg-margin">
        <div className="column og small-12 medium-12 large-9 lg_pl-0" style={{ padding: '0' }}>
          <h1 className="h1 mt-1" id="imcivree-resources">Helpful resources</h1>
          <h2 className="h2">Here are some available resources to support you throughout your treatment with IMCIVREE</h2>
          <h3 className="h3" id="imcivree-resources">IMCIVREE resources</h3>
        </div>
        <div className="column og small-12 medium-12 large-3 mt-1">
          <ChapterMenu title={`Jump to:`}>
            <li><span className="interior-menu__icon"><FontAwesomeIcon icon={faAngleDown} /></span><a href="#imcivree-resources">IMCIVREE resources</a></li>
            <li><span className="interior-menu__icon"><FontAwesomeIcon icon={faAngleDown} /></span><a href="#intune-resources">Rhythm InTune resources</a></li>
            <li><span className="interior-menu__icon"><FontAwesomeIcon icon={faAngleDown} /></span><a href="#informational-videos">Informational video</a></li>
          </ChapterMenu>
        </div>
      </div>


      <IconArticle title="Beginning Treatment Brochure" icon={TreatmentBrochure} iconAlt="Beginning Treatment Brochure" btnCopy={<>Download Beginning <br/>Treatment Brochure (English)</>} file="/Beginning_Treatment.pdf">
        <p>This brochure provides helpful information about what to expect during treatment.</p>
      </IconArticle>
      <IconArticle title="How to Give IMCIVREE Guide" icon={ImcivreeGuide} iconAlt="How to Give IMCIVREE Guide" btnCopy={<>Download How to Give <br/>IMCIVREE Guide (English)</>} file="/How_To_Give_IMCIVREE_Guide.pdf" btnCopy2={<>Download How to Give <br/>IMCIVREE Guide (Spanish)</>} file2="/How_To_Give_IMCIVREE_Guide_(Spanish).pdf">
        <p className="mb-0">A step-by-step guide that includes everything you need to know about giving an IMCIVREE injection.</p>
        <p className="small">NOTE: A printed version of the How to Give IMCIVREE Guide is also in the Getting Started Kit, which is included with your first shipment of IMCIVREE.</p>
      </IconArticle>
      <IconArticle title="IMCIVREE Treatment Journal" icon={TreatmentJournal} iconAlt="IMCIVREE Treatment Journal" btnCopy={<>Download IMCIVREE <br/>Treatment Journal (English)</>} file="/IMCIVREE_Treatment_Journal.pdf" btnCopy2={<>Download IMCIVREE <br/>Treatment Journal (Spanish)</>} file2="/IMCIVREE_Treatment_Journal_(Spanish).pdf">
        <p className="mb-0">A journal that helps you track treatment by recording when and where IMCIVREE was injected each day, as well as any important notes you may want to remember to tell your doctor.</p>
        <p className="small">NOTE: A printed version of the IMCIVREE Treatment Journal is also in the Getting Started Kit, which is included with your first shipment of IMCIVREE.</p>
      </IconArticle>

      <h3 className="h3" id="intune-resources">Rhythm InTune resources</h3>

      <IconArticle title="Patient Consent Form" icon={ConsentForm} iconAlt="Patient Consent Form" btnCopy={<>Download Patient <br/>Consent Form (English)</>} file="/Patient_Consent_Form.pdf">
        <p>To be contacted by a Patient Education Manager at Rhythm InTune for personalized support, please fill out a Patient Consent Form.</p>
      </IconArticle>
      
      <IconArticle title="Rhythm InTune Brochure" icon={RhythmBrochure} iconAlt="Rhythm InTune Brochure" btnCopy={<>Download Rhythm <br/>InTune Brochure (English)</>} file="/Rhythm_InTune_Brochure.pdf" btnCopy2={<>Download Rhythm <br/>InTune Brochure (Spanish)</>} file2="/Rhythm_InTune_Brochure-Sp.pdf">
        <p>A brochure detailing the personalized support, education, resources, and information that Rhythm InTune offers to people living with rare genetic diseases of obesity. You can also learn more about Rhythm InTune by clicking the link <Link to="/ppl/rhythm-intune/"><u>here</u></Link>.</p>
      </IconArticle>

      <IconArticle title="Starting on Treatment: Step-By-Step Guide" icon={StepByStepThumb} iconAlt="Starting on Treatment: Step-By-Step Guide" btnCopy={<>Download Starting on Treatment Guide (English)</>} file="/Step-By-Step-Guide.pdf" btnCopy2={<>Download Starting on Treatment Guide (Spanish)</>} file2="/Step-By-Step-Guide-Sp.pdf">
          <p className="mb-0">A step-by-step guide for patients and caregivers outlining what you need to know when starting treatment and how Rhythm InTune can help you through the process.</p>
          <p className="small">NOTE: A printed version of the Starting on Treatment: Guide is also in the Getting Started Kit, which is included with your first shipment of IMCIVREE.</p>
      </IconArticle>

      <IconArticle title="Wellness Brochure" icon={WellnessForm} iconAlt="Wellness Brochure" btnCopy={<>Download Wellness <br/>Brochure (English)</>} file="/PSP_Wellness_Brochure.pdf" btnCopy2={<>Download Wellness Brochure (Spanish)</>} file2="/PSP_Wellness_Brochure-Spanish_Version.pdf">
        <p>A brochure that provides wellness tips for people living with a rare genetic disease of obesity.</p>
      </IconArticle>

      <h3 className="h3" id="informational-videos">Informational video</h3>

      <IconArticle title="Injection Training Video" icon={TrainingVideo} iconAlt="Injection Training Video">
        <p>This video demonstrates how to inject and store your medicine.</p>
        <div className="download-btns-container row large-unstack">
          <div className="column small-12 large-4">
            <Link to="/ppl/injection-training/"className="global-button teal row align-middle">
              <div className="column">Watch the video (English)</div>
              <div className="column shrink"><img src={PlayBtn}/></div>
            </Link>
          </div>
          <div className="column small-12 large-4 ml-0 md_ml-0 lg_ml-3">
            <Link to="/ppl/injection-training-es/"className="global-button teal row align-middle">
              <div className="column">Watch the video (Spanish)</div>
              <div className="column shrink"><img src={PlayBtn}/></div>
            </Link>
          </div>
        </div>
      </IconArticle>


      <div className="text-center lg_mt-3 mt-3">
        <Button copy={<>Get answers to frequently asked <br className="hide-for-small show-for-medium" />questions about IMCIVREE</>} url="/ppl/faq/" />
      </div>
    </div>
  </Layout>
)

export default HelpfulResources
