import * as React from "react"

import Layout from "../../components/ppl-layout"
import Seo from "../../components/seo"
import Button from "../../components/button"
import IconList from "../../components/icon-list"

import RulerKidImage from "../../components/gatsby-images/kid-image"

import VideoTranscript from "../../components/video-transcript"
import TakingImcivreeVideo from "../../components/taking-imcivree-video"
import HowToInjectTranscriptPpl from "../../components/template-partials/patient/how-to-inject-transcript-ppl"

import ImageSyringe from "../../images/syringe.svg"
import ImageSunrise from "../../images/sunrise.svg"
import ImagePlusMinus from "../../images/plus-minus.svg"

const GiveImcivree = () => (
  <Layout>
    <Seo
      title="How IMCIVREE Is Given | IMCIVREE® (setmelanotide) injection"
      description="Learn how IMCIVREE® (setmelanotide) is given by watching the video. Please see full Prescribing Information for Important Safety Information."
      ogTitle="How IMCIVREE Is Given | IMCIVREE® (setmelanotide) injection"
      ogDesc="Learn how IMCIVREE® (setmelanotide) is given by watching the video. Please see full Prescribing Information for Important Safety Information."
    />
    <div className="content-block">
      <h1 className="h1">How is IMCIVREE given?</h1>
      <h2 className="h2">IMCIVREE is a once-daily injection used to help reduce weight in people living with POMC, PCSK1, or LEPR deficiency</h2>
      <h3 className="h1 color-gray"><strong>IMCIVREE is given:</strong></h3>

      <div className="row og lg_mt-2 mt-1">
        <div className="column og small-12 medium-4 text-center pt-1">
          <IconList icon={ImageSyringe} iconAlt="IMCIVREE injection under skin" copy={<>As an injection under <br/>the skin</>} />
        </div>
        <div className="column og small-12 medium-4 text-center">
          <IconList icon={ImageSunrise} iconAlt="Once daily at the beginning of the day" copy={<>Once daily at the beginning of the day
          </>} />
        </div>
        <div className="column og small-12 medium-4 text-center">
          <IconList icon={ImagePlusMinus} iconAlt="With or without food" copy="With or without food" />
        </div>
      </div>

      <div className="row og">
        <div className="columns og">
          <ul className="neon-green-bullets">
            <li className="no-mark md_mb-1">Because IMCIVREE is injected under the skin, the needle is short and thin</li>
            <li>The IMCIVREE needle is half an inch long</li>
          </ul>
        </div>
      </div>

      <div className="row og">
        <div className="columns og">
          <div className="kid-ruler-img-container lg_mt-2 mt-2">
            <RulerKidImage alt="IMCIVREE needle is half an inch long" />
          </div>
        </div>
      </div>

      <div className="row og">
        <div className="columns og">
          <h3 className="h1">Watch the video below to see how an IMCIVREE injection is given</h3>
        </div>
      </div>

      <TakingImcivreeVideo/>
      <VideoTranscript className="lg_mt-2">
        <HowToInjectTranscriptPpl/>
      </VideoTranscript>


      <div className="row og lg_mt-2 mt-2">
        <div className="columns og">
          <p className="">Please see the <a href="/How_To_Inject.pdf" target="_blank"><u>How to Give IMCIVREE Guide</u></a> for illustrated, step-by-step instructions for giving an IMCIVREE injection.</p>
        </div>
      </div>

      <div className="text-center lg_mt-2 mt-2" style={{maxWidth: 490+ 'px', margin: "auto"}}>
        <Button large copy={<>Getting started on IMCIVREE</>} url="/ppl/getting-started/" />
      </div>
    </div>
  </Layout>
)

export default GiveImcivree
