import * as React from "react"
import Layout from "../../../components/hcp-bbs-layout"
import Seo from "../../../components/seo"
import Callout from "../../../components/callout"
import HangingRef from "../../../components/hanging-ref"
import Button from "../../../components/button"

import ImpactImcivreeVideo from "../../../components/impact-imcivree-video"
import VideoTranscript from "../../../components/video-transcript"
import ImpactImcivreeVideoTranscript from "../../../components/template-partials/hcp/bbs/the-impact-of-imcivree-transcript"

import pedsQL from "../../../images/pedsQL.png"
import IWAOL from "../../../images/IWAOL.png"
   
const Qoa = () => (
  <Layout noNav>
    <Seo
      title="Quality of Life | IMCIVREE® (setmelanotide) injection | For HCPs"
      description="Learn about quality-of-life improvements in patients treated with IMCIVREE® (setmelanotide). Please see full Prescribing Information for Important Safety Information."
      ogTitle="Quality of Life | IMCIVREE® (setmelanotide) injection | For HCPs"
      ogDesc="Learn about quality-of-life improvements in patients treated with IMCIVREE® (setmelanotide). Please see full Prescribing Information for Important Safety Information."
    />
    <div className="content-block" style={{ paddingBottom: '2rem' }}>
      <h1 className="h1 lg_mt-1">
       Patient-Reported Health-Related Quality of Life
      </h1>
      <p>  <span>
      In the Phase 3 trial in patients with BBS, PedsQL and IWQOL-Lite were assessed as exploratory endpoints and were not powered for formal statistical testing or significance. Change from baseline after approximately 52 weeks of treatment was measured by the age-specific PedsQL or IWQOL-Lite assessments.<sup>1</sup>
      </span></p>
      <div className="row">
        <div className="columns og large-6 medium-12 small-12 align-self-top qol-column-wrapper">
          

        

          <div className="row">
            <div className="columns small-12 pr-0 md_pr-2">
              <ul className="neon-green-bullets" style={{ marginTop: '20px' }}>
                <li>
                The PedsQL is a 23-item, self-reported, age-dependent assessment of health-related quality of life (HRQOL) in children and adolescents with or without acute or chronic health conditions that encompasses 4 domain scores: physical, emotional, social, and school functioning. The total score is the mean score of the transformed items across the 4 domains. The PedsQL was administered to children &lt;18 years of age.<sup>1,2</sup>
                 
                </li>
              
              </ul>
              <img src={pedsQL} style={{ marginTop: '22px'}} alt="100%" />
            </div>
          </div>
        </div>

        <div className="columns og large-6 medium-12 small-12 align-self-top qol-column-wrapper">
         

        

          <div className="row">
            <div className="columns small-12">
              <ul className="neon-green-bullets md_ml-2 mb-1" style={{ marginTop: '18px' }}>
                <li>The Impact of Weight on Quality of Life (IWQOL)-Lite is a validated 31-item, self-reported, obesity specific, quality of life questionnaire that provides a total score inclusive of 5 domains: physical function, self-esteem, sexual life, public distress, and work. The IWQOL-Lite was administered to patients ≥18 years of age.<sup>1,3</sup></li>
                
              </ul>
              <img src={IWAOL} style={{ marginTop: '34px'}} alt="100%" />
             
            </div>
          </div>
        </div>
      </div>

      <ul className="neon-green-bullets md_ml-2 mb-1 hangingRef" style={{ marginTop: '20px' }}>
      <li class="hangingRef small">Raw scores for both PedsQL and IWQOL-Lite were transformed on a scale of 0–100, with 0 representing the worst possible and 100 the best possible HRQOL.<sup>3,4</sup></li> 
      <li class="hangingRef small">Limitations of these results include small sample sizes across assessments, which may be in part due to the rarity of the disease.<sup>1</sup></li>
      </ul>
    </div>

    <Callout left className="light-teal left text-left hcp-bbs-qol">
      <p style={{ paddingLeft: '20px'}}>
        These insights highlight the need to address hyperphagia and subsequent
        impaired quality of life for people with BBS and their caregivers<sup>5</sup>
      </p>
    </Callout>

  <div className="content-block">
    <div className="row">
        <div className="columns small-12">
          <HangingRef
            mb0
            symbol="*"
            isAsterisk
            copy={<>Using age-specific PedsQL or IWQOL-Lite assessments.<sup>1</sup><p class=".fw-normal">HRQoL=health-related quality of life; IWQOL-Lite=Impact of Weight on Quality of Life-Lite; PedsQL=Pediatric Quality of Life Inventory.</p></>}
          />
        </div>
      </div>
      <div className="row og">
        <div className="columns og text-center">
          <h3 className="h1 md_mt-2 mt-3 text-left">
          The impact of IMCIVREE
          </h3>
          <p className="h2 text-left">
            Hear from families and clinicians about how IMCIVREE is bringing hope for people living with BBS
          </p>

          <ImpactImcivreeVideo/>
          <VideoTranscript>
            <ImpactImcivreeVideoTranscript />
          </VideoTranscript>

        </div>
      </div>
      <div className="text-center mt-3 md_mt-3">
        <Button
          copy="See safety and tolerability"
          url="/hcp/bbs/safety-profile/"
        />
        <p
          className="text-left small md_mt-2 mt-2"
          style={{ fontWeight: "normal" }}
        >
          HRQoL=health-related quality of life; IWQOL-Lite=Impact of Weight on
          Quality of Life-Lite; PedsQL=Pediatric Quality of Life Inventory.
        </p>
      </div>
      <div className="references">
       <strong>References 1</strong>. Forsythe E, Beales PL. Bardet–Biedl syndrome. <em>Eur J Hum Genet</em>. 2013;21(1):8-13.&nbsp; 
       <strong>2</strong>. Varni JW. Scaling and scoring for the acute and standard versions of the Pediatric Qualitty of Life Inventory<sup>&trade;</sup> PedsQL<sup>&trade;</sup>. Version 21.&nbsp;
       <strong>3</strong>. Crosby RD, Kolotkin RL, Williams GR. An integrated method to determine meaningful changes in health-related quality of life. <em>J of Clin Epidemiology</em>. 2004;57:1153–1160.&nbsp;
       <strong>4</strong>. Varni JW, Burwinkle TM, et al. The PedsQLy* 4.0 as a Pediatric Population Health Measure: Feasibility, Reliability, and Validity. <em>Ambulatory Peds</em>. 2003;3:329 341. &nbsp;
       <strong>5</strong>.  Eneli I, Xu J, Webster M, et al. Tracing the effect of the melanocortin-4 receptor pathway in obesity: study design and methodology of the TEMPO registry. <em>Appl Clin Genet</em>. 2019;12:87-93.
       

       
      </div>
    </div>
  </Layout>
)

export default Qoa
