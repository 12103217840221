import React from "react"

import Layout from "../../../components/hcp-ppl-layout"
import Seo from "../../../components/seo"
import Button from "../../../components/button"
import Callout from "../../../components/callout"
import Quote from "../../../components/quote"
import Modal from "../../../components/modal"
import HangingRef from "../../../components/hanging-ref"

import HungerScoreChange1 from "../../../images/hunger-reduction-2-point.svg"
import HungerScoreChange2 from "../../../images/hunger-reduction-3-4-point.svg"

const HungerReduction = () => {
  return (
    <Layout>
      <Seo
        title="Hunger Reduction | IMCIVREE® (setmelanotide) injection | For HCPs"
        description="Learn about hunger reduction in patients with obesity due to POMC, PCSK1, or LEPR deficiency. Please see full Prescribing Information for Important Safety Information."
        ogTitle="Clinical Study Hunger Results | IMCIVREE® (setmelanotide) injection"
        ogDesc="Learn more about the clinical study hunger results of IMCIVREE® (setmelanotide). Please see full Prescribing Information for Important Safety Information."
      />
      <div className="content-block">
        <p className="h3-hcp lg_mt-1 md_mb-0">
          In patients with obesity due to POMC, PCSK1, or LEPR deficiency
        </p>
        <h1 className="h1 mb_mb-4">
          IMCIVREE decreased hunger over 1 year<sup>1</sup>
        </h1>
        <h5 className="color-gray text-center" style={{color: '#4B4F54 !important'}}>
          <strong>Median change in maximal hunger score over 1 year</strong>
        </h5>

        <div className="two-col-yellow-divider row medium-unstack">
          <div className="column md_mr-1 md_pr-1">
            <p className="text-center">
              <strong className="d-block">Study 1</strong>
              <span>POMC or PCSK1 (n=8)*</span>
            </p>
            <div className="text-center">
              <img
                src={HungerScoreChange1}
                alt="POMC or PCSK1 2-Point Reduction in median hunger score"
                className="mb-2 md_mb-2"
                width="60%"
              />
            </div>
            <ul className="neon-green-bullets">
              <li>
                In patients with obesity due to POMC or PCSK1 deficiency,
                IMCIVREE demonstrated&nbsp;
                <strong>
                  a median 2-point reduction in maximal hunger score
                </strong>
                &nbsp;at 1 year
                <br />
                (Range: -6.5, -0.1)
              </li>
            </ul>
          </div>
          <div>&nbsp;</div>
          <div className="column">
            <p className="text-center">
              <strong className="d-block">Study 2</strong>
              <span>
                LEPR (n=8)*<sup>&dagger;</sup>
              </span>
            </p>
            <div className="text-center">
              <img
                src={HungerScoreChange2}
                alt="LEPR 3.4-Point Reduction in median hunger score"
                className="mb-2 md_mb-2"
                width="60%"
              />
            </div>
            <ul className="neon-green-bullets">
              <li>
                In patients with obesity due to LEPR deficiency, IMCIVREE
                demonstrated&nbsp;
                <strong>
                  a median 3.4-point reduction in maximal hunger score
                </strong>
                &nbsp;at 1 year
                <br />
                (Range: -4.7, 1.0)
              </li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="columns small-12  mt-3 md_mt-3">
            <Callout left className="light-teal left text-left">
              <p>
                When treatment was withdrawn, hunger scores generally worsened
                and then improved when IMCIVREE was reinitiated<sup>1</sup>
              </p>
            </Callout>
          </div>
        </div>
      </div>

      <div className="content-block pt-0 md_pt-0">
        <div className="row">
          <div className="columns small-12 pt-1 pb-1">
            <HangingRef
              mb0
              symbol="*"
              isAsterisk
              copy={
                <>
                  Changes in patient-reported maximal hunger over the previous
                  24 hours were assessed by the Daily Hunger Questionnaire Item
                  2 for subjects aged &ge;12 years, with a hunger score ranging
                  from 0 (“not hungry at all”) to 10 (“hungriest possible”). The
                  median score at baseline (and week 52) was determined by
                  calculating the average week 1 score (and average week 52
                  score) for each patient and determining the median for the
                  group.<sup>1</sup>
                </>
              }
            />
            <HangingRef
              symbol={
                <>
                  <sup>&dagger;</sup>
                </>
              }
              copy={
                <>
                  Three patients had missing hunger data at 1 year.<sup>1</sup>
                </>
              }
            />
          </div>
        </div>
        <div className="row og lg_mt-1 lg_mb-2 md_mb-3 mb-3">
          <div className="columns">
            <Modal id="hunger-scale-hcp-ppl" className="color-black-bg" />
          </div>
        </div>
        <div className="row mt-2">
          <div className="columns small-12 medium-7 mb-3 lg_mt-1 lg_mb-4">
            <Quote
              copy={
                <>
                  A lot has changed, and when I look at the hunger today, I’m
                  assuming it’s on the same level as other people.
                </>
              }
              signoff={<>— Person who is living with POMC deficiency</>}
            ></Quote>
          </div>
          <div className="columns small-12 medium-offset-5 medium-7 mb-3 lg_mt-1 lg_mb-3">
            <Quote
              copy={
                <>
                  I’m very satisfied. I've lost a lot of weight, and I have
                  a lot more energy. It's nice to see that someone is paying
                  attention to this, and there’s a medication for this, that
                  they’re deploying people to deal with this problem, when
                  people used to have to go without care.
                </>
              }
              signoff={<>— Person who is living with POMC deficiency</>}
            ></Quote>
          </div>
          <div className="columns small-12 medium-7 mb-3">
            <Quote
              copy={
                <>
                  Now, I really feel it. My stomach growls and I notice that I
                  am hungry. It’s a feeling I never had before. It is funny.
                </>
              }
              signoff={<>— Person who is living with LEPR deficiency</>}
            ></Quote>
          </div>
          <div className="columns small-12">
            <div className="text-center mt-3 md_mt-2">
              <Button
                textCenter
                copy="See safety and tolerability"
                url="/hcp/ppl/safety-profile/"
              />
            </div>
          </div>
          <div className="columns small-12 mt-2 lg_mt-3">
            <HangingRef
              symbol={
                <>
                  <sup></sup>
                </>
              }
              copy="LEPR=leptin receptor; PCSK1=proprotein convertase subtilisin/kexin
              type 1; POMC=proopiomelanocortin."
            />
          </div>
          <div className="columns small-12">
            <p className="small references" style={{fontWeight: '400'}}>
              <strong>Reference: 1.</strong> IMCIVREE [prescribing information].
              Boston, MA. Rhythm Pharmaceuticals, Inc.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default HungerReduction
