import React, { useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import GatewayHero from "../components/GatewayHero"

export const query = graphql`
  query pdfBySlug($slug: String) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        file
        slug
        title
      }
    }
  }
`

export default function IndexPage({ props, data }) {
  let lastKnownScrollPosition = 0
  let ticking = false

  //pdf redirects
  const { slug, file } = data.markdownRemark.frontmatter

  function animate(scrollPos) {
    if (scrollPos > 25) {
      document.querySelector(`.main-logo--header`).classList.add("scrolled")
    } else {
      document.querySelector(`.main-logo--header`).classList.remove("scrolled")
    }
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      //pdf redirect
      // remove trailing slashes from pathname and slug
      if (
        window.location.pathname.replace(/\//g, "") === slug.replace(/\//g, "")
      ) {
        console.log(window.location.pathname)
        // redirect after 1 sec
        setTimeout(() => {
          window.location.href = file
        }, "1000")
      }

      document.addEventListener("scroll", e => {
        lastKnownScrollPosition = window.scrollY

        if (!ticking) {
          window.requestAnimationFrame(() => {
            animate(lastKnownScrollPosition)
            ticking = false
          })
          ticking = true
        }
      })
    }
  }, [])

  return (
    <>
      <Layout>
        <Seo
          title="IMCIVREE® (setmelanotide) injection"
          description="Discover IMCIVREE® (setmelanotide) injection, the first and only FDA-approved treatment to target an impaired MC4R pathway, a root cause of hunger and obesity in people living with Bardet-Biedl syndrome (BBS), or POMC, PCSK1, or LEPR deficiency. Please see full Prescribing Information and Important Safety Information."
          ogTitle="IMCIVREE® (setmelanotide) injection"
          ogDesc="Learn about IMCIVREE® (setmelanotide) injection and the condition that it treats. Please see full Prescribing Information and Important Safety Information."
        />
        <GatewayHero />
      </Layout>
    </>
  )
}
