import * as React from "react"

import Layout from "../../components/bbs-layout"
import Seo from "../../components/seo"

import VideoTranscript from "../../components/video-transcript"
import CloserLookImcivreeWorksBrain from "../../components/moa-video"
import CloserLookImcivreeWorksBrainTranscript from "../../components/template-partials/patient/moa-video-transcript"


const CloserLookImcivreeWorksBrainPage = () => (
  <Layout noNav>

    <Seo title="A closer look at how IMCIVREE works in the brain | IMCIVREE® (setmelanotide) injection" />

    <div className="content-block">

      <div className="row og">
        <div className="columns og">
          <h1 className="h1">A closer look at how IMCIVREE works in the brain</h1>
          <h2 className="h2">See how IMCIVREE targets an impaired melanocortin-4 receptor pathway, a root cause of hyperphagia and obesity in BBS.</h2>
        </div>
      </div>

      <CloserLookImcivreeWorksBrain />
        <VideoTranscript>
          <CloserLookImcivreeWorksBrainTranscript />
        </VideoTranscript>

    </div>

  </Layout>
)

export default CloserLookImcivreeWorksBrainPage