import React from "react"

import IconPhone from "../images/icons/icon-phone.svg"
import IconEmail from "../images/icons/icon-email.svg"

const ContactInfoHcpPpl = () => {
  return (
    <div className="contact-info">
      <div className="row mb-2 md_mb-2">
        <div className="column og small-3 pl-0 md_pr-0">
          <img src={IconPhone} alt="Rhythm InTune phone" />
        </div>
        <div className="column og small-9 md_pl-0">
          <a href="tel:18552060815" className="underline d-inline-block fw-bold">
            1-855-206-0815
          </a>
        </div>
      </div>
      <div className="row">
        <div className="column og small-3 pl-0 md_pr-0">
          <img src={IconEmail} alt="Rhythm InTune email" />
        </div>
        <div className="column og small-9 md_pl-0">
          <a href="mailto:patientsupport@rhythmtx.com" className="underline d-inline-block fw-bold">
            patientsupport@rhythmtx.com
          </a>
        </div>
      </div>
    </div>
  )
}

export default ContactInfoHcpPpl
