import React from "react"

import Layout from "../../../components/hcp-ppl-layout"
import Seo from "../../../components/seo"
import Button from "../../../components/button"
import HangingRef from "../../../components/hanging-ref"

import StudyDesignChart from "../../../images/hcp/ppl/hcp-ppl-chart-study-design.png"
import BaselineCharacteristicsChart from "../../../images/hcp/ppl/chart-baseline-characteristics.png"

const StudyDesign = () => {
  return (
    <Layout>
      <Seo
        title="Study Design | IMCIVREE® (setmelanotide) injection | For HCPs"
        description="IMCIVREE® (setmelanotide) was studied in patients with POMC, PCSK1, or LEPR deficiency. Please see full Prescribing Information for Important Safety Information."
        ogTitle="Study Design | IMCIVREE® (setmelanotide) injection"
        ogDesc="See how IMCIVREE® (setmelanotide) was studied in patients. Please see full Prescribing Information for Important Safety Information."
      />
      <div className="content-block">
        <h1 className="h1 h1--sm-mobile lg_mt-1">
        IMCIVREE was studied in 2 identically designed, 1-year, open-label studies, each with a double-blind withdrawal period in patients with POMC, PCSK1, or LEPR deficiency<sup>1,2</sup>
        </h1>
        <p className="text-center hide-for-medium">
          &larr; Swipe left or right to view &rarr;
        </p>
        <div className="text-center swipe-container lg_mt-3 mt-2 mb-1 md_mb-1">
          <img
            src={StudyDesignChart}
            style={{ minWidth: `800px`, maxWidth: `1000px` }}
            alt="IMCIVREE trial design"
          />
        </div>
        <p className="mb-0">Primary endpoint:</p>
        <ul className="neon-green-bullets md_mb-1 mb-1">
          <li>
            Proportion of participants who achieve ≥10% weight loss at 1 year of
            treatment (POMC or PCSK1, N=10; LEPR, N=11)
          </li>
        </ul>

        <HangingRef
          isAsterisk
          symbol="*"
          copy={
            <>
              Only participants who lost ≥5 kg weight (or &ge;5% of body weight
              if baseline weight was &lt;100 kg) in the first open-label active
              treatment phase entered an 8-week, double-blind withdrawal period.
            </>
          }
        />

        <h2 className="h1 mt-3 md_mt-2">
          Baseline characteristics in these IMCIVREE trials<sup>1,2</sup>
        </h2>
        <div className="text-center mb-1 md_mb-1">
          <img
            src={BaselineCharacteristicsChart}
            alt="IMCIVREE trial baseline characteristics"
          />
        </div>
        <div className="text-center mt-3 md_mt-2 mb-3 md_mb-2">
          <Button
            textCenter
            copy="Learn more about the efficacy of IMCIVREE"
            url="/hcp/ppl/weight-reduction/"
          />
        </div>
        <p className="small" style={{fontWeight: '400'}}>
          LEPR=leptin receptor; PCSK1=proprotein convertase subtilisin/kexin
          type 1; POMC=proopiomelanocortin; SD=standard deviation.
        </p>

        <p className="small" style={{fontWeight: '400'}}>
          <strong>References: 1.</strong> IMCIVREE [prescribing information].
          Boston, MA. Rhythm Pharmaceuticals, Inc. <strong>2.</strong> Clément K
          et al. <i>Lancet Diabetes Endocrinol</i>. 2020;8(12):960-970.
        </p>
      </div>
    </Layout>
  )
}

export default StudyDesign
