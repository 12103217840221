import React from "react"
import { Link } from "gatsby"
// import HeroMobile from "../images/gateway/gateway-hero-bg-mobile.png"
import GreenTab from "../images/green-tab-gateway.png"
import "./layout.scss"

const GatewayHero = () => {
  return (
    <div id="gateway_hero">
      <div className="greentab">
        <img src={GreenTab} alt="" style={{ width: "100%" }} />
      </div>

      <div className="hero_content">
        <h1 className="hero-title">
          IMCIVREE is the first and only FDA-approved treatment to target an
          impaired MC4R pathway in the brain, a root cause of hunger and obesity
          in people living with Bardet-Biedl syndrome (BBS) or POMC, PCSK1, or
          LEPR deficiency
          <sup>1,2</sup>
        </h1>

        <h3 className="hero-desc">Select a condition to learn more:</h3>

        <div className="cards">
          <div className="card">
            <h2 className="card-title">For Patients</h2>
            <h3 className="card-desc">Learn about treatment for:</h3>
            <Link to="/bbs">
              <button className="card-link patient">
                Bardet-Biedl syndrome
              </button>
            </Link>
            <Link to="/ppl">
              <button className="card-link patient">
                POMC, PCSK1, or LEPR deficiency
              </button>
            </Link>
          </div>

          <div className="card">
            <h2 className="card-title color-teal">
              For Healthcare Professionals
            </h2>
            <h3 className="card-desc">Select a condition:</h3>
            <Link to="/hcp/bbs">
              <button className="card-link hcp">Bardet-Biedl syndrome</button>
            </Link>
            <Link to="/hcp/ppl">
              <button className="card-link hcp">
                POMC, PCSK1, or LEPR deficiency
              </button>
            </Link>
          </div>
        </div>

        <div style={{ textAlign: "center", color: "white" }}>
          <p className="footnote" style={{ fontSize: 12 }}>
            LEPR=leptin receptor; MC4R=melanocortin-4 receptor; PCSK1=proprotein
            convertase subtilisin/kexin type 1; POMC=proopiomelanocortin.
          </p>
          <p className="footnote" style={{ fontSize: 12 }}>
            <b>References: 1.</b> IMCIVREE [prescribing information]. Boston,
            MA. Rhythm Pharmaceuticals, Inc. <b>2.</b> Eneli I et al.{" "}
            <em>Appl Clin Genet.</em> 2019;12:87-93.
          </p>
        </div>
      </div>
    </div>
  )
}

export default GatewayHero
