import React from 'react'


const HowToInjectTranscriptEs = (props) => {

  return (
    <>

      <p>¡Hola! Soy Ethan y hoy voy a demostrar cómo inyectar IMCIVREE.</p>

      <p>Ya sea que se esté inyectando o que inyecte a una persona a la que cuida, este video puede ayudar a reforzar lo que aprendió de su médico.</p>

      <p>Espero que este video sea útil, pero si tiene alguna pregunta sobre su tratamiento, siempre consulte a su médico.
      Y no olvide que su primer envío de IMCIVREE viene con una guía de capacitación para inyecciones, que explica cómo administrar el medicamento.</p>

      <p>Incluye instrucciones detalladas paso a paso, así que asegúrese de tenerla mano.</p>

      <p>El programa de apoyo  ofrecido por Rhythm Pharmaceuticals también está aquí para ayudar a orientarlo en la dirección correcta</p>

      <p>Cuando empecé a usar IMCIVREE, mi enfermero me enseñó a inyectarme.</p>

      <p>Al principio, necesitaba ayuda de mi madre, pero ahora me inyecto solo, y es parte de mi rutina matutina diaria.
      Muy bien, empecemos.</p>

      <p>Cuando esté listo para inyectar, saque el vial de la caja y deje que llegue a temperatura ambiente.</p>

      <p>En general, esto tarda 15 minutos, pero si tiene prisa, puede calentar el vial haciéndolo rodar suavemente entre las palmas de las manos durante un minuto.</p>

      <p>El mío ya está casi listo.</p>

      <p>No use el microondas ni agua caliente, y tenga cuidado de no agitar el vial.</p>

      <p>Mientras espera que el medicamento llegue a temperatura ambiente, aproveche ese tiempo para reunir los suministros y colocarlos sobre una superficie de trabajo limpia y plana.</p>

      <p>Necesitará el vial de IMCIVREE, una jeringa de 1 mililitro con una aguja calibre 28 o 29, 2 toallitas con alcohol, una gasa y un recipiente para objetos punzantes.</p>

      <p>Si no está seguro de tener todo lo que necesita, asegúrese de consultar la capacitación para inyecciones incluida en la caja con su primer envío de IMCIVREE.</p>

      <p>También puede descargar una copia en IMCIVREE.com.</p>

      <p>Recuerde inspeccionar todos los suministros antes de empezar, incluida la fecha de vencimiento en la etiqueta del vial.</p>

      <p>En el vial habrá aire y medicamento, pero la mayor parte del vial estará lleno de aire.</p>

      <p>El líquido del vial debe ser transparente o casi transparente, pero puede tener un color levemente amarillo.</p>

      <p>No debe haber partículas.</p>

      <p>No lo use si pasó la fecha de vencimiento, si el líquido está turbio, si hay partículas flotando en el vial o si la tapa de plástico de un vial nuevo está rota o falta.</p>

      <p>Use solamente las jeringas con agujas que le haya dado su médico o farmacia, y use siempre una nueva jeringa para evitar la contaminación.</p>

      <p>Nunca reutilice o comparta las agujas con otras personas.</p>

      <p>OK, ya verifiqué mis suministros y la fecha de vencimiento del vial, y todo parece estar bien.</p>

      <p>Por supuesto. antes de empezar, tengo que lavarme las manos con jabón y agua tibia.</p>

      <p>Ahora estoy listo para empezar.</p>

      <p>Primero, voy a retirar la tapa de plástico y tirarla a la basura.</p>

      <p>No vuelva a colocar la tapa de plástico en el vial.</p>

      <p>Luego, limpiaré la parte superior del tapón de goma con una toallita con alcohol y tiraré la toallita.</p>

      <p>No retire el tapón de goma del vial.</p>

      <p>Recuerde que su médico le dirá cuál es la dosis adecuada.</p>

      <p>Cada vial contiene suficiente medicamento para más de una dosis administrada.</p>

      <p>El número de dosis administradas en cada vial dependerá de su régimen de dosificaión específico.</p>

      <p>A continuación, le mostraré cómo preparar la jeringa.</p>

      <p>Esto puede parecer nuevo al principio, pero se sentirá mucho más cómodo cuando lo haya hecho varias veces.</p>

      <p>Cuando mida su dosis, asegúrese de leer las marcas desde el extremo más cercano al pistón de goma negra.</p>

      <p>Deberá llenar la jeringa de aire.</p>

      <p>Con la tapa protectora de la aguja puesta, tire del émbolo y llene la jeringa con una cantidad de aire igual a la cantidad de medicamento que necesita.</p>

      <p>Tómese su tiempo y asegúrese de tirar hacia atrás hasta llegar a la misma cantidad de la dosis recetada.</p>

      <p>Cuando haya terminado, retire la tapa protectora de la aguja de forma recta y lejos del cuerpo.</p>

      <p>Tire la tapa a la basura.</p>

      <p>Luego, deberá insertar la aguja en el vial.</p>

      <p>Coloque el vial sobre la superficie limpia y plana que preparó antes con los suministros de inyecciones.</p>

      <p>Después, coloque la jeringa directamente sobre el vial e inserte la aguja de forma recta en el centro del tapón de goma del vial.</p>

      <p>Finalmente, empuje el émbolo hacia abajo para introducir todo el aire de la jeringa en el vial.</p>

      <p>Ahora, llenaré la jeringa con IMCIVREE.</p>

      <p>Para hacer esto, hay que dar vuelta el vial lentamente, manteniendo la aguja dentro del vial.,</p>

      <p>Es importante asegurarse de que la punta de la aguja esté siempre totalmente cubierta por el medicamento.</p>

      <p>Luego, tire lentamente del émbolo para llenar la jeringa con la cantidad de IMCIVREE necesaria para la dosis recetada.</p>

      <p>Tenga cuidado de no sacar el émbolo fuera de la jeringa.</p>

      <p>Si el vial no tiene suficiente medicamento para su dosis, deséchelo y abra uno nuevo.</p>

      <p>No use medicamentos de dos viales diferentes para hacer una dosis.</p>

      <p>Antes de continuar, tendré que verificar si hay burbujas de aire.</p>

      <p>Con la aguja todavía en el vial, observe la jeringa y vea si nota alguna burbuja de aire grande.</p>

      <p>Si ve alguna, golpee suavemente el costado de la jeringa con los dedos, de este modo, hasta que las burbujas suban a la parte superior.</p>

      <p>Mueva la punta de la aguja por encima del medicamento y empuje lentamente el émbolo hacia arriba para volver a llevar las burbujas grandes al vial.</p>

      <p>Después de eliminar las burbujas de aire grandes, tire del émbolo otra vez para llenar la jeringa con la dosis recetada de medicamento.</p>

      <p>Si lo tira más lentamente esta vez, puede reducir la probabilidad de que se formen burbujas.</p>

      <p>¡OK, ya está!</p>

      <p>Ahora es el momento de sacar la jeringa del vial.</p>

      <p>Para ello, voy a volver a colocar el vial sobre la superficie de trabajo limpia y plana, sujetando el vial con una mano y el cilindro de la jeringa entre los dedos de la otra.</p>

      <p>Luego, puedo retirar la aguja del vial de forma recta y colocar la jeringa suavemente de costado.</p>

      <p>Tenga cuidado de que la aguja no toque la superficie de trabajo y no vuelva a colocar la tapa de la aguja.</p>

      <p>Ha llegado el momento de preparar el lugar de inyección.</p>

      <p>Hoy voy a inyectarme IMCIVREE en el vientre.</p>

      <p>Puede decidir cuál será el lugar exacto, pero debe estar al menos a 2 pulgadas del ombligo.</p>

      <p>Debe evitar el ombligo, las costillas y los huesos de la cadera, así como las cicatrices o lunares.</p>

      <p>Y, por supuesto, nunca inyecte en un área que esté roja, hinchada o irritada.</p>

      <p>También puede inyectar en la parte delantera del muslo o en la parte posterior de la parte superior del brazo.</p>

      <p>Aunque hoy elegí el estómago, es importante usar un lugar diferente cada día, al menos a 1 pulgada de distancia del que se usó previamente.</p>

      <p>Antes de inyectar el medicamento, tendrá que limpiar el lugar de inyección.</p>

      <p>Frote suavemente el área con la otra toallita con alcohol en un movimiento circular y espere unos 10 segundos a que la piel se seque.</p>

      <p>No la toque, abanique ni sople.</p>

      <p>Ahora estoy listo para inyectar IMCIVREE.</p>

      <p>Tomaré y pellizcaré aproximadamente 2 pulgadas de piel entre el pulgar y el dedo índice en el lugar de inyección.</p>

      <p>Esto ayuda a asegurar que el medicamento se inyecte en el tejido debajo de la piel, ya que nunca debe inyectar IMCIVREE en una vena o un músculo.</p>

      <p>Con la otra mano, sujeto la jeringa con el pulgar y el dedo índice.</p>

      <p>Ahora sujete la jeringa por el medio, en un ángulo de 90 grados con respecto al cuerpo, y empuje la aguja directamente en el lugar de inyección, asegurándose de introducir toda la aguja en la piel.</p>

      <p>Es importante que se asegure de no sujetar o empujar el émbolo mientras inserta la aguja.</p>

      <p>Solo cuando la aguja esté insertada en la piel, empujará lentamente el émbolo para inyectar el medicamento.</p>

      <p>Cuente hasta 5 para asegurarse de inyectar todo el medicamento.</p>

      <p>Una vez hecho esto, suelte la piel pellizcada, saque la aguja y deséchela en un recipiente para objetos punzantes.</p>

      <p>No vuelva a tapar la aguja antes de desecharla.</p>

      <p>Finalmente, puede usar la gasa para presión en el lugar de inyección.</p>

      <p>OK, eso es todo. ¡Ya se aplicó la inyección!</p>

      <p>Buen trabajo.</p>

      <p>Aunque ahora puedo inyectarme solo después de capacitarme con mi enfermero, mi madre debía ayudarme cuando era más joven.</p>

      <p>Ella me pidió que pasara estos consejos a las personas que están ayudando a alguien a inyectar IMCIVREE.</p>

      <p>Si es cuidador y necesita inyectar a un niño, estas son algunas sugerencias para que parezca menos aterrador.</p>

      <p>Pídale al niño que apriete algo blando, como su animal de peluche favorito.</p>

      <p>También puede pedirle que se concentre en otra cosa, como en respirar lentamente.</p>

      <p>Por último, puede distraer al niño pidiéndole que cante una canción, tararee, cuente o recite sus colores o animales favoritos.</p>

      <p>Está bien.</p>

      <p>Mañana a esta hora, volveré a inyectarme.</p>

      <p>Para ayudarme a recodar que lugar usé, lo anoto en mi teléfono.</p>

      <p>Algunas personas prefieren anotarlo en un calendario y eso también funciona.</p>

      <p>También anoto la fecha, la hora y la información del lote del vial por si lo necesito para mi médico en algún momento en el futuro.</p>

      <p>El último paso es almacenar el medicamento para la dosis de mañana.</p>

      <p>Recuerde que cada vial contiene suficiente medicamento para más de una dosis.</p>

      <p>Una vez abierto un vial, no vuelva a colocar la tapa de plástico.</p>

      <p>Vuelva a colocar el vial en el envase original para protegerlo de la luz y guárdelo en el refrigerador.</p>

      <p>Si es necesario, puede sacar los viales del refrigerador y almacenarlos a temperatura ambiente durante un máximo de 30 días, pero nunca</p>

      <p>También puede almacenarlos brevemente a una temperatura de hasta 86 grados.</p>

      <p>Si tiene algún vial a temperatura ambiente, puede volver a guardarlo en el refrigerador.</p>

      <p>Es buena idea anotar en la caja la fecha en que abrió el vial por primera vez para asegurarse de que no hayan pasado más de 30 días.</p>

      <p>Una vez abierto el vial, debe desecharlo después de 30 días, aunque quede medicamento en el vial.</p>

      <p>También debe desechar los viales que hayan pasado su fecha de vencimiento, que se hayan almacenado a temperaturas superiores a 86 grados Fahrenheit o que hayan estado fuera del refrigerador durante más de 30 días, aunque sea antes de la fecha de vencimiento.</p>

      <p>Algunos consejos finales.</p>

      <p>Deseche siempre las jeringas usadas con agujas inmediatamente después de usarlas en un recipiente resistente a perforaciones, como su recipiente para objetos punzantes; pero otros suministros, como las toallitas con alcohol, la gasa usada y los viales, pueden ir a la basura.</p>

      <p>Asegúrese de no tirar nunca agujas usadas a la basura o líquidos al recipiente para objetos punzantes.</p>

      <p>Una vez que el recipiente para objetos punzantes esté lleno, tendrá que desecharlo.</p>

      <p>El sitio web de la FDA puede ser útil, ya que las reglas sobre dónde y cómo hacerlo pueden variar según dónde viva.</p>

      <p>Sé que acostumbrarse a una rutina nueva puede llevar algo de tiempo, pero manténgala y vuelva a ver este video siempre que necesite un recordatorio.</p>

      <p>Si tiene alguna pregunta, recuerde hablar siempre con su médico.</p>

    </>
  )
}

export default HowToInjectTranscriptEs
