import React from 'react'
import vpl from "../images/video-placeholder.jpg";

const BbsDiagnoseVideo = (props) => {
  return (
    <>
    <div className="row og">
      <div className={`columns og responsive-video-container ` + props.className}>
        <iframe src="https://player.vimeo.com/video/882287699?h=558b8752d7" width="100%" height="auto" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen title="FPO Video"></iframe>
        <img src={vpl} width="100%" height="auto" alt="FPO Video"/>
      </div>
    </div>
    </>
  )
}

export default BbsDiagnoseVideo