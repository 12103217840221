/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, {useEffect, useState} from "react";
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

import ogImage from "../images/1200x630_150.jpg";

function Seo({ description, lang, meta, title, page, ogTitle, ogDesc }) {

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const defaultTitle = ''
  const [windowURL, setWindowURL] = useState('');

  const schema = {
    "@context": "https://schema.org",
    "@type": "VideoObject",
    "name": "Systemic Mastocytosis and the Central Role of the KIT D816V Mutation",
    "description": "A brief overview of systemic mastocytosis with emphasis on the central role of KIT D816V in the uncontrolled proliferation of abnormal mast cells",
    "thumbnailUrl": "https://i.vimeocdn.com/video/1519702840-c9cbede43b79f5ae0165349a0854c3afe070dc5e7a64729f1067829482655fd7-d.jpg",
    "uploadDate": "2022-10-24T00:00:00-05:00",
    "duration": "PT2M38S",
    "contentUrl": "https://player.vimeo.com/video/756507597?h=919ad85668&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    "embedURL": "https://player.vimeo.com/video/756507597?h=919ad85668"
  }

  useEffect(()=>{
    if(typeof window !== 'undefined'){
      setWindowURL(window.location.href);
    }
  },[]);

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
    >
      <meta property="og:title" key="og:title" content={ogTitle} />
      <meta name="description" content={metaDescription} />
      <meta name="og:description" key="og:description" content={ogDesc} />
      <meta property="og:type" key="og:type" content="website" />
      <meta property="og:site_name" key="og:site_name" content="IMCIVREE® (setmelanotide) injection" />
      <meta property="og:image" key="og:image" content={ogImage} />
      <meta property="og:locale" key="og:locale" content="en_US" />
      <meta name="google-site-verification" content="Vu2EOEhlGBGQAaK08_FNRYQj2sddzMM8aD42eH6tX3E" />
      <meta charSet="utf-8" />
      <link rel='alternate' hrefLang="en-us" href={windowURL} />
      <link rel="canonical" href={windowURL} />
      {/* swap on ifs use the page variable */}
      <script type="application/ld+json">{JSON.stringify(schema)}</script>
    </Helmet>
  )
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: `Learn about IMCIVREE® (setmelanotide) injection and the condition that it treats. Please see full Prescribing Information and Important Safety Information.`,
  page: 'home'
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  page: PropTypes.string.isRequired
}

export default Seo
