import React from "react"
import LineItem from "./template-partials/global-components/list-item"

const AdditionalClinicalFeaturesTable = props => {
  return (
    <table
      id="additional-clinical-features-table"
      cellPadding={"0"}
      cellSpacing={"0"}
    >
      <thead>
        <tr>
          <th>Additional clinical features</th>
          <th>Clincial manifestations</th>
          <th>Potential assessments</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Dental anomalies</td>
          <td>
            <div className="row">
              <div className="columns">
                <ul className="ul--standard">
                  {[
                    "Crowding",
                    "Malocclusion/micrognathia",
                    "Enamel hypoplasia",
                    "Discoloration",
                  ].map(item => {
                    return <LineItem copy={item} />
                  })}
                </ul>
              </div>
              <div className="columns">
                <ul className="ul--standard">
                  {[
                    "Microdontia",
                    "Taurodontism or short roots",
                    "High-arched or deep palate",
                    "Periodontal disease",
                  ].map(item => {
                    return <LineItem copy={item} />
                  })}
                </ul>
              </div>
            </div>
          </td>
          <td>
            <ul className="ul--standard">
              {["Dental exam"].map(item => {
                return <LineItem copy={item} />
              })}
            </ul>
          </td>
        </tr>

        <tr>
          <td>Congenital heart disease</td>
          <td>
            <ul className="ul--standard">
              {[
                "Valvular stenosis",
                "Patent ductus arteriosus",
                "Cardiomyopathy",
              ].map(item => {
                return <LineItem copy={item} />
              })}
            </ul>
          </td>
          <td>
            <ul className="ul--standard">
              {["Echocardiogram, chest x-ray"].map(item => {
                return <LineItem copy={item} />
              })}
            </ul>
          </td>
        </tr>

        <tr>
          <td>Speech delay</td>
          <td>
            <div className="row">
              <div className="columns">
                <ul className="ul--standard">
                  {[
                    "High-pitched nasal speech",
                    "Speech delay and deﬁcits",
                    "Unintelligible speech",
                  ].map(item => {
                    return <LineItem copy={item} />
                  })}
                </ul>
              </div>
            </div>
          </td>
          <td>
            <ul className="ul--standard">
              {[
                "Assessments, such as Ages and Stages Questionnaires, the Language Development Survey, and the MacArthur-Bates Communicative Development Inventories ",
              ].map(item => {
                return <LineItem copy={item} />
              })}
            </ul>
          </td>
        </tr>

        <tr>
          <td>Neurological deﬁcits</td>
          <td>
            <div className="row">
              <div className="columns">
                <ul className="ul--standard">
                  {[
                    "Ataxia",
                    "Clumsiness",
                    "Poor coordination and balance",
                  ].map(item => {
                    return <LineItem copy={item} />
                  })}
                </ul>
              </div>
            </div>
          </td>
          <td>
            <ul className="ul--standard">
              {["MRI"].map(item => {
                return <LineItem copy={item} />
              })}
            </ul>
          </td>
        </tr>

      </tbody>
      <tfoot>
        <tr>
          <td colSpan={3}>
          <p className="footnote">CKD=chronic kidney disease; MRI=magnetic resonance imaging.</p>
          <div class="references fw-normal md_mt-1 mt-1"><strong>References: 1.</strong> Lorem ipsum dolor sit amet. <strong>2.</strong> Condimentum lacinia quis vel eros donec ac odio tempor orci.  <strong>3.</strong> Dolor sit amet consectetur adipiscing elit ut. Nisi lacus sed viverra tellus in hac.</div>
          </td>
        </tr>
      </tfoot>
    </table>
    
  )
}

export default AdditionalClinicalFeaturesTable
