import React from 'react'

const IconList = ({ icon, iconAlt, copyBlack, copy }) => {
  return (
    <div className="icon-list text-center">
      <img src={icon} alt={iconAlt} />
      <p className={`${copyBlack ? "color-black" : ""}`}>{copy}</p>
    </div>
  )
}

export default IconList