import * as React from "react"
import { Link } from "gatsby"

import Layout from "../../../components/hcp-ppl-layout"
import Seo from "../../../components/seo"

const Sitemap = () => (
  <Layout noNav>
    <Seo
      title="Sitemap | IMCIVREE® (setmelanotide) injection | For HCPs"
      description="Explore the IMCIVREE® (setmelanotide) sitemap."
      ogTitle="Sitemap | IMCIVREE® (setmelanotide) injection"
      ogDesc="Explore the IMCIVREE® (setmelanotide) sitemap."
    />
    <div className="sitemap content-block">
      <h1 className="h1">Sitemap</h1>
      <ul style={{ marginLeft: '0.3rem' }}>
        <li><Link to="/hcp/ppl/">Home</Link></li>
        <li className="md_mt-1 mt-1 mb-1 md_mb-1">Diseases Overview
          <ul>
            <li><Link to="/hcp/ppl/overview/">Diseases overview</Link></li>
            <li><Link to="/hcp/ppl/types-of-obesity/">Types of obesity</Link></li>
            <li><Link to="/hcp/ppl/identifying-hyperphagia/">Identifying hyperphagia</Link></li>
          </ul>
        </li>
        <li><Link to="/hcp/ppl/moa/">Mechanism of Action</Link></li>
        <li className="lg_mt-1 mt-1">Efficacy
          <ul>
            <li><Link to="/hcp/ppl/study-design/">Study design</Link></li>
            <li><Link to="/hcp/ppl/weight-reduction/">Weight reduction</Link></li>
            <li><Link to="/hcp/ppl/hunger-reduction/">Hunger reduction</Link></li>
          </ul>
        </li>
        <li className="lg_mt-1 mt-1"><Link to="/hcp/ppl/safety-profile/">Safety Profile</Link></li>
        <li><Link to="/hcp/ppl/dosing-administration/">Dosing &amp; Administration</Link></li>
        <li><Link to="/hcp/ppl/prescribing-imcivree/">Prescribing IMCIVREE</Link></li>
        <li className="lg_mt-1 mt-1"><span>Patient Support &amp; Resources</span>
          <ul>
            <li><Link to="/hcp/ppl/patient-support/">Patient support</Link></li>
            <li><Link to="/hcp/ppl/patient-resources/">Downloadable resources</Link></li>
            <li><Link to="/hcp/ppl/faq/">Frequently asked questions</Link></li>
          </ul>
        </li>
      </ul>
    </div>
  </Layout>
)

export default Sitemap
